/* eslint react/prefer-stateless-function: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import { flattenDeep } from 'lodash'
import { Icon, IconButton, Popover } from '@mui/material'
import Grid from 'grid'
import { plainDeepEqual } from 'utils'
import { setField } from 'ddiForm/actions'
import DDITextField from 'ddiForm/wrapped/DDITextField'
import WebCatCheckbox from './components/WebCatCheckbox'
import DeleteButton from './components/DeleteButton'
import './styles/master-detail-grid-dropdown.scss'
import { dragCategory, setSelectedCategory } from './actions'

const isRowMaster = row => {
  return !!(row.children && Array.isArray(row.children) && row.children.length)
}

export const getSelectedCategory = (partId, uniqueRowId, acc, next) => {
  if (next[uniqueRowId] === partId) {
    acc = acc.concat(next)
  } else if (next.children) {
    acc = acc.concat(
      next.children.reduce(
        (a, n) => getSelectedCategory(partId, uniqueRowId, a, n),
        []
      )
    )
  }
  return acc
}

/* ref: https://stackoverflow.com/questions/62613338/react-ag-grid-identifying-overnode-when-dragging-grid-to-grid */
const getAgGridNodeBeingDraggedOver = (
  event,
  rowData = [],
  uniqueRowId = ''
) => {
  const elements = document.elementsFromPoint(event.clientX, event.clientY)
  const agGridRow = elements.find(r => r.classList.contains('ag-row'))

  if (agGridRow) {
    const dropTargetId = agGridRow.getAttribute('row-id')
    const targetNode = rowData.reduce(
      (acc, next) => getSelectedCategory(dropTargetId, uniqueRowId, acc, next),
      []
    )

    return targetNode
  }

  return null
}

const dynamicExpanderRow = memoize(
  (
    form,
    uniqueRowId = 'recordName',
    propertyName,
    closePopoverCallbackFn,
    isStandaloneInterface = false,
    includeDeleteButton = false,
    isEditing = false
  ) => {
    const baseCols = [
      {
        field: uniqueRowId,
        headerName: '',
        cellClass: 'cell-value-hidden',
        cellRendererSelector: params => {
          if (params.data.children && params.data.children.length) {
            return {
              component: 'agGroupCellRenderer',
              params: {
                form,
                propertyName,
                closePopoverCallbackFn
              }
            }
          }

          return {
            frameworkComponent: WebCatCheckbox,
            params: {
              form,
              propertyName,
              closePopoverCallbackFn,
              uniqueRowId
            }
          }
        },
        cellRendererParams: {
          form,
          propertyName,
          closePopoverCallbackFn
        },
        filter: false,
        minWidth: 38,
        maxWidth: 38,
        width: 38
      },
      {
        field: '',
        headerName: '',
        valueGetter: "'Drag'",
        rowDrag: true,
        maxWidth: 35,
        minWidth: 35,
        width: 35,
        hide: !isStandaloneInterface
      },
      {
        field: 'dataId',
        headerName: 'Code'
      },
      {
        field: 'description',
        headerName: 'Name'
      }
    ]

    // if (isStandaloneInterface && isEditing) {
    //   baseCols = [
    //     ...baseCols
    //   ]
    // }

    if (includeDeleteButton) {
      return [
        ...baseCols,
        {
          field: '',
          headerName: '',
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            form,
            propertyName,
            closePopoverCallbackFn,
            isEditing,
            uniqueRowId
          },
          width: 35,
          maxWidth: 35,
          minWidth: 35
        }
      ]
    }

    return baseCols
  }
)

const getAvailableSelections = (rowData, id) =>
  rowData && rowData.length
    ? rowData.reduce((acc, next, idx) => {
        if (next.children && next.children.length) {
          acc = acc.concat(...getAvailableSelections(next.children, id))
        }
        if (next.children && !next.children.length) {
          acc = acc.concat(next[id])
        }
        return acc
      }, [])
    : []

class MasterDetailGridDropdown extends Component {
  static propTypes = {
    dispatch: PropTypes.bool.isRequired,
    expandOnLoad: PropTypes.bool,
    getRowNodeId: PropTypes.func,
    hasRecord: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isStandaloneInterface: PropTypes.bool,
    includeDeleteButton: PropTypes.bool,
    label: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    rowData: PropTypes.array.isRequired,
    rowSelection: PropTypes.string,
    suppressCellSelection: PropTypes.bool,
    uniqueRowId: PropTypes.string,
    value: PropTypes.string
  }

  static defaultProps = {
    expandOnLoad: false,
    getRowNodeId: data => data.recordName,
    includeDeleteButton: false,
    isStandaloneInterface: false,
    rowSelection: '',
    suppressCellSelection: true,
    uniqueRowId: 'recordName',
    value: ''
  }

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      availableSelections: getAvailableSelections(
        props.rowData,
        props.uniqueRowId
      ),
      expandedRows: [],
      popoverOpen: false,
      rowData: props.rowData,
      value: props.value || ''
    }

    this._isMounted = false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!plainDeepEqual(nextProps.rowData, prevState.rowData)) {
      return {
        rowData: nextProps.rowData,
        availableSelections: getAvailableSelections(
          nextProps.rowData,
          nextProps.uniqueRowId
        )
      }
    }

    if (nextProps.value !== prevState.value) {
      return {
        value: nextProps.value
      }
    }

    return null
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // if (this.props.isStandaloneInterface) {
  //   //   if (this.props.isEditing !== nextProps.isEditing) {
  //   //     return true
  //   //   }

  //   //   if (
  //   //     !plainDeepEqual(this.props.rowData, nextProps.rowData) &&
  //   //     !plainDeepEqual(this.props.propChangeData, nextProps.propChangeData) &&
  //   //     nextProps?.propChangeData?.propertyName
  //   //   ) {
  //   //     /*
  //   //       do not perform an update if we are dealing with a singular property change event.
  //   //       That is handled externally via refs -- SVE 11/2/2021
  //   //     */
  //   //     return false
  //   //   }

  //   //   return (
  //   //     Object.keys(nextProps).some(
  //   //       prop => !plainDeepEqual(this.props[prop], nextProps[prop])
  //   //     ) || !plainDeepEqual(this.state, nextState)
  //   //   )
  //   // }

  //   return (
  //     Object.keys(nextProps).some(
  //       prop => !plainDeepEqual(this.props[prop], nextProps[prop])
  //     ) || !plainDeepEqual(this.state, nextState)
  //   )
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.props.value) {
      /*
        if the user has manually edited the text field
        and left an invalid value, clear it out
      */
      if (!this.state.availableSelections.includes(this.props.value)) {
        this.props.dispatch(
          setField(this.props.form, this.props.propertyName, '')
        )
      }
    }
  }

  openPopover = event => {
    this.setState(
      {
        popoverOpen: true
      },
      () => {
        if (this.gridApi && this.props.expandOnLoad) {
          setTimeout(() => {
            this.gridApi.expandAll()
          }, 0)
        }
      }
    )
  }

  closePopover = () => {
    if (!this.props.isStandaloneInterface) {
      this.setState(
        {
          popoverOpen: false
        },
        () => {
          if (this.gridApi && this.props.expandOnLoad) {
            setTimeout(() => {
              this.gridApi.collapseAll()
            }, 0)
          }
        }
      )
    }
  }

  buildGridConfiguration = memoize(
    (rowData = [], includeDeleteButton = false, isEditing = false) => {
      const makeParams = collection => {
        const ret =
          collection && collection.length
            ? collection.reduce((acc, next, index) => {
                if (next.children && next.children.length) {
                  acc.detailGridOptions = {
                    ...acc.detailGridOptions,
                    columnDefs: dynamicExpanderRow(
                      this.props.form,
                      this.props.uniqueRowId,
                      this.props.propertyName,
                      this.closePopover,
                      this.props.isStandaloneInterface,
                      includeDeleteButton,
                      isEditing
                    ),
                    onRowDragEnd: this.onRowDragEnd,
                    rowDragManaged: true,
                    suppressMoveWhenRowDragging: true,
                    isRowMaster,
                    components: {
                      webCatCheckbox: WebCatCheckbox
                    },
                    masterDetail: true,
                    reactUi: false,
                    getRowNodeId: this.props.getRowNodeId,
                    rowSelection: this.props.rowSelection,
                    onRowSelected: this.onRowSelected,
                    suppressCellSelection: this.props.suppressCellSelection,
                    detailRowAutoHeight: true,
                    onRowGroupOpened: this.onRowGroupOpened,
                    onRowDataChanged: this.onRowDataChanged,
                    onGridReady: params => {
                      params.api.sizeColumnsToFit()
                      this.addGridDropZone(params)

                      if (this.props.expandOnLoad) {
                        setTimeout(() => {
                          params.api.forEachNode(node => {
                            node.setExpanded(true)
                          })
                        }, 0)
                      }
                    },
                    detailCellRendererParams: makeParams(next.children)
                  }
                  acc.getDetailRowData = params => {
                    params.successCallback(params.data.children)
                  }
                } else {
                  acc.detailGridOptions = {
                    ...acc.detailGridOptions,
                    getRowNodeId: this.props.getRowNodeId,
                    rowSelection: this.props.rowSelection,
                    onRowSelected: this.onRowSelected,
                    suppressCellSelection: this.props.suppressCellSelection,
                    reactUi: false,
                    detailRowAutoHeight: true,
                    components: {
                      webCatCheckbox: WebCatCheckbox
                    },
                    columnDefs: dynamicExpanderRow(
                      this.props.form,
                      this.props.uniqueRowId,
                      this.props.propertyName,
                      this.closePopover,
                      this.props.isStandaloneInterface,
                      includeDeleteButton,
                      isEditing
                    ),
                    onRowDragEnd: this.onRowDragEnd,
                    rowDragManaged: true,
                    suppressMoveWhenRowDragging: true,
                    // onRowGroupOpened: this.onRowGroupOpened,
                    onRowDataChanged: this.onRowDataChanged,
                    onGridReady: params => {
                      params.api.sizeColumnsToFit()
                      this.addGridDropZone(params)
                    },
                    isRowMaster
                  }
                  acc.getDetailRowData = params =>
                    params.successCallback(params.data.children)
                }
                return acc
              }, {})
            : {}

        return ret
      }

      const newConfig = {
        rowData,
        columnDefs: dynamicExpanderRow(
          this.props.form,
          this.props.uniqueRowId,
          this.props.propertyName,
          this.closePopover,
          this.props.isStandaloneInterface,
          this.props.includeDeleteButton,
          this.props.isEditing
        ),
        onRowDragEnd: this.onRowDragEnd,
        rowDragManaged: false,
        // suppressMoveWhenRowDragging: true,
        isRowMaster,
        components: {
          webCatCheckbox: WebCatCheckbox
        },
        masterDetail: true,
        reactUi: false,
        getRowNodeId: this.props.getRowNodeId,
        rowSelection: this.props.rowSelection,
        onRowSelected: this.onRowSelected,
        suppressCellSelection: this.props.suppressCellSelection,
        onRowGroupOpened: this.onRowGroupOpened,
        onRowDataChanged: this.onRowDataChanged,
        onGridReady: this.onGridReady,
        domLayout: 'autoHeight',
        detailRowAutoHeight: true,
        detailCellRendererParams: makeParams(rowData[0]?.children)
      }

      return newConfig
    }
  )

  onRowSelected = params => {
    const { uniqueRowId } = this.props
    const selectedCategoryId = params?.data?.[uniqueRowId] || null
    if (
      // selectedCategoryId &&
      params.type === 'rowSelected' &&
      params?.node?.selected &&
      this?.masterDetailGridDropdown?.api &&
      this._isMounted
    ) {
      this.masterDetailGridDropdown.api.forEachNode(node => {
        if (
          (node?.data?.[uniqueRowId] &&
            node.data[uniqueRowId] !== selectedCategoryId) ||
          (!node?.data?.[uniqueRowId] && selectedCategoryId)
        ) {
          node.setSelected(false)
        }

        if (node?.beans?.gridApi?.forEachDetailGridInfo) {
          node.beans.gridApi.forEachDetailGridInfo(info => {
            if (info?.api?.forEachNode) {
              info.api.forEachNode(n => {
                if (
                  (n?.data?.[uniqueRowId] &&
                    n.data[uniqueRowId] !== selectedCategoryId) ||
                  (!n?.data?.[uniqueRowId] && selectedCategoryId)
                ) {
                  n.setSelected(false)
                }

                if (n?.detailNode?.detailGridInfo?.api) {
                  n.detailNode.detailGridInfo.api.forEachNode(x => {
                    if (
                      (x?.data?.[uniqueRowId] &&
                        x.data[uniqueRowId] !== selectedCategoryId) ||
                      (!x?.data?.[uniqueRowId] && selectedCategoryId)
                    ) {
                      x.setSelected(false)
                    }
                  })
                }
              })
            }
          })
        }
      })

      this.props.dispatch(
        setSelectedCategory(this.props.form, {
          selectedId: selectedCategoryId
        })
      )
    }
  }

  onRowDragEnd = params => {
    const { dispatch, isEditing, form, uniqueRowId, rowData } = this.props

    if (params?.type && params.type === 'rowDragEnd') {
      let dropTarget = getAgGridNodeBeingDraggedOver(
        params.event,
        rowData,
        uniqueRowId
      )

      const draggedItem = params?.node?.data
      dropTarget = dropTarget?.[0] ? dropTarget[0] : {}

      if (isEditing && draggedItem && dropTarget) {
        dispatch(
          dragCategory(form, {
            draggedItem,
            dropTarget
          })
        )
      }
    }
  }

  onRowGroupOpened = params => {
    const { selectedId, uniqueRowId } = this.props
    const toggledRow = params?.data?.[uniqueRowId]

    if (params?.node?.expanded) {
      this.setState(prevState => {
        const { expandedRows } = prevState

        return {
          expandedRows: toggledRow
            ? [...expandedRows, toggledRow]
            : [...expandedRows]
        }
      })

      // params.api.forEachNode(node => {
      //   if (node?.data?.[uniqueRowId] === null) {
      //     node.setSelected(true)
      //   }
      // })
    } else {
      this.setState(prevState => {
        const { expandedRows } = prevState

        return {
          expandedRows: expandedRows.filter(x => x !== toggledRow)
        }
      })
    }
  }

  onRowDataChanged = params => {
    if (this._isMounted) {
      setTimeout(() => {
        const { selectedId, uniqueRowId } = this.props
        params.api.forEachNode(node => {
          if (
            (node?.data?.[uniqueRowId] &&
              this.state.expandedRows.includes(node.data[uniqueRowId])) ||
            (node?.data?.children &&
              Array.isArray(node.data.children) &&
              node.data.children.some(x => x?.isNew))
          ) {
            node.setExpanded(true)
          }

          if (
            (node?.data?.[uniqueRowId] &&
              selectedId &&
              node.data[uniqueRowId] === selectedId) ||
            (node?.data?.isNew && !node?.data?.dataId)
          ) {
            node.setSelected(true)
          }
        })
      }, 0)
    }
  }

  onGridReady = params => {
    const { expandOnLoad } = this.props
    this.gridApi = params.api
    this.columnApi = params.columnApi
    // this.addGridDropZone(params)
    // debugger
    if (expandOnLoad) {
      setTimeout(() => {
        this.gridApi.forEachNode(node => {
          node.setExpanded(true)
        })
      }, 0)
    }
  }

  addGridDropZone = params => {
    // debugger
    /* https://www.ag-grid.com/javascript-data-grid/row-dragging-to-external-dropzone/ */
    const grid = document.querySelector('.web-options-cat-dropdown')
    const dropZone = {
      getContainer: () => grid,
      onDragStop: this.onRowDragEnd
    }

    params.api.addRowDropZone(dropZone)
  }

  render() {
    const { rowData, isStandaloneInterface } = this.props
    const {
      hasRecord,
      includeDeleteButton,
      isEditing,
      label,
      propertyName
    } = this.props
    const { popoverOpen, value } = this.state
    const buttonDisabled = !(hasRecord && isEditing)
    // const open = Boolean(anchorEl)

    const gridData = this.buildGridConfiguration(
      rowData,
      includeDeleteButton,
      isEditing
    )

    if (isStandaloneInterface) {
      return (
        <div className="web-options-cat-dropdown" style={{ width: '100%' }}>
          <Grid
            {...gridData}
            reactUi={false}
            ref={el => (this.masterDetailGridDropdown = el)}
          />
        </div>
      )
    }

    return (
      <div>
        <div
          ref={el => (this.dropdown = el)}
          style={{ position: 'relative', width: '100%' }}
        >
          <DDITextField
            label={label}
            propertyName={propertyName}
            value={value}
          />
          <IconButton
            disabled={buttonDisabled}
            onClick={this.openPopover}
            style={{
              position: 'absolute',
              right: -10,
              top: 5
            }}
          >
            <Icon style={{ color: '#444' }}>arrow_drop_down</Icon>
          </IconButton>
        </div>
        <Popover
          id="master-detail-grid-popover"
          open={popoverOpen}
          anchorEl={this.dropdown}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div className="web-options-cat-dropdown" style={{ width: 600 }}>
            <Grid
              {...gridData}
              reactUi={false}
              ref={el => (this.masterDetailGridDropdown = el)}
            />
          </div>
        </Popover>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(MasterDetailGridDropdown)
