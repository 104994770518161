import React, { memo } from 'react'
import { Button, DialogActions } from '@mui/material'
import "../styles/footer.scss"

const Footer = props => (
  <DialogActions className="primary-button-wrapper">
    <Button
      variant="contained"
      color="primary"
      onClick={props.onSendClick}>
      Send
    </Button>
    <Button color="secondary" onClick={props.onClose}>Cancel</Button>
  </DialogActions>
)

export default memo(Footer)
