import React from 'react'
import Grid from 'grid'
import { ButtonBase } from '@mui/material'

export default props => (
  console.log(props),
  (
    <div
      style={{
        height: 400,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ flex: 0.9 }}>
        <Grid
          // height={this.getGridHeight()}
          height={350}
          columnDefs={props.columnDefs}
          meta={props.meta}
          rowData={props.rowData}
          suppressCellSelection
          // onRowDoubleClicked={this.onRowClicked}
          // getRowNodeId={this.getRowNodeId}
          // paginationCb={this.paginationCb}
          // toggleFilter={this.toggleFilter}
          // toggles={this.toggles}
          // isFiltered={props.isFiltered}
          // autoSize
        />
      </div>
    </div>
  )
)
