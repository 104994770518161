import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@mui/material'
/*eslint-disable*/
const AddNew = ({ addButtonText, onClick, variant, color, size, showIcon, backgroundColor }) => (
  <Button
    variant={variant}
    color={color}
    onClick={onClick}
    size={size}
    tabIndex={-1}
    style={{ backgroundColor }}
  >
    {showIcon ? (
      <Icon style={{ fontSize: 16, marginRight: 5 }}>add_circle</Icon>
    ) : null}
    {addButtonText}
  </Button>
)

AddNew.propTypes = {
  addButtonText: PropTypes.string,
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  size: PropTypes.string,
  backgroundColor: PropTypes.string
}
AddNew.defaultProps = {
  variant: 'contained',
  color: 'secondary',
  showIcon: true,
  size: 'small',
  backgroundColor: ''
}
export default AddNew
