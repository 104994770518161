import {
  all,
  take,
  select,
  call,
  put,
  putResolve,
  fork,
  cancel
} from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { getErrorMessages } from 'components/SalesOrderReport/utils'

import { getIn, toJS, is } from 'utils'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
// import * as actions from 'ddiForm/actions'
import { disableFields, enableFields, setField } from 'ddiForm/actions'
import { confirmationModal } from 'modals/sagas'
import { show } from 'snackbar/actions'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { api } from 'services'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'

import {
  EXACT_MATCH,
  EXECUTE_INQUIRY,
  OPEN_DROPDOWN,
  OPEN_SCREEN,
  PRINT_PURCHASE_ORDERS
} from './constants'

import * as actions from './actions'
// import { CLEAR_SEARCH } from 'components/Search/IndexSearch/constants'
// import { SET_FIELD } from 'ddiForm/constants'
// import { setField } from 'ddiForm/actions'

export function* onOpenProcess(form) {
  const payload = { meta: {} }
  const indexSearchFields = yield select(state =>
    getIn(state, 'auth.common.purchaseOrderInquiryMeta.indexSearchFields')
  )

  const statusOptions = yield select(state =>
    getIn(state, 'auth.common.purchaseOrderInquiryMeta.statusOptions')
  )

  payload.meta.statusOptions = statusOptions.toJS()
  payload.meta.indexSearchFields = indexSearchFields.toJS()
  yield putResolve({
    type: OPEN_SCREEN,
    payload,
    meta: { form }
  })
}

export function* purchaseOrderExactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH.REQUEST)
    const {
      payload: { keyword, propertyName, subProperty },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    const { response, error } = yield call(api.purchaseOrderExactMatch, {
      keyword
    })
    if (response) {
      yield putResolve({
        meta: { form, thunk },
        payload: {
          ...response,
          propertyName,
          subProperty
        },
        type: EXACT_MATCH.SUCCESS
      })
    } else {
      yield putResolve({
        meta: { form, thunk },
        payload: {
          ...error,
          propertyName,
          subProperty
        },
        type: EXACT_MATCH.FAILURE
        // error: true
      })
    }
  }
}

export function* onBlurListener() {
  const mapper = {
    job: ['poNumber', 'product', 'vendor'],
    product: ['poNumber', 'vendor', 'job'],
    vendor: ['poNumber', 'product', 'job']
  }

  while (true) {
    const action = yield take([
      INDEX_SEARCH_CONSTANTS.BLUR,
      DDICONSTANTS.BLUR,
      DDICONSTANTS.SET_FIELD
    ])
    const {
      meta,
      payload: { isSet, propertyName, value }
    } = action

    if (meta.form) {
      if (meta.form.includes('purchaseOrderInquiry')) {
        const field = yield select(state =>
          getIn(
            getIn(state, 'ddiForm'),
            `${meta.form}.fields.${action.payload.propertyName}`
          )
        )

        const fields = mapper[action.payload.propertyName]
        if (
          (action.type === INDEX_SEARCH_CONSTANTS.BLUR && fields && isSet) ||
          (action.type === DDICONSTANTS.BLUR &&
            value &&
            propertyName === 'job' &&
            fields)
        ) {
          yield put(disableFields(meta.form, fields))
        } else if (action.type !== DDICONSTANTS.SET_FIELD) {
          yield put(enableFields(meta.form, fields || []))
        }

        if (action.type === DDICONSTANTS.SET_FIELD && !value && fields) {
          yield put(enableFields(meta.form, fields))
        }

        // if (action.type === DDICONSTANTS.BLUR) {

        // }
      }
    }
  }
}

// export function* setFieldListener() {
//   const trackedProps = ['poNumber', 'product']
//   while (true) {
//     const action = yield take(DDICONSTANTS.SET_FIELD)
//     const { meta, payload: { propertyName, value } } = action
//     if (meta.form) {
//       if (meta.form.includes('purchaseOrderInquiry')) {
//         if (trackedProps.includes(propertyName)) {
//           // console.log(action)
//           yield put(actions.setFlagged(meta.form, !!value))
//         }
//       }
//     }
//   }
// }

export function* executeInquiryListener() {
  let task
  while (true) {
    const action = yield take(EXECUTE_INQUIRY.TRY)
    const {
      meta: { form },
      payload: { propertyName }
    } = action
    // console.log('executeInquiryListener', action)
    if (task) yield cancel(task)

    task = yield call(executeInquiryProcess, form, propertyName)
  }
}

export function* executeInquiryProcess(form, propertyName) {
  const formState = yield select(getFormSelector(form))

  const editedFields = getIn(formState, 'editedFields')?.toJS
    ? getIn(formState, 'editedFields').toJS()
    : []

  let params = {}
  let status = getIn(formState, 'values.status') || 'O'
  if (is.array(editedFields) && editedFields.length) {
    params = editedFields.reduce((acc, next) => {
      const field = getIn(formState, `fields.${next}`)
      const value = getIn(field, 'rowData') || getIn(field, 'value')
      if (field === 'status') {
        status = value
      } else if (value && value !== '') {
        if (next === 'poNumber') {
          acc.dataId = toJS(value)
        } else {
          acc[next] = toJS(value)
        }
      }
      return acc
    }, {})
  }

  // const status = getIn(formState, 'status.value')
  // const dataId = getIn(formState, 'poNumber.value')
  // const productId = getIn(formState, 'product.value')
  // const vendorId = getIn(formState, 'vendor.value')
  // const jobId = getIn(formState, 'job.value')

  const invalidFormSelection =
    status !== 'E' && status !== 'R' && Object.keys(params).length === 0

  if (form === 'purchaseOrderInquiry' && invalidFormSelection) {
    yield call(
      confirmationModal,
      'Do you wish to see all orders?',
      'No Selection Entered'
    )
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CANCELED) {
      return
    }
  }

  yield put({ type: EXECUTE_INQUIRY.REQUEST, meta: { form, apiRequest: true } })

  const { response, error } = yield call(api.purchaseOrderInquiry, {
    ...params,
    status
  })

  if (response) {
    yield put({
      meta: { form },
      payload: { ...response },
      type: EXECUTE_INQUIRY.SUCCESS
    })
  } else {
    const { status: errorStatus, validationErrors } = error
    if (errorStatus === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      if(errorMessages){
        yield put(
          show({
            message: {
              message: errorMessages,
              type: 'warning',
              persist: false
            }
          })
        )
      }
    }
    yield put({
      meta: { form },
      payload: { ...error, propertyName },
      type: EXECUTE_INQUIRY.FAILURE
    })
  }
}

export function* dropDownOpenListener() {
  while (true) {
    const action = yield take(OPEN_DROPDOWN.REQUEST)
    const { meta } = action
    // console.log('dropDownListener', meta)
    yield putResolve({
      meta: {
        form: meta.form,
        thunk: meta.thunk
      },
      type: OPEN_DROPDOWN.SUCCESS
    })
  }
}

export function* updateIndexSearchLabelListener(formListener) {
  const searchFields = ['branch', 'shipVia', 'writer', 'product', 'vendor']

  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value, results, description },
      type
    } = yield take([
      INDEX_SEARCH_CONSTANTS.CLEAR_SEARCH,
      DDICONSTANTS.SET_FIELD,
      INDEX_SEARCH_CONSTANTS.FIND_NEXT.SUCCESS,
      INDEX_SEARCH_CONSTANTS.FIND_PREV.SUCCESS
    ])

    if (form === formListener && propertyName) {
      const propertyNameinSearchFields = searchFields.includes(propertyName)

      if (propertyNameinSearchFields) {
        const descriptionField = `${propertyName}Description`
        const resetValue = ''
        if (type === DDICONSTANTS.SET_FIELD) {
          if (value && results && results.description) {
            yield put(setField(form, descriptionField, results.description))
          } else {
            yield put(setField(form, descriptionField, resetValue))
            yield put(actions.clearInquiryResults(form))
          }
        } else if (type === INDEX_SEARCH_CONSTANTS.CLEAR_SEARCH) {
          yield put(setField(form, descriptionField, resetValue))
          yield put(actions.clearInquiryResults(form))
        }

        if (
          type === INDEX_SEARCH_CONSTANTS.FIND_NEXT.SUCCESS ||
          type === INDEX_SEARCH_CONSTANTS.FIND_PREV.SUCCESS
        ) {
          yield put(actions.clearInquiryResults(form))
          if (description) {
            yield put(setField(form, descriptionField, description))
          }
        }
      } else {
        yield put(actions.clearInquiryResults(form))
      }
    }
  }
}

export function* printPurchaseOrdersProcess(form, dataIds = []) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))

  const status = getIn(formState, 'fields.status.value') || 'O'

  if (!dataIds || !Array.isArray(dataIds) || !dataIds?.length) {
    return
  }

  yield put(actions.printPurchaseOrders.request(form))

  const { response, error } = yield call(api.printSelectedPurchaseOrders, {
    status,
    dataIds
  })

  if (response) {
    yield put(actions.printPurchaseOrders.success(response, form))
    yield fork(showPrintDocumentModalProcess, form, {
      ...response,
      form
    })
  } else {
    yield put(actions.printPurchaseOrders.failure(error, form))
  }
}

export function* printPurchaseOrdersListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { dataIds = [] }
    } = yield take(PRINT_PURCHASE_ORDERS.TRY)

    if (form === formListener && dataIds?.length) {
      yield fork(printPurchaseOrdersProcess, form, dataIds)
    }
  }
}

export default function* purchaseOrderInquirySagas(form) {
  yield fork(executeInquiryListener)
  yield fork(onBlurListener)
  yield fork(onOpenProcess, form)
  yield fork(updateIndexSearchLabelListener, form)
  yield fork(printPurchaseOrdersListener, form)
}
