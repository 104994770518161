// import { EventColorCell } from 'Grid'
import EventColorCell from 'grid/EventColorCell'
import EventColorCellWithTooltip from 'pages/Dashboard/components/Calendar/components/EventColorCellWithTooltip'

export const tasksColumnDefs = [
  {
    cellClass: 'cell-left-align',
    cellRendererFramework: EventColorCellWithTooltip,
    // field: 'subject',
    field: 'description',
    headerName: 'Subject',
    filter: false,
    width: 140
  },
  {
    // cellRendererFramework: CheckboxCell,
    cellClass: 'cell-value-hidden calendar-checkbox-cell',
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: 30,
      padding: 0
    },
    checkboxSelection: true,
    field: 'completed',
    headerClass:
      'fa fa-check-circle success-green flex-centered hide-filter-icon text-center',
    //  headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' },
    headerName: '', // 'chk',
    filter: false,
    width: 30,
    maxWidth: 30
  }
]

export const optionsColumnDefs = [
  {
    cellRendererFramework: EventColorCell,
    field: 'description',
    filter: 'text',
    headerName: 'Choose Options',
    width: 150
  }
]

export const userColumnDefs = [
  {
    field: 'dataId',
    headerName: 'code',
    filter: false,
    width: 70
  },
  {
    field: 'description',
    headerName: 'description',
    width: 100
  }
]
