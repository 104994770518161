import React, { Component } from 'react'
import {
  Input,
  InputAdornment,
  ButtonBase,
  Icon,
  IconButton
} from '@mui/material'
import memoize from 'memoize-one'
import shouldUpdate from 'hoc/shouldUpdate'
import { debounce } from 'lodash'
import { noop, plainDeepEqual } from 'utils'

const whitelist = ['value', 'mouseover', 'disabled', 'meta', 'filtersGrid']

const toDebounce = [
  'onSearchClick',
  'redoSearch',
  'findPrev',
  'findNext',
  'showQuickEntityClick',
  'handleBarcodeScanner'
]

const getButtonSetWidth = memoize(
  (
    allowNavigation,
    allowInstantSearch,
    allowSearchAll,
    showQuickEntityButton,
    allowSearchAllProp,
    showQuickEntityButtonProp,
    disabled,
    mouseover,
    isSet,
    value,
    disableRefresh,
    lastSearch,
    hasAppliedFilters,
    disableSearchAll
  ) => {
    let width = 0

    if (allowNavigation && !disabled && mouseover && isSet && value) {
      width += 40
    }

    if (
      disableRefresh &&
      allowInstantSearch &&
      lastSearch &&
      mouseover &&
      !disabled
    ) {
      width += 20
    }

    if (
      hasAppliedFilters ||
      (!disableSearchAll && (allowSearchAll || allowSearchAllProp))
    ) {
      width += 20
    }

    if (showQuickEntityButton || showQuickEntityButtonProp) {
      width += 20
    }

    return width
  }
)

export class TextInput extends Component {
  static defaultProps = {
    navigationArrowStyles: {
      iconStyle: { fontSize: 16, padding: 0 },
      style: {
        // float: 'right',
        height: 20,
        marginTop: 4,
        padding: 0,
        width: 20
      }
    },
    navWrapper: {
      // float: 'right',
      height: 20,
      marginTop: 4,
      padding: 0,
      width: 40
    },
    iconStyle: {
      color: '#fff',
      fill: '#fff',
      fontSize: 16,
      height: 16,
      width: 20,
      outline: 'none'
    },
    onSearchClick: noop,
    getSearchFilters: noop,
    showQuickEntityClick: noop,
    handleBarcodeScanner: noop,
    redoSearch: noop,
    findPrev: noop,
    findNext: noop
  }

  constructor(...args) {
    super(...args)
    toDebounce.forEach(prop => {
      Object.assign(this, {
        [prop]: debounce((...args) => this.props[prop](...args), 1000, {
          leading: true
        })
      })
    })
  }

  componentDidMount() {
    if (this.props.autoFocus && this.textInput) {
      setTimeout(() => this.textInput && this.textInput.focus(), 1)
    }
  }

  onFocus = e => {
    e.persist()
    if (this.textInput) this.textInput.select()
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  _getSearchFilters = () => {
    const { propertyName } = this.props
    const indexSearchType =
      this.props.indexSearchType ||
      (this.props.meta && this.props.meta.searchType)
    this.props.getSearchFilters({ indexSearchType, propertyName })
  }

  inputRef = c => {
    this.textInput = c
    if (this.props.inputRef) {
      this.props.inputRef(c)
    }
  }

  _hasAppliedFilters = rowData => {
    let ret = false
    if (rowData) {
      // ret = true
      ret = rowData.some(el => el?.fileAssociationSearchType.value)
    }
    // console.log(ret)
    return ret
  }
  // onSearchClick = () => this.props.onSearchClick()

  render() {
    const {
      autoFocus,
      inputRef,
      label,
      placeholder,
      id,
      disabled,
      lastSearch,
      value,
      onChange,
      onDoubleClick,
      onFocus,
      onKeyDown,
      onBlur,
      meta = {},
      buttonStyle,
      onSearchClick,
      iconStyle,
      // disableLastSearchButton,
      mouseover,
      navigationArrowStyles,
      redoSearch,
      isSet,
      findNext,
      findPrev,
      getSearchFilters,
      filtersButtonRef,
      disableRefresh,
      disableSearchAll,
      isOpen
    } = this.props
    // console.log(this)
    const hasAppliedFilters = this._hasAppliedFilters(
      this.props.filtersGrid?.rowData
    )

    return (
      <Input
        autoFocus={autoFocus}
        inputRef={this.inputRef}
        fullWidth={this.props.fullWidth || true}
        label={label}
        placeholder={placeholder}
        id={id}
        readOnly={disabled}
        value={value}
        onChange={onChange}
        onDoubleClick={onDoubleClick}
        onFocus={this.onFocus}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        endAdornment={
          <InputAdornment position="end">
            <div
              className="utility-buttons"
              style={{
                height: 26,
                zIndex: 2,
                display: 'flex',
                justifyContent: 'flex-end',
                width: getButtonSetWidth(
                  meta.allowNavigation,
                  meta.allowInstantSearch,
                  meta.allowSearchAll,
                  meta.showQuickEntityButton,
                  this.props.allowSearchAll,
                  this.props.showQuickEntityButton,
                  disabled,
                  mouseover,
                  isSet,
                  value,
                  disableRefresh,
                  lastSearch,
                  hasAppliedFilters,
                  disableSearchAll
                )
              }}
            >
              {meta.allowNavigation &&
              !disabled &&
              mouseover &&
              isSet &&
              value ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <IconButton
                    className="hvr-bounce-in"
                    style={navigationArrowStyles.style}
                    onClick={findPrev}
                    tabIndex={-1}
                    onDoubleClick={noop}
                  >
                    <Icon
                      // color={informBlue}
                      tabIndex={-1}
                      style={navigationArrowStyles.iconStyle}
                    >
                      keyboard_arrow_left
                    </Icon>
                  </IconButton>
                  <IconButton
                    className="hvr-bounce-in"
                    style={navigationArrowStyles.style}
                    onClick={findNext}
                    tabIndex={-1}
                    onDoubleClick={noop}
                  >
                    <Icon
                      // color={informBlue}
                      tabIndex={-1}
                      style={navigationArrowStyles.iconStyle}
                    >
                      keyboard_arrow_right
                    </Icon>
                  </IconButton>
                </div>
              ) : null}

              {!disableRefresh &&
              meta.allowInstantSearch &&
              lastSearch &&
              mouseover &&
              !disabled ? (
                <div
                  style={{
                    background: 'rgb(81, 123, 156)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight:'1px'
                  }}
                >
                <ButtonBase
                  className="hvr-bounce-in"
                  style={{
                    ...buttonStyle,
                    height: 26,
                    background: disabled ? '#ccc' : undefined,
                    color:'#ffffff',
                  }}
                  onClick={this.props.redoSearch}
                  tabIndex={-1}
                  onDoubleClick={noop}
                >
                  <Icon tabIndex={-1} style={iconStyle}>
                    refresh
                  </Icon>
                </ButtonBase>
                </div>
              ) : null}
              {hasAppliedFilters ||
              (!disableSearchAll &&
                (meta.allowSearchAll || this.props.allowSearchAll)) ? (
                <div
                  style={{
                    background: 'rgb(81, 123, 156)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius:
                      meta.showQuickEntityButton ||
                      this.props.showQuickEntityButton
                        ? undefined
                        : '0px 5px 5px 0px'
                  }}
                >
                  <ButtonBase
                    id={`${id}-search-all-trigger`}
                    disabled={disabled}
                    style={{
                      ...buttonStyle,
                      height: 26,
                      borderRadius:
                        meta.showQuickEntityButton ||
                        this.props.showQuickEntityButton
                          ? undefined
                          : '0px 5px 5px 0px',
                      background: disabled ? '#ccc' : undefined
                    }}
                    onClick={this.onSearchClick}
                    tabIndex={-1}
                    onDoubleClick={noop}
                  >
                    <Icon tabIndex={-1} style={iconStyle}>
                      search
                    </Icon>
                  </ButtonBase>
                </div>
              ) : null}
              {meta.showQuickEntityButton ||
                (this.props.showQuickEntityButton && (
                  <div
                    style={{
                      background: 'rgb(81, 123, 156)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '0px 5px 5px 0px',
                      borderLeft:
                        meta.allowSearchAll || this.props.allowSearchAll
                          ? '1px solid #cc'
                          : undefined
                    }}
                  >
                    <ButtonBase
                      id={`${id}-search-all-trigger`}
                      disabled={disabled}
                      style={{
                        ...buttonStyle,
                        height: 26,
                        borderRadius: '0px 5px 5px 0px',
                        background: disabled ? '#ccc' : undefined,
                        borderLeft:
                          meta.allowSearchAll || this.props.allowSearchAll
                            ? '1px solid #ccc'
                            : undefined
                      }}
                      onClick={this.showQuickEntityClick}
                      tabIndex={-1}
                      onDoubleClick={noop}
                    >
                      <Icon tabIndex={-1} style={iconStyle}>
                        add
                      </Icon>
                    </ButtonBase>
                  </div>
                ))}
            </div>
          </InputAdornment>
        }
        startAdornment={
          !isSet && meta.hasFilters ? (
            <InputAdornment position="start">
              <div style={{ width: 20 }}>
                <IconButton
                  disabled={disabled}
                  // className="hvr-bounce-in"
                  style={{
                    bottom: -2,
                    height: 20,
                    left: 0,
                    padding: 0,
                    // position: 'absolute',
                    width: 20,
                    zIndex: 2
                  }}
                  onClick={this._getSearchFilters}
                  // ref={el => (this.filtersButton = el)}
                  tabIndex={-1}
                  ref={filtersButtonRef}
                  onDoubleClick={noop}
                >
                  <Icon
                    tabIndex={-1}
                    style={{
                      height: 20,
                      padding: 0,
                      width: 20,
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: hasAppliedFilters ? '#32CD32' : null,
                      outline: 'none'
                    }}
                  >
                    {meta.hasFilters &&
                      (mouseover || (hasAppliedFilters && isOpen)) &&
                      !disabled &&
                      'filter_list'}
                  </Icon>
                </IconButton>
              </div>
            </InputAdornment>
          ) : (
              null
              )
        }
        inputProps={{
          style: {
            height: 26,
            paddingBottom: 0,
            paddingTop: 0
          },
          'data-lpignore': true
        }}
      />
    )
  }
}
export default shouldUpdate({ whitelist })(TextInput)
