import React from 'react'
import Draggable from 'react-draggable'
import { Paper } from '@mui/material'

const PaperComponent = props => (
  <Draggable>
    <Paper {...props} />
  </Draggable>
)
export default PaperComponent
