import SendDocumentModal from 'components/SendDocument'
import { addModal } from 'modals/actions'
import {
  take,
  call,
  select,
  put,
  spawn,
  fork,
  cancel
} from 'redux-saga/effects'
import { show } from 'snackbar/actions'
import { api } from 'services'
import { getIn } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'

export function* sendDocumentSendLister() {
  while (true) {
    const {
      payload,
      meta: { form, thunk }
    } = yield take('SEND_DOCUMENT_SEND_TRY')
    // debugger

    yield fork(sendDocumentSendProcess, payload, form, thunk)
  }
}

export function* sendDocumentSendProcess(args, form, thunk) {
  const {
    body,
    fromCompany,
    fromName,
    open,
    printJobId,
    sendType,
    subject,
    dataId
  } = args
  let { sendTo } = args

  debugger
  sendTo = sendTo.map(x => ({
    ...x,
    companySearchType:
      x.companySearchType === 'Free Form' || x.companySearchType === 'FreeForm'
        ? 'None'
        : x.companySearchType
  }))
  const validSendToLength = sendTo.length > 0
  const validSubject = subject.length > 0
  const validBody = subject.length > 0
  // debugger
  if (sendType === 'Email') {
    const ineligibleRecipients = sendTo.reduce((acc, next) => {
      if (next?.companyId && (!next?.email || !next?.description)) {
        acc = acc.concat(next)
      }
      return acc
    }, [])

    if (ineligibleRecipients?.length) {
      yield put(
        show({
          message: {
            message: 'Missing recipient information. Please check your recipients and try again.',
            type: 'warning',
            persist: false
          }
        })
      )

      return
    }
  }

  if (!validSendToLength || !validSubject || !validBody) {
    const msg = [
      { bool: validSendToLength, msg: 'Send To Required' },
      { bool: validSubject, msg: 'Subject Required' },
      { bool: validBody, msg: 'Body Required' }
    ].reduce((acc, next) => {
      if (!next.bool) {
        acc = acc.concat(`${next.msg}\n`)
      }
      return acc
    }, '')
    yield put(
      show({
        message: {
          message: msg,
          type: 'warning',
          persist: false
        }
      })
    )
  } else {
    // valid
    yield put({
      type: 'SEND_DOCUMENT_SEND_REQUEST',
      meta: {
        form
      }
    })

    const { response, error } = yield call(
      form === 'reportExplorer'
        ? api.reportExplorerSendDocumentSend
        : api.sendDocumentSend,
      {
        sendTo,
        sendType,
        fromCompany,
        fromName,
        subject,
        content: body,
        dataId
      }
    )

    if (response) {
      yield put({
        type: 'SEND_DOCUMENT_SEND_SUCCESS',
        meta: {
          form,
          thunk
        }
      })
    } else {
      yield put({
        type: 'SEND_DOCUMENT_SEND_FAILURE',
        meta: {
          form,
          thunk
        },
        error: true
      })
      yield fork(displayValidationErrors, error)
    }
  }
}
export function* sendDocumentProcess(payload, form, type) {
  yield put({
    type: 'SEND_DOCUMENT_REQUEST', // CONSTANTS.SEND_DOCUMENT.REQUEST,
    meta: { apiRequest: true, form }
  })
  let response
  let error

  if (type.includes('PRINT')) {
    const result = yield call(api.sendDocumentPrint, {
      dataId: payload.dataId
    })
    response = result.response
    error = result.error
  } else {
    const result = yield call(api.sendDocumentRead, {
      dataId: payload.dataId,
      sendType: payload.sendType,
      parentType: payload.parentType,
      parentId: payload.parentId,
      ...payload
    })
    response = result.response
    error = result.error
    // const formState = yield select(getFormSelector(form))
    // const dataId = getIn(formState, 'values.dataId')
    // debugger
    // return
  }

  if (response) {
    // debugger
    yield put({
      type: 'SEND_DOCUMENT_SUCCESS', // CONSTANTS.SEND_DOCUMENT.SUCCESS,
      meta: { form },
      payload: response
    })

    const modalOpts = {
      component: SendDocumentModal,
      props: {
        ...response,
        dataId: response.dataId,
        form
      }
    }
    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    // yield fork(destroyListeners)
  }
}
export function* sendDocumentListener() {
  while (true) {
    const action = yield take(x => x.type.includes('SEND_DOCUMENT_TRY')) // yield take(CONSTANTS.SEND_DOCUMENT.TRY)
    // debugger
    const {
      payload,
      meta: { form },
      type
    } = action

    yield fork(sendDocumentProcess, payload, form, type)
  }
}
export default function* sendDocumentSagas() {
  yield fork(sendDocumentSendLister)
  yield fork(sendDocumentListener)
}
