import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* new Material UI components */
import {
  AppBar,
  Button,
  Icon,
  Typography,
  TextField,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'

import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import * as actions from 'auth/actions'
import { canceled as modalCanceled } from 'modals/actions'
import ComponentStyles from 'theme/ComponentStyles'

import './styles/css-mod-ignore.scss'
import ProtectedPassword from './components/ProtectedPassword'

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

// const regex = /./g

const Title = () => (
  <div
    style={{
      ...ComponentStyles.modal.heading,
      borderBottom: 'none',
      margin: 0
    }}
  >
    <Icon style={ComponentStyles.modal.headingIcon}>lock</Icon> Protected Area
  </div>
)

// on keyPress
// stash true state
// update fake state
// render fake state
// update fake state on tab change as well

// const mapStateToProps = (state, ownProps) => ({
//   accessPath: getIn(state, `auth.accessPathList.${ownProps.name}`).toJS()
// })
const ignoreLp = {
  'data-lpignore': true,
  '-webkit-text-security': 'disc !important'
}
const mapDispatchToProps = dispatch => ({
  canceled: (id, accessId) => dispatch(modalCanceled(null, null, null, true)),
  tryRequestAccess: opts => dispatch(actions.tryRequestAccess(opts))
})

class AccessOverride extends Component {
  static propTypes = {
    accessPath: PropTypes.string,
    canceled: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    tryRequestAccess: PropTypes.func
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {
        open: nextProps.open
      }
    }
    return null
  }

  constructor(props) {
    super(props)
    this.state = {
      open: true,
      password: '',
      fakepin: '',
      pin: '',
      tab: 'pin',
      username: '',
      requesting: false,
      wrongPINFlag: false,
      inavlidMessage: false
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp)
    // if (this.pinField) {
    //  debugger
    //   this.pinField.focus()
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyUp = event => {
    if (event.key === 'Enter') {
      const { password, pin, username, requesting } = this.state
      if (!requesting && (pin || (username && password))) {
        this.requestAccess()
        // console.log(this.okButton)
        // this.okButton.click()
      }
    }
  }

  handleTabChange = (event, value) => {
    if (typeof value === 'string') {
      this.setState(
        {
          password: '',
          pin: '',
          tab: value,
          username: ''
        },
        () => {
          if (value === 'password') {
            this.usernameField.focus()
          } else {
            this.pinField.focus()
          }
        }
      )
    }
  }

  // requestAccess = () => {
  //   const {
  //     tryRequestAccess,
  //     accessPath,
  //     accessId,
  //   } = this.props
  //   const { password, pin, username } = this.state
  //   this.setState({ requesting: true }, () => {
  //     tryRequestAccess({
  //       accessPath,
  //       id: accessId,
  //       password,
  //       pin,
  //       username
  //     })
  //   })
  // }

  requestAccess = () => {
    const { tryRequestAccess, accessPath, accessId } = this.props
    const { password, pin, username } = this.state
    this.setState({ requesting: true }, async () => {
      try {
        await tryRequestAccess({
          accessPath,
          id: accessId,
          password,
          pin,
          username
        })
      } catch (e) {
        this.setState({
          password: '',
          pin: '',
          username: '',
          requesting: false,
          wrongPINFlag: true,
          inavlidMessage: true
        })
        setTimeout(() => {
          this.setState({
            inavlidMessage: false
          });
          }, 10000);
      }
    })
  }

  canceled = () => {
    const { accessId, canceled, id } = this.props
    this.setState({ open: false, wrongPINFlag: false, inavlidMessage: false }, () => canceled(id, accessId))
  }

  buttonRef = c => (this.okButton = c)

  onEntered = () => {
    if (this.pinField) {
      this.pinField.focus()
    }
  }

  // onFieldChange = field => event =>
  //   this.setState({ [field]: event.target.value })
  onFieldChange = field => event => {
    this.setState({
      [field]: event.target.value
    })
  }

  okDisabled = () => {
    // requesting || (!pin && (!username || !password))
    const { requesting, pin, username, password } = this.state
    if (requesting) return true
    if (!pin && !password && !username) return true
    if ((username && !password) || (!username && password)) return true
    return false
  }

  render() {
    const { password, tab, username, requesting, wrongPINFlag, inavlidMessage } = this.state
    const { accessPath, requiredLevel } = this.props
    // console.log(this.props)
    const okDisabled = this.okDisabled()

    /*
      NOTE: added dialog z-index 6/10/2019 to account for
      forms in modals like Customer Ship To -- SVE 6/10/2019
    */
    return (
      <div>
        <Dialog
          open={this.state.open}
          disablePortal
          onExited={this.props.onExited}
          onEntered={this.onEntered}
          style={{ zIndex: 1301 }}
        >
          <DialogTitle>
            <Title />
          </DialogTitle>
          <DialogContent>
            <Row style={{ marginBottom: 10, marginTop: 10 }}>
            <Col sm={12}>
                {accessPath ? <><span>Access Path:</span> {wrongPINFlag ? <span className="warning-text">{accessPath}</span>: <span>{accessPath}</span>} </>: null}
                <br />
                {requiredLevel ? (
                  <><span>Required Level: </span> {wrongPINFlag ? <span className="warning-text">{requiredLevel}</span> : <span>{requiredLevel}</span>} </>
                ) : null}
              </Col>
            </Row>
            <AppBar position="static">
              <Tabs
                id="auth-tabs"
                onChange={this.handleTabChange}
                value={tab}
                indicatorColor="#1abcf7"
              >
                <Tab value="pin" label="PIN" />
                <Tab value="password" label="User Name / Password" />
              </Tabs>
            </AppBar>
            {tab === 'pin' && (
              <TabContainer>
                <div
                  style={{
                    marginTop: 15,
                    minHeight: 120
                  }}
                >
                  <ProtectedPassword
                    autoFocus
                    variant="standard"
                    label="PIN"
                    id="pin"
                    disabled={requesting}
                    onChange={this.onFieldChange('pin')}
                    inputRef={c => (this.pinField = c)}
                    type="text"
                    // type="password"
                    value={this.state.pin}
                    fullWidth
                    inputProps={ignoreLp}
                    className="pw"
                    autoComplete="off"
                    style={{marginBottom:"6px"}}
                  />
                  {wrongPINFlag && inavlidMessage ? 
                     <span className="error-text">Invalid Access</span>
                  : null}
                </div>
              </TabContainer>
            )}
            {tab === 'password' && (
              <TabContainer>
                <div
                  style={{
                    marginTop: 15,
                    minHeight: 120
                    //   width: 288
                  }}
                >
                  <TextField
                    autoFocus
                    variant="standard"
                    label="User Name"
                    id="username"
                    onChange={this.onFieldChange('username')}
                    disabled={requesting}
                    inputRef={c => (this.usernameField = c)}
                    value={username}
                    fullWidth
                    style={{ marginBottom: 10 }}
                    autoComplete="off"
                  />
                  <ProtectedPassword
                    variant="standard"
                    id="password"
                    label="Password"
                    onChange={this.onFieldChange('password')}
                    type="text"
                    // type="password"
                    disabled={requesting}
                    value={password}
                    inputProps={ignoreLp}
                    fullWidth
                    className="pw"
                    autoComplete="off"
                    style={{marginBottom:"6px"}}
                  />
                  {wrongPINFlag && inavlidMessage ?
                      <span className="error-text">Invalid Access</span>
                   : null}
                </div>
              </TabContainer>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.requestAccess}
              // disabled={requestingAccess || (!pin && (!username || !password))}
              disabled={okDisabled}
              variant="contained"
              buttonRef={this.buttonRef}
            >
              OK
            </Button>
            <Button
              keyboardfocused
              onClick={this.canceled}
              variant="contained"
              disabled={requesting}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default connect(
  null, // mapStateToProps,
  mapDispatchToProps
)(AccessOverride)
