import React, { Component, Fragment } from 'react'
import memoize from 'memoize-one'
import { getRgb } from 'utils'
import { upperCase } from 'lodash'
import shortid from 'shortid'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'

const numericFields = ['bo', 'ordered', 'toShip']

export default class DocSearchTooltipTemplate extends Component {
  getImage = ({ image }) => {
    if (!image) {
      return null
    }

    return <img src={image} height={200} width={200} alt="tooltip" />
  }
  getStatus = status => {
    if (!status) return null
    const { color, description, value } = status
    return (
      <Fragment>
        {description && (
          <div
            style={{
              backgroundColor: getRgb(color),
              borderRadius: 4,
              color: 'white',
              fontSize: 12,
              lineHeight: '16px',
              marginLeft: 10,
              padding: 5,
              textTransform: 'uppercase'
            }}
          >
            {description}
          </div>
        )}
        {value && <div style={{ color: '#fff' }}>{value}</div>}
      </Fragment>
    )
  }
  recurParent = body => {
    let ret = []
    function helper(body) {
      if (body && body.parent) {
        const obj = { children: [] }
        obj.title = body.parent.title
        const info = body.parent.body.info || []
        for (let i = 0; i < info.length; i++) {
          obj.children = obj.children.concat(info[i])
        }
        ret = ret.concat(obj)
        helper(body.parent.body)
      }
    }
    helper(body)
    ret = ret.map(obj => (
      <div key={obj.title.id}>
        <div className="tooltip-heading">
          <div>
            <h3
              style={{
                fontSize: 12,
                fontWeight: 700,
                lineHeight: '14px'
              }}
            >
              Parent Account
            </h3>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'center'
            }}
          >
            <h3
              style={{
                color: '#fff',
                fontSize: 12,
                fontWeight: 700,
                lineHeight: '14px'
              }}
            >
              {obj.title.description}
            </h3>
            <div>{this.getStatus(obj.title.status)}</div>
          </div>
        </div>
        <div className="tooltip-content">
          {obj.children.map((x, i) => (
            <div style={{ color: 'white', fontSize: 11 }} key={x}>
              {x}
            </div>
          ))}
        </div>
      </div>
    ))
    return ret
  }

  renderAddresses = addresses => {
    const listItemStyle = {
      color: '#fff',
      fontSize: 12,
      lineHeight: '16px',
      marginBottom: 0
    }
    const formattedLists = addresses.map((item, index) => {
      console.log(item)
      return (
        <ul
          style={{
            listStyleType: 'none',
            marginLeft: 0,
            paddingLeft: 0
          }}
        >
          {item.map((li, idx) => {
            if (idx === 0) {
              return (
                <li
                  key={shortid.generate()}
                  style={{ ...listItemStyle, marginTop: 10 }}
                >
                  <strong>{li}</strong>
                </li>
              )
            }
            return (
              <li key={shortid.generate()} style={listItemStyle}>
                {li}
              </li>
            )
          })}
        </ul>
      )
    })

    return formattedLists
  }

  showDetailsTable = details => {
    if (details.length) {
      return (
        <Table>
          <TableHead>
            <TableRow style={{ height: 20 }}>
              {Object.keys(details[0]).map((key, idx) => (
                <TableCell
                  style={
                    numericFields.includes(key)
                      ? { color: '#fff', padding: 2, textAlign: 'right' }
                      : { color: '#fff', padding: 2 }
                  }
                  key={shortid.generate()}
                >
                  {upperCase(key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {details.map((row, idx) => (
              <TableRow style={{ height: 20 }} key={shortid.generate()}>
                {Object.keys(row).map((item, index) => (
                  <TableCell
                    style={
                      numericFields.includes(item)
                        ? { color: '#fff', padding: 2, textAlign: 'right' }
                        : { color: '#fff', padding: 2 }
                    }
                    key={shortid.generate()}
                  >
                    {row[item]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    }

    return null
  }

  render() {
    // console.log(this.props)
    const { content } = this.props
    if (!content) return null
    let {
      summary: {
        body,
        title: { description, status }
      }
    } = content

    body = body || {}
    const { info = [], image } = body
    return (
      <div className="inform-tooltip" style={{ width: 400 }}>
        <div
          className="tooltip-heading"
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <h2
            style={{
              color: '#fff',
              fontSize: 12,
              fontWeight: 500,
              lineHeight: '14px'
            }}
          >
            {description}
          </h2>
          {this.getStatus(status)}
        </div>
        <div className="tooltip-content">
          <div>
            {this.getImage({ image })}
            {info.map((x, i) => (
              <div style={{ color: 'white', fontSize: 11 }} key={x}>
                {x}
              </div>
            ))}
            {/* {body && body.addresses ? this.renderAddresses(addresses) : null}
            {body && body.details ? this.showDetailsTable(body.details) : null} */}
          </div>
        </div>
      </div>
    )
  }
}
