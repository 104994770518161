import React from 'react'
import { Icon, IconButton } from '@mui/material'

const TitleBar = ({ titleIcon, title, backButtonAction = null }) => (
  <div
    style={{
      alignItems: 'center',
      background: '#E1E3E4',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '5px 5px 5px 10px'
    }}
  >
    <span style={{ alignItems: 'center', display: 'flex' }}>
      {titleIcon ? (
        <Icon fontSize="small" style={{ marginRight: 10 }}>
          {titleIcon}
        </Icon>
      ) : null}
      <span style={{ color: '#444', fontSize: 14 }}>{title}</span>
    </span>
    {backButtonAction && typeof backButtonAction === 'function' ? (
      <IconButton onClick={e => backButtonAction()} style={{ padding: 5 }}>
        <Icon fontSize="small">arrow_back_ios</Icon>
      </IconButton>
    ) : null}
  </div>
)

export default TitleBar
