import React from 'react'
import { CircularProgress } from '@mui/material'

const requestProcessingMessage = (
  message = 'Request Processing...',
  minHeight = 50,
  size = 40
) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10,
      minHeight,
      padding: 15,
      width: '100%'
    }}
  >
    {/* <Icon style={{ color: '#1abcf7' }}>warning</Icon> */}
    <CircularProgress size={size} thickness={7} color="primary" />
    <p style={{ fontSize: 14, margin: '0 0 0 10px' }}>{message}</p>
  </div>
)

export default requestProcessingMessage
