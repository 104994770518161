import React, { Component } from 'react'
import { Icon, TextField, Tooltip as SimpleToolTip } from '@mui/material'
import PhoneNumberTextField from 'components/masks/PhoneNumberTextField'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'

export default class FaxCellEditor extends Component {
  static propTypes = { 
    onChange: PropTypes.func.isRequired, 
    field: PropTypes.string.isRequired, 
    node: PropTypes.object.isRequired, 
    value: PropTypes.string 
  }
  constructor(...args) {
    super(...args)
    this.state = {
      value: this.props.value,
      errorMessage: false
    }
    this.domNodeRef = React.createRef();
  }

  componentWillUnmount() {
    let { value } = this.state
    if (value) {
      value = value
        .replace('(', '')
        .replace(') ', '-')
        .replace(' ', '')
    }
    this.props.onChange(this.props.field, value, this.props.node)
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  onChange = e => {
    const { value } = e.target
    this.setState({ value })
  }

 componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
 }

 handleClickOutside = event => {
  const domNode = this.domNodeRef.current;

  if (!domNode?.contains(event.target)) {
    this.setState({
        errorMessage: false
      });
  }
}

  getValue = () => this.state.value
  setErrorMessage = () => this.setState({ errorMessage: true})
  handleTooltipClose = () => this.setState({ errorMessage: false})

  render() {
    const Tooltip = withStyles(theme => ({
      tooltip: {
        margin: theme.spacing(1)
      },
    }))(SimpleToolTip)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 0.9,
            height: '100%',
            width: '!00%'
          }}
        >
          <div style={{ alignSelf: 'flex-end' }}>
            <TextField
              value={this.state.value}
              readOnly
              onChange={this.onChange}
              InputProps={{
                inputComponent: PhoneNumberTextField,
                style: { height: 26, padding: 0 }
              }}
              style={{ height: 26, padding: 0 }}
              inputProps={{
                style: { height: 26, padding: 0 }
              }}
              fullWidth
              variant="standard"
            />
          </div>
        </div>
        <div
          style={{
            flex: 0.1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          {!this.state.value ? (
           <Tooltip
           title={
             !this.state.value && 'Fax is required'
           }
           open={this.state.errorMessage}
           onClose={this.handleTooltipClose}
         >
           <Icon style={{ color: '#d9534f', fontSize: 16, cursor: 'pointer' }} onClick={this.setErrorMessage} 
            onMouseEnter={this.setErrorMessage} 
            onMouseLeave={this.handleTooltipClose}
            ref={this.domNodeRef}>
             error_outline
           </Icon>
           </Tooltip>
          ) : null}
        </div>
      </div>
    )
  }
}
