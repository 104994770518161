/* eslint react/no-did-update-set-state: 0 */
import React, { useEffect } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import dayjs from 'dayjs'
import useState from 'hooks/useStateWithCallback'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField';
import {
  Icon,
  InputAdornment,
  IconButton
} from '@mui/material';

const DatePickerDDI = props => {
  const {
    disabled,
    tabIndex,
    value,
    initialValue,
    disableFuture,
    maxDate,
    setField,
    onBlur,
    inputRef,
    onFocus,
    inputFormat= 'MM/dd/yyyy',
    placeholder,
    marginTop,
    label,
    onExternalChange,
  } = props

  const [state, setState] = useState({
    value: initialValue || value || null,
    isDirty: false
  })
  const [calendarOpen, setCalendarOpen] = React.useState(false)
  useEffect(() => {

   }, [value])

  const onChange = date => {
    setState(prevState => ({ ...prevState, value: date, isDirty: true }))
  }

  const onAccept = date => {
    setState(
      prevState => ({ ...prevState, value: date, isDirty: false }),
      () => {
        setField(validateDate(date)?date:'');
      }
    )
  }

  const convertToDateFormat = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');        // Ensure day is two digits
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const validateDate = (input) => {

    let date;

    if (typeof input === 'string') {
      // If it's not a valid Date string, assume it's in MM/DD/YYYY format
      date = input;
    } else {
      const parsedDate = new Date(input);
      date = convertToDateFormat(parsedDate);
    }

    // Regex to validate the format MM/DD/YYYY
    const regex = /^(0\d|1[0-2])\/(0\d|[12]\d|3[01])\/(\d{4})$/;

    // Check if date matches the regex
    if (!regex.test(date)) {
      return false;
    }

    // Extract month, day, and year from the date
    const [month, day, year] = date.split('/').map((part) => parseInt(part, 10));

    // Create a new Date object to validate the date
    const validDate = new Date(year, month - 1, day); // months are 0-indexed in JavaScript Date
    return validDate.getDate() === day && validDate.getMonth() === month - 1 && validDate.getFullYear() === year;
  };

  const onBlurLocal = e => {
    e.persist();
  
    const inputValue = e.target.value;
  
    // Check if the input value is a valid date before updating the state
    if (validateDate(inputValue)) {
      const formattedDate = dayjs(inputValue,'MM/DD/YYYY');
  
      // If the date hasn't changed, call onBlur with the formatted value
      if (formattedDate === dayjs(state.value,'MM/DD/YYYY')) {
        setField(formattedDate);
      } else {
        onBlur(inputValue); // If the date changed, propagate it
      }
  
      setState(prevState => ({ ...prevState, isDirty: false }));
    } else if (state.isDirty) {
      // If the input is invalid and dirty, clear the value
      setState(prevState => ({ ...prevState, value: null }), () => {
        onBlur(''); // Call onBlur with an empty string when value is cleared
      });
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className="ddi-picker-with-label"
        style={{ position: 'relative',marginTop: marginTop || 16 }}
        tabIndex={tabIndex}
      >
        <DesktopDatePicker
          renderInput={props => 
          <div>
          <TextField  
            {...props}         
            placeholder={placeholder}
            //value={state.value? isValidDate(state?.value?.toString())?moment(state?.value.toString()).format('MM/DD/YYYY'):'':''}
            variant="standard"
            style={{ width: '100%' }}
            onBlur={onBlurLocal}
            helperText={state.isDirty && !validateDate(state.value) ? 'Invalid date format' : ''}
            onChange={e => {
              if(onExternalChange){
              onExternalChange(e.target.value)
              }else{
                onChange(e.target.value);
              }
              onAccept(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton style={{ padding: 5 }}
                    onClick={() => setCalendarOpen((calendarOpen) => !calendarOpen)}
                    disabled={disabled}
                  >
                    <Icon
                      fontSize="small"
                    >
                      event
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
           />
         </div>
       }
          open={calendarOpen}
          label={label}
          onClose={() => setCalendarOpen(false)}
          value={state.value}
          autoOk
          onChange={onExternalChange || onChange}
          onAccept={onAccept}
          inputFormat={'MM/dd/yyyy'}
          disabled={disabled}
          disableFuture={disableFuture}
          onYearChange={onChange}
          maxDate={ maxDate || undefined}
          onDoubleClick={props.onDoubleClick}
          inputRef={inputRef}
          allowSameDateSelection={true}
          onFocus={onFocus}
        />
      </div>
    </LocalizationProvider>
  )
}

DatePickerDDI.defaultProps = {
  inputFormat: 'MM/dd/yyyy',
}
DatePickerDDI.propTypes = {
  onFocus: PropTypes.func.isRequired,
  inputFormat: PropTypes.string,
  inputRef: PropTypes.any.isRequired,
  placeholder: PropTypes.string
}

export default DatePickerDDI
