import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Switch from '@mui/material/Switch'
import { makeStyles } from '@mui/styles'
import { removeModal } from 'modals/actions'
import { isMobileSelector } from 'mobile/selectors'
import { getIn, isUsaCanadaOrBlank, toJS } from 'utils'
import { required } from 'ddiForm/validations'
import { priceOverride, cancelPriceOverride } from 'pages/SalesOrder/actions'
import { withDDIForm } from 'ddiForm/DDIFormContext'

const useStyles = mobile =>
  makeStyles(theme => {
    // debugger
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
        // width: 500
      },
      card: {
        // width: 500
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120
      },
      selectEmpty: {
        marginTop: theme.spacing(2)
      },
      cardMarginTop: {
        // width: 400,
        marginTop: 20
      },
      inputStyle: {
        width: mobile ? '2.7rem !important' : '4rem !important',
        height: mobile ? '3rem' : '4rem',
        margin: mobile ? '0 0.25rem' : '0 1rem',
        fontSize: mobile ? '1.5rem' : '3rem',
        borderRadius: 4,
        border: '1px solid rgba(0,0,0,0.3)'
      },
      error: {
        border: '1px solid red !important'
      },
      iconStyle: {
        color: 'green',
        fontSize: 48,
        animationDuration: '2s',
        animationName: 'fadeIn'
      },
      iconContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
      }
    }
  })
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export function PriceOverrideModal(props) {
  const { form } = props
  console.log(props)
  const [open, setOpen] = useState(true)
  const [success, setSuccess] = useState(false)

  const [apiActive, setApiActive] = useState(false)
  const [isError, setError] = useState(false)

  const handleClose = bool => {
    // setOpen(false)
    props.dispatch(removeModal(form, props.id))
    if (bool) {
      props.dispatch(cancelPriceOverride.try(form, props.apiParams))
    }
    setOpen(false)
  }

  const close = useCallback(() => handleClose(true))
  const recalculate = async () => {
    try {
      setApiActive(true)
      // let selectedRows = props.gridApi.getSelectedRows().map(x => x.uniqueKey)
      // props.gridApi.forEachDetailGridInfo(info => {
      //   const selected = info.api.getSelectedRows().map(x => x.uniqueKey)

      //   if (selected.length) {
      //     selectedRows = selectedRows.concat(selected)
      //   }
      // })
      await props.dispatch(
        priceOverride.request(form, {
          // action: props.apiParams.action,
          // guid: props.apiParams.guid,
          // childLineNumber: props.apiParams.childLineNumber,
          // lineNumber: props.apiParams.lineNumber,
          reasonCode: props.reasonCode,
          body: props.body
          // apiMethod: props.apiMethod,
          // successFn: props.successFn,
          // failFn: props.failFn
        })
      )

      setSuccess(true)
      await delay(1000)
      setOpen(false)
      handleClose()
    } catch (ex) {
      console.log(ex)

      setError(true)
      setApiActive(false)
      // setOtp('')
      if (ex?.error === 'invalid_grant') {
        handleClose()
      }
    }
  }
  const classes = useStyles(props.mobile)()

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">
          {props.title || 'Price Override Reason'}
        </DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <div style={{ margin: '25px auto', minWidth: 280 }}>
              <div style={{ display: 'flex' }}>
                <div>
                  <div style={{ marginBottom: 10 }}>Reason:</div>
                  <div>Initials:</div>
                </div>
                <div style={{ paddingLeft: 25 }}>
                  <div style={{ marginBottom: 10 }}>
                    <DDIIndexSearch
                      propertyName="reasonCode"
                      validate={[required]}
                      disablePropertyChange
                      meta={{ ...props?.search }}
                      preventAutoDisable
                    />
                  </div>
                  <div>{props.initials}</div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={recalculate}
            color="primary"
            disabled={apiActive || !props.reasonCode}
          >
            OK
          </Button>
          <Button
            variant="contained"
            onClick={close}
            color="primary"
            disabled={apiActive}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
const mapState = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const reasonCode = getIn(formState, 'values.reasonCode') || ''
  return {
    mobile: isMobileSelector(state),
    reasonCode
  }
}
export default connect(mapState)(withDDIForm(PriceOverrideModal))
