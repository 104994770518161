import {
  actionChannel,
  take,
  select,
  call,
  put,
  putResolve,
  fork
} from 'redux-saga/effects'
import { CHANGE_TAB } from 'pages/Layout/constants'
import {
  AUTHENTICATION_ERROR,
  SUBMIT_LOGOUT_USER,
  CLEAR_SELECTED_BRANCH
} from 'auth/constants'
import { removeModal } from 'modals/actions'
import $ from 'jquery'
import { show } from 'snackbar/actions'
import { showInternalNotesModal } from 'modals/NotesModal/sagas'
import { getIn, noop, toJS, toCamelCase, trimNonWord, is } from 'utils'
import { api } from 'services'
import { CANCELED, CONFIRMED, REMOVE_MODAL } from 'modals/constants'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import * as ddiFormActions from './actions'
import {
  getAlternateMetaAsync,
  cancelEditAsync,
  getEntityAsync,
  resetMasterFields
} from './MasterScreen/actions'
import masterSagas, {
  searchProcess,
  accessProcess,
  getTabAccess,
  handleEntityResponse
} from './MasterScreen/sagas'
import {
  BEFORE_DESTROY,
  CANCEL,
  DELETE_ENTITY,
  EXIT,
  INITIALIZE,
  OPEN_SCREEN,
  SAVE,
  UNLOCK_ENTITY,
  TRY_CHANGE_FORM_TAB
} from './constants'
import { CANCEL_EDIT } from './MasterScreen/constants'

import {
  getFormSelector,
  getSelectedTabsFromState,
  getMapResponse
} from './utils'
import { loadLedgerRecord } from '../pages/CustomerMaster/tabs/Ledger/lib/actions'
import { isMobileSelector } from '../mobile/selectors'
// let forms = empty
let forms = {}

// needs to be looked at again
export function* onBeforeDestroyListener() {
  const channel = yield actionChannel(BEFORE_DESTROY)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form },
      payload: { retainData }
    } = action

    //
    const formState = yield select(getFormSelector(form))
    const isEditing = getIn(formState, 'isEditing')
    if (!isEditing) {
      yield fork(onDestroyProcess, form, retainData)
    }
  }
}

export function* closeSendDocument() {
  while (true) {
    const action = yield take('CLOSE_SEND_DOCUMENT')
    const {
      meta: { form },
      payload: { dataId }
    } = action
    // debugger
    const formState = yield select(getFormSelector(form))
    const modals = getIn(formState, 'modals')
    const modal = modals.last()
    if (modal) {
      const id = getIn(modal, 'id')
      yield put({
        payload: { id },
        meta: { form, modal: true },
        type: REMOVE_MODAL
      })
      if (form === 'reportExplorer') {
        yield fork(api.closeExplorerSendDocumentWithoutSending)
      } else {
        yield put({
          meta: { form },
          type: 'DESTROY_FIELD',
          payload: {
            propertyName: 'sendInfoSearch' // change this
          }
        })
      }
    }
  }
}

export function* destroyScreen(form) {
  // eslint-disable-line

  const frm = forms[form] // yield getIn(forms, form)
  if (frm) {
    const sga = frm.initialized
    console.log(sga)
    // sga.cancel()
    if (sga) {
      sga.cancel()
    }
    console.log(sga)
    delete forms[form]
    console.log(forms)
    yield putResolve(ddiFormActions.destroy(form))
  }
}

export function* onDestroyProcess(form, retainData) {
  const saga = forms[form] && forms[form].sagas.onDestroy
  // yield call(saga)
  if (saga) {
    yield fork(saga, form)
  }
  if (!retainData) {
    yield fork(destroyScreen, form)
  }
}

// export function* onScreenCloseListener()
// needs to be looked at again
export function* onDeleteListener() {
  const channel = yield actionChannel(DELETE_ENTITY)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form }
    } = action
    const saga = yield call(getIn, forms, `${form}.onDelete`)
    yield call(saga, action)
  }
}

export const formMapToControllerName = { customerMaster: 'customer' }
// deprecated..
export function* formInitializeListener() {
  // debugger
  const actionChan = yield actionChannel(INITIALIZE)
  while (true) {
    const action = yield take(actionChan)
    // debugger
    const {
      meta: { form },
      meta,
      payload: {
        sagas,
        disableMeta,
        masterOptions,
        mobile,
        noAPIForCancelConfirm
      }
    } = action
    yield putResolve({
      meta: { form, thunk: meta.thunk },
      payload: {},
      type: OPEN_SCREEN.SUCCESS
    })
    // forms = setIn(forms, form, fromJS(sagas))
    let task // = //yield fork(sagas.onInit, form)

    if (!forms[form]) {
      forms = {
        ...forms,
        [form]: {
          ...forms[form],
          sagas
        }
      }

      // task = yield fork(function* master() {
      //   yield fork(sagas.onInit, form)
      //   yield fork(masterSagas, form)
      // }, form)

      if (masterOptions || mobile || noAPIForCancelConfirm) {
        task = yield fork(function* master() {
          yield fork(sagas.onInit, form)
          yield fork(masterSagas, form)
        }, form)
      } else {
        task = yield fork(sagas.onInit, form)
      }
    }

    forms = {
      ...forms,
      [form]: {
        ...forms[form],
        initialized: task
      }
    }
    // console.log(forms)
  }
}

export function* onCancelListener() {
  const channel = yield actionChannel(CANCEL)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form }
    } = action
    // yield call(forms[form].onCancel, action)
    const saga = yield call(getIn, forms, `${form}.onCancel`)
    yield call(saga, action)
  }
}
export function* saveProcess(form) {
  let formState = yield select(getFormSelector(form))
  formState = yield call(toJS, formState)
  const editedFields = getIn(formState, 'editedFields')
  const groupNames = getSelectedTabsFromState(formState)

  let payload
  if (editedFields && Array.isArray(editedFields) && editedFields.length) {
    payload = editedFields.reduce((acc, next) => {
      const field = getIn(formState, `fields.${next}`)
      let value
      if (getIn(field, 'rowData')) {
        value = getIn(field, 'rowData')
        if (getIn(field, 'isPending') && value.length) {
          value = value.slice(0, value.length - 1)
        }
      } else {
        value = getIn(field, 'value')
      }

      acc[next] = toJS(value)
      return acc
    }, {})
  }
  const dataId = getIn(formState, 'fields.dataId.value')
  const templateKey = getIn(formState, 'values.templateKey')

  payload = templateKey
    ? {
        properties: { ...payload },
        form,
        dataId,
        templateKey,
        groupNames: groupNames.map(trimNonWord)
      }
    : {
        properties: { ...payload },
        form,
        dataId,
        groupNames: groupNames.map(trimNonWord)
      }

  const saveMethod = templateKey ? api.saveTemplate : api.save
  const transFormDataMethod = getIn(
    formState,
    'masterOptions.transformDataBeforeSave'
  )
  if (transFormDataMethod && is.fn(transFormDataMethod)) {
    payload = transFormDataMethod(payload, formState)
  }
  // debugger
  const R = yield call(saveMethod, payload)
  if (R.response) {
    // debugger
    // update for mapResponse logic.
    formState = yield select(getFormSelector(form))
    formState = formState.toJS()
    const mapResponse = getMapResponse({ formState, tabIds: groupNames })

    const response = mapResponse({
      response: R.response,
      tabIds: groupNames,
      formState,
      groupNames
    })
    // debugger

    yield put({
      meta: { form },
      payload: { ...response },
      type: SAVE.SUCCESS
    })
  } else {
    yield put({
      meta: { form },
      payload: R.error,
      type: SAVE.FAILURE
    })
    yield fork(displayValidationErrors, R.error)
  }
}
export function* onSaveListener() {
  const channel = yield actionChannel(SAVE.TRY)
  while (true) {
    const action = yield take(channel)
    // console.log(action, 'save called')
    const {
      meta: { form }
    } = action
    let formState = yield select(getFormSelector(form))
    formState = toJS(formState)
    // formData = formData
    // const saga = yield call(getIn, forms, `${form}.sagas.onSave`)
    const saga = forms[form]?.sagas?.onSave
    // debugger
    if (saga && saga !== noop) {
      // debugger
      yield fork(saga, formState, form)
    } else {
      // debugger
      yield fork(saveProcess, form)
    }
  }
}

export function* unlockEntityListener() {
  const channel = yield actionChannel(UNLOCK_ENTITY.REQUEST)
  while (true) {
    const action = yield take(channel)
    const { form } = action.meta
    const {
      payload: { dataId }
    } = action
    let formState = yield select(getFormSelector(form))
    formState = yield call(toJS, formState)
    const groupNames = getSelectedTabsFromState(formState)
    const args = {
      dataId,
      form,
      groupNames: groupNames.map(trimNonWord)
    }
    const R = yield call(api.unlockEntity, args)
    if (R.response) {
      // debugger
      // update for mapResponse logic.
      const mapResponse = getMapResponse({ formState, groupNames })
      const response = mapResponse({
        response: R.response,
        groupNames,
        formState
      })
      yield put({
        meta: { form },
        payload: { ...response },
        type: UNLOCK_ENTITY.SUCCESS
      })
    } else {
      yield put({
        meta: { form },
        payload: R.error,
        type: UNLOCK_ENTITY.FAILURE
      })
    }
  }
}

export function* onExitListener() {
  const channel = yield actionChannel(EXIT)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form }
    } = action

    const el = $(`.lm_close_tab[namespace=${form}]`)
    console.log(el)
    if (el) {
      el.click()
    }
  }
}

export function* tryTabChangeProcess({
  form,
  selectedPrimaryTab,
  selectedSecondaryTab,
  screenOpen
}) {
  const formState = yield select(getFormSelector(form))

  const tabComponents = getIn(formState, 'masterOptions.tabComponents')
  const changeTab = put(
    ddiFormActions.changeFormTab(form, selectedPrimaryTab, selectedSecondaryTab)
  )
  // debugger
  if (getIn(formState, 'fetchingEntity')) {
    return
  }

  const tabId = selectedSecondaryTab
    ? `${selectedPrimaryTab}-${selectedSecondaryTab}`
    : selectedPrimaryTab
  const tab = getIn(tabComponents, tabId)
  const work = getIn(tab, 'access')
  const hasRecord = getIn(formState, 'hasRecord')
  const trackedTabs = getIn(formState, 'masterOptions.trackedTabs')
  const isNew =
    getIn(formState, 'values.isNew') ||
    getIn(formState, 'values.preNewMode') ||
    getIn(formState, 'newMode') ||
    getIn(formState, 'preNewMode')
  // debugger

  // work should be either a string, fn or null.
  // if it's a string call getMeta 2 with the appropriate 'groupName'

  if (hasRecord) {
    // we need to call searchProcess if we haven't been there yet...
    // debugger
    if (is.string(work)) {
      const callAlways = getIn(tab, 'callAlways')
      if (
        (callAlways && !isNew) ||
        (!trackedTabs.has(toCamelCase(trimNonWord(work))) && !isNew)
      ) {
        try {
          const f = yield call(searchProcess, form, [
            selectedPrimaryTab,
            selectedSecondaryTab
            // tabId
          ])

          if (f) {
            yield changeTab
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        yield changeTab
      }
    } else if (is.fn(work)) {
      yield call(work, form)
      yield changeTab
    } else if (!work) {
      yield changeTab
    }
  } else if (work) {
    if (is.string(work)) {
      // call access
      // debugger
      const { response } = yield call(getTabAccess, {
        form,
        groupName: work
      })
      if (response) {
        yield changeTab
      }
    } else if (is.fn(work)) {
      // i suppose this might still be needed..
      const accessed = getIn(tab, 'accessed')
      const callAlways = getIn(tab, 'callAlways')
      // const dataId = getIn()
      if (callAlways || !accessed) {
        try {
          yield call(work, form)
          yield changeTab
        } catch (e) {
          // // debugger
          console.log(e)
          // throw e
          // yield put(ddiFormActions.beforeDestroy(form, { name: form }))
        }
      } else {
        // accessed.. send them on their way,...
        yield changeTab
      }
    }
  } else {
    // no access check or anyting needed just change tab...
    yield changeTab
  }
}
export function* tryTabChangeListener() {
  const channel = yield actionChannel(TRY_CHANGE_FORM_TAB)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form },
      payload: { selectedPrimaryTab, selectedSecondaryTab }
    } = action

    yield fork(tryTabChangeProcess, {
      form,
      selectedPrimaryTab,
      selectedSecondaryTab
    })
  }
}

export function* displayValidationErrors(error) {
  let errorMessageString
  if (error?.validationErrors && Array.isArray(error.validationErrors)) {
    errorMessageString = error.validationErrors.reduce((acc, next) => {
      acc = acc.concat(`${next.property}: ${next.message}\n`)
      return acc
    }, '')
  } else if (error?.messages && Array.isArray(error.messages)) {
    for (const msg of error.messages) {
      yield put(
        show({
          message: {
            message: msg.title? `${msg.title}- ${msg.message}` : msg.message,
            type: 'warning',
            persist: false
          }
        })
      )
    }
  } else if (error?.message) {
    errorMessageString = error.message
  }
  if (errorMessageString && !errorMessageString.includes('Must use Save As')){
    yield put(
      show({
        message: {
          message: errorMessageString,
          type: 'warning',
          persist: false
        }
      })
    )
  }
}

export function* onLogoutListener() {
  while (true) {
    const action = yield take([
      SUBMIT_LOGOUT_USER.SUCCESS,
      SUBMIT_LOGOUT_USER.FAILURE,
      AUTHENTICATION_ERROR,
      CLEAR_SELECTED_BRANCH
    ])
    let route
    const isMobile = yield select(isMobileSelector)
    if (isMobile) {
      route = yield select(state =>
        state
          .get('router')
          .get('location')
          .get('pathname')
      )
    }
    /* eslint-disable no-loop-func */
    const bool =
      isMobile &&
      route === '/app' &&
      action.type.includes('CLEAR_SELECTED_BRANCH')

    Object.keys(forms).forEach(x => {
      if (bool && x !== 'calendar') {
        const f = forms[x]
        if (f?.initialized?.isRunning()) {
          if (f?.initialized?.cancel) {
            f.initialized.cancel()
          }
        }
      }
    })
    if (bool) {
      const { calendar } = forms
      forms = { calendar }
    } else {
       for (const form of Reflect.ownKeys(forms)) {
           yield fork(destroyScreen, form);
       }
      forms = {}
    }
  }
}

export const tabChangeProcess = function* tabChangeProcess({
  startTab,
  screen,
  activeKey,
  dataId,
  ledgerOpenId,
  groupNames,
  screenOpen,
  ...rest
}) {
  // ledgerOpenId
  // debugger
  groupNames = groupNames || ['setup']
  // getFormState
  // if form hasRecord
  // if locked// call cancel lock try
  const formState = yield select(getFormSelector(screen))
  let canceled
  if (getIn(formState, 'hasRecord')) {
    if (getIn(formState, 'isEditing')) {
      yield put(cancelEditAsync.try(screen))
      canceled = yield take([CANCEL_EDIT.SUCCESS, CANCEL_EDIT.FAILURE])
    }
  }

  // debugger
  if (
    (!canceled || (canceled && canceled.type === CANCEL_EDIT.SUCCESS)) &&
    (dataId || ledgerOpenId)
  ) {
    // look up the new thing
    const { error, response } = yield call(api.read, {
      ...rest,
      activeKey,
      groupNames,
      name: screen,
      dataId: ledgerOpenId || dataId
    })
    // console.log(openScreen)

    if (response) {
      // debugger
      // reset the formState
      // debugger
      yield put(resetMasterFields(screen))

      if (activeKey === 'ledger') {
        /* 
          IMPORTANT NOTE: the uppercase 'Ledger' and lowercase 'invoices' is super 
          important here. Also change the form tab before the response -- SVE 3/5/2020
        */
        yield put(ddiFormActions.tryChangeFormTab(screen, 'Ledger', 'invoices'))
      }
      const mapResponse = getMapResponse({ formState })

      const responseData =
        groupNames && groupNames.length && formState && mapResponse
          ? mapResponse({ response, groupNames, formState })
          : response
      yield putResolve(
        getEntityAsync.success(responseData, {
          form: screen,
          screenOpen
        }) // TODO GENERICS... args of cb succ/fail takes async method etc..
      )

      yield fork(handleEntityResponse, screen, response)
      if (activeKey === 'analysis') {
        yield put(
          ddiFormActions.tryChangeFormTab(screen, 'analysis', 'overview')
        )
      }
    } else if (error) {
      yield putResolve(
        getEntityAsync.failure(error, {
          form: screen
        })
      )
    }
  }
}
export const onTabChangeListener = function* onTabChangeAction() {
  // cases..
  // screen has no record - no need to do anything
  // screen has entity
  // screen is locked
  // allow for injectable saga...

  // contact needs to change its title on load/etc
  const channel = yield actionChannel(CHANGE_TAB)
  while (true) {
    const action = yield take(channel)
    const {
      startTab,
      screen,
      activeKey,
      dataId,
      ledgerOpenId,
      ...rest
    } = action.payload

    yield fork(tabChangeProcess, {
      startTab,
      screen,
      activeKey,
      dataId,
      ledgerOpenId,
      ...rest
    })
  }
}
export const getChildAndParentForm = form => {
  const formParts = form?.split('.') || []

  if (!formParts?.[1]) {
    return {
      childForm: form,
      parentForm: form
    }
  }

  if (formParts?.[2] && formParts?.[1]) {
    return {
      childForm: formParts[2],
      parentForm: `${formParts[0]}.${formParts[1]}`
    }
  }

  const childForm = formParts[1]
  const parentForm = formParts[0]

  return {
    childForm,
    parentForm
  }
}
export function* exitScreenFromModalProcess(form, modalId) {
  const { parentForm } = getChildAndParentForm(form)
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false
  // debugger
  if (isEditing) {
    yield put({
      type: MASTER_CONSTANTS.CANCEL_EDIT.TRY,
      meta: { form }
    })

    const result = yield take([
      MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS,
      MASTER_CONSTANTS.CANCEL_EDIT.FAILURE
    ])

    if (
      result?.meta?.form &&
      result?.meta?.form === form &&
      result?.type === MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS
    ) {
      yield put(removeModal(parentForm, modalId))
    }
  } else {
    yield put(removeModal(parentForm, modalId))
  }
}

export function* exitScreenFromModalListener() {
  while (true) {
    const {
      meta: { form },
      payload: { modalId }
    } = yield take(action => action.type.includes('EXIT_SCREEN_FROM_MODAL'))

    yield fork(exitScreenFromModalProcess, form, modalId)
  }
}

export default function* ddiFormListeners() {
  yield fork(onBeforeDestroyListener)
  yield fork(onCancelListener)
  yield fork(onDeleteListener)
  yield fork(formInitializeListener)
  yield fork(onSaveListener)
  yield fork(unlockEntityListener)
  yield fork(onExitListener)
  yield fork(tryTabChangeListener)
  yield fork(closeSendDocument)
  yield fork(onLogoutListener)
  yield fork(onTabChangeListener)
  yield fork(exitScreenFromModalListener)
}
