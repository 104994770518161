import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import DDICardWrapper from 'components/DDICardWrapper'
import { getIn, toJS } from 'utils'
import { required } from 'ddiForm/validations'
import PropTypes from 'prop-types'
import CustomDateField from './components/CustomDateField'
import CustomDropdownField from './components/CustomDropdownField'
import CustomTextField from './components/CustomTextField'
import CustomToggleField from './components/CustomToggleField'
import './styles/master-style.scss'


const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const customFieldsDataSet =
    getIn(formState, 'fields') || {}
  const hasCustomFields = getIn(formState, 'meta.customFields.hasCustomFields')

  return {
    customFieldsDataSet,
    hasCustomFields
  }
}

 const CustomFieldsComp = ({
  form,
  customFieldsData,
  hasCustomFields,
  isActivityScreen = false,
  customFieldsDataSet,
  dataCloudHelp
}) => {
  const fieldsMap = {
    CBX: CustomToggleField,
    TXT: CustomTextField,
    TXTN: CustomTextField,
    D: CustomDateField,
    DDB: CustomDropdownField,
    MD: CustomTextField
  }
  const [data, setData] = useState(null)

  useEffect(() => {
    const d = customFieldsData?.toJS ? customFieldsData?.toJS() : customFieldsData
    const sortedD = d?.length
      ? d.sort((a, b) => a.fieldNumber - b.fieldNumber)
      : d
    // debugger
      setData(sortedD)
  }, [customFieldsData])

  const renderFields = (fields = []) => {
    const customFieldsList = []
    fields.forEach((field, i) => {
      const Comp = fieldsMap[field.fieldType]
      const editedFields = customFieldsDataSet.toJS();
      const editedValueItem = Object.keys(editedFields).find(item=>{
        return item.split('-')[1] === field.fieldNumber;
      });
      const editedValue = editedFields[editedValueItem]?.value || "";
      const Field = (
        <Comp
          {...field}
          propertyName={`customFields-${field.fieldNumber}`}
          label={field.description}
          meta={{
            dataId: field.dataId
          }}
          editedValue={editedValue || ""}
          validate={field.required ? [required] : null}
          wrapperStyle={{ marginBottom: 15 }}
          noRequiredIcon={false}
        />
      )
      customFieldsList.push(Field)
    })
   
    return (
        <div className="left-fields">{customFieldsList}</div>
    )
  }

  if (isActivityScreen) {
  return (
      <div className="inner-wrapper" data-cloud-help={dataCloudHelp}>
      {hasCustomFields && data?.length ? (
         renderFields(data)
      ) : (
         <span>No Custom Fields.</span>
      )}
    </div>
    )
  }

  return (
    <div className="custom-fields-outer-wrapper" data-cloud-help={dataCloudHelp}>
      <DDICardWrapper title="Custom Fields" style={{ textAlign: 'center' }}>
        <div className="inner-wrapper">
          {hasCustomFields && data?.length ? (
            renderFields(data)
          ) : (
            <span>No Custom Fields.</span>
          )}
        </div>
      </DDICardWrapper>
    </div>
  )
}

const CustomFields = connect(
  mapStateToProps,null,null
)(CustomFieldsComp)

CustomFieldsComp.defaultProps = {
  customFieldsData:[],
  hasCustomFields: PropTypes.bool,
  customFieldsDataSet:{},
  toJS: ()=>{}
}

CustomFieldsComp.propTypes={
  form: PropTypes.string.isRequired,
  customFieldsData: PropTypes.array,
  hasCustomFields: PropTypes.bool,
  isActivityScreen: PropTypes.bool.isRequired,
  customFieldsDataSet:PropTypes.object,
  toJS: PropTypes.any
}

CustomFields.defaultProps = {
  customFieldsDataSet:{},
  customFieldsData:[],
  hasCustomFields: false,
}

CustomFields.propTypes = {
  form: PropTypes.string.isRequired,
  customFieldsDataSet: PropTypes.object,
  customFieldsData: PropTypes.array,
  hasCustomFields: PropTypes.bool,
  isActivityScreen: PropTypes.bool.isRequired,
}

export default CustomFields