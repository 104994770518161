/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@mui/material'
import { getRgb, plainDeepEqual } from 'utils'
import moment from 'moment'
import shortid from 'shortid'

export const mapStateToProps = state => ({
  tasksData: taskDataSelector(state) || []
})

export default class ActivityTooltipTemplate extends Component {
  static propTypes = {
    content: PropTypes.object,
    event: PropTypes.object,
    node: PropTypes.object
  }

  static defaultProps = {
    content: {},
    event: null,
    node: null
  }

  constructor(props) {
    super(props)
    this.state = {
      data: null,
      isSet: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps &&
      nextProps.content &&
      !plainDeepEqual(nextProps.content, prevState.data)
    ) {
      return {
        data: nextProps.content
      }
    }

    if (
      nextProps.node &&
      nextProps.node.data &&
      nextProps.node.data.tooltip &&
      !plainDeepEqual(prevState.data, nextProps.node.data.tooltip)
    ) {
      return {
        data: nextProps.node.data.tooltip
        // isSet: true
      }
    }

    return null
  }

  render() {
    if (!this.state.data || !this.state.data.title) return null
    const { content } = this.state.data
    let {
      body,
      footer: { actions = [] },
      title: { description, id }
    } = this.state.data

    body = body || {}
    const {
      info = [],
      startDate,
      endDate,
      location,
      notes,
      reference,
      reminder,
      users
    } = body

    return (
      <div className="inform-tooltip" style={{ width: 400 }}>
        <div
          className="tooltip-heading"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center'
          }}
        >
          <h2
            style={{
              color: '#fff',
              fontSize: 12,
              fontWeight: 500,
              lineHeight: '14px'
            }}
          >
            <Icon
              style={{ color: '#fff', fontSize: 16, verticalAlign: 'middle' }}
            >
              event
            </Icon>
            {description}
          </h2>
        </div>
        <div className="tooltip-content">
          <div>
            {info.map((x, i) => (
              <div style={{ color: 'white', fontSize: 11 }} key={x}>
                {x}
              </div>
            ))}
            <ul className="calendar-tooltip-data" style={{ marginLeft: 0 }}>
              {startDate && (
                <li>
                  <strong>Start Date:</strong>{' '}
                  {moment(startDate).format('MMMM Do YYYY, h:mm a')}
                </li>
              )}
              {endDate && (
                <li>
                  <strong>End Date:</strong>{' '}
                  {moment(endDate).format('MMMM Do YYYY, h:mm a')}
                </li>
              )}
              {reminder && (
                <li>
                  <strong>Reminder:</strong> {reminder}
                </li>
              )}
              {location && (
                <li>
                  <strong>Location:</strong> {location}
                </li>
              )}
              {users && (
                <li>
                  <strong>Users:</strong> {users}
                </li>
              )}
              {reference && <li>{reference}</li>}
            </ul>
            {notes && (
              <div>
                <h3 style={{ color: '#fff', margin: '0 0 5px 0' }}>Notes</h3>
                <p style={{ color: '#fff', fontSize: 11, lineHeight: '15px' }}>
                  {notes}
                </p>
              </div>
            )}
          </div>
          {/*actions && actions.length ? (
            <div style={{ marginTop: 10 }}>
              {actions.map((x, i) => (
                <div style={{ marginBottom: 5, color: '#444' }} key={x.id}>
                  <span
                    style={{
                      background: '#fff',
                      borderRadius: 4,
                      display: 'inline-block',
                      fontSize: 10,
                      lineHeight: '10px',
                      marginRight: 3,
                      padding: 2,
                      minWidth: 12
                    }}
                  >
                    {x.id}
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      fontSize: 11,
                      color: '#fff'
                    }}
                  >
                    {x.description}
                  </span>
                </div>
                  ))}
            </div>
                ) : null*/}
        </div>
      </div>
    )
  }
}
