import React from 'react'
import { Icon } from '@mui/material'

const noDataMessage = (message = 'no data available') => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10,
      padding: 15,
      width: '100%'
    }}
  >
    <Icon style={{ color: '#1abcf7' }}>warning</Icon>
    <p style={{ fontSize: 14, margin: '0 0 0 10px' }}>{message}</p>
  </div>
)

export default noDataMessage
