import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import memoize from 'memoize-one'

import { noop } from 'utils'
import IconButton from '@mui/material/IconButton'
import { Icon, Popover, TextField } from '@mui/material'

import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { intersection, isEqual, uniq } from 'lodash'
import shortid from 'shortid'
import { ddiFormStyles } from 'ddiForm/ddiFormStyles'
import DropdownGrid from './components/DropdownGrid'
import './styles/css-mod-ignore.scss'


const comma = ','
const tab = 'Tab'
const blur = 'blur'

const nonEmptyString = x => x !== ''

const split = str => str.split(comma).filter(nonEmptyString)

const getGridHeight = memoize(data => {
  let height = 200
  if (data && Array.isArray(data) && data.length) {
    height = data.length * 28+40;
  }

  return height
}, isEqual)

const propsToUpdateFor = ['value']

/* eslint-disable no-nested-ternary */
const mapStateToProps = (state, ownProps) => {
  const rowData =
    ownProps.rowDataSelector && !ownProps.rowData
      ? ownProps.rowDataSelector(state)
      : typeof ownProps.rowData === 'function'
      ? ownProps.rowData()
      : ownProps.rowData
  const ret = {
    gridOptions: {
          ...ownProps.gridOptions,
          columnDefs: [{
              cellClass: 'is-checkbox-selection flex-centered search-dropdown-cell',
              cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 50, padding: 0 },
              checkboxSelection: true,
              headerClass: 'is-checkbox-selection no-padding flex-centered',
              headerStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' },
              suppressSorting: true,
              headerCheckboxSelection: ownProps.selectAll || false,
              width: 50,
              maxWidth: 50
          },
              ...ownProps.gridOptions.columnDefs],
    },
    rowData
  }
  return ret
}

const makeSelectable = (rowData = [], initialValue) => {
  let initialList = []
    if (initialValue) {
        initialList = split(initialValue)
  }

  return rowData.map(x => ({
      ...x,
      isSelected: getIsSelected(initialList, x)
  }))
}

const getIsSelected = (initialList, element) =>
{
    let isSelected
    if (element?.dataId) {
        isSelected = initialList.includes(element.dataId)
    }
    else {
        isSelected = intersection(initialList, Object.values(element)).length > 0
    }
    return isSelected
}

class SearchDropdown extends Component {
  static propTypes = {
    disableToolTip: PropTypes.bool,
    enableRowClickSelection: PropTypes.bool,
    floatingLabelFocusStyle: PropTypes.object,
    floatingLabelShrinkStyle: PropTypes.object,
    floatingLabelStyle: PropTypes.object,
    floatingLabelText: PropTypes.string,
    fullWidth: PropTypes.bool,
    gridOptions: PropTypes.object.isRequired,
    height: PropTypes.number,
    hintStyle: PropTypes.object,
    hintText: PropTypes.string,
    id: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    value:PropTypes.string,
    inputLabelProps: PropTypes.object,
    inputStyle: PropTypes.object,
    isMobile: PropTypes.bool,
    onCloseCb: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onTab: PropTypes.func,
    placeholder: PropTypes.string,
    rowData: PropTypes.array.isRequired,
    rowSelection: PropTypes.string,
    sortValue: PropTypes.bool,
    style: PropTypes.object,
    wrapperMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    disableToolTip: false,
    enableRowClickSelection: false,
    floatingLabelFocusStyle: ddiFormStyles.selectField.floatingLabelFocusStyle,
    floatingLabelShrinkStyle:
      ddiFormStyles.selectField.floatingLabelShrinkStyle,
    floatingLabelStyle: {
      color: '#517b9c',
      fontSize: 12,
      fontWeight: 'bold',
      top: 24
    },
    floatingLabelText: undefined,
    fullWidth: false,
    gridOptions: {},
    height: 400,
    hintStyle: ddiFormStyles.textField.hintStyle,
    hintText: undefined,
    id: null,
    initialValue: undefined,
    value:undefined,
    inputLabelProps: null,
    inputStyle: ddiFormStyles.textField.inputStyle,
    isMobile: false,
    onCloseCb: noop,
    onTab: noop,
    placeholder: undefined,
    rowSelection: 'multiple',
    sortValue: false,
    style: ddiFormStyles.selectField.style,
    width: 300,
    wrapperMargin: '5px 0',
    onDoubleClick: noop,
  }

  constructor(props) {
    super(props)
    this.state = {
      gridKey: shortid.generate(),
      gridOptions: {
        ...props.gridOptions,
        className: props.gridOptions.className
          ? props.gridOptions.className
          : 'ag-theme-balham',
        onGridReady: ({ api }) => {
          this.gridApi = api
          api.forEachNode(node => {
            if (node.data.isSelected) node.setSelected(true)
          })
        }
      },
      id: props.id || shortid.generate(),
      isDirty: false,
      isOpen: false,
      rowData: makeSelectable(props.rowData, props.initialValue),
      value: props.value || props.initialValue,
    }
  }

  componentDidMount() {
    this.anchorEl = findDOMNode(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps?.form &&
      nextProps?.ConnectedField &&
      nextProps.value !== prevState.value &&
      !prevState.isOpen &&
      Array.isArray(nextProps.rowData)
    ) {
      /* this importantly fixes an issue for when users cancel edit (or change entities on a given screen) */
      return {
        gridKey: shortid.generate(),
        rowData: makeSelectable(nextProps.rowData, nextProps.value),
        lastValue: nextProps.value,
        value: nextProps.value,
       
      }
    }

    if (nextProps.value !== prevState.lastValue) {
      return {
        lastValue: nextProps.value,
        value: nextProps.value
      }
    }

    return null
  }


    componentDidUpdate(prevProps, prevState) {
      if (prevState.value !== this.state.value) {
        this.onTab(this.state.value);
    }
        if (this.gridApi && !this.gridApi.destroyCalled)
        {
            this.gridApi?.forEachNode(node => {
                if (node.data.isSelected) node.setSelected(true)
            })
        }
    if (!prevState.focus && this.state.focus && this.input) {
      // kinda hacky.. need a better way to figure this out?
      setTimeout(() => this.input.select(), 0)
    }
  }

  onBlur = event => {
    this.onKeyDown(event)
  }

  onChange = event => {
    this.setState({ isDirty: true, value: event.target.value })
  }

  onKeyDown = event => {
    const { isDirty, rowData } = this.state
    const { identifier } = this.props
    if (isDirty && (event.key === tab || event.type === blur)) {
      event.preventDefault()
      const val = event.target.value
        let valList = split(val)
        valList = uniq(valList)
      valList = valList.filter(
        x => ~rowData.findIndex(y => y[identifier] === x)
      )
      this.setState(
        {
          isDirty: false,
          value: this.props.sortValue
            ? valList.sort().join(comma)
            : valList.join(comma)
        },
        () => this.onTab(this.state.value)
      )
    }
  }

  onKeyUp = event => {
    const { identifier } = this.props
    const val = event.target.value
    const valList = split(val)

    this.setState(prevState => {
      const newRowData = prevState.rowData.map(row => ({
        ...row,
        isSelected: Boolean(~valList.indexOf(row[identifier]))
      }))

      return {
        ...prevState,
        rowData: newRowData
      }
    })
  }

  onTab = val => {
    if (this.props.setField) {
      this.props.setField(val)
    } else {
      this.props.onTab(val)
    }

    if (this.input && this.input.blur) {
      this.input.blur()
    }
  }

  onRowSelected = (event) => {
    const node = event.node;
    const { rowIndex } = node;
    const { identifier } = this.props;
    const selectedRows = event.api.getSelectedRows();
    let value = this.state.value || '';
    let valList = '';
    const isSelected = node.isSelected();
    

    if (selectedRows.length === 0) {
        
        valList = selectedRows.map(a => a.dataId);
        this.setState(prevState => {
          const newRowData = prevState.rowData.map(row => ({
              ...row,
              isSelected: false // Set isSelected to false for all rows
          }));
          const newValue = '';
          const noChange = this.state.value != newValue;
          return {
              rowData: newRowData,
              value: newValue,
              valueChanged: noChange // Update to reflect that the value has changed
          };
      });
    } else if (selectedRows.length === event.api.getDisplayedRowCount()) {
      valList = selectedRows.map(a => a.dataId);
          this.setState(prevState => {
            const newRowData = prevState.rowData.map(row => ({
                ...row,
                isSelected: true // Set isSelected to true for all rows
            }));
            const newValue = newRowData.map(row => row.dataId).sort().join(',');
            const allValueChanged = this.state.value != newValue;
            return {
                rowData: newRowData,
                value: newValue,
                valueChanged: allValueChanged // Update to reflect that the value has changed
            };
        });
    } else if (selectedRows.length !== event.api.getDisplayedRowCount()){
        valList = uniq(split(value));
        const id = node.data[identifier];
        const idx = valList.indexOf(id);

        if (isSelected) {
            if (idx === -1) {
                valList.push(id);
            }
        } else if (idx !== -1) {
            valList.splice(idx, 1); // Use splice to remove the item
        }
    }

    value =  valList.sort().join(',');
    const changed = this.state.value != value;
    if(this.state.value===""){
      this.setState({valueChanged: false});
    }
    // Use functional state updates to prevent dependency issues
    if(!this.state.valueChanged || this.state.value===""){
      if(changed){
          this.setState(prevState => {
          const newRowData = [...prevState.rowData];
          newRowData[rowIndex] = {
              ...newRowData[rowIndex],
              isSelected
          };

          return {
              rowData: newRowData,
              value: changed ? value : prevState.value,
          };
      });
    }
  }
}

  select = () => {
    if (this.input) {
      this.setState({ focus: true });
    }
  }

  makeTooltip = () => {
    const { value } = this.state
    const { disableToolTip } = this.props
    let returnVal = null
    if (disableToolTip) return null

    if (typeof value === 'string' && value) {
      returnVal = (
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {value.split(comma).map((v, k, arr) => {
            const len = arr.length
            const c = len > 1 && k + 1 < len ? comma : ''
            return <li key={v}>{`${v}${c}`}</li>
          })}
        </ul>
      )
    }
    return returnVal
  }

  inputRef = c => {
    this.input = c
    if (this.props.inputRef && typeof this.props.inputRef === 'function') {
      this.props.inputRef(c)
    }
  }

  getReference = () =>
    this.props.inputRef && typeof this.props.inputRef === 'object'
      ? this.props.inputRef
      : this.inputRef

  positionFilterEditor = params => {
    params.ePopup.style.zIndex = 1302
    params.ePopup.style.paddingBottom = '20px'
  }

  onCloseCb = () => {
    if (this?.props?.onCloseCb && typeof this.props.onCloseCb) {
      this.props.onCloseCb(this.state.value)
    }
  }

  render() {
    const {
      disabled,
      enableRowClickSelection,
      floatingLabelFocusStyle,
      floatingLabelShrinkStyle,
      floatingLabelStyle,
      floatingLabelText,
      fullWidth,
      height,
      hintStyle,
      hintText,
      inputLabelProps,
      inputStyle,
      placeholder,
      rowSelection,
      style,
      width,
      wrapperMargin,
      onDoubleClick,
      isMobile,
      positionRight
    } = this.props

    const { gridOptions, id, rowData } = this.state
    const reference = this.getReference()
    const gridHeight = getGridHeight(rowData)

    return (
      <div style={{ position: 'relative', margin: wrapperMargin }}>
        {!isMobile ? (
          <ReactTooltip
            delayShow={300}
            getContent={this.makeTooltip}
            id={id}
            place="right"
          />
        ) : null}
        <TextField
          label={floatingLabelText}
          fullWidth={fullWidth}
          placeholder={placeholder}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          onDoubleClick={onDoubleClick}
          inputRef={reference}
          value={this.state.value || ''}
          disabled={disabled}
          InputLabelProps={inputLabelProps}
          variant="standard"
        />
        <IconButton
          onClick={() => this.setState({ isOpen: true })}
          style={{
            background: '#fff',
            paddingBottom: 5,
            position: 'absolute',
            right: positionRight || -8,
            top: floatingLabelText ? 10 : -5
          }}
          data-tip
          data-for={id}
          data-class="inform-tooltip"
          data-effect="solid"
          tabIndex={-1}
          disabled={disabled}
        >
          <Icon style={{ color: '#444' }}>arrow_drop_down</Icon>
        </IconButton>
        <Popover
          anchorEl={this.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={this.state.isOpen}
          onClose={() =>
            this.setState({ isOpen: false }, () => this.onCloseCb())
          }
          style={{ width }}
        >
          <div
            id="search-dropdown-inner-wrapper"
            style={{
              width: width || 300,
              paddingRight: 15,
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <DropdownGrid
                        key={this.state.gridKey}
                        gridOptions={gridOptions}
                        gridRef={c => (this.grid = c)}
                        rowSelection={rowSelection}
                        onRowSelected={this.onRowSelected}
                        rowData={rowData}
                        suppressRowClickSelection={!enableRowClickSelection}
                        gridHeight={gridHeight}
                        postProcessPopup={this.positionFilterEditor}
                        value={this.state.value}
                        isOpen={this.state.isOpen}
            />
          </div>
        </Popover>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(SearchDropdown)
