import React, { useState } from 'react'
import moment from 'moment'
import {
  FormControlLabel,
  Radio
} from '@mui/material'
import { getRgb, numToDollar } from 'utils'
import ColorDataCell from 'grid/ColorDataCell'
import GridCellNumberSpinner from './components/GridCellNumberSpinner'
import ProductCell from './components/ProductCell'
// import ProductTooltipCell from 'pages/CustomerOrderPad/components/ProductTooltipCell'

export const metaData = {
  indexSearchFields: {
     customer: {
        "fieldName":"Customer",
        "searchType":"Customer",
        "hideToggle":false,
        "hasFilters":true,
        "allowSearchAll":false,
        "allowInstantSearch":true,
        "minimumKeywordLength":2,
        "allowNavigation":false,
        "allowSpecialProducts":false,
        "openText":"Open Customer Master",
        "isSecure":false
     },
     shipTo: {
        "fieldName":"Ship To",
        "searchType":"ShipTo",
        "hideToggle":true,
        "hasFilters":false,
        "allowSearchAll":false,
        "allowInstantSearch":false,
        "minimumKeywordLength":2,
        "allowNavigation":false,
        "allowSpecialProducts":false,
        "openText":"Open Ship To Master",
        "isSecure":false
     },
     productLine: {
        "fieldName":"Product Line",
        "searchType":"ProductLine",
        "hideToggle":true,
        "hasFilters":false,
        "allowSearchAll":true,
        "allowInstantSearch":true,
        "minimumKeywordLength":0,
        "allowNavigation":false,
        "allowSpecialProducts":false,
        "openText":"Open Product Line Master",
        "isSecure":false
     },
     vendor: {
        "fieldName":"Primary Vendor",
        "searchType":"Vendor",
        "hideToggle":true,
        "hasFilters":false,
        "allowSearchAll":false,
        "allowInstantSearch":true,
        "minimumKeywordLength":2,
        "allowNavigation":false,
        "allowSpecialProducts":false,
        "openText":"Open Vendor Master",
        "isSecure":false
     }
  },
  defaultGroupBy: "V",
  groupByOptions: [
    {
       "dataId":"N",
       "description":"Product"
    },
    {
       "dataId":"P",
       "description":"Product Line"
    },
    {
       "dataId":"V",
       "description":"Primary Vendor"
    },
    {
       "dataId":"T",
       "description":"24 Month#Hits"
    },
    {
       "dataId":"S",
       "description":"Line Seq #"
    }
 ],
  filterByOptions: [
     {
        "color":{
           "r":0,
           "g":0,
           "b":0
        },
        "dataId":"A",
        "description":"Show All"
     },
     {
        "color":{
           "r":255,
           "g":0,
           "b":0
        },
        "dataId":"D",
        "description":"Due To Buy"
     },
     {
        "color":{
           "r":50,
           "g":205,
           "b":50
        },
        "dataId":"Q",
        "description":"Quoted"
     },
     {
        "color":{
           "r":0,
           "g":0,
           "b":255
        },
        "dataId":"I",
        "description":"History"
     },
     {
        "color":{
           "r":0,
           "g":0,
           "b":0
        },
        "dataId":"H",
        "description":"Hidden"
     }
  ],
  reportTypeOptions: [
     {
        "dataId":"O",
        "description":"Customer Order Form"
     },
     {
        "dataId":"P",
        "description":"Customer Price Analysis"
     },
     {
        "dataId":"L",
        "description":"Customer Price List"
     }
  ],
  printOptions: [
     {
        "dataId":"P",
        "description":"Prices"
     },
     {
        "dataId":"M",
        "description":"Stock Minimum Qtys"
     },
     {
        "dataId":"N",
        "description":"None"
     }
  ],
  accessPathReport: null,
  defaultCutOffDate: "2013-04-11T12:35:53.8412347-04:00",
  isSingleInstance: true,
  maxInstances: 1,
  guid: null
}

export const makeRadioButtons = (data, name = 'groupBy') => {
  if (data && Array.isArray(data) && data.length) {
    return data.map((filter, idx) => (
        <FormControlLabel
          control={
            <Radio
              style={{
                color: filter.color,
                height: 24,
                marginRight: 5,
                width: 24
              }}
            />
          }
          style={(idx === data.length - 1) ? {} : { paddingRight: 15, minWidth: 90 }}
          value={filter.dataId}
          label={
            name === 'filterBy' ? (
              <span style={{ color: getRgb(filter.color) }}>
                {filter.description}
              </span>
            ) : (
              filter.description
            )
          }
        />
      ))
  }

  return []
}


export const styles = {
  inputPaperStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20 },
  radioButtonSetWrapperStyle: { background: '#fdfefe', border: '1px solid #edecec', borderRadius: 100, marginTop: 10, padding: '5px 5px 0 30px' },
  radioButtonInnerWrapperStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  radioButtonStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }
}

export const useTouchedFields = () => {
  const [touchedField, setTouchedField] = useState(false);

  const handleFocus = (fieldValue) => {
    if(fieldValue===''){
      setTouchedField(true);
    } 
  };

    return { handleFocus, touchedField, setTouchedField };
};

const dateFields = ['priceDate']
const tooltipFields = ['description', 'currentPrice']
const dataFields = ['productLine', 'vendor']

export const mapGridHeaders = (col, i) => {
   // col = {
   //   ...col,
   //   rowGroup: false
   // }
 
   col = {
     ...col,
     cellRendererFramework: ColorDataCell
   }
 
   if (col.field === 'dataId') {
     col = {
       ...col,
       cellRendererFramework: ProductCell
     }
   }
 
   if (dataFields.indexOf(col.field) !== -1) {
     col = {
       ...col,
       valueGetter: params => {
         if (params.data) {
           return params.data[col.field] && (`${params.data[col.field].dataId}: ${params.data[col.field].description}`)
         }
         return null
       }
     }
   }
 
   if (tooltipFields.indexOf(col.field) !== -1) {
     col = {
       ...col,
       tooltipField: `${col.field}.tooltip`,
       valueGetter: params => {
         if (params.data && params.data[col.field] && params.data[col.field].value) {
           return params.data[col.field].value
         }
         return null
       }
     }
   }
   /* parse the dollar field */
   if (col.field === 'currentPrice' || col.field === 'priceDisplay') {
     col = {
       ...col,
       cellClass: 'align-right',
       headerClass: 'align-right',
       valueFormatter: params => numToDollar(params.value, 'en-US', 'USD', 4, 10)
     }
   }
 
   /* format any date fields */
   if (dateFields.indexOf(col.field) !== -1) {
     col = {
       ...col,
       cellClass: 'text-center align-center',
       valueFormatter: params => {
         if (params.value) {
           return params.value
             ? moment(new Date(params.value)).format('M/D/YY')
             : params.value
         }
         return null
       }
     }
   }
 
   if (col.field === 'numberOfPurchases') {
     col = {
       ...col,
       valueGetter: params => {
         if (params.data) {
           return `Hits: ${params.data.numberOfPurchases}`
         }
         return null
       }
     }
   }
 
   if (col.field === 'qtyAndUOM' || col.field === 'ytdQty' || col.field === 'minimumQuantity') {
     col = {
       ...col,
       cellClass: 'align-right',
       headerClass: 'align-right'
     }
   }
 
   if (col.field === 'quantity') {
     col = {
       ...col,
       width: 125,
       cellClass: 'align-center text-center-important',
       cellRendererFramework: GridCellNumberSpinner,
       cellRendererParams: {
         form: 'mobileOrderPad'
       },
       headerClass: 'text-center text-center-important',
       editable: false
      //  cellEditor: 'numericCellEditor'
     }
   }


   if (col.field === 'uomId') {
      col = {
         ...col,
         cellClass: 'align-center text-center',
         headerClass: 'align-center text-center'
      }
   }

   const smallFields = ['uomId', 'seqNo', 'qtyAndUOM']

   col = {
      ...col,
      minWidth: smallFields.includes(col.field) ? 80 : 150,
      suppressMenu: true
   }
 
   return col
 }
 