import React, { forwardRef, useState, useCallback } from 'react';
import { TextField } from '@mui/material';
 
const ProtectedPassword = forwardRef((props, ref) => {
  const { onChange, ...rest } = props;
  const [displayValue, setDisplayValue] = useState('');
 
  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setDisplayValue(newValue); // Store the actual value
      if (onChange) {
        onChange({ target: { value: newValue } }); // Notify parent of value change
      }
    },
    [onChange]
  );
 
  return (
<TextField
      {...rest}
      variant="standard"
      type="password" // Ensure the text input type is password for browser behavior
      value={displayValue}
      onChange={handleChange} // Handle change via onChange event
      ref={ref}
    />
  );
});
 
ProtectedPassword.displayName = 'ProtectedPassword';
 
export default ProtectedPassword;