import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Icon } from '@mui/material'
import nl2br from 'react-newline-to-break'
import { connect } from 'react-redux'
import useInterval from 'hooks/useInterval'

const Dialog = props => {
  const { data } = props
  const [count, setCount] = useState(data.timer)
  const [timeoutSubmitted, setTimeoutSubmitted] = useState(false)

  useInterval(() => {
    if (count !== 0) {
      setCount(count - 1)
    }
  }, 1000)

  useEffect(() => {
    if (count === 0) {
      if (!timeoutSubmitted) {
        props.dispatch({ type: 'TIMEOUT_MODAL_EXCEEDED', payload: {} })
        setTimeoutSubmitted(true)
      }
    }
  }, [count])

  return (
    <Row style={{ alignItems: 'center', display: 'flex' }}>
      <Col sm={2} style={{ textAlign: 'center' }}>
        {data.type === 'alert' ? (
          <Icon style={{ fontSize: 40 }}>error_outline</Icon>
        ) : (
          <Icon style={{ fontSize: 40 }}>info_outline</Icon>
        )}
      </Col>
      <Col sm={10} style={{ paddingLeft: 0 }}>
        {nl2br(
          `${data.message} ${count} second${
            count > 1 ? 's' : ''
          }. Press OK to continue.`
        )}
      </Col>
    </Row>
  )
}

export default connect()(Dialog)
