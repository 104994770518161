/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { Paper } from '@mui/material'
import { Label, StatusLabel } from 'ddiForm'
// import { Col, Row, PageHeader } from 'react-bootstrap'
import { getIn } from 'utils'
import DDITabTextField from 'ddiForm/wrapped/DDITabTextField'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import DDITextField from 'ddiForm/wrapped/DDITextField'
import DDISelectField from 'ddiForm/wrapped/DDISelectField'
import { smallTextFieldStyles } from 'ddiForm/ddiFormStyles'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
// import SearchField from '../SearchField'
// const log = args => console.log(args)
const styles = {
  ddiLabelStyle: {
    width: 'auto'
  },
  flexInputs: {
    description: { flex: 1, order: 3 },
    input: { flex: 1, marginRight: 10, maxWidth: 300, minWidth: 300, order: 2 },
    label: {
      flex: 1,
      marginRight: 10,
      maxWidth: 150,
      minWidth: 150,
      order: 1,
      textAlign: 'right'
    }
  },
  inputText: { fontSize: 12 },
  label: {
    color: '#444',
    fontSize: 12,
    fontWeight: 500,
    margin: 0
  },
  labelColumn: { textAlign: 'right' },
  rowStyle: {
    alignItems: 'center',
    display: 'inline-flex',
    flexWrap: 'wrap',
    margin: '10px 0',
    width: '100%'
  }
}

const nativeMUITextFieldStyles = {
  inputStyle: { marginTop: 0 },
  style: smallTextFieldStyles.textFieldStyle,
  underlineStyle: smallTextFieldStyles.underlineStyle,
  wrapperStyle: {
    clear: 'both',
    float: 'left',
    height: 26,
    margin: 0,
    position: 'relative',
    width: '100%'
  }
}

/* we don't need these options anymore because we are getting this info from meta */

/*
const options = [
  {
    value: 'O',
    description: 'Open'
  },
  {
    value: 'C',
    description: 'Completed'
  },
  {
    value: 'B',
    description: 'Backorders'
  },
  {
    value: 'Q',
    description: 'Quotes'
  },
  {
    value: 'E',
    description: 'Expired Quotes'
  },
  {
    value: 'R',
    description: 'Recurring'
  },
  {
    value: 'A',
    description: 'All'
  }
]
*/

/* eslint-disable react/no-multi-comp */
const selector = arr => (field, formState) => {
  if (!formState) return []
  const fields = getIn(formState, 'fields')
  return fields ? arr.some(x => fields[x] && !!fields[x].value) : []
}

export class InquiryTab extends Component {
  static propTypes = {
    hintStyle: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    textFieldStyle: PropTypes.object.isRequired
  }

  onChange = id => event => {
    event.persist()
    this.props.setField(id, event.target.value, false)
  }

  onStatusChange = (event, index, value) => {
    this.props.setField('status', value, false)
  }

  orderNumberDisabled = memoize(
    selector(['product', 'customer', 'poNumber', 'jobName', 'manualShipToInfo'])
  )

  productDisabled = memoize(
    selector(['orderNumber', 'poNumber', 'jobName', 'manualShipToInfo'])
  )

  customerDisabled = memoize(selector(['orderNumber']))

  poNumberDisabled = memoize(selector(['orderNumber', 'product']))

  jobNameDisabled = memoize(selector(['orderNumber', 'product']))

  manualShipToDisabled = memoize(selector(['orderNumber', 'product']))

  render() {
    const { hintStyle, meta, textFieldStyle } = this.props

    const metaData = {
      allowInvalidValues: true,
      allowInstantSearch: true,
      allowNavigation: false,
      allowSearchAll: false,
      fieldName: 'dataId',
      hasFilters: false,
      hideToggle: true,
      minimumKeywordLength: 0,
      openText: null,
      searchType: 'customer'
    }

    // const printTypes = getIn(meta, 'printTypes').toJS()
    // const salesOrderStatusOptions = getIn(
    //   meta,
    //   'salesOrderStatusOptions'
    // ).toJS()
    /* can cause errors without toJS check^^ -- SVE 12/18/2020 */

    let printTypes = getIn(meta, 'printTypes')
    printTypes = printTypes?.toJS ? printTypes.toJS() : []
    let salesOrderStatusOptions = getIn(meta, 'salesOrderStatusOptions')
    salesOrderStatusOptions = salesOrderStatusOptions?.toJS
      ? salesOrderStatusOptions.toJS()
      : []

    const showPrintTypes =
      (this.props.status === 'A' ||
        this.props.status === 'O' ||
        this.props.status === 'B') &&
      !this.props.asModal

    const colWidth = showPrintTypes ? 2 : 4

    return (
      <Paper style={{ padding: '10px 20px 15px 20px' }}>
        <div className="form-module-wrapper sales-order-inquiry-fields">
          <div className="input-flex-row" style={styles.rowStyle}>
            <div className="label" style={styles.flexInputs.label}>
              <label htmlFor="orderNumber" style={styles.label}>
                Order Number:
              </label>
            </div>
            <div className="input" style={styles.flexInputs.input}>
              <DDITabTextField
                propertyName="orderNumber"
                fullWidth
                onTab="find"
                disabled={this.orderNumberDisabled}
              />
            </div>
          </div>
          <div className="input-flex-row" style={styles.rowStyle}>
            <div className="label" style={styles.flexInputs.label}>
              <label htmlFor="product" style={styles.label}>
                Product:
              </label>
            </div>
            <div className="input" style={styles.flexInputs.input}>
              <DDIIndexSearch
                propertyName="product"
                metaKey="Product"
                searchType={INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.PRODUCT}
                popoverStyle={{ width: 1000 }}
                moreInfo
                openScreenData={{
                  name: 'productMaster',
                  image: 'product_master_16.png',
                  title: 'Product Master'
                }}
                minWidth="100%"
                openScreenMenuText="Open Product Master"
                disabled={this.productDisabled}
              />
            </div>
            <div className="description" style={styles.flexInputs.description}>
              <Label propertyName="product.description" />
            </div>
          </div>
          <div className="input-flex-row" style={styles.rowStyle}>
            <div className="label" style={styles.flexInputs.label}>
              <label htmlFor="customer" style={styles.label}>
                Customer:
              </label>
            </div>
            <div className="input" style={styles.flexInputs.input}>
              <DDIIndexSearch
                propertyName="customer"
                metaKey="Customer"
                searchType={INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.CUSTOMER}
                popoverStyle={{ width: 700 }}
                moreInfo
                autoFocus
                openScreenData={{
                  name: 'customerMaster',
                  image: 'customer_master_16.png',
                  title: 'Customer Master'
                }}
                minWidth="100%"
                openScreenMenuText="Open Customer Master"
                disabled={this.customerDisabled}
              />
            </div>
            <div className="description" style={styles.flexInputs.description}>
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <Label propertyName="customer.description" />
                <StatusLabel propertyName="customer" />
              </div>
            </div>
          </div>
          <div className="input-flex-row" style={styles.rowStyle}>
            <div className="label" style={styles.flexInputs.label}>
              <label htmlFor="poNumber" style={styles.label}>
                P/O Number:
              </label>
            </div>
            <div className="input" style={styles.flexInputs.input}>
              <DDITextField
                propertyName="poNumber"
                // {...nativeMUITextFieldStyles}
                disabled={this.poNumberDisabled}
              />
            </div>
          </div>
          <div className="input-flex-row" style={styles.rowStyle}>
            <div className="label" style={styles.flexInputs.label}>
              <label htmlFor="jobName" style={styles.label}>
                Job Name:
              </label>
            </div>
            <div className="input" style={styles.flexInputs.input}>
              <DDIIndexSearch
                propertyName="jobName"
                hintText="Search..."
                // metaKey="Job Name"
                // searchType={INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.JOB}
                meta={{
                  ...metaData,
                  searchType: 'job',
                  allowSearchAll: true
                }}
                popoverStyle={{ width: 400 }}
                openScreenData={{
                  name: 'customerJob',
                  image: 'customer_job_16.png',
                  title: 'Customer Job'
                }}
                minWidth="100%"
                openScreenMenuText="Open Customer Job Master"
                disabled={this.jobNameDisabled}
              />
            </div>
            <div className="description" style={styles.flexInputs.description}>
              <Label propertyName="jobName.description" />
            </div>
          </div>
          <div className="input-flex-row" style={styles.rowStyle}>
            <div className="label" style={styles.flexInputs.label}>
              <label htmlFor="manualShipToInfo" style={styles.label}>
                Manual Ship To Info:
              </label>
            </div>
            <div className="input" style={styles.flexInputs.input}>
              <DDITextField
                propertyName="manualShipToInfo"
                // {...nativeMUITextFieldStyles}
                disabled={this.manualShipToDisabled}
              />
            </div>
          </div>
          <div
            className="input-flex-row input-flex-row-multiple-inputs"
            style={styles.rowStyle}
          >
            <div className="label" style={styles.flexInputs.label}>
              <label htmlFor="status" style={styles.label}>
                Sales Order:
              </label>
            </div>
            <div className="input" style={styles.flexInputs.input}>
              <DDISelectField
                propertyName="status"
                initialValue="O"
                fullWidth
                style={styles.inputText}
                values={salesOrderStatusOptions}
              />
            </div>
            {showPrintTypes && (
              <div
                className="secondary-input-set"
                style={{ flex: 1, order: 4 }}
              >
                <div className="input-flex-row" style={styles.rowStyle}>
                  <div
                    className="label label-secondary"
                    style={styles.flexInputs.label}
                    key="print-type"
                  >
                    <label htmlFor="status" style={styles.label}>
                      Print Type:
                    </label>
                  </div>
                  <div
                    className="input input-secondary"
                    style={styles.flexInputs.input}
                    key="print-type-2"
                  >
                    <DDISelectField
                      propertyName="printType"
                      fullWidth
                      values={printTypes}
                      initialValue={printTypes.length && printTypes[0].dataId}
                      // values={(_, state) =>
                      //   getIn(
                      //     state,
                      //     'auth.common.salesOrderInquiryMeta.printTypes'
                      //   )
                      // }
                      // initialValue={(_, state) =>
                      //   getIn(
                      //     state,
                      //     'auth.common.salesOrderInquiryMeta.printTypes[0].dataId'
                      //   )
                      // }
                      style={styles.inputText}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>
    )
  }
}

export default InquiryTab
