import React from 'react'
import Skeleton from '@mui/lab/Skeleton'


export const HomeSkeleton = () => (
  <div style={{ width: '100%'}}>
    <div style={{ width: '100%', padding: 15, background: '#f5f7f7'}}>
      <div className="rbc-calendar-wrapper-mobile" style={{ height: 450 }}>
        <div style={{ padding: 10 }}>
          <Skeleton animation="wave" variant="rect" width="100%" height="50" />
        </div>

        <div style={{ padding: 10 }}>
          <Skeleton animation="wave" variant="rect" width="100%" height="250" />
        </div>
        
      </div>
    </div>
  </div>
)

export default HomeSkeleton