import React, { Component } from 'react'
import Grid from 'grid'
import PropTypes from 'prop-types'

import {
  Input,
  InputAdornment,
  IconButton,
  Icon,
  Popover,
  Tooltip as SimpleToolTip
} from '@mui/material'
import { withStyles } from '@mui/styles'

const columnDefs = [
  { field: 'description', headerName: 'Name' },
  { field: 'jobTitle', headerName: 'Title' },
  { field: 'fax', headerName: 'Fax' },
  { field: 'email', headerName: 'Email' },
  { field: 'defaultSendToDisplay', headerName: 'Def Send To' }
]
export default class ContactsCellEditor extends Component {

  input = null;

  static propTypes = {
    node: PropTypes.shape({
      data: PropTypes.shape({
        contacts: PropTypes.array.isRequired,
        description: PropTypes.string
      }).isRequired
    }).isRequired,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    data: PropTypes.shape({
      companySearchType: PropTypes.string.isRequired,
      contacts: PropTypes.array.isRequired
    }).isRequired,
    colDef: PropTypes.shape({
      cellRendererParams: PropTypes.shape({
        sendType: PropTypes.string
      }).isRequired
    })
  };

  static defaultProps = {
    colDef: {}
  };

  constructor(...args) {
    super(...args)
    this.state = {
      open: false,
      rowData: this.props.node.data.contacts,
      value: this.props.node.data.description || '',
      errorMessage: false
    }
    this.domNodeRef = React.createRef();
  }

  componentDidMount() {
    if (this.input) {
      this.input.select()
    }
    document.addEventListener('click', this.handleClickOutside, true);
  }

  setErrorMessage = () => this.setState({ errorMessage: true})
  handleTooltipClose = () => this.setState({ errorMessage: false})

  componentWillUnmount() {
    if (this.state.isDirty) {
      if (this.props.onChange) {
        this.props.onChange(this.props.field, this.state.value, this.props.node)
      }
    }
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside = event => {
    const domNode = this.domNodeRef.current;
  
    if (!domNode?.contains(event.target)) {
      this.setState({
          errorMessage: false
        });
    }
  }

  onChange = e => {
    e.persist()
    const { value } = e.target
    if (this.props.data.companySearchType === 'Customer') {
      const regex = new RegExp(value, 'gi')
      let open = false
      if (value.length >= 5) {
        open = this.props.data.contacts.filter(x => x.description.match(regex))
          .length
        open = Boolean(open)
      }

      this.setState({
        value,
        open,
        rowData: open
          ? this.props.data.contacts.filter(x => x.description.match(regex))
          : this.props.data.contacts,
        isDirty: true
      })
    } else {
      this.setState({
        value,
        isDirty: true
      })
    }
    }


  onClick = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  onClose = () =>
    this.setState({ open: false, rowData: this.props.data.contacts })

  onFocus = () => this.input.select()

  onRowClicked = e => {
    this.setState(
      {
        value: e.data.description,
        open: false
      },
      () => {
        this.props.onChange(
          this.props.field,
          e.data.description,
          this.props.node
        )
        if (
          this?.props?.colDef?.cellRendererParams?.sendType === 'Email' &&
          e.data.email
        ) {
          this.props.onChange(
            'email',
            e.data.email,
            this.props.node,
            e.data.dataId
          )
        }

        if (
          this?.props?.colDef?.cellRendererParams?.sendType === 'Fax' &&
          e.data.fax
        ) {
          this.props.onChange('fax', e.data.fax, this.props.node, e.data.dataId)
        }
      }
    )
  }

  bindInputRef = c => (this.input = c)


  getHeight = () => {
    const { rowData } = this.state
    return  rowData?.length ? rowData.length * 27 + 52 : 150
  }


  getRowNodeId = data => `${data.description}-${data.dataId}`

  onBlur = event => {
    event.persist()
    const { value } = event.target
    if (this.props.data.companySearchType === 'Customer') {
      const isCustomer = this.props.data.contacts.find(
        x => x.description === value
      )

      if (!isCustomer) {
        this.setState({ value: '' })
      }
    }
  }

  render() {
    const Tooltip = withStyles(theme => ({
      tooltip: {
        margin: theme.spacing(1)
      },
    }))(SimpleToolTip)
    return (
      <div style={{ width: '100%', height: 27, position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: 27
          }}
        >
          <Input
            inputRef={this.bindInputRef}
            variant="standard"
            onFocus={this.onFocus}
            endAdornment={
              this.props.node.data.companySearchType !== 'Free Form' ? (
                <InputAdornment position="end">
                  <IconButton onClick={this.onClick}>
                    <Icon>keyboard_arrow_down</Icon>
                  </IconButton>
                  <Tooltip
                   title={
                    !this.state.value && 'Name is required'
                    }
                    open={this.state.errorMessage}
                    onClose={this.handleTooltipClose}
                 >
                  <Icon style={{ color: '#d9534f', fontSize: 16, paddingRight: 20, cursor: 'pointer' }} 
                  onClick={this.setErrorMessage} 
                  onMouseEnter={this.setErrorMessage} 
                  onMouseLeave={this.handleTooltipClose}
                  ref={this.domNodeRef}>
                  error_outline
                </Icon>
                </Tooltip>
                </InputAdornment>
              ) : null
            }
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={this.state.value}
            fullWidth
            style={{ height: 26 }}
            inputProps={{
              style: { height: 26 }
            }}
          />
          <Popover
            anchorEl={this.input}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            onClose={this.onClose}
            open={this.state.open}
            height={this.getHeight()}
          >
            <div style={{ width: 400, height: 300 }}>
              <Grid
                columnDefs={columnDefs}
                onRowClicked={this.onRowClicked}
                rowData={this.state.rowData}
                getRowNodeId={this.getRowNodeId}
                autoSize
              />
            </div>
          </Popover>
        </div>
      </div>
    )
  }
}
