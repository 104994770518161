/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Button, ButtonBase, Icon, TextField } from '@mui/material'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { Page } from 'react-pdf'
import { isNumber, toNumeral } from 'utils'
import { Document } from 'react-pdf/dist/entry.webpack'
// import { Page,Document } from 'react-pdf/dist/esm/entry.parcel'
// import { isNumber, toNumeral } from 'utils'
// import { Document } from 'react-pdf/dist/entry.webpack'

const getClientWidth = () =>
  (window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth) *
    0.95 -
  50

class ReportViewer extends Component {
  static propTypes = { data: PropTypes.object.isRequired }

  constructor(props) {
    super(props)
    this.state = {
      numPages: null,
      pageNumber: 1,
      prevPageNumber: 1,
      width: getClientWidth()
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => this.setState({ width: getClientWidth() })

  onDocumentLoad = ({ numPages }) => this.setState({ numPages })

  onLoadError = error => {
    console.log('onLoadError', error)
  }

  page = direction => {
    const { numPages, pageNumber } = this.state
    if (direction === 'next') {
      this.setState(
        {
          pageNumber: pageNumber + 1 <= numPages ? pageNumber + 1 : pageNumber
        },
        () => console.log('pageNumberNext', this.state)
      )
    } else {
      this.setState(
        { pageNumber: pageNumber - 1 >= 1 ? pageNumber - 1 : pageNumber },
        () => console.log('pageNumberPrev', this.state)
      )
    }
  }

  onChange = id => event => {
    this.setState({
      [id]:
        event.target.value && isNumber(parseFloat(event.target.value, 10))
          ? parseFloat(event.target.value, 10)
          : ''
    })
  }

  render() {
    const {
      data: { pdfData }
    } = this.props
    const { pageNumber, numPages, width } = this.state
    return (
      <div className="disable-text-selection">
        <div className="clear">
          <div
            className="pagination-controls"
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ButtonBase
              onClick={e => this.page('prev')}
              style={{ background: '#517b9c', borderRadius: 100 }}
            >
              <Icon style={{ color: '#fff' }}>keyboard_arrow_left</Icon>
            </ButtonBase>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                margin: '0 10px 0 10px'
              }}
            >
              Page
              <TextField
                value={pageNumber}
                variant="standard"
                onChange={this.onChange('pageNumber')}
                style={{ margin: '0 10px', width: 20 }}
                inputProps={{ style: { textAlign: 'center' } }}
              />
              of {numPages}
            </div>
            <ButtonBase
              onClick={e => this.page('next')}
              style={{ background: '#517b9c', borderRadius: 100 }}
            >
              <Icon style={{ color: '#fff' }}>keyboard_arrow_right</Icon>
            </ButtonBase>
          </div>
        </div>
        <div className="clear" style={{ height: 1000 }}>
          <Document
            file={pdfData}
            onLoadSuccess={this.onDocumentLoad}
            onLoadError={this.onLoadError}
          >
            {pageNumber && <Page pageNumber={pageNumber} width={width} />}
          </Document>
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(ReportViewer)
