import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Icon,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material'

import ActionButtons from './components/ActionButtons'

const RowsPerPageModal = props => {
  const [dontShowAgain, toggleDontShowAgain] = useState(false)
  const {
    data: { form, rowsPerPage },
    id
  } = props

  const onToggleSwitch = event => toggleDontShowAgain(event.target.checked)

  return (
    <div className="rows-per-page-flex-modal-wrapper">
      <div className="rows-per-page-modal-primary-interface-wrapper">
        <p>{`Do you want to make ${rowsPerPage} the default selection?`}</p>
        <div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={dontShowAgain}
                  onChange={onToggleSwitch}
                  name="dontShowAgain"
                />
              }
              label="Don't Show This Message Again"
            />
          </FormGroup>
        </div>
      </div>
      <ActionButtons
        form={form}
        modalId={id}
        dontShowAgain={dontShowAgain}
        rowsPerPage={rowsPerPage}
      />
    </div>
  )
}

export default RowsPerPageModal
