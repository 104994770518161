import { Component } from 'react'

export default class MailTitle extends Component {
  constructor(...args) {
    super(...args)
    const fromUserName =
      (this.props.modal &&
        this.props.modal.options &&
        this.props.modal.options.data.fromUserName &&
        this.props.modal.options.data.fromUserName) ||
      ''
    const title = this.props.modal.options.data.newMail
      ? 'New Mail'
      : `Mail From ${fromUserName}`
    this.state = { title }
  }
  render() {
    return this.state.title
  }
}
