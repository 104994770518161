import {
  take,
  select,
  call,
  put,
  cancel,
  fork,
  actionChannel,
  putResolve
} from 'redux-saga/effects'

import { api } from 'services'
import { getIn, dataURItoBlob } from 'utils'
import { show } from 'snackbar/actions'
import { getFormSelector } from 'ddiForm/utils'
import { setField } from 'ddiForm/actions'
import { EXACT_MATCH_SEARCH, GET_TOOLTIP, EXECUTE_INQUIRY } from './constants'

export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)

    // const formState = yield select(getFormSelector(form))

    task = yield call(exactMatchProcess, {
      form,
      ...payload,
      thunk
    })
  }
}

export function* getApiParams(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const templateKey = getIn(formState, 'values.templateKey')
  const parentId = getIn(formState, 'values.parent.parentId') || null
  const parentType = getIn(formState, 'values.parent.parentType') || null

  return { dataId, templateKey, parentId, parentType }
}

export function* exactMatchProcess({
  form,
  keyword,
  propertyName,
  ignoreGetApiParams,
  thunk,
  ...rest
}) {
  let params
  if (!ignoreGetApiParams) {
    const { dataId, parentId, parentType } = yield call(getApiParams, form)
    params = {
      keyword,
      form,
      dataId,
      parentId,
      parentType
    }
  } else {
    params = {
      ...rest,
      form,
      keyword,
      propertyName,
      thunk
    }
  }
  // debugger
  const { response, error } = yield call(api.salesOrderExactMatch, params)
  // on error?
  // debugger
  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })

    if (error?.status !== 404 && error?.message) {
      yield put(
        show({
          message: {
            message: error.message,
            type: 'warning',
            persist: false
          }
        })
      )
    }
  }
}

function* executeInquiryProcess(action) {
  yield console.log(action.payload)
  const {
    payload: {
      customerId,
      orderType,
      startDate,
      endDate,
      productId,
      jobName,
      poNumber,
      manualShipToInfo
    },
    meta
  } = action
  const { response, error } = yield call(api.salesOrderInquiry, {
    customerId,
    productId,
    status: orderType,
    startDate: startDate ? startDate.toISOString() : startDate,
    endDate: endDate ? endDate.toISOString() : endDate,
    jobName,
    poNumber,
    manualShipToInfo
  })
  if (response) {
    yield put({
      meta,
      payload: { ...response },
      type: EXECUTE_INQUIRY.SUCCESS
    })
  } else {
    yield put({
      meta,
      payload: { ...error },
      error: true,
      type: EXECUTE_INQUIRY.FAILURE
    })
  }
}

function* executeInquiryListener() {
  let task
  while (true) {
    const action = yield take(EXECUTE_INQUIRY.TRY)

    if (task) yield cancel(task)

    task = yield call(executeInquiryProcess, action)
  }
}

export default function* salesOrderSearchSagas() {
  yield fork(executeInquiryListener)
  yield fork(exactMatchSearchListener)
}
