import React, { Component, Fragment } from 'react'
import { Icon, TextField } from '@mui/material'
import invariant from 'invariant'
/* eslint-disable no-useless-escape */

export default class RegexCellRenderer extends Component {
  static defaultProps = {
    required: true
  }

  constructor(...args) {
    super(...args)
    invariant(this.props.regex, 'must provide a regex!')

    const value = this.props.value || ''
    this.state = {
      value,
      valid: this.props.regex.test(value)
    }
  }
  onRowClicked = e =>
    this.setState({
      value: e.data.description,
      open: false
    })

  bindInputRef = c => (this.input = c)

  input = null

  refresh = params => false
  getValue = () => this.state.value

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%'
        }}
      >
        <div style={{ flex: 0.8 }}>{this.state.value}</div>
        <div
          style={{
            flex: 0.2,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginRight: -10
          }}
        >
          {!this.state.valid ? (
            <Icon
              style={{
                color: '#d9534f',
                fontSize: 16
              }}
            >
              error_outline
            </Icon>
          ) : null}
        </div>
      </div>
    )
  }
}
