/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import { DDIDatePicker } from 'ddiForm/wrapped'

import {
  InputLabel,
  Icon,
  NativeSelect,
  FormControl,
  Button,
  TextField,
  Accordion as MuiExpansionPanel,
  AccordionDetails as MuiExpansionPanelDetails,
  AccordionSummary as MuiExpansionPanelSummary,
  LinearProgress,
  Tooltip as SimpleToolTip,
  ClickAwayListener
} from '@mui/material'
import { withStyles } from '@mui/styles'

// import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearchMobile'
import { mobileStateSelector } from 'mobile/selectors'
import IndexSearch from 'mobile/components/IndexSearchMobile'
import Grid from 'mobile/grid'

import {
  partialMatchSearch,
  exactMatchSearch
} from 'mobile/components/IndexSearchMobile/actions'
import { mapColumnHeaders } from 'utils'
import { mapSOInquiryHeaders } from 'pages/SalesOrderInquiry/behaviors'
import { executeInquiry } from './actions'
import '../SalesOrderSearch/styles/DropDownComponent.scss'

const errorIconStyle = {
  color: '#d9534f',
  fontSize: 16,
  marginTop: 21
}

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel)

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 30,
    padding: '0 20px 0 14px',
    '&$expanded': {
      minHeight: 30,
      margin: 0
    }
  },
  content: {
    margin: '5px 0',
    '&$expanded': {
      margin: '5px 0'
    }
  },
  expandIcon: {
    padding: 0
  },
  expanded: {}
})(MuiExpansionPanelSummary)

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 10
  }
}))(MuiExpansionPanelDetails)

export const Tooltip = withStyles(theme => ({
  tooltip: {
    margin: theme.spacing(1)
  },
}))(SimpleToolTip)

const expandIcon = (
  <Icon classes={{ root: 'ddi-expansion-icon' }} style={{ color: '#444' }}>
    expand_more
  </Icon>
)

const getRowNodeId = x => x.dataId
const types = [
  {
    value: 'O',
    description: 'Open'
  },
  {
    value: 'C',
    description: 'Completed'
  },
  {
    value: 'B',
    description: 'Backorders'
  },
  {
    value: 'Q',
    description: 'Quotes'
  },
  {
    value: 'E',
    description: 'Expired Quotes'
  },
  {
    value: 'R',
    description: 'Recurring'
  },
  {
    value: 'A',
    description: 'All'
  }
]

// const searchDisabled =
// (!customerId && !productId) ||
// fetching ||
// ((startDate && !endDate) || (endDate && !startDate | (startDate > endDate)))

const isSearchDisabled = memoize(
  (
    startDate,
    endDate,
    customerId,
    productId,
    jobName,
    poNumber,
    manualShipToInfo,
    fetching
  ) => {
    if (fetching) {
      return true
    }

    if (startDate && endDate && startDate <= endDate) {
      return false
    }

    if (
      (startDate && !endDate) ||
      (endDate && !startDate | (startDate > endDate))
    ) {
      return true
    }

    if (
      !customerId &&
      !productId &&
      !jobName &&
      !poNumber &&
      !manualShipToInfo
    ) {
      return true
    }

    return false
  }
)

const DropDownComponent = props => {
  const searchRef = React.createRef()
  console.log(searchRef)
  const partialMatch = args =>
    props.dispatch(partialMatchSearch(null, { ...args }))
  const exactMatch = args => props.dispatch(exactMatchSearch(null, { ...args }))
  const [description, setDesc] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [productId, setProductId] = useState('')
  const [productDescription, setProductDescription] = useState('')

  const [poNumber, setPoNumber] = useState('')
  const [manualShipToInfo, setManualShipTo] = useState('')
  const [jobName, setJobName] = useState('')
  const [jobNameDescription, setJobNameDescription] = useState('')
  const [expanded, setExpanded] = useState('fieldsPanel')

  const [orderType, setOrderType] = useState(types[0].value)

  const [startDate, startDateChange] = useState(null)
  const [endDate, endDateChange] = useState(null)

  const [showGrid, setShowGrid] = useState(false)
  const [height, setHeight] = useState(360)
  const [fetching, setFetching] = useState(false)
  const [columnDefs, setColumnDefs] = useState([])
  const [rowData, setRowData] = useState([])
  const [counter, setCounter] = useState(0)

  const [startDateError, setStartDateErrorOpen] = useState(false);
  const [endDateError, setEndDateErrorOpen] = useState(false);
  const [disableProduct,setDisableProduct] = useState(false);
  const handleTooltipClose = (isStartDate) => {
    if(isStartDate && startDateError){
      setStartDateErrorOpen(false);
    }
    else if(!isStartDate && endDateError){
      setEndDateErrorOpen(false)
    }
  };

  const handleTooltipOpen = (isStartDate) => {
    isStartDate ? setStartDateErrorOpen(true) : setEndDateErrorOpen(true)
  };

  const setData = (data = {}) => {
    setDesc(data.description)
    setCustomerId(data.dataId)
  }

  const setProductData = (data = {}) => {
    setProductDescription(data?.description)
    setProductId(data?.dataId)
  }

  const setJobNameData = (data = {}) => {
    setJobNameDescription(data?.description)
    setJobName(data?.dataId)
  }

  // const onRowSelected = params => {
  //   if (this.props.onRowSelected) {
  //     // debugger
  //     // this.props.onRowSelected.call(this, params)
  //     this.props.onRowSelected(params)
  //   }
  // }
  const changeOrderType = e => setOrderType(e.target.value)
  const changeDate = mod => date => mod(date)

  const onChangePoNumber = event => setPoNumber(event.target.value)

  const onChangeManualShipTo = event => setManualShipTo(event.target.value)
  useEffect(()=>{
    if(manualShipToInfo||(jobName && jobName!==undefined)||poNumber){
      setDisableProduct(true);
    } else {
      setDisableProduct(false);
    }
  },[manualShipToInfo,jobName,poNumber]);

  const inquiry = async () => {
    setFetching(true)
    try {
      const r = await props.dispatch(
        executeInquiry(props.form, {
          customerId,
          startDate,
          endDate,
          orderType,
          productId,
          poNumber,
          manualShipToInfo,
          jobName,
          disableProduct
        })
      )
      setFetching(false)
      if (r) {
        if (r?.salesOrders?.data?.length) {
          setColumnDefs(
            r.salesOrders.meta.columns
              .map(mapColumnHeaders)
              .map(mapSOInquiryHeaders)
              .map(x => {
                const { pinned, ...rest } = x
                return rest
              })
          )
          setRowData(r.salesOrders.data)
        }
        setCounter(r.salesOrders.data.length)

        const mobState = props?.mobileState?.toJS
          ? props.mobileState.toJS()
          : {}
        const gridWrapperHeight = mobState?.isTablet ? 700 : 520

        setHeight(gridWrapperHeight)

        setExpanded(false)
        setShowGrid(true)
      }
    } catch (e) {
      setFetching(false)
      setShowGrid(false)
    }
    // else {
    //   setFetching(false)
    //   setShowGrid(false)
    // }
  }
  console.log(columnDefs, rowData)
  const changeEnd = changeDate(endDateChange)
  const changeStart = changeDate(startDateChange)

  const mobileState = props?.mobileState?.toJS ? props.mobileState.toJS() : {}
  // const gridHeight = mobileState?.isTablet ? 400 : 225
  const gridHeight = mobileState?.isTablet ? 575 : 400
  const inputPadding = mobileState?.isTablet ? 20 : 0

  /*
  const searchDisabled =
    (!customerId && !productId) ||
    fetching ||
    ((startDate && !endDate) || (endDate && !startDate | (startDate > endDate)))
  */

  const searchDisabled = isSearchDisabled(
    startDate,
    endDate,
    customerId,
    productId,
    jobName,
    poNumber,
    manualShipToInfo,
    fetching
  )

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div
      style={{
        height,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden'
      }}
    >
      <ExpansionPanel
        expanded={expanded === 'fieldsPanel'}
        onChange={handleChange('fieldsPanel')}
        square
      >
        <ExpansionPanelSummary expandIcon={expandIcon}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon fontSize="small" style={{ color: '#444', marginRight: 10 }}>
              search
            </Icon>
            <span
              style={{
                color: '#444',
                fontFamily: 'Roboto',
                fontSize: '0.9em'
              }}
            >
              Search Fields
            </span>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0, width: '100%' }}>
          <div style={{ width: '100%' }}>
            {fetching ? (
              <div style={{ width: '100%', minHeight: 4 }}>
                <LinearProgress mode="indeterminate" />
              </div>
            ) : null}
            <div
              style={{
                padding: '0 10px 10px 10px',
                borderBottom: '1px solid #c9c9c9'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  paddingLeft: inputPadding,
                  paddingRight: inputPadding
                }}
              >
                <div className='mobile-so-modal-input-wrapper-with-placeholder' style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                  <IndexSearch
                    label="Customer"
                    placeholder="Select Customer"
                    indexSearchType="customer"
                    // label="Customer"
                    setField={(...args) => {
                      setData(args[3])
                    }}
                    variant="standard"  
                    disableLastSearchButton
                    fullWidth
                    preventAutoDisable
                    displayDescription
                    exactMatchSearch={exactMatch}
                    partialMatchSearch={partialMatch}
                    moreInfo
                    forwardRef
                    description={description}
                    value={customerId}
                  />
                </div>
                <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10, display: 'flex' }}>
                  <DDIDatePicker
                    propertyName="startDate"
                    placeholder="Start Date"
                    label="Start Date"
                    marginTop="0px"
                    dialogContainerStyle={{ zIndex: 5000 }}
                    inputStyle={{ marginTop: 5 }}
                    wrapperStyle={null}
                    initialValue={new Date()}
                    onChange={changeStart}
                    value={startDate}
                    // meta={indexSearchFields.customer}
                  />
                 {(endDate && !startDate) ||
                  (startDate && endDate && endDate < startDate) ? (
                    <ClickAwayListener onClickAway={()=>handleTooltipClose(true)}>
                    <Tooltip
                      title={
                        endDate && !startDate
                          ? 'Start Date is required'
                          : 'Start Date cannot be after End Date'
                      }
                      open={startDateError}
                      onClose={() => handleTooltipClose(true)}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <Icon style={errorIconStyle} onClick={() => handleTooltipOpen(true)}>
                        error_outline
                      </Icon>
                    </Tooltip>
                    </ClickAwayListener>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  paddingLeft: inputPadding,
                  paddingRight: inputPadding
                }}
              >
                <div className='mobile-so-modal-input-wrapper-with-placeholder' style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                  <FormControl variant="standard">
                    <InputLabel
                      style={{
                        color: '#517b9c',
                        fontSize: 12,
                        margin: 0
                      }}
                      htmlFor="order-type"
                    >
                      Type
                    </InputLabel>
                    <NativeSelect
                      native
                      // value={types[0].value}
                      value={orderType}
                      onChange={changeOrderType}
                      inputProps={{
                        name: 'order-type',
                        id: 'order-type'
                      }}
                    >
                      {types.map(x => (
                        <option value={x.value}>{x.description}</option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10, display: 'flex' }}>
                  <DDIDatePicker
                    propertyName="endDate"
                    placeholder="End Date"
                    label="End Date"
                    dialogContainerStyle={{ zIndex: 5000 }}
                    inputStyle={{ marginTop: 5 }}
                    marginTop="0px"
                    wrapperStyle={null}
                    // initialValue={null}
                    value={endDate}
                    onChange={changeEnd}
                    // meta={indexSearchFields.customer}
                  />
                {(startDate && !endDate) ||
                  (startDate && endDate && endDate < startDate) ? (
                    <ClickAwayListener onClickAway={()=>handleTooltipClose(false)}>
                    <Tooltip
                      title={
                        startDate && !endDate
                          ? 'End Date is required'
                          : 'Start Date cannot be after End Date'
                      }
                      open={endDateError}
                      onClose={() => handleTooltipClose(false)}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <Icon style={errorIconStyle} onClick={() => handleTooltipOpen(false)}>
                        error_outline
                      </Icon>
                    </Tooltip>
                    </ClickAwayListener>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  paddingLeft: inputPadding,
                  paddingRight: inputPadding
                }}
              >
                <div className='mobile-so-modal-input-wrapper-with-placeholder'  style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                  <IndexSearch
                    label="Job Name"
                    placeholder="Select Job Name"
                    indexSearchType="job"
                    setField={(...args) => {
                      setJobNameData(args[3])
                    }}
                    variant="standard"
                    disableLastSearchButton
                    fullWidth
                    preventAutoDisable
                    displayDescription
                    exactMatchSearch={exactMatch}
                    partialMatchSearch={partialMatch}
                    moreInfo
                    forwardRef
                    description={jobNameDescription}
                    value={jobName}
                    disabled={productId}
                    meta={{
                      allowInstantSearch: true,
                      allowInvalidValues: true,
                      allowNavigation: false,
                      allowSearchAll: true,
                      fieldName: 'dataId',
                      hasFilters: false,
                      hideToggle: true,
                      minimumKeywordLength: 0,
                      openText: null,
                      searchType: 'job'
                    }}
                  />
                </div>
                <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                  <TextField
                    label="P/O Number"
                    value={poNumber}
                    onChange={onChangePoNumber}
                    disabled={productId}
                    variant="standard"
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  paddingLeft: inputPadding,
                  paddingRight: inputPadding
                }}
              >
                <div className='mobile-so-modal-input-wrapper-with-placeholder' style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                  <IndexSearch
                    label="Product"
                    placeholder="Select Product"
                    indexSearchType="product"
                    setField={(...args) => {
                      setProductData(args[3])
                    }}
                    variant="standard"
                    disableLastSearchButton
                    fullWidth
                    preventAutoDisable
                    displayDescription
                    exactMatchSearch={exactMatch}
                    partialMatchSearch={partialMatch}
                    moreInfo
                    forwardRef
                    description={productDescription}
                    value={productId}
                    disabled={disableProduct}
                  />
                </div>

                <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                  <TextField
                    label="Manual Ship To Info"
                    value={manualShipToInfo}
                    onChange={onChangeManualShipTo}
                    disabled={productId}
                    variant="standard"
                  />
                </div>
              </div>

              <div />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {showGrid ? (
        <div id="mobile-sales-order-search" style={{ padding: '0 10px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10px'
            }}
          >
            <h4 style={{ color: '#404040', fontSize: 14 }}>Search Results</h4>
            {counter ? (
              <div style={{ color: 'rgb(81, 123, 156)', fontSize: 11 }}>
                showing results 1-{counter} (tap to select)
              </div>
            ) : null}
          </div>
          <Grid
            height={gridHeight}
            rowData={rowData}
            columnDefs={columnDefs}
            getRowNodeId={getRowNodeId}
            onRowSelected={props.onRowSelected}
            rowSelection="single"
            rowDeselection
            autoSize
          />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 10
        }}
      >
        <Button
          variant="contained"
          size="large"
          startIcon={<Icon>search</Icon>}
          disabled={searchDisabled}
          // onCLick={}
          onClick={inquiry}
        >
          Search Orders
        </Button>
      </div>
    </div>
  )
}

const mapState = state => ({ mobileState: mobileStateSelector(state) })
export default connect(mapState)(DropDownComponent)