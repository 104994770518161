/* eslint react/jsx-boolean-value: 0 */
import React, { Component } from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import Measure from 'react-measure'
import PropTypes from 'prop-types'
import Grid from 'grid'
import { GridField } from 'ddiForm'
import { Icon, IconButton } from '@mui/material'
import { dateFilterComparator, layoutFlex, plainDeepEqual } from 'utils'
import withDimensions from 'hoc/withDimensions'
import DeleteAttachmentCell from './components/DeleteAttachmentCell'
import DownloadAttachmentCell from './components/DownloadAttachmentCell'
import InvoiceInquiryAttachments from './components/InvoiceInquiryAttachments'
import { uploadAttachments } from './actions'
import { mimeTypes, readFile } from './utils'

const getRowNodeId = data => data.description

const getColumnDefs = memoize(
  (
    form,
    isSendDocumentEditor = false,
    isStandaloneComponent = false,
    additionalAttachmentsData = null,
    targetType = ''
  ) => {
    const deleteFormName =
      additionalAttachmentsData?.parentForm === 'reportExplorer'
        ? additionalAttachmentsData.parentForm
        : isSendDocumentEditor
        ? 'sendDocument'
        : form

    const colDefs = [
      {
        field: 'description',
        headerName: 'Attachment',
        cellRendererFramework: DownloadAttachmentCell,
        cellRendererParams: {
          form,
          isSendDocumentEditor,
          isStandaloneComponent,
          additionalAttachmentsData,
          targetType
        }
      },
      { field: 'sizeFriendly', headerName: 'Size' },
      { field: 'typeFriendly', headerName: 'Type' },
      {
        field: 'dateCreated',
        headerName: 'Date Created',
        cellClass: 'center-align align-center',
        filter: 'agDateColumnFilter',
        filterParams: {
          clearButton: true,
          comparator: dateFilterComparator,
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          browserDatePicker: true
        }
      },
      {
        field: 'dateModified',
        headerName: 'Date Modified',
        cellClass: 'center-align align-center',
        filter: 'agDateColumnFilter',
        filterParams: {
          clearButton: true,
          comparator: dateFilterComparator,
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          browserDatePicker: true
        }
      },
      {
        field: '',
        headerName: '',
        maxWidth: 80,
        cellRendererFramework: DeleteAttachmentCell,
        cellRendererParams: {
          form: deleteFormName,
          isSendDocumentEditor,
          isStandaloneComponent,
          additionalAttachmentsData,
          targetType
        }
      }
    ]

    return colDefs
  },
  plainDeepEqual
)

const defaultAllowedFileTypes = Object.keys(mimeTypes)

const defaultAllowedMimeTypes = defaultAllowedFileTypes.reduce((acc, next) => {
  acc = acc.concat(mimeTypes[next])
  return acc
}, [])

const initialState = {
  errorMessage: '',
  errorMessageAreaDimensions: {
    width: -1,
    height: -1
  },
  invalidFiles: []
}

class Attachments extends Component {
  static propTypes = {
    allowedFileTypes: PropTypes.array,
    allowedMimeTypes: PropTypes.array,
    isSendDocumentEditor: PropTypes.bool,
    isStandaloneComponent: PropTypes.bool,
    maxUploadSize: PropTypes.number,
    rowData: PropTypes.array,
    additionalAttachmentsData: PropTypes.object,
    targetType: PropTypes.string,
    dataCloudHelp: PropTypes.string,
    dataCloudHelpPopup: PropTypes.string
  }

  static defaultProps = {
    allowedFileTypes: defaultAllowedFileTypes,
    allowedMimeTypes: defaultAllowedMimeTypes,
    isSendDocumentEditor: false,
    isStandaloneComponent: false,
    maxUploadSize: 25000000,
    rowData: [],
    additionalAttachmentsData: {},
    targetType: '',
    dataCloudHelp: '',
    dataCloudHelpPopup: ''
  }

  constructor(props) {
    super(props)

    this.state = initialState
  }

  onOpenFileBrowserHandler = e => (e.target.value = null)

  onSelectFileUpload = e => {
    const { files } = e.target
    this.handleFileUploadProcess(files)
  }

  setErrorStatus = (fileName, reason = 'Invalid Type') =>
    this.setState(prevState => {
      const invalidFiles = [...prevState.invalidFiles, { fileName, reason }]
      return {
        invalidFiles,
        errorMessage: invalidFiles
          .reduce((acc, next) => {
            acc = acc.concat(`${next.fileName} - ${next.reason}, `)
            return acc
          }, 'Invalid Files: ')
          .slice(0, -2)
      }
    })

  handleFileUploadProcess = files => {
    const allFilePromises = []
    const {
      isStandaloneComponent,
      allowedFileTypes,
      maxUploadSize,
      isSendDocumentEditor,
      targetType
    } = this.props

    /* get rid of any pre-existing error message */
    this.setState(initialState)

    // Iterate over all uploaded files
    for (let i = 0; i < files.length; i++) {
      // assume each file is valid until its not
      let validFile = true
      const f = files[i]
      const extension = f.name.split('.').pop() || ''

      if (!extension) {
        validFile = false
      }

      if (extension && !allowedFileTypes.includes(extension.toLowerCase())) {
        validFile = false
        this.setErrorStatus(f.name, 'Invalid Type')
      }

      // Check for file size
      if (f.size > maxUploadSize) {
        validFile = false
        this.setErrorStatus(f.name, 'Invalid Size')
      }

      if (validFile) {
        allFilePromises.push(readFile(f))
      }
    }

    if (allFilePromises.length) {
      Promise.all(allFilePromises).then(newFilesData => {
        const uploadData = []
        newFilesData.forEach(newFileData => {
          uploadData.push({
            fileName: newFileData?.file?.name,
            content: newFileData?.content,
            overwrite: false,
            rename: false
          })
        })
        /* make the API call */
        if (uploadData.length) {
          const formName =
            this.props.additionalAttachmentsData?.parentForm ===
            'reportExplorer'
              ? this.props.additionalAttachmentsData.parentForm
              : isSendDocumentEditor
              ? 'sendDocument'
              : this.props.form

          const params =
            isSendDocumentEditor || isStandaloneComponent
              ? {
                  uploadData,
                  additionalAttachmentsData: this.props
                    .additionalAttachmentsData,
                  targetType
                }
              : {
                  uploadData
                }

          this.props.dispatch(uploadAttachments.try(formName, params))
        }
      })
    }
  }

  onAddAttachment = e => {
    this.inputEl.click()
  }

  dismissErrorMessage = e => this.setState(initialState)

  onResize = contentRect =>
    this.setState({ errorMessageAreaDimensions: contentRect.bounds })

  render() {
    const { errorMessage } = this.state
    const {
      isSendDocumentEditor,
      isStandaloneComponent,
      additionalAttachmentsData,
      height,
      form,
      rowData = [],
      targetType,
      dataCloudHelp,
      dataCloudHelpPopup
    } = this.props

    let gridHeight = errorMessage
      ? height - (this?.state?.errorMessageAreaDimensions?.height + 30)
      : height

    gridHeight = form && form.match(/\.contact/) ? 300 : gridHeight

    const wrapperStyle = isSendDocumentEditor
      ? { width: '100%' }
      : layoutFlex('120rem', 'row', 'wrap')

    const gridTitle =
      isSendDocumentEditor && Array.isArray(rowData)
        ? `Attachments (${rowData.length})`
        : 'Attachments'

    const primaryAttachmentsInterface =
      form === 'invoiceInquiry' ? (
        <InvoiceInquiryAttachments
          addBlankRowAction={this.onAddAttachment}
          columnDefs={getColumnDefs(form, false, false, null, '')}
          getRowNodeId={getRowNodeId}
          gridHeight={gridHeight}
        />
      ) : (
        <GridField
          propertyName="attachments"
          title="Attachments"
          addButtonText="Add Attachment"
          showAddButtonIfHasRecord
          addBlankRow={this.onAddAttachment}
          headerStyle={{
            background: '#e1e3e4',
            color: '#444',
            fontSize: 13,
            fontWeight: 400,
            lineHeight: '17px',
            margin: 0,
            padding: '5px 0',
            textAlign: 'center',
            width: '100%'
          }}
          columnDefs={getColumnDefs(form, false, false, null, '')}
          getRowNodeId={getRowNodeId}
          enableSorting
          height={gridHeight}
          width="100%"
        />
      )
    // debugger
    return (
      <div
        className="attachments-interface-wrapper"
        style={wrapperStyle}
        data-cloud-help={dataCloudHelp}
        data-cloud-help-popup={dataCloudHelpPopup}
      >
        {errorMessage ? (
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Measure bounds onResize={this.onResize}>
                {({ measureRef }) => (
                  <div
                    ref={measureRef}
                    className="alert alert-warning"
                    style={{
                      backgroundColor: '#fcf8e3',
                      borderColor: '#faebcc',
                      padding: 5,
                      marginBottom: 10,
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1
                      }}
                    >
                      <Icon size="small" style={{ marginRight: 5 }}>
                        warning
                      </Icon>
                      <span>{errorMessage}</span>
                    </div>
                    <IconButton
                      onClick={this.dismissErrorMessage}
                      style={{ padding: 5 }}
                    >
                      <Icon size="small">close</Icon>
                    </IconButton>
                  </div>
                )}
              </Measure>
            </div>
          </div>
        ) : null}
        <div style={{ width: '100%' }}>
          {isSendDocumentEditor || isStandaloneComponent ? (
            <Grid
              title={gridTitle}
              addButtonText="Add Attachment"
              showAddButtonAlways
              addBlankRow={this.onAddAttachment}
              headerStyle={{
                background: '#e1e3e4',
                color: '#444',
                fontSize: 13,
                fontWeight: 400,
                lineHeight: '17px',
                margin: 0,
                padding: '5px 0',
                textAlign: 'center',
                width: '100%'
              }}
              addButtonStyleParams={{
                backgroundColor: '#e1e3e4',
              }}
              columnDefs={getColumnDefs(
                form,
                isSendDocumentEditor,
                isStandaloneComponent,
                additionalAttachmentsData,
                targetType
              )}
              rowData={rowData}
              getRowNodeId={getRowNodeId}
              enableSorting
              height={300}
              width="100%"
            />
          ) : (
            primaryAttachmentsInterface
          )}
        </div>
        <div style={{ opacity: 0, zIndex: -1 }}>
          {/* multiple={true} has to be listeed that way or it won't work - SVE 9/3/2020 */}
          <input
            type="file"
            ref={el => (this.inputEl = el)}
            onChange={this.onSelectFileUpload}
            onClick={this.onOpenFileBrowserHandler}
            multiple={true}
          />
        </div>
      </div>
    )
  }
}

const attachments = withDimensions({
  display: 'flex',
  flex: 1,
  maxWidth: '100%'
})(Attachments)

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(attachments)
