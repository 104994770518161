import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@mui/material'
import { noop } from 'utils'

export default class CheckboxEditor extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      value: this.props.value
    }
  }
  onChange = (e, value) => {
    // console.log(...args)
    this.setState({ value }, () => {
      this.props.onChange(this.props.field, value, this.props.node)
    })
  }

  getValue = p => this.state.value

  render() {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Checkbox
            // onCheck={() => propertyChange({ meta: { action: 'delete', sortIndex: this.props.rowIndex + 1, } })}
            checked={this.state.value}
            onChange={this.onChange}
            // onCheck={(event, isInputChecked) => { this.updateChecked(event, isInputChecked) }}
            style={{ textAlign: 'center', width: 'auto', margin: '0 auto' }}
            value={this.state.value}
            // disabled
          />
        </div>
      </div>
    )
  }
}
