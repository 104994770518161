/* eslint react/sort-comp: 0, prefer-destructuring: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { find, isEqual } from 'lodash'
import { getIn, getRgb, plainDeepEqual } from 'utils'

import { Icon } from '@mui/material'

// import { eventsSelector } from 'pages/Dashboard/selectors'

import { eventModalSelector } from '../selectors'

export const getRowNodeId = data => data.dataId
// const eventModal = 'eventModal'

const mapStateToProps = (state, ownProps) => {
  const eventTypeEditor = getIn(state, 'ddiForm.eventModal.eventTypeEditor')
  const eventTypeOptions = getIn(
    state,
    'ddiForm.eventModal.meta.eventTypeOptions'
  )

  return {
    // eventTypeEditor: eventTypeEditor && eventTypeEditor.toJS ? eventTypeEditor.toJS() : {},
    // eventTypeOptions: eventTypeOptions && eventTypeOptions.toJS ? eventTypeOptions.toJS() : {},
    callLocation: getIn(state, 'ddiForm.eventModal.callLocation') || 'calendar',
    eventModal:
      (eventModalSelector(state) && eventModalSelector(state).toJS()) || {}
  }
}

const createTitle = eventModal => {
  let title
  let prefix
  if (eventModal && eventModal.fields && eventModal.meta) {
    const {
      fields: { subject = {}, eventType = {}, taskType = {} },
      meta: { eventTypeOptions = [], taskTypeOptions = [] }
    } = eventModal
    if (
      subject &&
      subject.value &&
      eventType &&
      eventType.value &&
      eventType.value !== 'T'
    ) {
      prefix = find(eventTypeOptions, { dataId: eventType.value }) || {
        description: ''
      }
      title = `${prefix?.description}: ${subject?.value}`
    } else if (
      subject &&
      subject.value &&
      eventType &&
      eventType.value &&
      eventType.value === 'T' &&
      taskType &&
      taskType.value
    ) {
      prefix = find(taskTypeOptions, { dataId: taskType.value }) || {
        description: ''
      }
      title = `${prefix?.description}: ${subject?.value}`
    } else {
      title = subject && subject?.value ? subject.value : 'New Event'
    }
  } else {
    title = ''
  }

  return title
}

const getEventIcon = color => (
  <Icon
    style={{
      color: getRgb(color),
      fontSize: 16,
      marginRight: 5,
      verticalAlign: 'middle'
    }}
  >
    event
  </Icon>
)

export class TitleBar extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    eventModal: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      title: ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.eventModal &&
      nextProps.eventModal.fields &&
      prevState.initialized === false
    ) {
      return {
        initialized: true,
        subject:
          nextProps.eventModal &&
          nextProps.eventModal.fields &&
          nextProps.eventModal.fields.subject &&
          nextProps.eventModal.fields.subject.value
            ? nextProps.eventModal.fields.subject.value
            : '',
        title:
          nextProps.eventModal && nextProps.eventModal.dataId
            ? createTitle(nextProps.eventModal)
            : ''
      }
    }

    if (
      nextProps.eventModal &&
      nextProps.eventModal.fields &&
      nextProps.eventModal.fields.subject &&
      nextProps.eventModal.fields.subject.value !== prevState.subject
    ) {
      return {
        subject: nextProps.eventModal.fields.subject.value,
        title: createTitle(nextProps.eventModal)
      }
    }

    if (
      nextProps.eventModal &&
      nextProps.eventModal.fields &&
      nextProps.eventModal.fields.eventType &&
      nextProps.eventModal.fields.eventType.value !== prevState.eventType
    ) {
      return {
        eventType: nextProps.eventModal.fields.eventType.value,
        title: createTitle(nextProps.eventModal)
      }
    }

    if (
      nextProps.eventModal &&
      nextProps.eventModal.fields &&
      nextProps.eventModal.fields.taskType &&
      nextProps.eventModal.fields.taskType.value !== prevState.taskType
    ) {
      return {
        taskType: nextProps.eventModal.fields.taskType.value,
        title: createTitle(nextProps.eventModal)
      }
    }

    return null
  }

  showEventTypeColor = memoize((colorId, startDate, mode = 'icon') => {
    if (
      colorId === 'T' &&
      this?.props?.eventModal?.meta?.taskColors &&
      Array.isArray(this.props.eventModal.meta.taskColors)
    ) {
      const isFutureDate =
        (startDate && new Date(startDate).getTime() >= new Date().getTime()) ||
        false
      const color = isFutureDate
        ? this.props.eventModal.meta.taskColors.find(
            x => x.description === 'Task'
          )?.color
        : this.props.eventModal.meta.taskColors.find(
            x => x.description === 'Task Past Due'
          )?.color

      if (color && typeof color === 'object') {
        return mode === 'icon' ? getEventIcon(color) : getRgb(color)
      }
    }

    if (
      this?.props?.eventModal?.meta?.eventTypeOptions &&
      Array.isArray(this.props.eventModal.meta.eventTypeOptions)
    ) {
      /* for a standard event */
      const color = this.props.eventModal.meta.eventTypeOptions.find(
        x => x.dataId === colorId
      )?.color

      if (color && typeof color === 'object') {
        return mode === 'icon' ? getEventIcon(color) : getRgb(color)
      }
    }

    if (
      this?.props?.eventModal?.eventTypeEditor?.meta?.colorOptions &&
      Array.isArray(this.props.eventModal.eventTypeEditor.meta.colorOptions)
    ) {
      // debugger
      /* for a newly created or modified event */
      const color = this.props.eventModal.eventTypeEditor.meta.colorOptions.find(
        x => x.dataId === colorId
      )?.color

      if (color && typeof color === 'object') {
        return mode === 'icon' ? getEventIcon(color) : getRgb(color)
      }
    }

    return null
  })

  render() {
    // console.log('titleBar', this.props)
    const {
      eventModal: { fields }
    } = this.props
    const { initialized, title } = this.state
    // const titleColor = fields && fields.eventType && fields.eventType.value ? this.showEventTypeColor(fields.eventType.value, 'color') : '#444'

    // console.log('TitleBar', this.props)
    return initialized ? (
      <span>
        {fields && fields.eventType && fields.eventType.value
          ? this.showEventTypeColor(
              fields.eventType.value,
              fields?.startDate?.value,
              'icon'
            )
          : null}
        <span
          style={{
            color:
              this.showEventTypeColor(
                fields?.eventType?.value,
                fields?.startDate?.value,
                'style'
              ) || '#444'
          }}
        >
          {title || 'New Event'}
        </span>
      </span>
    ) : null
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TitleBar)
