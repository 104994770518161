import React from 'react'
import { Icon } from '@mui/material'
import { errorRed, successGreen } from 'theme/colors'

const BooleanCellRenderer = ({ value }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      {value ? (
        <Icon
          style={{
            color: successGreen,
            fontSize: 14
          }}
        >
          check_circle
        </Icon>
      ) : (
        <Icon
          style={{
            color: errorRed,
            fontSize: 14
          }}
        >
          block
        </Icon>
      )}
    </div>
  )
}

export default BooleanCellRenderer
