/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  InputLabel,
  Icon,
  NativeSelect,
  FormControl,
  Button,
  TextField
} from '@mui/material'
// import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearchMobile'
import { mobileStateSelector } from 'mobile/selectors'
import IndexSearch from 'mobile/components/IndexSearchMobile'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import Grid from 'mobile/grid'

import {
  exactMatchSearch,
  partialMatchSearch
} from 'mobile/components/IndexSearchMobile/actions'
import { mapColumnHeaders, noop } from 'utils'
import { mapSOInquiryHeaders } from 'pages/SalesOrderInquiry/behaviors'
import { mapGridHeaders } from 'pages/PurchaseOrderInquiry/behaviors'
import TabTextField from './components/TabTextField'
import { executeInquiry, exactMatch } from './actions'
import './styles/css-mod-ignore.scss'

const getRowNodeId = x => x.dataId

const productStatusOptions = [
  {
    dataId: 'O',
    description: 'Open'
  },

  {
    dataId: 'R',
    description: 'Request for Quote'
  }
]
const options = [
  {
    dataId: 'O',
    description: 'Open'
  },
  {
    dataId: 'C',
    description: 'Completed'
  },
  {
    dataId: 'R',
    description: 'Request for Quote'
  },
  {
    dataId: 'E',
    description: 'Expired Request for Quote'
  },
  {
    dataId: 'A',
    description: 'All'
  }
]
const DropDownComponent = props => {
  const searchRef = React.createRef()
  console.log(searchRef)
  const ttfRef = React.createRef()
  const [job, setJob] = useState('')
  const [types, setTypes] = useState(options)
  const [prodDescription, setProdDesc] = useState('')
  const [prodDisabled, setProdDisabled] = useState(false)
  const [jobDisabled, setJobDisabled] = useState(false)
  const [vendorDisabled, setVendorDisabled] = useState(false)
  const [poDisabled, setPODisabled] = useState(false)
  const [vendorDescription, setVendDesc] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [purchaseOrderId, setPOId] = useState('')
  const [productId, setProductId] = useState('')
  const [orderType, setOrderType] = useState(options[0].dataId)

  const [showGrid, setShowGridAction] = useState(false)
  const [height, setHeight] = useState(275)
  const [fetching, setFetching] = useState(false)
  const [columnDefs, setColumnDefs] = useState([])
  const [rowData, setRowData] = useState([])
  const [counter, setCounter] = useState(0)

  const setShowGrid = bool => {
    const mobState = props?.mobileState?.toJS ? props.mobileState.toJS() : {}

    const gridWrapperHeight = mobState?.isTablet ? 700 : 600
    setShowGridAction(bool)
    if (!bool) {
      setHeight(275)
    } else {
      setHeight(gridWrapperHeight)
      //
    }
  }
  useEffect(() => {
    const resetPo = () => {
      if (purchaseOrderId) {
        setPOId('')
      }
    }

    if (productId && types === options) {
      setTypes(productStatusOptions)
    } else if (types !== options) {
      setTypes(options)
    }
    if (productId) {
      resetPo()
      setJobDisabled(true)
      setVendorDisabled(true)
      setPODisabled(true)
    } else if (vendorId) {
      resetPo()
      setJobDisabled(true)
      setProdDisabled(true)
      setPODisabled(true)
    } else if (job) {
      resetPo()
      setVendorDisabled(true)
      setProdDisabled(true)
      setPODisabled(true)
    }
    if (!productId && !job && !vendorId && !purchaseOrderId) {
      setJobDisabled(false)
      setVendorDisabled(false)
      setPODisabled(false)
      setProdDisabled(false)
    }
  }, [productId, job, vendorId, productId, purchaseOrderId])

  // if prod set => PO vendor job disabled
  // if vendor set => PO product job disabled
  // if job set => PO PROd vendor disabled
  // if PO set, nothing disabled but it gets killed if a field is set..
  const jobSet = v => {
    setJob(v)
    setShowGrid(false)
  }
  const setData = (data = {}) => {
    setPOId(data.description)
    setShowGrid(false)
  }

  const setProd = (data = {}) => {
    setProdDesc(data.description)
    setProductId(data.dataId)
    setShowGrid(false)
  }
  const setVendor = (data = {}) => {
    setVendDesc(data.description)
    setVendorId(data.dataId)
    setShowGrid(false)
  }
  const exactMatchPO = async keyword => {
    try {
      const ret = await props.dispatch(exactMatch(null, { keyword }))
      if (ret && ret.description) {
        setData(ret)
      } else {
        setData('')
      }
      return ret
    } catch (ex) {
      setData('')
      throw new Error('no record')
    }
  }

  const partialMatch = args =>
    props.dispatch(partialMatchSearch(null, { ...args }))

  const exactMatchS = args =>
    props.dispatch(exactMatchSearch(null, { ...args }))

  // const onRowSelected = params => {
  //   if (this.props.onRowSelected) {
  //     // debugger
  //     // this.props.onRowSelected.call(this, params)
  //     this.props.onRowSelected(params)
  //   }
  // }
  const changeOrderType = e => {
    setOrderType(e.target.value)
    setShowGrid(false)
  }

  const inquiry = async () => {
    setFetching(true)
    try {
      // let r
      // props.dispatch({ type: 'test' })
      const r = await props.dispatch(
        executeInquiry(props.form, {
          // customerId,
          status: orderType,
          jobId: job,
          productId,
          vendorId,
          dataId: purchaseOrderId
        })
      )

      setFetching(false)
      if (r) {
        if (r?.purchaseOrders?.data?.length) {
          setColumnDefs(
            r.purchaseOrders.meta.columns
              .map(mapColumnHeaders)
              .map(mapGridHeaders)
              .map(x => {
                const {
                  pinnedRowCellRendererFramework,
                  cellRendererFramework,
                  pinned,
                  ...rest
                } = x
                return rest
              })
          )
          setRowData(r.purchaseOrders.data)
        }
        setCounter(r.purchaseOrders.data.length)

        const mobState = props?.mobileState?.toJS
          ? props.mobileState.toJS()
          : {}

        const gridWrapperHeight = mobState?.isTablet ? 700 : 600

        setHeight(gridWrapperHeight)

        setShowGrid(true)
      }
    } catch (e) {
      setFetching(false)
      setShowGrid(false)
    }
    // else {
    //   setFetching(false)
    //   setShowGrid(false)
    // }
  }
  console.log(columnDefs, rowData)

  const mobileState = props?.mobileState?.toJS ? props.mobileState.toJS() : {}
  const gridHeight = mobileState?.isTablet ? 400 : 325
  const inputPadding = mobileState?.isTablet ? 20 : 0

  const searchDisabled = fetching || showGrid

  return (
    <div
      className="mobile-po-inquiry-wrapper"
      style={{ width: 500, overflow: 'auto' }}
    >
      <DDIExpansionPanel
        title="Purchase Order Inquiry"
        expansionPanelContentStyle={{ padding: 0, width: '100%' }}
        expansionPanelDetailsStyle={{ padding: 0, width: '100%' }}
        style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)' }}
      >
        <div
          className="mobile-po-inquiry-inner-wrapper"
          style={{
            minWidth: 500,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
          }}
        >
          <div style={{ padding: '0 10px 10px 10px' }}>
            <div
              className="mobile-po-inquiry-inner-flex-wrapper"
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingLeft: inputPadding,
                paddingRight: inputPadding
              }}
            >
              <div className='mobile-po-inquiry-input-wrapper-with-placeholder' style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                <TabTextField
                  propertyName="poNumber"
                  fullWidth
                  onTab={async v => {
                    return exactMatchPO(v)
                  }}
                  onBlur={noop}
                  label="P/O Number"
                  placeholder="Search"
                  disabled={poDisabled || fetching}
                  ref={ttfRef}
                  value={purchaseOrderId}
                  wrapperStyle={null}
                  variant="standard"
                />
              </div>
              <div className='mobile-po-inquiry-input-wrapper-with-placeholder' style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                <IndexSearch
                  label="Product"
                  placeholder="Select Product"
                  indexSearchType="product"
                  setField={(...args) => {
                    setProd(args[3])
                  }}
                  disableLastSearchButton
                  fullWidth
                  preventAutoDisable
                  displayDescription
                  // description={}
                  exactMatchSearch={exactMatchS}
                  partialMatchSearch={partialMatch}
                  moreInfo
                  forwardRef
                  description={prodDescription}
                  value={productId}
                  disabled={prodDisabled || fetching}
                  variant="standard"
                />
              </div>
            </div>
            <div
              className="mobile-po-inquiry-inner-flex-wrapper"
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingLeft: inputPadding,
                paddingRight: inputPadding
              }}
            >
              <div className='mobile-po-inquiry-input-wrapper-with-placeholder' style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                <IndexSearch
                  label="Vendor"
                  placeholder="Select Vendor"
                  indexSearchType="vendor"
                  // label="Customer"
                  setField={(...args) => {
                    setVendor(args[3])
                    // setData(args[3])
                  }}
                  disableLastSearchButton
                  fullWidth
                  preventAutoDisable
                  displayDescription
                  disableSearchAll
                  // description={}
                  exactMatchSearch={exactMatchS}
                  partialMatchSearch={partialMatch}
                  moreInfo
                  forwardRef
                  description={vendorDescription}
                  value={vendorId}
                  disabled={vendorDisabled || fetching}
                  variant="standard"
                />
              </div>
              <div style={{ flexBasis: '50%', flexGrow: 0, padding: 10 }}>
                <TextField
                  value={job}
                  onChange={e => jobSet(e.target.value)}
                  fullWidth
                  onBlur={noop}
                  label="Job"
                  disabled={jobDisabled || fetching}
                  variant="standard"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingLeft: inputPadding,
                paddingRight: inputPadding
              }}
            >
              <div
                className="mobile-po-inquiry-dropdown-selection-wrapper"
                style={{ flexGrow: 0, padding: 10 }}
              >
                <FormControl>
                  <InputLabel
                    variant="standard"
                    style={{
                      color: '#517b9c',
                      fontSize: 12,
                      margin: 0
                    }}
                    htmlFor="order-type"
                  >
                    Type
                  </InputLabel>
                  <NativeSelect
                    native
                    // value={types[0].value}
                    disabled={fetching}
                    value={orderType}
                    onChange={changeOrderType}
                    inputProps={{
                      name: 'order-type',
                      id: 'order-type'
                    }}
                  >
                    {types.map(x => (
                      <option value={x.dataId}>{x.description}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </DDIExpansionPanel>
      {showGrid ? (
        <div
          id="mobile-sales-order-search"
          style={{ padding: '0 10px', minHeight: gridHeight, overflow: 'auto' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 10px'
            }}
          >
            <h4 style={{ color: '#404040', fontSize: 14 }}>Search Results</h4>
            {counter ? (
              <div style={{ color: 'rgb(81, 123, 156)', fontSize: 11 }}>
                showing results 1-{counter} (tap to select)
              </div>
            ) : null}
          </div>
          <Grid
            height={gridHeight}
            rowData={rowData}
            columnDefs={columnDefs}
            getRowNodeId={getRowNodeId}
            onRowSelected={props.onRowSelected}
            rowSelection="single"
            rowDeselection
            autoSize
          />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          padding: 10
        }}
      >
        <Button
          variant="contained"
          size="large"
          startIcon={<Icon>search</Icon>}
          disabled={searchDisabled}
          // onCLick={}
          onClick={inquiry}
        >
          Search Orders
        </Button>
      </div>
    </div>
  )
}

const mapState = state => ({ mobileState: mobileStateSelector(state) })
export default connect(mapState)(DropDownComponent)

