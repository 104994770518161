import { call, fork, take, put, delay } from 'redux-saga/effects'
import { api } from 'services'
import shortid from 'shortid'
import { push } from 'connected-react-router'
import { show } from 'snackbar/actions'
import { removeToken } from 'auth/actions'
import { signup, resetPassword, changePassword, changeEmail } from './actions'

export function* signupProcess(action) {
  const { payload } = action
  const { response, error } = yield call(api.signup, {
    username: payload.username,
    password: payload.password,
    confirmPassword: payload.confirmPassword,
    company: payload.company,
    email: payload.email,
    userKey: payload.userKey
  })

  if (response) {
    yield put(signup.success(response, action.meta))
    yield delay(5000)
    yield put(push(`/login?username=${payload.username}`))
  } else {
    yield put(signup.failure(error, action.meta.thunk))
  }
}
export function* signupListener() {
  while (true) {
    const action = yield take(signup.type.REQUEST)
    yield fork(signupProcess, action)
  }
}

export function* resetPasswordProcess({ payload, meta }) {
  debugger
  const { response, error } = yield call(api.resetPassword, payload)

  if (response) {
    yield put(resetPassword.success(response, meta))

    yield put(
      show({
        message: {
          message: 'Password was reset successfully',
          type: 'success',
          id: shortid.generate()
        }
      })
    )
    yield delay(2000)
    yield put(push('/login'))
  } else {
    yield put(resetPassword.failure(error, meta.thunk))
  }
}
export function* resetPasswordListener() {
  while (true) {
    const action = yield take(resetPassword.type.REQUEST)
    yield fork(resetPasswordProcess, action)
  }
}

export function* changeEmailListener() {
  while (true) {
    const action = yield take(changeEmail.type.REQUEST)
    yield fork(changeEmailProcess, action)
  }
}
export function* changeEmailProcess({ payload, meta }) {
  const { response, error } = yield call(api.changeEmail, payload)

  if (response) {
    yield put(changeEmail.success({ ...response }, meta))
    yield put(
      show({
        message: {
          message: 'Email was changed successfully',
          type: 'success',
          id: shortid.generate()
        }
      })
    )
  } else {
    yield put(changeEmail.failure(error, meta.thunk))
  }
}

export function* changePasswordProcess({ payload, meta }) {
  const { response, error } = yield call(api.changePassword, payload)
  // yield put(removeToken())
  if (response) {
    yield put(changePassword.success({ ...response, flag: payload.flag }, meta))
    yield put(
      show({
        message: {
          message: 'Password was changed successfully',
          type: 'success',
          id: shortid.generate()
        }
      })
    )
    if (!payload.flag) {
      yield put(removeToken())
      yield delay(2000)
      yield put(push('/login'))
    }
  } else {
    yield put(changePassword.failure(error, meta.thunk))
  }
}
export function* changePasswordListener() {
  while (true) {
    const action = yield take(changePassword.type.REQUEST)
    yield fork(changePasswordProcess, action)
  }
}
export default function* accountListeners() {
  yield fork(signupListener)
  yield fork(resetPasswordListener)
  yield fork(changePasswordListener)
  yield fork(changeEmailListener)
}
