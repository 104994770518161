import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Icon,IconButton,InputAdornment} from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import { noop } from 'utils'

function isValidDate(date) {
  return date && !isNaN(Date.parse(date))
}

export default class DDITimePicker extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    displayFormat: PropTypes.string,
    displayLabel: PropTypes.bool,
    disablePropChange: PropTypes.bool,
    disabled: PropTypes.bool,
    dispatch: PropTypes.func,
    format: PropTypes.string,
    hintStyle: PropTypes.object,
    hintText: PropTypes.string,
    iconStyles: PropTypes.object,
    inputStyle: PropTypes.object,
    id: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFound: PropTypes.bool,
    label: PropTypes.string,
    labelStyles: PropTypes.object,
    loadingIndicator: PropTypes.string,
    locale: PropTypes.string,
    placeholder: PropTypes.string,
    // propertyChange: PropTypes.func.isRequired,
    propertyName: PropTypes.string.isRequired,
    style: PropTypes.object,
    textFieldStyle: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }

  static defaultProps = {
    initialValue: null,
    disabled: false,
    disablePropChange: false,
    displayFormat: 'hh:mm a',
    displayLabel: true,
    dispatch: noop,
    format: 'MM/DD/YY hh:mm a',
    hintStyle: { display: 'none', fontSize: 12 },
    hintText: 'Select a Time',
    iconStyles: {},
    inputStyle: { marginTop: 0 },
    isFetching: false,
    isFound: false,
    label: '',
    labelStyles: {},
    loadingIndicator: '',
    locale: 'en-US',
    placeholder: 'Select a Time',
    // textFieldStyle: { height: 26, marginTop: 8, width: '100%' },
    style: {},
    textFieldStyle: {
      fontSize: 11,
      width: '100%',
      height: 48
    },
    type: 'Field',
    value: null
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.dirty && isValidDate(nextProps.value)) {
      return {
        value: nextProps.value,
        dirty: false
      }
    }
    return null
  }

  constructor(props) {
    super(props)
    console.log(props)
    let value;

    if (props.initialValue) {
      value = props.initialValue;
    } else if (props.value) {
      value = props.value;
    } else {
      value = null;
    }
    this.state = {
      labelDisplayCSS: 'none',
      value: value,
      calendarOpen: false,
    }
  }



  onChange = date => {
    if (date) {
      this.setState(
        { labelDisplayCSS: 'none',value:date, dirty: true }
      )
    }
  }
  onAccept = date => {
    this.setState(
      prevState => ({ ...prevState, value: date, isDirty: false }),
      () => {
        this.props.setField(isValidDate(date)?date:'');
      }
    )
  }
  // onFocus = e => {
  //   // e.preventDefault()
  //   //  this.dp.focus()
  // }

  onBlur = e => {
    e.persist()
    this.setState({ dirty: false }, () => {
      if (this.state.value) {
        this.props.onBlur(this.state.value)
      } else {
        this.props.onBlur(null)
      }
    })
  }

  render() {
    const {
      displayFormat,
      initialValue,
      disabled,
      id,
      value,
      isFound,
      displayLabel,
      dispatch,
      hintStyle,
      hintText,
      label,
      locale,
      placeholder,
      textFieldStyle,
      // propertyChange,
      propertyName,
      dataId,
      isFetching,
      loadingIndicator,
      labelStyles,
      inputStyle,
      style
    } = this.props

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          className="ddi-picker-with-label"
          style={{ position: 'relative' }}
          tabIndex={0}
          onFocus={this.onFocus}
        >
          <TimePicker
            className="ddi-picker"
            renderInput={props => 
              <div>
              <TextField  
                {...props}         
                placeholder={placeholder}
                variant="standard"
                style={{ width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton style={{ padding: 5 }}
                        onClick={() => this.setState(prevState => ({ calendarOpen: !prevState.calendarOpen }))}
                        disabled={disabled}
                      >
                        <Icon
                          fontSize="small"
                        >
                          event
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
               />
             </div>
           }
            {...this.props}
            hintStyle={hintStyle}
            open={this.state.calendarOpen}
            label={label}
            onClose={() => this.setState({calendarOpen: false})}
            placeholder="Please Select a Time"
            inputFormat={displayFormat}
            locale={locale}
            id={id}
            value={this.state.value}
            disabled={disabled}
            onChange={this.onChange}
            onAccept={this.onAccept}
            style={style}
            ref={c => (this.dp = c)}
            fullWidth
            onBlur={this.onBlur}
          />
          {isFetching && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 5
              }}
            >
              <img alt="loading" src={loadingIndicator} />
            </div>
          )}
        </div>
      </LocalizationProvider>
    )
  }
}
