
import '@babel/polyfill'
import 'file-loader?name=[name].[ext]!./favicon.ico'
import React, { useState, useEffect } from 'react'
import {createRoot} from 'react-dom/client';
import { Provider, connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import Snackbar from 'snackbar'
import { fromJS } from 'immutable'
import createHistory from 'history/createHashHistory'
import FontFaceObserver from 'fontfaceobserver'
import 'sanitize.css/sanitize.css'
import MuiModal from 'modals/MuiModal'
import { ThemeProvider } from '@mui/material/styles'
import {
  isMobile,
  isTablet,
  isMobileOnly,
  isIOS,
  isMobileSafari,
  isAndroid,
  mobileVendor,
  mobileModel,
  isIOS13
} from 'react-device-detect'
import { getAsyncInjectors } from 'utils/asyncInjectors'
import { checkTokenExpiration } from 'auth/actions'
import informTheme from 'theme/informTheme'

import { TOGGLE_MOBILE } from 'mobile/constants'
import rootSaga from './sagas'
import configureStore from './store'
import Routes from './routes'
const root = createRoot(document.getElementById('root'));
const appHistory = createHistory()
const robotoObserver = new FontFaceObserver('Roboto', {})
robotoObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded')
  },
  () => {
    document.body.classList.remove('fontLoaded')
  }
)

const mobileArgs = {
  mobile: {
    isMobile,
    isTablet,
    isMobileOnly,
    isIOS,
    isAndroid,
    mobileVendor,
    mobileModel,
    isMobileSafari,
    isIOS13
  }
}
const initialState = fromJS(mobileArgs)
const store = configureStore(initialState, appHistory)
const { injectReducer } = getAsyncInjectors(store)
store.runSaga(rootSaga, injectReducer)

function useWindowSize(dispatch) {
  const isDevClient =
    typeof window === 'object' && process.env.NODE_ENV === 'development'
  const toggle = bool =>
    dispatch({
      type: TOGGLE_MOBILE,
      payload: {
        isMobile: bool,
        isTablet,
        isMobileOnly,
        isIOS,
        isAndroid,
        mobileVendor,
        mobileModel,
        isMobileSafari,
        isIOS13
      }
    })
  function getSize() {
    return {
      width: isDevClient ? window.innerWidth : undefined,
      height: isDevClient ? window.innerHeight : undefined
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)
  const [thisIsMobile, setIsMobile] = useState(null)

  useEffect(() => {
    if (!isDevClient) {
      return false
    }
    if (thisIsMobile === null) {
      const size = getSize()
      if (size.width <= 1024) {
        setIsMobile(true)
        toggle(true)
      } else {
        setIsMobile(false)
        toggle(false)
      }
    }
    function handleResize() {
      const size = getSize()
      if (!thisIsMobile && size.width <= 1024) {
        setIsMobile(true)
        toggle(true)
      }
      if (thisIsMobile && size.width >= 1024) {
        setIsMobile(false)
        toggle(false)
      }
      setWindowSize(size)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [thisIsMobile]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

const ResizeWrapper = ({ dispatch, children }) => {
  useWindowSize(dispatch)
  return children
}
const ConnectedResize = connect()(ResizeWrapper)

store.dispatch(checkTokenExpiration())

root.render(
  <ThemeProvider theme={informTheme}>
      <Provider store={store}>
        <ConnectedResize>
          <ConnectedRouter history={appHistory} noInitialPop>
            <Routes />

            <MuiModal />
            <Snackbar />
          </ConnectedRouter>
        </ConnectedResize>
      </Provider>
  </ThemeProvider>
)

if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install() // eslint-disable-line
}
