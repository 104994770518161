/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox as MuiCB,
  FormGroup,
  FormControlLabel
} from '@mui/material'
import { noop, plainDeepEqual } from 'utils'

export default class Checkbox extends Component {
  static ddiType = 'Checkbox'
  constructor(props) {
    super(props)

    this.state = { value: props.initialValue ? props.initialValue : false }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: !!nextProps.value }
    }
    return null
  }

  onChange = (e, date) => {
    console.log(e, date, this.props)
  }

  handleChange = event => {
    const value = event.target.checked
    this.setState({ value }, () => this.props.setField(value))
  }

  getCheckbox = () => {
    const { id, disabled, propertyName, value } = this.props

    return (
      <MuiCB
        // {...this.props}
        id={id}
        value={this.state.value}
        disabled={disabled}
        onChange={this.handleChange}
        checked={this.state.value}
      />
    )
  }

  render() {
    const {
      initialValue,
      disabled,
      id,
      value,
      isFound,
      dispatch,
      hintStyle,
      hintText,
      label,
      locale,
      textFieldStyle,
      // propertyChange,
      propertyName,
      dataId,
      isFetching,
      loadingIndicator
    } = this.props

    return label ? (
      <FormControlLabel control={this.getCheckbox()} label={label} />
    ) : (
      this.getCheckbox()
    )
  }
}
