import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import {Icon,IconButton,InputAdornment} from '@mui/material'
import useState from 'hooks/useStateWithCallback'
import { noop } from 'utils'
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types'
import { isValidDate } from './utils'

const DateTimePicker = props => {
  const {
    disabled,
    label,
    tabIndex,
    value,
    maxDate,
    setField,
    onBlur,
    initialValue,
    hintStyle,
    format='MM/dd/yyyy hh:mm a',
    openTo = 'day',
  } = props

  const [state, setState] = useState({
    value: initialValue || value,
    isDirty: false
  })
  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const onChange = date => {
    setState(prevState => ({ ...prevState, value: date, isDirty: true }))
  }

  const onAccept = date => {
    setState(
      prevState => ({ ...prevState, value: date, isDirty: false }),
      () => {
        setField(isValidDate(date)?date:'');
      }
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className="ddi-picker-with-label"
        style={{ position: 'relative' }}
        tabIndex={tabIndex}
      >
        <DesktopDateTimePicker
          renderInput={props => 
            <div>
            <TextField  
              {...props}         
              variant="standard"
              style={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton style={{ padding: 5 }}
                      onClick={() => setCalendarOpen((calendarOpen) => !calendarOpen)}
                      disabled={disabled}
                    >
                      <Icon
                        fontSize="small"
                      >
                        event
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
             />
           </div>
         }
          hintStyle={hintStyle}
          open={calendarOpen}
          label={label}
          placeholder="Please Select a Date"
          inputFormat={format}
          onClose={() => setCalendarOpen(false)}
          value={isValidDate(state.value)?state.value:''}
          disabled={disabled}
          onChange={onChange}
          onAccept={onAccept}
          fullWidth
          onBlur={noop}
          maxDate={maxDate}
          onDoubleClick={props.onDoubleClick}
          openTo={openTo}
        />
      </div>
    </LocalizationProvider>
  )
}

DateTimePicker.propTypes = {
  format: PropTypes.string,
}

export default DateTimePicker
