function findElementWithOnClickedMenu(attributeName) {
  const allDivs = document.querySelectorAll('.lm_item_container')
  const allModals = document.querySelectorAll('.MuiDialog-root')
  // Find the first `div` that is not hidden
  const visibleDiv = Array.from(allDivs).find(div => {
    // Check if the element has `display` set to something other than `none`
    return window.getComputedStyle(div).display !== 'none'
  })
  const visibleModals = Array.from(allModals).find(modal => {
    // Check if the element has `display` set to something other than `none`
    return window.getComputedStyle(modal).display !== 'none'
  })
  if(attributeName==='data-cloud-help-popup') {
    return visibleModals;
  }
    return visibleDiv
}

function findElementWithOnClickedMenuforMobile() {
  const mobileDiv = document.getElementById('inform-cloud-mobile-container')
  return mobileDiv
}

function findElementWithAttributeRecursive(element, attributeName) {
  // Check if the current element has the specified attribute
  if (element.hasAttribute(attributeName)) {
    return element
  }

  // Recursively search through each child element
  for (const child of element.children) {
    const found = findElementWithAttributeRecursive(child, attributeName)
    if (found) {
      return found
    }
  }

  // If no element is found with the attribute, return null
  return null
}

export function findElementWithCustomAttribute(element, attribute) {
  while (element) {
    // Check if the current element has the custom attribute
    if (element.hasAttribute(attribute)) {
      return element
    }
    element = element.parentElement
    // Move to the parent element if the attribute is not found
  }
  return null // Return null if no element with the attribute is found
}

function openURL(targetElement, links, attributeName) {
  const attributeValue = targetElement.getAttribute(attributeName)

  // Match the attribute value to a link in the list
  const linkList = links.find(link => (link.name || link.Name) === attributeValue)
  const url = linkList?.link ? linkList.link: linkList?.Link ?linkList.Link : ''
  window.open(
    `https://advantive.my.site.com/support/s/article/${url}`,
    '_blank'
  )
}

export function openURLforPopup(links, attributeValue) {
  // Match the attribute value to a link in the list
  const linkList = links.find(link => link.Name === attributeValue)
  const url = linkList ? linkList.Link : ''
  if (url) {
    window.open(
      `https://advantive.my.site.com/support/s/article/${url}`,
      '_blank'
    )
  } else {
    window.open('https://advantive.my.site.com/support/s/', '_blank')
  }
}

export const CLOUD_ATTRIBUTE_NAME = 'data-cloud-help'
export const MOBILE_ATTRIBUTE_NAME = 'data-cloud-mobile-Help'
export const CLOUD_NAME = 'inform-cloud'
export const MOBILE_NAME = 'inform-mobile'

export function openHelpLink(targetElement, links, attributeName, appName) {
  if (targetElement) {
    openURL(targetElement, links, attributeName)
  } else {
    let menuDiv
    if (appName === CLOUD_NAME) {
      menuDiv = findElementWithOnClickedMenu(attributeName)
    } else {
      menuDiv = findElementWithOnClickedMenuforMobile(appName)
    }
    const mainElement = findElementWithAttributeRecursive(
      menuDiv,
      attributeName
    )
    if (mainElement) {
      openURL(mainElement, links, attributeName)
    } else {
      window.open('https://advantive.my.site.com/support/s/', '_blank')
    }
  }
}

export const helpArray = [
  {
    Name: 'ID_NOT_FOUND'
  },
  {
    Name: 'SelectionCriteriaView',
    Link: 'Selecting-data'
  },
  {
    Name: 'customermasterview',
    Link: 'How-to-create-a-customer'
  },
  {
    Name: 'customermasterview_setuphelp_mainhelp',
    Link: 'Specify-standard-customer-properties'
  },
  {
    Name: 'customermasterview_setuphelp_generalhelp',
    Link: 'Configure-default-order-preferences'
  },
  {
    Name: 'customermasterview_setuphelp_mainhelp',
    Link: 'Add-a-customer'
  },
  {
    Name: 'customermasterview_setuphelp_credithelp',
    Link: 'Configure-tax-rate-and-credit-options'
  },
  {
    Name: 'customermasterview_setuphelp_noteshelp',
    Link: 'Add-customer-notes'
  },
  {
    Name: 'mobile_customermasterview_setuphelp_noteshelp',
    Link: 'mobile-Add-customer-notes'
  },
  {
    Name: 'customermasterview_setuphelp_freighthelp',
    Link: 'Configure-default-customer-freight-options'
  },
  {
    Name: 'CustomerMasterView_SetupHelp_TruckRoutesHelp',
    Link: 'Set-up-default-customer-truck-routes'
  },
  {
    Name: 'CustomerMasterView_SetupHelp_CreditCardsHelp',
    Link: 'Add-customer-credit-references'
  },
  {
    Name: 'customermasterview_setuphelp_contractshelp',
    Link: 'Assign-a-price-contract-to-a-customer'
  },
  {
    Name: 'customermasterview_setuphelp_POLimitsHelp',
    Link: 'Configure-spending-limits-for-purchase-orders-by-customer'
  },
  {
    Name: 'customermasterview_setuphelp_vendoridshelp',
    Link: 'Associate-a-consignment-vendors-ID-with-a-customer'
  },
  {
    Name: 'customermasterview_setuphelp_sourceshelp',
    Link: 'Add-a-customer-to-a-source'
  },
  {
    Name: 'customermasterview_setuphelp_taxmatrixhelp',
    Link: 'Work-with-customer-tax-matrices'
  },
  {
    Name: 'Customermasterview_setuphelp_ConsignmentProductsHelp',
    Link: 'Set-up-a-consignment-warehouse'
  },
  {
    Name: 'customermasterview_setuphelp_customfieldshelp',
    Link: 'Manage-custom-fields'
  },
  {
    Name: 'customermasterview_setuphelp_WebOptionshelp',
    Link: 'Configure-global-customer-eCommerce-options'
  },
  {
    Name: 'customermasterview_setuphelp_partnumbershelp',
    Link: 'Assign-customer-part-numbers-by-product'
  },
  {
    Name: 'customermasterview_ledgerhelp',
    Link: 'How-to-view-customer-invoice-and-payment-history'
  },
  {
    Name: 'CustomerMasterView_LedgerHelp_InvoicesHelp',
    Link: 'Use-the-customer-ledger'
  },
  {
    Name: 'CustomerMasterView_LedgerHelp_PaymentHistoryHelp',
    Link: 'View-payment-history'
  },
  {
    Name: 'CustomerMasterView_LedgerHelp_OrdersHelp',
    Link: 'Get-a-customers-order-history'
  },
  {
    Name: 'CustomerMasterView_LedgerHelp_SalesHistoryHelp',
    Link: 'Review-a-customers-prior-year-sales'
  },
  {
    Name: 'CustomerMasterView_LedgerHelp_SalesAnalysisHelp',
    Link: 'Analyze-a-customers-sales-history'
  },
  {
    Name: 'CustomerMasterView_LedgerHelp_SubAccountLedgerHelp',
    Link: 'Organize-customer-invoices-by-ship-to-locations'
  },
  {
    Name: 'CustomerMasterView_LedgerHelp_MonthlyBreakdownHelp',
    Link: 'Subtotal-customers-invoices-by-month'
  },
  {
    Name: 'customermasterview_shiptohelp',
    Link: 'Set-up-customer-Ship-To-locations'
  },
  {
    Name: 'ShipToMasterView',
    Link: 'Set-up-customer-Ship-To-locations'
  },
  {
    Name: 'ShipToMasterView_SetupHelp_InformationHelp',
    Link: 'Set-up-customer-Ship-To-locations'
  },
  {
    Name: 'ShipToMasterView_SetupHelp_CreditHelp',
    Link: 'Configure-tax-rate-and-credit-options-by-specific-ship-to-location'
  },
  {
    Name: 'ShipToMasterView_SetupHelp_ediHelp',
    Link: 'Set-up-and-use-EDI'
  },
  {
    Name: 'ShipToMasterView_SetupHelp_TruckRoutesHelp',
    Link: 'Set-up-specific-ship-to-truck-routes'
  },
  {
    Name: 'shiptomasterview_setuphelp_monthlybudgethelp',
    Link: 'Ship-To-Monthly-Budget'
  },
  {
    Name: 'shiptomasterview_setuphelp_stocklevelshelp',
    Link: 'Configure-minimum-and-maximum-stock-levels-per-ship-to'
  },
  {
    Name: 'ShipToMasterView_AuditHelp',
    Link: 'Review-changes-made-to-ship-to-locations'
  },
  {
    Name: 'ShipToMasterView_Setuphelp_activities',
    Link: 'View-and-create-activities'
  },
  {
    Name: 'CustomerMasterView_ContactsHelp',
    Link: 'Manage-customer-engagement-with-contact-analysis'
  },
  {
    Name: 'ActivityMasterView',
    Link: 'View-and-create-activities'
  },
  {
    Name: 'customermasterview_attachmentshelp_attachmentshelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'customermasterview_attachmentshelp_edocshelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'customermasterview_audithelp',
    Link: 'Get-an-audit-history-of-changes-made'
  },
  {
    Name: 'CustomerJobMasterView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'customermasterview_activitieshelp',
    Link: 'View-and-create-activities'
  },
  {
    Name: 'customercategorymasterview',
    Link: 'Categorize-customers-by-business-or-pricing'
  },
  {
    Name: 'customertaxview',
    Link: 'Create-tax-codes'
  },
  {
    Name: 'salesmanmasterview',
    Link: 'Manage-your-salespeople'
  },
  {
    Name: 'SORecalculatePricesView',
    Link: 'Recalculate-cost-or-pricing-on-a-sales-order'
  },
  {
    Name: 'customersourcemasterview',
    Link: 'Create-sources-to-group-customers'
  },
  {
    Name: 'customerterritoryview',
    Link: 'Manage-sales-territories'
  },
  {
    Name: 'customerjobmasterview',
    Link: 'Create-jobs-to-group-sales-and-purchase-orders'
  },
  {
    Name: 'customerrankcriteriaview',
    Link: 'Create-customer-ranking-criteria'
  },
  {
    Name: 'ReindexCustomerYTDSales',
    Link: 'About-reindexing'
  },
  {
    Name: 'ReindexCustomer',
    Link: 'About-reindexing'
  },
  {
    Name: 'vendormasterview',
    Link: 'Manage-your-vendors'
  },
  {
    Name: 'vendormasterview_LedgerHelp',
    Link: 'How-to-view-a-vendors-ledger'
  },
  {
    Name: 'vendormasterview_setuphelp_mainhelp',
    Link: 'How-to-create-and-edit-vendors'
  },
  {
    Name: 'vendormasterview_setuphelp_generalhelp',
    Link: 'Add-a-vendor'
  },
  {
    Name: 'vendormasterview_setuphelp_purchasinghelp',
    Link: 'Configure-default-purchasing-options-for-vendors'
  },
  {
    Name: 'vendormasterview_setuphelp_noteshelp',
    Link: 'Add-vendor-notes'
  },
  {
    Name: 'vendormasterview_setuphelp_consignmenthelp',
    Link: 'Set-up-vendor-consignments'
  },
  {
    Name: 'vendormasterview_setuphelp_warrantyhelp',
    Link: 'Add-vendor-warranty-requirements-and-reasons'
  },
  {
    Name: 'vendormasterview_setuphelp_sourceshelp',
    Link: 'Add-a-vendor-to-a-source'
  },
  {
    Name: 'vendormasterview_setuphelp_patternshelp',
    Link: 'Configure-pattern-requirements-for-product-numbers'
  },
  {
    Name: 'vendormasterview_setuphelp_customfieldshelp',
    Link: 'Manage-custom-fields'
  },
  {
    Name: 'vendormasterview_ledgerhelp_invoiceshelp',
    Link: 'View-a-vendors-ledger'
  },
  {
    Name: 'vendormasterview_ledgerhelp_paymenthistoryhelp',
    Link: 'View-a-vendors-payment-history'
  },
  {
    Name: 'vendormasterview_ledgerhelp_ordershelp',
    Link: 'Search-for-a-vendors-PO'
  },
  {
    Name: 'vendormasterview_contactshelp',
    Link: 'Manage-vendor-engagement-with-contact-analysis'
  },
  {
    Name: 'aptermmasterview',
    Link: 'Set-up-vendor-accounts-payable-terms'
  },
  {
    Name: 'vendorcategorymasterview',
    Link: 'Categorize-vendors-by-business-or-product-line'
  },
  {
    Name: 'vendormasterview_attachmentshelp_edocshelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'vendormasterview_attachmentshelp_attachmentshelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'vendorsourcemasterview',
    Link: 'Add-a-vendor-to-a-source'
  },
  {
    Name: 'vendormasterview_activitieshelp',
    Link: 'View-and-create-activities'
  },
  {
    Name: 'vendormasterview_audithelp',
    Link: 'Get-an-audit-history-of-changes-made'
  },
  {
    Name: 'ReindexVendorYTDSales',
    Link: 'About-reindexing'
  },
  {
    Name: 'ReindexVendor',
    Link: 'About-reindexing'
  },
  {
    Name: 'productmasterview',
    Link: 'Manage-your-products'
  },
  {
    Name: 'productmasterview_LedgerHelp_Overview',
    Link: 'Analyze-and-view-product-details'
  },
  {
    Name: 'productmasterview_setuphelp_mainhelp',
    Link: 'Add-main-product-and-price-information'
  },
  {
    Name: 'productmasterview_attachmentshelp_edocshelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'productmasterview_setuphelp_purchasinghelp',
    Link: 'Configure-all-product-purchasing-preferences'
  },
  {
    Name: 'productmasterview_setuphelp_noteshelp',
    Link: 'Attach-notes-to-a-product'
  },
  {
    Name: 'productmasterview_setuphelp_optionshelp',
    Link: 'Enable-options-and-accessories'
  },
  {
    Name: 'productmasterview_setuphelp_assemblyhelp',
    Link: 'Working-with-assemblies-and-kits'
  },
  {
    Name: 'productmasterview_setuphelp_binshelp',
    Link: 'Specify-bin-locations-for-a-product'
  },
  {
    Name: 'productmasterview_setuphelp_replenishmenthelp',
    Link: 'Arrange-for-individual-product-replenishment'
  },
  {
    Name: 'productmasterview_setuphelp_vendorhelp',
    Link: 'Assign-a-vendor-to-a-product'
  },
  {
    Name: 'productmasterview_setuphelp_substituteshelp',
    Link: 'Add-product-substitutions'
  },
  {
    Name: 'productmasterview_setuphelp_keywordshelp',
    Link: 'Set-up-product-search-keywords'
  },
  {
    Name: 'productmasterview_setuphelp_rolluphistoryhelp',
    Link: 'View-price-rollup-history-for-a-product'
  },
  {
    Name: 'productmasterview_setuphelp_customfieldshelp',
    Link: 'Manage-custom-fields'
  },
  {
    Name: 'productmasterview_setuphelp_partnumbershelp',
    Link: 'Assign-product-part-numbers-by-customer'
  },
  {
    Name: 'NeedsID-Analysis-productmasterview',
    Link: 'Analyze-and-view-product-details'
  },
  {
    Name: 'productmasterview_setuphelp_weboptionshelp',
    Link: 'Add-products-to-the-catalog'
  },
  {
    Name: 'productmasterview_ledgerhelp_assembly',
    Link: 'View-product-assembly-details'
  },
  {
    Name: 'productmasterview_ledgerhelp_demand',
    Link: 'Analyze-historical-demand'
  },
  {
    Name: 'productmasterview_ledgerhelp_vendors',
    Link: 'View-product-purchase-orders-by-vendor'
  },
  {
    Name: 'productmasterview_ledgerhelp_transactions',
    Link: 'View-inventory-transaction-history'
  },
  {
    Name: 'productmasterview_ledgerhelp_salesorders',
    Link: 'Review-open-orders'
  },
  {
    Name: 'productmasterview_ledgerhelp_purchaseorders',
    Link: 'View-open-product-purchase-orders'
  },
  {
    Name: 'productmasterview_ledgerhelp_transfers',
    Link: 'View-a-products-open-stock-transfers'
  },
  {
    Name: 'productmasterview_ledgerhelp_workorders',
    Link: 'View-a-products-work-order-history'
  },
  {
    Name: 'productmasterview_ledgerhelp_serialnumbers',
    Link: 'View-and-update-product-serial-numbers'
  },
  {
    Name: 'productmasterview_ledgerhelp_lots',
    Link: 'View-lots-assigned-to-a-product'
  },
  {
    Name: 'productmasterview_ledgerhelp_substitutes',
    Link: 'View-available-product-substitutes'
  },
  {
    Name: 'productmasterview_ledgerhelp_bins_bins',
    Link: 'Update-product-bin-locations'
  },
  {
    Name: 'BinsUpdateView',
    Link: 'Update-product-bin-locations'
  },
  {
    Name: 'ProductMasterView_InventoryHelp',
    Link: 'How-to-look-up-inventory'
  },
  {
    Name: 'productmasterview_setuphelp_generalhelp',
    Link: 'Configure-general-product-options'
  },
  {
    Name: 'productmasterview_audithelp',
    Link: 'Get-an-audit-history-of-changes-made'
  },
  {
    Name: 'productmasterview_pricinghelp',
    Link: 'Check-a-price-for-a-customer'
  },
  {
    Name: 'majorbuylinemasterview',
    Link: 'Work-with-major-buy-lines'
  },
  {
    Name: 'buylinemasterview',
    Link: 'Set-up-product-buy-lines'
  },
  {
    Name: 'majorgroupmasterview',
    Link: 'Work-with-major-product-groups'
  },
  {
    Name: 'pricegroupmasterview',
    Link: 'Set-up-price-groups'
  },
  {
    Name: 'productlinemasterview',
    Link: 'Set-up-product-lines'
  },
  {
    Name: 'productmasterview_attachmentshelp_attachmentshelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'webcategorymasterview',
    Link: 'Create-categories-and-subcategories'
  },
  {
    Name: 'unitofmeasuremasterview',
    Link: 'Add-units-of-measure'
  },
  {
    Name: 'productlabelmasterview',
    Link: 'View-product-label-templates'
  },
  {
    Name: 'producttaxgroupmasterview',
    Link: 'Set-up-product-tax-groups'
  },
  {
    Name: 'optionsandaccessoriesmasterview',
    Link: 'Create-option-and-accessory-product-groups'
  },
  {
    Name: 'cyclecountgroupmasterview',
    Link: 'Set-up-manual-cycle-count-groups'
  },
  {
    Name: 'dothazardousmasterview',
    Link: 'Set-up-hazardous-material-classifications'
  },
  {
    Name: 'InventoryAdjustmentReasonMasterView',
    Link: 'Set-up-reasons-for-inventory-adjustments'
  },
  {
    Name: 'warrantyfailcodemasterview',
    Link: 'Set-up-reasons-for-warranty-failures'
  },
  {
    Name: 'productimportview',
    Link: 'Import-product-information'
  },
  {
    Name: 'schedulecyclecountview',
    Link: 'Schedule-cycle-counts'
  },
  {
    Name: 'productrankcriteriaview',
    Link: 'Configure-product-ranking-criteria'
  },
  {
    Name: 'ReindexCustomerYTDSales',
    Link: 'About-reindexing'
  },
  {
    Name: 'ReindexProduct',
    Link: 'About-reindexing'
  },
  {
    Name: 'EssendantImportView',
    Link: 'Essendant-integration'
  },
  {
    Name: 'companymasterview',
    Link: 'System-configuration-settings-in-the-Company-Master'
  },
  {
    Name: 'DivisionMasterView',
    Link: 'Manage-company-divisions'
  },
  {
    Name: 'branchmasterview',
    Link: 'Configure-company-branch-sites'
  },
  {
    Name: 'branchmasterview_defaultshelp',
    Link: 'Set-up-default-branch-order-prefix-and-invoice-options'
  },
  {
    Name: 'branchmasterview_GeneralHelp',
    Link: 'Configure-product-replenishments-by-branch'
  },
  {
    Name: 'branchmasterview_printershelp',
    Link: 'Assign-default-printers-by-branch'
  },
  {
    Name: 'branchmasterview_notificationhelp',
    Link: 'Set-up-internal-notifications-by-branch'
  },
  {
    Name: 'PriceContractView_AttachmentsHelp_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'branchmasterview_audithelp',
    Link: 'Get-an-audit-history-of-changes-made'
  },
  {
    Name: 'warehousemasterview',
    Link: 'Set-up-warehouses'
  },
  {
    Name: 'branchmasterview_generalledgerhelp',
    Link: 'Assign-branch-level-general-ledger-accounts'
  },
  {
    Name: 'warehousemasterview_consignmentproductshelp',
    Link: 'Set-up-a-customer-consignment-warehouse'
  },
  {
    Name: 'NeedsID-warehousemasterview-TransferLead',
    Link: 'Configure-warehouse-transfer-lead-times'
  },
  {
    Name: 'warehousebuyzonemasterview',
    Link: 'Group-warehouses-by-buy-zone'
  },
  {
    Name: 'bankmasterview',
    Link: 'Create-a-new-bank-account'
  },
  {
    Name: 'bankmasterview_LedgerHelp',
    Link: 'Review-the-bank-ledger'
  },
  {
    Name: 'bankmasterview_ReconciliationHelp',
    Link: 'Information-on-bank-reconciliation'
  },
  {
    Name: 'bankmasterview_DepositTransfersHelp',
    Link: 'Synchronize-receipts-in-Inform-with-their-bank-deposit-dates'
  },
  {
    Name: 'shipviamasterview',
    Link: 'Create-and-configure-Ship-Via-options'
  },
  {
    Name: 'ShipViaMasterView_BranchTaxHelp',
    Link: 'Assign-branch-tax-codes-to-a-ship-via'
  },
  {
    Name: 'truckmasterview',
    Link: 'Manage-trucks'
  },
  {
    Name: 'OrderTypeMasterView',
    Link: 'Manage-order-types'
  },
  {
    Name: 'DocumentMessageMasterView',
    Link: 'Add-a-message-to-your-orders-invoices-and-statements'
  },
  {
    Name: 'GrossProfitIndicatorsMasterView',
    Link: 'Customize-GP-indicators'
  },
  {
    Name: 'miscellaneouschargemasterview',
    Link: 'Set-up-miscellaneous-charges'
  },
  {
    Name: 'miscchargeprogrammasterview',
    Link: 'Set-up-miscellaneous-charge-programs'
  },
  {
    Name: 'BaseOrderSpecialInstructionsMasterView',
    Link: 'Define-special-instructions-for-sales-orders'
  },
  {
    Name: 'purchaseorderspecialinstructionsmasterview',
    Link: 'Define-special-instructions-for-purchase-orders'
  },
  {
    Name: 'DashboardDetailRankView',
    Link: 'Review-the-Inventory-Rank-Dashboard'
  },
  {
    Name: 'AccessLevelsMasterView',
    Link: 'Define-and-assign-access-levels-to-users'
  },
  {
    Name: 'UserMasterView',
    Link: 'Manage-users'
  },
  {
    Name: 'FileSecurityMasterView',
    Link: 'Configure-master-file-security'
  },
  {
    Name: 'auditreportview',
    Link: 'Run-an-audit-report'
  },
  {
    Name: 'printermasterview',
    Link: 'Add-printers'
  },
  {
    Name: 'currencymasterview',
    Link: 'Add-currency-codes'
  },
  {
    Name: 'controlsequencenumbersmasterview',
    Link: 'Change-the-number-assigned-to-orders-and-other-records'
  },
  {
    Name: 'CayanConfigurationView',
    Link: 'Configure-credit-card-through-TSYS-Cayan'
  },
  {
    Name: 'NetworkDevices2View',
    Link: 'Set-up-network-devices'
  },
  {
    Name: 'CustomFieldImportView',
    Link: 'Import-data-into-custom-fields'
  },
  {
    Name: 'CustomFieldMasterView',
    Link: 'Create-custom-fields-for-reports-and-exports'
  },
  {
    Name: 'eCommerceMasterview',
    Link: 'Configure-initial-site-settings-eCommerce'
  },
  {
    Name: 'eCommerceMasterView_OptionsHelp',
    Link: 'Configure-ordering-options'
  },
  {
    Name: 'eCommerceMasterView_SocialMediaHelp',
    Link: 'Add-social-media-links-to-your-eCommerce-site'
  },
  {
    Name: 'eCommerceMasterView_FeaturedProductsHelp',
    Link: 'Choose-your-featured-products'
  },
  {
    Name: 'eCommerceMasterView_ShipViaHelp',
    Link: 'Select-the-default-shipping-methods'
  },
  {
    Name: 'eCommerceMasterview_MetaTagsHelp',
    Link: 'Optimize-your-site-for-web-searches'
  },
  {
    Name: 'eCommerceMasterView_ContactUsHelp_AboutUsHelp',
    Link: 'Add-About-Us-and-Contact-Us-pages-to-your-eCommerce-site'
  },
  {
    Name: 'eCommerceMasterView_SlidersHelp',
    Link: 'Add-slider-images-to-your-homepage'
  },
  {
    Name: 'TaxableStatesView',
    Link: 'Add-or-edit-states-that-charge-tax'
  },
  {
    Name: 'contactmasterview',
    Link: 'Manage-contacts'
  },
  {
    Name: 'masschangeview',
    Link: 'Update-data-in-bulk-using-mass-change'
  },
  {
    Name: 'ProductMassChangeView',
    Link: 'Update-data-in-bulk-using-mass-change'
  },
  {
    Name: 'StorableCommentView',
    Link: 'Add-notes'
  },
  {
    Name: 'ContactMasterView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'ContactMasterView_eDocsHelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'cashreceiptspostingview',
    Link: 'How-to-post-cash-receipts'
  },
  {
    Name: 'CashReceiptsInvoiceEntryview',
    Link: 'Post-cash-receipts-by-invoice'
  },
  {
    Name: 'ARCustomerStatementReportView',
    Link: 'Customer-statements-finance-charges'
  },
  {
    Name: 'ARCheckVoidView',
    Link: 'Void-AR-checks'
  },
  {
    Name: 'ARReInstatePaidInvoiceView',
    Link: 'Cancel-a-cash-or-credit-payment-and-reinstate-the-invoice'
  },
  {
    Name: 'ARAgingReportView',
    Link: 'AR-Aging-report'
  },
  {
    Name: 'ARCashReceiptsHistoryReportView',
    Link: 'AR-Cash-Receipts-History-report'
  },
  {
    Name: 'ARDailyCashReceiptsJournalView',
    Link: 'AR-Daily-Cash-Receipts-Journal'
  },
  {
    Name: 'ARCustomerOpenCreditReportView',
    Link: 'AR-Customer-Open-Credit-Report'
  },
  {
    Name: 'ARExceededCreditLimitReportView',
    Link: 'AR-Exceeded-Credit-Limit-Report'
  },
  {
    Name: 'ARCollectionLettersView',
    Link: 'Create-and-send-AR-collection-letters'
  },
  {
    Name: 'ARFinanceChargeJournalView',
    Link: 'Print-a-Monthly-AR-Finance-Charge-Journal'
  },
  {
    Name: 'ARFinancialChargeUpdateView',
    Link: 'AR-Finance-Charge-Update'
  },
  {
    Name: 'artermmasterview',
    Link: 'Set-up-customer-payment-terms'
  },
  {
    Name: 'APInvoiceEntryView',
    Link: 'Enter-vendor-invoices'
  },
  {
    Name: 'MainView_APInvoiceEntryView',
    Link: 'Enter-vendor-invoices'
  },
  {
    Name: 'APInvoiceEntryView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'APInvoiceEntryView_eDocsHelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'APInvoiceEntryView_EntryLogHelp',
    Link: 'View-a-log-of-invoice-entries'
  },
  {
    Name: 'APInvoiceEntryView_InvoiceHelp',
    Link: 'Enter-vendor-invoices'
  },
  {
    Name: 'PendingInvoicesView',
    Link: ''
  },
  {
    Name: 'APCheckPaymentInquiryView',
    Link: 'Look-up-a-check-payment'
  },
  {
    Name: 'APNonVendorCheckView',
    Link: 'How-to-print-checks'
  },
  {
    Name: 'APChecksMasterView',
    Link: 'How-to-print-checks'
  },
  {
    Name: 'APAgingReportView',
    Link: 'Report-on-all-open-APs'
  },
  {
    Name: 'APPaymentScheduleReportView',
    Link: 'Report-on-the-payment-schedule'
  },
  {
    Name: 'OutstandingChecksReportView',
    Link: 'Outstanding-Checks-Report'
  },
  {
    Name: 'APPurchaseJournalView',
    Link: 'AP-Purchase-Journal'
  },
  {
    Name: 'APCashDisbursementsJournalView',
    Link: 'AP-Cash-Disbursements-Journal'
  },
  {
    Name: 'GeneralLedgerInquiryView',
    Link: 'Make-a-general-ledger-inquiry'
  },
  {
    Name: 'GeneralLedgerFinancialReportView',
    Link: 'Create-edit-and-print-financial-reports'
  },
  {
    Name: 'JournalEntryView',
    Link: 'Make-journal-entries'
  },
  {
    Name: 'JournalEntryTemplateView',
    Link: 'Make-journal-entries'
  },
  {
    Name: 'GeneralLedgerAccountView',
    Link: 'Maintain-your-general-ledger-accounts'
  },
  {
    Name: 'GeneralLedgerGroupView',
    Link: 'Group-your-general-ledger-accounts'
  },
  {
    Name: 'GLBudgetView',
    Link: 'Use-financial-budgets'
  },
  {
    Name: 'GeneralLedgerControlTableView',
    Link: 'General-ledger-control-table'
  },
  {
    Name: 'EndOfMonthJournalsView',
    Link: 'End-of-Month-close-process-and-reports'
  },
  {
    Name: 'EndOfMonthJournalsView_AttachmentsHelp',
    Link: 'End-of-Month-close-process-and-reports'
  },
  {
    Name: 'SalesOrderEntryView',
    Link: 'Create-a-sales-order'
  },
  {
    Name: 'LineItemImportView',
    Link: 'Import-a-sales-order-from-Excel'
  },
  {
    Name: 'GLAccountingPeriodLockView',
    Link: 'Lock-down-changes-to-a-fiscal-period'
  },
  {
    Name: 'SalesOrderEntryView_ShipmentsHelp',
    Link: 'Review-completed-order-shipments'
  },
  {
    Name: 'SalesOrderEntryView_ActivitiesHelp',
    Link: 'View-and-create-activities'
  },
  {
    Name: 'SalesOrderEntryView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'SalesOrderEntryView_eDocsHelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'SalesOrderEntryView_AuditHelp',
    Link: 'Get-an-audit-history-of-changes-made'
  },
  {
    Name: 'PointofSaleView',
    Link: 'Post-cash-sales-transactions-to-AR'
  },
  {
    Name: 'CopyOrderFromView',
    Link: 'Copy-an-order'
  },
  {
    Name: 'FastProductView',
    Link: 'Add-a-new-product-using-Fast-Product'
  },
  {
    Name: 'SalesOrderInquiryView',
    Link: 'Searchview-sales-orders'
  },
  {
    Name: 'FastReceiptView',
    Link: 'Generate-a-fast-receipt-for-counter-payment'
  },
  {
    Name: 'ReleaseOrdersOnHoldView',
    Link: 'Release-sales-orders-that-are-on-hold'
  },
  {
    Name: 'AutomaticBackorderReleaseView',
    Link: 'Run-the-automatic-backorder-release-on-demand'
  },
  {
    Name: 'SalesOrderReportView',
    Link: 'Sales-Order-report'
  },
  {
    Name: 'OpenOrdersAgainstPOReceivedReportView',
    Link: 'Open-Orders-Against-PO-Received-report'
  },
  {
    Name: 'OpenOrderDeliveryReportView',
    Link: 'Open-Order-Delivery-report'
  },
  {
    Name: 'POSOpenDepositReportView',
    Link: 'POS-Open-Deposit-report'
  },
  {
    Name: 'EpodSynchronizationSignatureReportView',
    Link: 'ePOD-Synchronization-Signature-report'
  },
  {
    Name: 'InvoicingView',
    Link: 'Review-and-bill-invoices'
  },
  {
    Name: 'InvoicingView_BillingHelp',
    Link: 'Review-and-bill-invoices'
  },
  {
    Name: 'InvoicingView_InvoicingHelp_InvoicePreviewHelp',
    Link: ''
  },
  {
    Name: 'InvoiceInquiryView',
    Link: 'Invoice-inquiry'
  },
  {
    Name: 'InvoiceInquiryView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'InvoiceReprintView',
    Link: 'Reprint-an-invoice'
  },
  {
    Name: 'SentInvoiceResultsReportView',
    Link: 'Sent-Invoice-Results-report'
  },
  {
    Name: 'DailySalesJournalReportView',
    Link: 'Daily-Sales-Journal-report'
  },
  {
    Name: 'SalesJournalReportsView',
    Link: 'Sales-Journal-reports'
  },
  {
    Name: 'WhoBoughtWhatReportView',
    Link: 'Who-Bought-What-report'
  },
  {
    Name: 'IssuedProductsCreditReportView',
    Link: 'Issued-Products-Credit-report'
  },
  {
    Name: 'InactiveCustomerReportView',
    Link: 'Inactive-Customer-report'
  },
  {
    Name: 'PriceMatrixMasterView',
    Link: 'Create-a-price-formula-in-the-matrix'
  },
  {
    Name: 'PriceContractView_MainHelp_CustomerHelp',
    Link: 'Create-a-price-contract-to-group-customer-pricing'
  },
  {
    Name: 'PriceContractView_MainHelp_CategoryHelp',
    Link: 'Create-a-price-contract-to-group-customer-pricing'
  },
  {
    Name: 'PriceContractView_MainHelp',
    Link: 'Create-a-price-contract-to-group-customer-pricing'
  },
  {
    Name: 'PriceRollupScheduleView',
    Link: 'Set-a-price-roll-up-schedule'
  },
  {
    Name: 'PriceMarginCapView',
    Link: 'Set-price-margin-caps'
  },
  {
    Name: 'PriceChangeI2View',
    Link: 'Manually-change-a-product-price'
  },
  {
    Name: 'PriceContractView',
    Link: 'To-generate-a-custom-price-contract-report'
  },
  {
    Name: 'PriceContractView_AttachmentsHelp_eDocsHelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'PriceChangebyFormulaView',
    Link: 'Change-a-price-by-formula'
  },
  {
    Name: 'FuturePriceUpdateView',
    Link: 'Force-a-future-price-update'
  },
  {
    Name: 'CustomerMarginPriceUpdateView',
    Link: 'Update-customer-margin-prices-for-a-future-date'
  },
  {
    Name: 'PriceMatrixImportView',
    Link: 'Import-price-matrix-or-price-contracts'
  },
  {
    Name: 'PriceBookReportView',
    Link: 'Price-Book-Report'
  },
  {
    Name: 'CustomerMarginPriceReportView',
    Link: 'Customer-Margin-Price-Report'
  },
  {
    Name: 'CustomerMarginPriceLettersView',
    Link: 'Notify-customers-of-margin-price-changes'
  },
  {
    Name: 'CustomerContractPriceLettersView',
    Link: 'Notify-customers-of-price-contract-changes'
  },
  {
    Name: 'PriceContractReportView',
    Link: 'To-generate-a-custom-price-contract-report'
  },
  {
    Name: 'CustomerOrderFormView',
    Link: 'Print-an-order-form-or-price-analysis-from-the-Order-Pad'
  },
  {
    Name: 'CustomerOrderPadView',
    Link: 'Create-a-new-order-from-the-Order-Pad'
  },
  {
    Name: 'CustomerDueToBuyReportView',
    Link: 'Customer-Due-to-Buy-Report'
  },
  {
    Name: 'CustomerSalesHistoryView',
    Link: 'Customer-Sales-History-report'
  },
  {
    Name: 'SalesAnalysisInquiryView',
    Link: 'Analyze-year-to-date-sales'
  },
  {
    Name: 'VendorRebateReportView',
    Link: 'Vendor-Rebate-report'
  },
  {
    Name: 'ReindexVendorRebatesView',
    Link: 'Reindex-vendor-rebates'
  },
  {
    Name: 'VendorSalesReportParametersView',
    Link: 'Sales-Order-report'
  },
  {
    Name: 'VendorSalesReportView',
    Link: 'Vendor-Sales-report'
  },
  {
    Name: 'ExecutivePerformanceSummaryView_AtAGlanceHelp',
    Link: 'Executive-Performance-Summary-report'
  },
  {
    Name: 'ExecutivePerformanceSummaryView',
    Link: 'Executive-Performance-Summary-report'
  },
  {
    Name: 'MFGRepCommissionInvoiceEntryView',
    Link: 'Enter-and-post-invoices-for-manufacturer-rep-commissions'
  },
  {
    Name: 'MFGRepCommissionImportView',
    Link: 'Import-manufacturer-rep-commission-details'
  },
  {
    Name: 'PurchaseOrderEntryView',
    Link: 'Create-a-manual-purchase-order'
  },
  {
    Name: 'PurchaseOrderEntryView_eDocsHelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'PurchaseOrderEntryView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'PurchaseOrderInquiryView',
    Link: 'Look-up-purchase-orders-using-Purchase-Order-Inquiry'
  },
  {
    Name: 'CriticalInventoryView',
    Link: 'Identify-critical-inventory'
  },
  {
    Name: 'SpecialPurchaseRequisitionsView',
    Link: 'Place-special-purchase-requests'
  },
  {
    Name: 'PODeliveryConfirmationView',
    Link: 'Confirm-a-PO-delivery'
  },
  {
    Name: 'StockReceiptsView',
    Link: 'Log-stock-receipts-against-purchase-orders'
  },
  {
    Name: 'StockReceiptsView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'StockReceiptsView_eDocsHelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'StockReceiptsReportView',
    Link: 'Stock-Receipts-report'
  },
  {
    Name: 'ContainerManagementView',
    Link: 'Manage-shipping-containers'
  },
  {
    Name: 'ContainerManagementView_eDocsHelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'ContainerManagementView_AuditHelp',
    Link: 'Get-an-audit-history-of-changes-made'
  },
  {
    Name: 'ContainerManagementView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'ProjectedPOView',
    Link: 'Create-projected-purchase-orders-vendor-returns-and-stock-transfers'
  },
  {
    Name: 'UnusualDemandView',
    Link:
      'Identify-and-adjust-reorder-quantities-for-products-with-unusual-demand'
  },
  {
    Name: 'SporadicDemandView',
    Link: 'Adjust-reorder-quantities-for-products-with-sporadic-demand'
  },
  {
    Name: 'ForecastParametersView',
    Link: 'Set-forecast-parameters'
  },
  {
    Name: 'ProcurementForecastAssignmentView',
    Link: 'Automatically-assign-the-forecast-formula'
  },
  {
    Name: 'ReIndexProductForecastQuantities',
    Link: 'Reindex-product-forecast-quantities'
  },
  {
    Name: 'PurchasingMatrixView',
    Link: 'Create-a-purchasing-matrix-formula'
  },
  {
    Name: 'PurchaseOrderReportView',
    Link: 'Purchase-Order-report'
  },
  {
    Name: 'UnInvoicedPOReportView',
    Link: 'Un-Invoiced-PO-report'
  },
  {
    Name: 'SpecialProductsEnteredReportView',
    Link: 'Special-Products-Entered-report'
  },
  {
    Name: 'POPaymentScheduleView',
    Link: 'Purchase-Order-Payment-Schedule-report'
  },
  {
    Name: 'InventoryInquiryView',
    Link: 'Look-up-inventory-by-product-line-price-group-or-vendor'
  },
  {
    Name: 'WarrantyTagReportView',
    Link: 'Search-for-existing-warranty-tags'
  },
  {
    Name: 'WarrantyTagView',
    Link: 'Review-edit-and-print-warranty-tags'
  },
  {
    Name: 'WarrantyClaimView',
    Link: 'Receive-compensation-for-a-warranty-claim'
  },
  {
    Name: 'WarrantyClaimReportView',
    Link: 'Search-for-existing-warranty-claims'
  },
  {
    Name: 'CreateWarrantyClaimView',
    Link: 'Create-a-warranty-claim'
  },
  {
    Name: 'MainView_SerialNumberInquiryView',
    Link: 'Track-inventory-by-serial-number'
  },
  {
    Name: 'SerialNumberInquiryView',
    Link: 'Track-inventory-by-serial-number'
  },
  {
    Name: 'LotsMasterView',
    Link: 'Run-a-lot-control-inventory'
  },
  {
    Name: 'LotsMasterView_AttachmentsHelp',
    Link: 'Work-with-attachments'
  },
  {
    Name: 'LotsMasterView_eDocsHelp',
    Link: 'Work-with-eDocs'
  },
  {
    Name: 'InventoryAdjustmentView',
    Link: 'Adjust-inventory-quantities'
  },
  {
    Name: 'StockTransferEntryView',
    Link: 'Create-view-and-edit-requests-to-transfer-stock-between-warehouses'
  },
  {
    Name: 'StockTransferReceivingView',
    Link: 'Receive-stock-into-your-warehouse-from-a-transfer'
  },
  {
    Name: 'warehouseoverstockview',
    Link: 'Transfer-warehouse-overstock'
  },
  {
    Name: 'warehousereplenishmententryview',
    Link: 'Automate-warehouse-replenishment'
  },
  {
    Name: 'PhysicalInventoryView',
    Link: 'Run-physical-inventory'
  },
  {
    Name: 'KitProductionView',
    Link: 'Producing-Kits'
  },
  {
    Name: 'CutMaterialView',
    Link: 'Cut-pipes-wires-and-other-inventory-items'
  },
  {
    Name: 'CustomCuttingParametersView',
    Link: 'Cut-pipes-wires-and-other-inventory-items'
  },
  {
    Name: 'InventoryBinAllocationView',
    Link: 'Move-product-among-bins'
  },
  {
    Name: 'InventoryAdjustmentReportView',
    Link: 'Inventory-Adjustment-report'
  },
  {
    Name: 'InventoryValueReportView',
    Link: 'Inventory-Value-report'
  },
  {
    Name: 'InvoiceAverageCostVarianceReportView',
    Link: 'Invoice-Average-Cost-Variance-Report'
  },
  {
    Name: 'inventorytransactionsreportview',
    Link: 'Inventory-Transactions-report'
  },
  {
    Name: 'ReservedInventoryReportView',
    Link: 'Report-on-reserved-inventory-levels'
  },
  {
    Name: 'PrintProductLabelsView',
    Link: 'Print-product-receiving-labels'
  },
  {
    Name: 'ShipConfirmationView',
    Link: 'Confirm-shipment'
  },
  {
    Name: 'BinLocationMasterView',
    Link: 'Add-bin-locations-to-a-warehouse'
  },
  {
    Name: 'WarehouseSOPickedByView',
    Link: 'Assign-an-order-picker-to-track-status'
  },
  {
    Name: 'SalesOrderStagingView',
    Link: 'Stage-products-for-sales-order-prior-to-ship-confirm'
  },
  {
    Name: 'SalesOrderShippingLabelsView',
    Link: 'Print-shipping-labels-for-a-sales-order'
  },
  {
    Name: 'TruckManifestView',
    Link: 'How-to-route-trucks-and-print-a-manifest'
  },
  {
    Name: 'OpenPOReceivingTicketView',
    Link: 'Print-an-open-PO-receiving-ticket'
  },
  {
    Name: 'OrderPickingProgressView',
    Link: 'Monitor-your-order-picking-progress'
  },
  {
    Name: 'ReportExplorerView',
    Link: 'View-an-ARW-report-Report-Explorer'
  },
  {
    Name: 'prospectmasterview',
    Link: 'How-to-add-a-prospect'
  },
  {
    Name: 'prospectimportview',
    Link: 'How-to-add-a-prospect'
  },
  {
    Name: 'PivotTableViewerView',
    Link: 'Salesperson-reporting'
  },
  {
    Name: 'opportunitypipelineview',
    Link: 'Track-your-sales-pipeline'
  },
  {
    Name: 'salesopportunitiesview',
    Link: 'Track-your-sales-opportunities'
  },
  {
    Name: 'MarketingManagerView',
    Link: 'Manage-targeted-email-marketing-campaigns'
  },
  {
    Name: 'customertrendi2view',
    Link: 'View-sales-and-profit-trend-by-customer'
  },
  {
    Name: 'customerrewardprogramview',
    Link: 'Set-up-a-customer-reward-program'
  },
  {
    Name: 'MainView_ReportViewerView',
    Link: 'The-Advanced-Report-Writer'
  },
  {
    Name: 'ReportWriterView',
    Link: 'Create-and-modify-an-ARW-report'
  },
  {
    Name: 'ReportWriterView_DefinitionHelp',
    Link: 'Create-and-modify-an-ARW-report'
  },
  {
    Name: 'ReportWriterView_DesignerHelp',
    Link: 'Customize-the-report-layout'
  },
  {
    Name: 'ReportWriterView_SettingsHelp',
    Link: 'Make-your-ARW-report-available-for-others'
  },
  {
    Name: 'ReportWriterview_ScheduleHelp',
    Link: 'Schedule-ARW-reports'
  },
  {
    Name: 'ReportWriterView_NotesHelp',
    Link: 'Add-developer-notes-to-an-ARW-report'
  },
  {
    Name: 'PivotTableExplorerView',
    Link: 'Build-and-save-a-pivot-report-layout'
  },
  {
    Name: 'PivotTableEditorView',
    Link: 'Create-a-pivot-table-Pivot-Table-Editor'
  },
  {
    Name: 'DashboardView',
    Link: 'Set-up-your-Inform-dashboard'
  },
  {
    Name: 'MainView',
    Link: 'Set-up-your-Inform-dashboard'
  },
  {
    Name: 'UserMailView',
    Link: 'Use-your-Inform-Mail'
  },
  {
    Name: 'UserMailDetailView',
    Link: 'Use-your-Inform-Mail'
  },
  {
    Name: 'DashboardDetailAROverSalesView',
    Link: 'Review-the-SalesAR-Dashboard'
  },
  {
    Name: 'DashboardDetailRankView',
    Link: 'Review-the-SalesAR-Dashboard'
  },
  {
    Name: 'InventoryDashboardDetailSIRatioView',
    Link: 'Review-the-SalesInventory-Dashboard'
  },
  {
    Name: 'InventoryDashboardDetailTurnsView',
    Link: 'Review-the-Inventory-Turns-Dashboard'
  },
  {
    Name: 'DashboardDetailInventoryTrendView',
    Link: 'Review-the-Inventory-Trend-Dashboard'
  },
  {
    Name: 'TwelveMonthVarianceCreditsKPI',
    Link: 'Compare-credits-over-the-last-year-12M-Credits-Dashboard'
  },
  {
    Name: 'TwelveMonthVarianceGPPKPI',
    Link: 'Compare-gross-profit-percent-over-the-last-year-12M-GP-Grid'
  },
  {
    Name: 'TwelveMonthVarianceInvoicesKPI',
    Link: 'Compare-number-of-invoices-over-the-last-year-12M-invoices-Grid'
  },
  {
    Name: 'TwelveMonthVarianceLinesKPI',
    Link:
      'Review-the-total-number-of-line-items-by-month-12M-Lines-Grid-Dashboard'
  },
  {
    Name: 'TwelveMonthVarianceAveInvLinesKPI',
    Link:
      'Review-the-average-number-of-line-items-per-invoice-by-month-12M-LinesInvoice-Grid-Dashboard'
  },
  {
    Name: 'TwelveMonthVarianceProfitKPI',
    Link: 'Review-the-annual-profits-by-month-12M-Profit-Grid-Dashboard'
  },
  {
    Name: 'TwelveMonthVarianceQuantitiesKPI',
    Link:
      'Review-the-annual-number-of-units-shipped-12M-Quantities-Grid-Dashboard'
  },
  {
    Name: 'TwelveMonthVarianceSalesKPI',
    Link: 'Review-total-annual-sales-by-month-12M-Sales-Grid-Dashboard'
  },
  {
    Name: 'TwelveMonthVarianceAveInvSalesKPI',
    Link: 'Compare-annual-salesinvoice-by-month-12M-SalesInvoice-Grid-Dashboard'
  },
  {
    Name: 'DashboardDetailAverageOrderSizeView',
    Link: 'Review-average-sales-by-invoice-Average-Invoice-Sales-Dashboard'
  },
  {
    Name: 'DashboardDetailSlmnLedgerBackOrdersView',
    Link: 'Review-all-backorders-Back-Orders-Grid-Dashboard'
  },
  {
    Name: 'DashboardDetailToDateCreditsRefundsView',
    Link:
      'Review-the-monthly-credits-and-refunds-issued-CreditsRefunds-to-Date-Dashboard'
  },
  {
    Name: 'GrossMarginTrendKPI',
    Link:
      'Review-the-your-3-year-gross-margin-performance-trend-Gross-Margin-Trend-Dashboard'
  },
  {
    Name: 'DashboardDetailSlmnLedgerBillingOrdersView',
    Link: 'Review-the-all-orders-in-billing-In-Billing-Orders-Dashboard'
  },
  {
    Name: 'DashboardDetailInHouseVDirect',
    Link:
      'Compare-in-house-vs-direct-sales-and-profit-In-House-vs-Direct-Dashboard'
  },
  {
    Name: 'DashboardDetailToDateFillRateView',
    Link: 'Review-details-on-the-Lines-Fill-Rate-MTD-KPI'
  },
  {
    Name: 'DashboardDetailOpenOrdersView',
    Link: 'Compare-monthly-open-orders-Open-Orders-Dashboard'
  },
  {
    Name: 'DashboardDetailSlmnLedgerOpenOrdersView',
    Link: 'Review-all-open-orders-Open-Orders-Grid-Dashboard'
  },
  {
    Name: 'ProfitDollarsTrendKPI',
    Link:
      'Review-the-your-3-year-profit-performance-trend-Profit-Dollars-Trend-Dashboard'
  },
  {
    Name: 'DashboardDetailSlmnLedgerQuotesView',
    Link: 'Review-all-open-quotes-Quotes-Grid-Dashboard'
  },
  {
    Name: 'DashboardDetailToDateSalesProfitView',
    Link:
      'Compare-the-sales-and-profit-per-day-month-to-date-Sales-Profit-Methods-MTD-Dashboard'
  },
  {
    Name: 'SalesDollarsTrendKPI',
    Link:
      'Review-the-your-3-year-sales-performance-trend-Sales-Dollars-Trend-Dashboard'
  },
  {
    Name: 'DashboardDetailToDateShipMethodsView',
    Link:
      'Review-the-sales-by-ship-method-including-walk-in-and-pickup-Ship-Methods-MTD-Dashboard'
  },
  {
    Name: 'DashboardDetailThisWeeksSalesView',
    Link:
      'Review-the-weekly-sales-by-open-and-completed-orders-This-Weeks-Sales-Dashboard'
  },
  {
    Name: 'SalesManagerDashboardDetailThreeYrSalesView',
    Link: 'Compare-sales-over-three-years-Three-Year-Sales-Dashboard-KPI'
  },
  {
    Name: 'DashboardDetailSalesVarianceView',
    Link:
      'Review-the-sales-variance-between-this-year-and-last-YTD-Variance-Dashboard'
  },
  {
    Name: 'SalesTrendReportView',
    Link: 'Report-on-sales-trends'
  },
  {
    Name: 'DataWarehouseHealthView',
    Link: 'Validate-the-health-of-your-data-warehouse-reporting-data'
  },
  {
    Name: 'SystemUsersLocksView',
    Link: 'Review-and-reset-system-and-user-locks'
  },
  {
    Name: 'PhantomMonitorView',
    Link: 'Phantom-jobs'
  },
  {
    Name: 'UpgradeView',
    Link: 'Upgrade-Inform'
  },
  {
    Name: 'BetaScreenMasterView',
    Link: ''
  },
  {
    Name: 'BulkRecieverView',
    Link: ''
  },
  {
    Name: 'CustomProgramsView',
    Link: ''
  },
  {
    Name: 'TradeServiceProductLinkView',
    Link: 'Add-main-product-and-price-information'
  },
  {
    Name: 'ItemGroupsView',
    Link: 'Group-products-into-item-groups'
  },
  {
    Name: 'ShipToMasterView_SetupHelp_CustomHelp',
    Link: 'Manage-custom-fields'
  }
]
