import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from '@mui/material'

import { withStyles } from '@mui/styles'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
})

class BranchList extends Component {
  static propTypes = {
    branchList: PropTypes.array,
    selectBranch: PropTypes.func.isRequired
  }

  static defaultProps = { branchList: [] }

  constructor(props) {
    super(props)
    // const branchList = Array.isArray(props.branchList.toJS()) ? props.branchList : []
    this.state = {
      // branchList,
      selectedBranchId: null
      // props.branchList.size > 0 ? props.branchList[0].dataId : null
    }
  }

  componentDidMount() {
    // this.onRowSelection([0])
    // this.onRowSelection(null, this.props.branchList[0].dataId)
    this.onRowSelection(
      null,
      this.props.branchList
        .sort(this.sort)
        .get(0)
        .get('dataId')
    )
  }

  onRowSelection = (event, dataId) => {
    /* getting branchList from props without sorting caused a bug */
    // const { branchList, selectBranch } = this.props
    const { selectBranch } = this.props
    const { branchList } = this.state
    // const selectedBranchId =
    //   branchList[row] && branchList[row].dataId ? branchList[row].dataId : null
    const selectedBranchId = dataId || null

    this.setState({ selectedBranchId }, () => selectBranch(selectedBranchId))
  }

  sort = (a, b) => {
    if (a.get('sortIndex') > b.get('sortIndex')) {
      return 1
    }
    if (a.get('sortIndex') < b.get('sortIndex')) {
      return -1
    }
    return 0
  }

  getBranchList = memoize((branchList, selectedBranchId) =>
    branchList.sort(this.sort).map((row, index) => {
      const dataId = row.get('dataId')
      const isSelected = selectedBranchId === dataId
      return (
        <TableRow
          key={dataId}
          role="checkbox"
          aria-checked={isSelected}
          selected={dataId === selectedBranchId}
          onClick={event => this.onRowSelection(event, dataId)}
        >
          <TableCell style={{ padding: 10, width: 35 }}>
            <Checkbox checked={isSelected} style={{ height: 24, width: 24 }} />
          </TableCell>
          <TableCell style={{ textAlign: 'center', width: 50 }}>
            {dataId}
          </TableCell>
          <TableCell style={{ width: 240 }}>{row.get('description')}</TableCell>
          <TableCell style={{ width: 170 }}>{row.get('city')}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            {row.get('state')}
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            {row.get('postalCode')}
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            {row.get('defaultWarehouseId') || null}
          </TableCell>
        </TableRow>
      )
    })
  )

  render() {
    const { selectedBranchId } = this.state
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow style={{ background: '#444', color: '#fff' }}>
              <TableCell style={{ width: 35 }}>&nbsp;</TableCell>
              <TableCell
                style={{
                  color: '#fff',
                  textAlign: 'center',
                  width: 50
                }}
              >
                Branch
              </TableCell>
              <TableCell style={{ color: '#fff', width: 240 }}>Name</TableCell>
              <TableCell style={{ color: '#fff', width: 170 }}>City</TableCell>
              <TableCell style={{ color: '#fff', textAlign: 'center' }}>
                State
              </TableCell>
              <TableCell style={{ color: '#fff', textAlign: 'center' }}>
                Postal Code
              </TableCell>
              <TableCell style={{ color: '#fff', textAlign: 'center' }}>
                Default WH
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.getBranchList(this.props.branchList, selectedBranchId)}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles)(BranchList)
