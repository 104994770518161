import React from 'react'

import { AppBar, Tabs, Tab, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

export function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  )
}

export const MobileTab = withStyles({
  root: {
    backgroundColor: '#517b9c',
    color: 'rgba(255, 255, 255, 0.60)',
    textTransform: 'uppercase',
    maxWidth: 150,
    '&$selected': {
      color: '#ffffff' 
    },  
    '&$disabled': {
      color: 'rgba(255, 255, 255, 0.60)' 
    }, 
  },
  selected: {},
  disabled: {}
})(Tab)

export const MobileTabWide = withStyles({
  textColorInherit: {
    backgroundColor: '#517b9c',
    color: 'rgba(255, 255, 255, 0.60)'
  },
  root: {
    textTransform: 'uppercase',
    minWidth: 175,
    maxWidth: 200
  }
})(Tab)

export const MobileAppBar = withStyles({
  root: {
    backgroundColor: '#517b9c'
  }
})(AppBar)

export const MobileSecondaryTabs = withStyles({
  indicator: {
    backgroundColor: '#517b9c',
    '& > span': {
      width: '100%',
      backgroundColor: '#517b9c'
    }
  }
})(Tabs)

export const MobileSecondaryTab = withStyles({
  textColorInherit: {
    backgroundColor: '#e5ebf0',
    color: 'rgba(81, 123, 156, 0.80)'
  },
  root: {
    textTransform: 'uppercase',
    maxWidth: 150,
    '&$selected': {
      backgroundColor: '#e5ebf0',
      color: '#517b9c'
    }
  },
  selected: {}
})(Tab)

export const MobileSecondaryAppBar = withStyles({
  root: {
    backgroundColor: '#e5ebf0'
  }
})(AppBar)
