import React, { Component, createElement } from 'react'
import { Button } from '@mui/material'
import { connect } from 'react-redux'
import { getIn, empty } from 'utils'
import { debounce } from 'lodash'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { confirm, modalAction, canceled } from './actions'

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps)
  const { form, getFormState, dispatch } = ownProps
  // const formState = { ...getIn(getFormState(state).toJS(), form) }
  // let formState = {}
  // if (getFormState) {
  //   formState = { ...getIn(getFormState(state).toJS(), form), form }
  // }
  // const { ...rest } = formState
  // const formState = getFormState(state)
  let formState = empty
  if (getFormState) {
    formState = getFormState(state)
  }

  let style
  if (
    ownProps.modal &&
    ownProps.modal.options &&
    ownProps.modal.options.data &&
    ownProps.modal.options.data.actions
  ) {
    style = {
      maxHeight: ownProps.modal.options.maxHeight || 400,
      maxWidth: ownProps.modal.options.width ? 'none' : 768,
      width: ownProps.modal.options.width || '75%'
    }
  } else {
    style = {
      maxHeight: 400,
      maxWidth: 768,
      width: '75%'
    }
  }

  return {
    formState,
    contentStyle: style
  }
}

class ConnectedActions extends Component {
  buttonRef = React.createRef()

  componentDidMount() {
    if (this.buttonRef && this.buttonRef.current) {
      // console.log(this.buttonRef.current.focus)
      this.buttonRef.current.focus()
    }
  }

  // shouldComponentUpdate() {
  //   return false
  // }
  componentDidUpdate() {
    if (this.buttonRef && this.buttonRef.current) {
      // console.log(this.buttonRef.current.focus)
      this.buttonRef.current.focus()
    }
  }

  getActions = props => {
    let formActions
    const { form, formState, dispatch } = props
    // const formState = { ...getIn(getFormState(state).toJS(), form) }

    if (
      props.modal &&
      props.modal.options &&
      props.modal.options.data &&
      props.modal.options.data.actions
    ) {
      formActions = props.modal.options.data.actions.map((x, i, arr) => {
        const autoFocus = !!x.keyboardfocused
        // debugger
        if (x.clickEvent) {
          x.clickEvent.args = x.clickEvent.args || {}
          x.clickEvent.args.id = props.modal.id
        }
        let disabled = false

        if (x.disabled) {
          if (typeof x.disabled === 'function') {
            const { id } = props.modal
            let ref = props.modalRef[id]
            ref = ref ? ref.wrappedInstance || ref : {}
            disabled = x.disabled.call(ref, formState)
          }
        }
        const color = () => {
          if (x.primary || x.secondary) {
            if (x.primary) {
              return 'primary'
            }
            return 'secondary'
          }
          return undefined
        }
        const key = `${props.modal.id}-${i + 1}`

        const buttonStyle = x.buttonStyle ? x.buttonStyle : {}

        const args = {
          primary: x.primary || false,
          // secondary={x.secondary || false}
          // autoFocus={autoFocus}
          ref: autoFocus ? this.buttonRef : undefined,
          focusRipple: !!autoFocus,
          icon: props.renderIcon(x.icon),
          color: color(),
          key,
          // style={x.style || null}
          // labelStyle={x.labelStyle || null}
          style: {
            marginRight: i === arr.length - 1 ? 0 : 5,
            ...buttonStyle
          },
          variant: 'contained',
          disabled,
          onClick: debounce((e, additionalArgObj) => {
            // debugger
            let newArgs = x?.clickEvent?.args ?? {}

            newArgs = { ...newArgs, ...additionalArgObj }
            if (x.clickEvent && typeof x.clickEvent === 'function') {
              // x.clickEvent.apply
              // debugger
              console.log('sanity')
              const { id } = props.modal
              let ref = props.modalRef[id]
              ref = ref && ref.wrappedInstance ? ref.wrappedInstance : ref
              x.clickEvent.call(
                ref,
                newArgs,
                formState,
                () => {
                  return dispatch(confirm(form, props.modal.id))
                },
                props.modalRef
              )
              // dispatch(confirm(form, props.modal.id))
            } else if (x.clickEvent) {
              //   debugger
              dispatch(
                modalAction(form, {
                  action: x.clickEvent.action,
                  saga: x.clickEvent.saga,
                  callback: x.clickEvent.callback,
                  args: newArgs
                })
              )
            } else if (x.cancel) {
              dispatch(canceled(form, props.modal.id))
            } else {
              //    debugger
              dispatch(confirm(form, props.modal.id))
            }
          }, 200)
        }

        if (x.component) {
          return <x.component {...args} title={x.title} />
        }
        return <Button {...args}>{x.title}</Button>
      })
    } else {
      formActions = [
        <Button
          secondary
          onClick={() => dispatch(confirm(form, props.modal.id))}
          key={`${props.modal.id}-${1}`}
          // autoFocus
          focusRipple
          ref={this.buttonRef}
          keyboardfocused
        >
          OK
        </Button>
      ]
    }
    return formActions
  }

  render() {
    console.log(this.props)
    // return this.props.formActions
    // return null
    return this.getActions(this.props)
  }
}

export default connect(mapStateToProps)(
  withImmutablePropsToJS(ConnectedActions)
)
