import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { smallTextFieldStyles } from 'ddiForm/ddiFormStyles'
import { noop } from 'utils'

export default class TabTextField extends Component {
  static defaultProps = {
    disabled: false,
    hintStyle: {
      bottom: 0,
      fontSize: 12,
      width: '100%'
    },
    onTab: noop,
    textFieldStyle: { ...smallTextFieldStyles.textFieldStyle },
    underlineStyle: { bottom: 0 }
  }

  static propTypes = {
    disabled: PropTypes.bool,
    hintStyle: PropTypes.object,
    onTab: PropTypes.func,
    propertyName: PropTypes.string.isRequired,
    textFieldStyle: PropTypes.object,
    underlineStyle: PropTypes.object
  }

  static ddiType = 'TabTextField'

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {}
    if (
      !prevState.isDirty &&
      nextProps.value !== prevState.value &&
      nextProps.value !== undefined
    ) {
      state.value = nextProps.value
    } else if (prevState.isDirty && prevState.value === '') {
      state.value = ''
      state.isDirty = false
    }
    const len = Object.keys(state).length
    state = len >= 1 ? state : null

    return state
  }

  constructor(props) {
    super(props)
    this.state = { value: props.initialValue || props.value || '' }
  }

  onKeyDown = event => {
    event.persist()
    if (event.key === 'Tab' && this.state.isDirty) {
      event.preventDefault()

      this.props
        .onTab(event.target.value)
        .then(_ => this.setState({ isDirty: false }))
        .catch(err => {
          /* exactMatch API throws a 404 if the result is not matched */
       
          if (err.status === 404) {
            this.setState(
              { value: '', isDirty: false },
              () => {}
              // this.props.onTab(this.state.value)
            )
          }
        })
    }
  }

  onBlur = event => {
  
    this.props
      .onTab(event.target.value)
      .then(_ => this.setState({ isDirty: false }))
      .catch(err => {
        /* exactMatch API throws a 404 if the result is not matched */
      
        if (err.status === 404) {
          this.setState(
            { value: '', isDirty: false },
            () => {}
            // this.props.onTab(this.state.value)
          )
        }
      })
      .finally(() => this.props.onBlur(this.state.value))
  }

  onFocus = () => this.textInput && this.textInput.select()

  bindInputRef = c => (this.textInput = c)

  handleChange = event =>
    this.setState({ value: event.target.value, isDirty: true })

  render() {
    const { value } = this.state
    const { disabled } = this.props
    console.log('TabTextField', this, this.props)
    return (
      <div style={{ height: 26, margin: 0 }}>
        <TextField
          disabled={disabled}
          fullWidth
          onKeyDown={this.onKeyDown}
          value={value}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          inputRef={this.bindInputRef}
          label={this.props.label}
          placeholder={this.props.placeholder}
          variant="standard"
        />
      </div>
    )
  }
}
