import React from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { Icon, IconButton } from '@mui/material'
import { errorRed } from 'theme/colors'
import { deleteAttachment as deleteAttachmentAction } from '../actions'

const DeleteAttachmentCell = ({
  form,
  data,
  isSendDocumentEditor = false,
  isStandaloneComponent = false,
  additionalAttachmentsData = null,
  targetType = '',
  ...rest
}) => {
  console.log('DeleteAttachmentCell', rest)
  const dispatch = useDispatch()

  const deleteAttachment = debounce(e => {
    if (
      (form && data?.description) ||
      (isSendDocumentEditor && additionalAttachmentsData && data?.description)
    ) {
      const formName =
        form === 'reportExplorer'
          ? form
          : isSendDocumentEditor
          ? 'sendDocument'
          : form
      const params =
        isSendDocumentEditor || isStandaloneComponent
          ? {
              additionalAttachmentsData,
              fileNames: [data.description],
              targetType
            }
          : {
              fileNames: [data.description]
            }

      dispatch(deleteAttachmentAction.try(formName, params))
    }
  }, 300)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <IconButton onClick={deleteAttachment} style={{ padding: 5 }}>
        <Icon size="small" style={{ color: errorRed }}>
          cancel
        </Icon>
      </IconButton>
    </div>
  )
}

export default DeleteAttachmentCell
