/* eslint no-nested-ternary: 0 */
import React, { Component } from 'react'
import { CircularProgress } from '@mui/material'
import { HotKeys } from 'react-hotkeys'
import shortid from 'shortid'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { throttle, debounce } from 'lodash'
import { noop, plainDeepEqual, is, getIn } from 'utils'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import Pagination from './Pagination'
import AddNew from './AddNew'
import FindInGrid from './FindInGrid'

import CustomFilter from './TextFilter'
import { fetchGridColsFromLocal, toggleFieldInLocal } from './utils'

LicenseManager.setLicenseKey(
  'CompanyName=Ddi System, LLC,LicensedApplication=Inform,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-018918,ExpiryDate=30_October_2022_[v2]_MTY2NzA4NDQwMDAwMA==42710b6b4bb0b5cbe3722f426fa8125a'
)
function autoSizeAll() {
  const allColumnIds = []
  this.columnApi.getAllColumns().forEach(column => {
    allColumnIds.push(column.colId)
  })
  if (this._isMounted && this.columnApi) {
    setTimeout(() => this.columnApi.autoSizeColumns(allColumnIds), 1)
  }
}
function autoFit() {
  if (this.api && this._isMounted) this.api.sizeColumnsToFit()
}
function removeHighlight(node, rows) {
  const d = { ...node.data }
  delete d.highlighted
  node.setData(d)
  rows.push(node)
}
const countStyle = {
  width: 90,
  margin: 0,
  textAlign: 'right'
}
const keyMap = {
  ALT_S: 'alt+s'
}

export class Grid extends Component {
  static defaultProps = {
    addBlankRow: noop,
    addButtonStyleParams: {},
    addButtonText: 'Add Row',
    animateRows: true,
    autoSize: false,
    columnDefs: [],
    columnDefVisibilityManagedExternally: false,
    defaultColDef: {
      width: 300
    },
    immutableData: true,
    disableFilter: false,
    stopEditingWhenCellsLoseFocus: true,
    paginationCb: noop,
    headerHeight: null,
    headerStyle: {
      textAlign: 'center',
      backgroundColor: '#f6f6f6',
      marginBottom: 0,
      padding: 5,
      fontSize: 12
    },
    find: false,
    height: '100%',
    hideHeader: false,
    meta: null,
    onGridReady: null,
    onFirstDataRendered: null,
    paginate: {},
    reactUi: 'true',
    rowData: [],
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true
          }
        }
      ]
    },
    suppressHorizontalScroll: false,
    title: null
  }

  static propTypes = {
    addBlankRow: PropTypes.func,
    addButtonStyleParams: PropTypes.object,
    addButtonText: PropTypes.string,
    animateRows: PropTypes.bool,
    autoSize: PropTypes.bool,
    columnDefs: PropTypes.array,
    columnDefVisibilityManagedExternally: PropTypes.bool,
    defaultColDef: PropTypes.object,
    immutableData: PropTypes.bool,
    disableFilter: PropTypes.bool,
    stopEditingWhenCellsLoseFocus: PropTypes.bool,
    headerHeight: PropTypes.number,
    headerStyle: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideHeader: PropTypes.bool,
    meta: PropTypes.object,
    onGridReady: PropTypes.func,
    onFirstDataRendered: PropTypes.func,
    paginationCb: PropTypes.func,
    rowData: PropTypes.array,
    suppressHorizontalScroll: PropTypes.bool,
    title: PropTypes.string
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      columnDefs: props.columnDefs,
      count: 0,
      trackedCells: [],
      showFind: !!props.find
    }
    this.selectedRows = new Set()
    this.expandedRowIds = new Set()
    this.resize = throttle(this.resize, 200)
    Object.assign(this, {
      autoSizeAll: this.props.autoSize
        ? autoSizeAll.bind(this)
        : autoFit.bind(this)
    })
    this.onBodyScroll = debounce(this.onBodyScroll, 200)
    this.debounceChange = debounce(this.onFindChange, 250)
    if (this.props.allowFieldChooser) {
      const hiddenColumns = fetchGridColsFromLocal(this.props) || []
      this.hiddenColumns = hiddenColumns
    }
  }

  _isMounted = false

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this._isMounted = true
  }

  shouldComponentUpdate(nextProps, nextState) {
    let ret
    if (!nextProps.immutableData) {
      ret =
        Object.keys(nextProps).some(prop => {
          if (prop === 'rowData' || prop === '_ddiForm') {
            return false
          }
          return !plainDeepEqual(this.props[prop], nextProps[prop])
        }) || !plainDeepEqual(this.state, nextState)
    } else {
      ret =
        Object.keys(nextProps).some(
          prop => !plainDeepEqual(this.props[prop], nextProps[prop])
        ) || !plainDeepEqual(this.state, nextState)
    }

    return ret
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.api) return
    Object.keys(prevProps).forEach(prop => {
      if (!plainDeepEqual(this.props[prop], prevProps[prop])) {
        if (!this.props.immutableData) {
          if (prop === 'rowData') {
            this.api.forEachNodeAfterFilter(node => {
              if (this.expandedRowIds.has(node.id)) {
                console.log(node)
                node.expanded = true
              }
            })
            this.api.onGroupExpandedOrCollapsed()
          }
        } else if (prop === 'rowData') {
          if (prevProps.pinnedData) {
            this.pinnedData(this.props)
          }
        }
      }
    })
    if (this.props.rowData && this.props.rowData.length) {
      if (this.props.hideHeader) {
        this.api.setHeaderHeight(0)
      } else {
        this.api.setHeaderHeight()
      }
    }
    if (this.props.isPending) {
      const index = this.api.getDisplayedRowCount() - 1
      if (index > -1) {
        this.api.ensureIndexVisible(index, 'bottom')
      }
    }
    if (!plainDeepEqual(this.state.columnDefs, this.props.columnDefs)) {
      /* eslint-disable react/no-did-update-set-state */
      if (this._isMounted) {
        this.setState({ columnDefs: this.props.columnDefs }, () => {})
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('keydown', this.checkAlt)
    this._isMounted = false
  }

  onFirstDataRendered = params => {
    if (this.props.onFirstDataRendered) {
      this.props.onFirstDataRendered(params)
    }
  }

  onGridReady = params => {
    this.api = params.api
    this.columnApi = params.columnApi

    this.api.sizeColumnsToFit()
    window.addEventListener('keydown', this.checkAlt)
    const { rowData } = this.props
    if (rowData) {
      if (!rowData.length) {
        this.api.setHeaderHeight(0)
        this.api.showNoRowsOverlay()
      } else {
        this.autoSizeAll()
      }
      if (rowData.length && this.props.pinnedData) {
        this.pinnedData(this.props)
      }
    }
    if (this.props.onGridReady) {
      this.props.onGridReady(params)
    }
    if (this.props.hideHeader) {
      this.api.setHeaderHeight(0)
    }
  }

  onRowGroupOpened = params => {
    const {
      node: { expanded, id }
    } = params
    if (expanded) {
      this.expandedRowIds.add(id)
    } else {
      this.expandedRowIds.delete(id)
    }
    if (this.props.onRowGroupOpened) {
      this.props.onRowGroupOpened(params)
    }
  }

  onRowSelected = params => {
    const {
      node: { id, selected }
    } = params
    if (selected) {
      this.selectedRows.add(id)
    } else {
      this.selectedRows.delete(id)
    }
    if (this.props.onRowSelected) {
      this.props.onRowSelected(params)
    }
  }

  onBodyScroll = ({ direction }) =>
    direction === 'horizontal' ? this.autoSizeAll() : null

  onCellContextMenu = ({ node }) => {
    if (this.props.hideHeader) {
      this.api.setHeaderHeight(0)
    }

    node?.setSelected(true, true)
  }

  resize = () => {
    if (this.api) this.autoSizeAll()
  }

  pinnedData = nextProps => {
    if (typeof nextProps.pinnedData === 'function') {
      if (nextProps.rowData.length) {
        const data = nextProps.pinnedData(nextProps.rowData)
        this.api.setPinnedBottomRowData(data)
      } else {
        this.api.setPinnedBottomRowData([])
      }
    }
  }

  getData = () => this.props.rowData

  removeCheckbox = memoize(
    columnDefs =>
      columnDefs.map(col => {
        if (col.checkboxSelection) {
          return {
            ...col,
            checkboxSelection: false,
            headerCheckboxSelection: false
          }
        }
        return col
      }),
    plainDeepEqual
  )

  mapColumnDefs = memoize(
    columnDefs =>
      columnDefs.map(col => {
        const ret = {
          ...col,
          filter: col?.filter
            ? col.filter
            : this.props.reactUi
            ? 'agTextColumnFilter'
            : 'customFilter',
          menuTabs: ['filterMenuTab'],
          cellClass: this.props.find
            ? params => {
                if (this.api) {
                  const focusedCell = this.api.getFocusedCell()
                  let rowIndex
                  let colId
                  let pIndex
                  let pColId
                  let className
                  if (focusedCell) {
                    rowIndex = focusedCell.rowIndex
                    colId = focusedCell.column.colId
                    pIndex = params.rowIndex
                    pColId = params.colDef.field
                    if (rowIndex === pIndex && colId === pColId) {
                      className = 'found-selected-cell'
                    }
                    return className
                  }
                }
                return undefined
              }
            : col.cellClass
            ? col.cellClass
            : undefined
        }
        if (!col.headerName && !col.children) {
          ret.suppressColumnsToolPanel = true
          ret.suppressFiltersToolPanel = true
        } else if (
          this.props.allowFieldChooser &&
          this.hiddenColumns &&
          this.hiddenColumns.includes(col.field) &&
          !this.props.columnDefVisibilityManagedExternally
        ) {
          ret.hide = true
        } else if (
          ret.hide &&
          this.hiddenColumns &&
          col.field &&
          !this.hiddenColumns.includes(col.field) &&
          !this.props.columnDefVisibilityManagedExternally
        ) {
          ret.hide = false
        }
        return ret
      }),
    plainDeepEqual
  )

  getFrameworkComponents = memoize(fwComponents => ({
    ...fwComponents,
    customFilter: CustomFilter
  }))

  handleFindChange = e => this.debounceChange(e.target.value)

  onFindChange = v => {
    const newValue = v.toLowerCase()
    let count = 0
    let minChars = 0
    const { find } = this.props
    if (find && is.number(find.minChars)) {
      minChars = find.minChars
    }
    const trackedCells = []
    if (this.api) {
      const rows = []
      this.api.forEachNodeAfterFilterAndSort((node, index) => {
        let rowHasText = false
        Object.keys(node.data)
          .reverse()
          .forEach(d => {
            const v = node.data[d].toString().toLowerCase()
            const ret = v.includes(newValue) && newValue.length >= minChars
            if (ret && !rowHasText) {
              rowHasText = true
            }
            if (ret && newValue.length) {
              count += 1
              trackedCells.push([index, d])
            }
          })
        if (!newValue) {
          if (node.data.highlighted) {
            removeHighlight(node, rows)
          }
        } else if (rowHasText && !node.data.highlighted) {
          const data = { ...node.data, highlighted: true }
          node.setData(data)
          rows.push(node)
        } else if (!rowHasText) {
          removeHighlight(node, rows)
        }
      })

      if (trackedCells.length) {
        this.api.setFocusedCell(...trackedCells[0])
      } else if (this.api.getFocusedCell()) {
        this.api.clearFocusedCell()
      }
    }
    if (this._isMounted) {
      this.setState({ count, trackedCells, selectedCell: 0 }, () => {
        if (this.findRef) {
          this.findRef.focus()
        }
      })
    }
  }

  getRowStyle = params => {
    if (this.props.getRowStyle) {
      return this.props.getRowStyle(params)
    }
    if (params.data && params.data.highlighted) {
      return {
        background: 'yellow'
      }
    }
    return null
  }

  onFindClick = num => () => {
    if (this.api && this._isMounted) {
      this.setState(
        prevState => ({
          selectedCell: prevState.selectedCell + num
        }),
        () => {
          const cell = this.state.trackedCells[this.state.selectedCell]
          const row = this.api.getDisplayedRowAtIndex(cell[0])
          this.api.setFocusedCell(...cell)
        }
      )
    }
  }

  onColumnVisible = params => {
    /* 
      ag-grid made changes (or perhaps fixed a bug that resulted in this change),
      but in any event, this is the fix. -- SVE 2/4/2021
    */
    const { column, columns } = params

    if (column && column.colId) {
      toggleFieldInLocal(this.props, column.colId, params.visible)
    } else if (columns && columns.length) {
      columns.forEach(col => {
        if (col.colId) {
          toggleFieldInLocal(this.props, col.colId, col.visible)
        }
      })
    }

    if (
      this.props.onColumnVisibleCb &&
      typeof this.props.onColumnVisibleCb === 'function'
    ) {
      this.props.onColumnVisibleCb(params)
    }
  }

  textFieldId = shortid.generate()

  findInputRef = c => (this.findRef = c)

  findRef = null

  toggleShowFind = () => {
    if (this._isMounted) {
      this.setState(
        prevState => ({
          showFind: !prevState.showFind
        }),
        () => this.onFindChange('')
      )
    }
  }

  addBlankRow = debounce(this.props.addBlankRow, 1000)

  grid = React.createRef()

  saveCart = () => {
    if (this.props.showCart && this.props.saveCart) {
      this.props.saveCart()
    }
  }

  handlers = {
    ALT_S: this.saveCart
  }

  render() {
    let {
      animateRows,
      addButtonStyleParams,
      addButtonText,
      allowFieldChooser,
      meta,
      height,
      headerStyle,
      showAddButtonAlways,
      showAddButtonOnlyIfEditing,
      showAddButtonIfHasRecord,
      suppressHorizontalScroll,
      title,
      hasRecord,
      isEditing,
      find,
      enableSorting,
      popupParent,
      ...rest
    } = this.props
    if (title) {
      height -= 33
    }

    let sideBar
    if (allowFieldChooser) {
      sideBar = this.props.sideBar
    } else {
      sideBar = undefined
    }
    let allowNew = false
    if (showAddButtonAlways) {
      allowNew = true
    } else if (
      (showAddButtonOnlyIfEditing && isEditing) ||
      (showAddButtonOnlyIfEditing && this?.props?.isEditing)
    ) {
      /* noticed this condition was not always being met prob because of line 544 up there -- SVE 9/14/2020 */
      allowNew = true
    } else if (showAddButtonIfHasRecord && hasRecord) {
      allowNew = true
    }
    if (allowNew || find) {
      height -= 30
    }
    const findRightDisabled = this.state.selectedCell >= this.state.count - 1

    const findLeftDisabled = this.state.selectedCell <= 0
    const Wrapper = this.props.saveCart ? HotKeys : React.Fragment
    const wrapperProps = this.props.saveCart
      ? { keyMap, handlers: this.handlers }
      : {}
    return (
      <div ref={c => (this.gridContainer = c)}>
        {title && <h4 style={headerStyle}>{title}</h4>}
        <div style={{ position: 'relative', height }}>
          {this.props.isFetching && (
            <div
              style={{
                width: '100%',
                height: '100%',
                background: 'black',
                opacity: 0.4,
                position: 'absolute',
                top: 0,
                zIndex: 99,
                margin: 'auto'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height,
                  width: '100%'
                }}
              >
                <CircularProgress size={60} thickness={7} color="primary" />
              </div>
            </div>
          )}

          <Wrapper {...wrapperProps}>
            <AgGridReact
              reactUi={this.props.reactUi}
              className={this.props.className || 'ag-theme-balham'}
              ref={this.grid}
              animateRows={animateRows}
              {...rest}
              sideBar={sideBar}
              onRowSelected={this.onRowSelected}
              onBodyScroll={this.onBodyScroll}
              onGridReady={this.onGridReady}
              onFirstDataRendered={this.onFirstDataRendered}
              columnDefs={this.mapColumnDefs(
                this.props.asModalSOI
                  ? this.removeCheckbox(this.state.columnDefs)
                  : this.state.columnDefs
              )}
              onRowGroupOpened={this.onRowGroupOpened}
              onCellContextMenu={this.onCellContextMenu}
              suppressKeyboardEvent={this.props.suppressKeyboardEvent}
              suppressHorizontalScroll={suppressHorizontalScroll}
              popupParent={popupParent || document.querySelector('body')}
              tabToNextCell={this.props.tabToNextCell}
              navigateToNextCell={this.props.navigateToNextCell}
              frameworkComponents={this.getFrameworkComponents(
                this.props.frameworkComponents
              )}
              getRowStyle={this.getRowStyle}
              defaultColDef={{
                resizable: true,
                filter: true,
                sortable: enableSorting ?? true
              }}
              onColumnVisible={this.onColumnVisible}
              immutableData={false}
              suppressScrollOnNewData
            />
          </Wrapper>

          {meta && Object.hasOwn(meta, 'recordCount') && (
            <Pagination
              recordCount={meta.recordCount}
              pageNumber={meta.pageNumber}
              pageCount={meta.pageCount}
              paginationCb={this.props.paginationCb}
              toggles={this.props.toggles}
              isFiltered={this.props.isFiltered}
              toggleFilter={this.props.toggleFilter}
              showCart={this.props.showCart}
              saveCart={this.props.saveCart}
              gridRef={this.grid}
            />
          )}
          {allowNew && (
            <AddNew
              addButtonText={addButtonText}
              onClick={this.addBlankRow}
              variant={addButtonStyleParams.variant || 'contained'}
              size={addButtonStyleParams.size || 'small'}
              color={addButtonStyleParams.color || 'secondary'}
              showIcon={addButtonStyleParams.showIcon}
              backgroundColor={addButtonStyleParams.backgroundColor}
            />
          )}
          {this.state.showFind && (
            <FindInGrid
              handleFindChange={this.handleFindChange}
              textFieldId={this.textFieldId}
              onFindClick={this.onFindClick}
              selectedCell={this.state.selectedCell}
              count={this.state.count}
              countStyle={countStyle}
              findLeftDisabled={findLeftDisabled}
              findRightDisabled={findRightDisabled}
              findInputRef={this.findInputRef}
              toggleShowFind={this.toggleShowFind}
            />
          )}
        </div>
      </div>
    )
  }
}
const wrapped = connect(
  state => ({ userId: getIn(state, 'auth.dataId') }),
  null,
  null,
  { forwardRef: true }
)(Grid)

export default wrapped
