/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-update-set-state */
import React, { useEffect, useState, createRef } from 'react'
import { Snackbar, SnackbarContent } from '@mui/material'
import { connect } from 'react-redux'
import {
  addedMailSelector,
  mailMessagesSelector,
  removedMailSelector
} from '../selectors'

export const MailStacks = props => {
  const { messages, removed, added } = props
  const [stacks, setStacks] = useState([[]])
  const [stackRefs, setStackRefs] = useState([createRef()])
  const [trackedAdd, setAdded] = useState(null)
  const [trackedRemoved, setRemoved] = useState(null)
  const [refMap, setRefMap] = useState({})

  useEffect(() => {
    let msg
    let tempStacks
    let lastStackIdx
    let stackRef
    let tempRefs
    // reflow if height/width changes..
    if (added != null && trackedAdd !== added) {
      setAdded(added)
      msg = messages.find(x => x.get('id') === added)
      if (msg) {
        lastStackIdx = 0
        if (!refMap[msg.get('id')]) {
          setRefMap({ ...refMap, [msg.get('id')]: createRef() })
        }
        stackRef = stackRefs[lastStackIdx]

        const stackHeight = stackRef?.current?.clientHeight ?? 0
        if (stackHeight + 150 < props.height) {
          // add to stack
          tempStacks = [...stacks]
          tempStacks[lastStackIdx] = [...tempStacks[lastStackIdx], msg]
          setStacks(tempStacks)
        } else {
          lastStackIdx = 0 // (stacks.length ? stacks.length - 1 : 0) + 1
          tempStacks = [...stacks]
          const newStack = [msg]
          tempStacks.unshift(newStack)

          tempRefs = [...stackRefs]

          tempRefs.unshift(createRef())
          setStacks(tempStacks)
          setStackRefs(tempRefs)
        }
      }
    } else if (removed != null && trackedRemoved !== removed) {
      setRemoved(removed)
      const loc = []
      for (const [id, s] of Object.entries(stacks)) {
        if (loc.length) {
          break
        }
        for (const [idx, s2] of Object.entries(s)) {
          if (s2.get('id') === removed) {
            loc[0] = id
            loc[1] = idx
            break
          }
        }
      }
      tempStacks = [...stacks]
      const temp = [
        ...tempStacks[loc[0]].slice(0, loc[1]),
        ...tempStacks[loc[0]].slice(loc[1] + 1)
      ]
      if (!temp.length) {
        tempStacks = [
          ...tempStacks.slice(0, loc[0]),
          ...tempStacks.slice(loc[0] + 1)
        ]
        tempRefs = [
          ...stackRefs.slice(0, loc[0]),
          ...stackRefs.slice(loc[0] + 1)
        ]
        if (!tempStacks.length) {
          tempStacks = [[]]
        }
        if (!tempRefs.length) {
          tempRefs = [createRef()]
        }
        setStackRefs(tempRefs)
        // debugger
      } else {
        tempStacks[loc[0]] = temp
      }

      setStacks(tempStacks)
    }
  }, [messages, removed, added])

  return (
    <>
      {stacks.map((x, idx) => (
        <Snackbar
          open
          anchorOrigin={props.anchorOrigin}
          key={`stack${idx}`}
          style={{ width: 350, marginRight: idx * 350 }}
        >
          <div ref={stackRefs[idx]}>
            {stacks[idx].map(y => {
              const Comp = y.get('component').toJS()
              return (
                <SnackbarContent
                  key={y.get('id')}
                  style={{ marginBottom: 5 }}
                  message={
                    <Comp ref={refMap[y.get('id')]} {...y.get('data').toJS()} />
                  }
                />
              )
            })}
          </div>
        </Snackbar>
      ))}
    </>
  )
}

export const mapStateToProps = state => ({
  messages: mailMessagesSelector(state),
  removed: removedMailSelector(state),
  added: addedMailSelector(state)
})
export default connect(mapStateToProps)(MailStacks)
