/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0, react/sort-comp: 0, max-classes-per-file: 0 */
import React, { Component } from 'react'
import {
  Icon,
  MenuItem,
  Paper,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Table
} from '@mui/material'

import ScrollArea from 'react-scrollbar'
import { getIn, toDate } from 'utils'
import { Field, withContext } from 'ddiForm'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'

import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import DDIDatePicker from 'ddiForm/wrapped/DDIDatePicker'
import DDITextField from 'ddiForm/wrapped/DDITextField'
import DDISelectField from 'ddiForm/wrapped/DDISelectField'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import DDIToggle from 'ddiForm/wrapped/DDIToggle'
import { noop } from 'lodash'
// import { setField } from 'ddiForm/actions'

const errorIconStyle = {
  color: '#d9534f',
  fontSize: 16,
  position: 'absolute',
  bottom: 7,
  right: -15
}

/**
 * Use the native Intl.DateTimeFormat if available, or a polyfill if not.
 */

const rangeOptions = [
  { value: 'B', primaryText: 'Below' },
  { value: 'G', primaryText: 'Greater' }
]
const overrideOptions = [
  { value: 'O', primaryText: 'Only' },
  { value: 'E', primaryText: 'Exclude' }
]
const { DateTimeFormat } = global.Intl
const meta = { allowSearchAll: true }
/* eslint-disable react/no-multi-comp */

const pageStyles = {
  // clearAll: {
  //   width: '100%', float: 'left', clear: 'both'
  // },
  datepickerWrapper: { margin: '0 auto 10px auto', maxWidth: 300 },
  flexLabels: {
    label: {
      flex: 1,
      order: 1,
      minWidth: 60,
      maxWidth: 100,
      textAlign: 'right',
      fontSize: '1rem',
      fontWeight: 400,
      marginBottom: 0,
      marginRight: 10
    },
    input: { flex: 3, order: 2, maxWidth: 200 },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 15
    }
  },
  pctOptsWrapper: { margin: '15px auto 10px auto', maxWidth: 350 },
  tableStyles: {
    analysisOpts: {
      checkboxCol: { marginHorizontal: 'auto', width: 55 },
      labelCol: {
        fontSize: 12,
        paddingLeft: 5,
        textAlign: 'left'
      }
    }
  },
  threeColFlex: {
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '100%',
      padding: '0 5px',
      width: '100%'
    },
    left: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 1 },
    center: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 2 },
    right: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 3 }
  }
}

const inputStyle = {
  fontSize: 12,
  textAlign: 'right'
}

// const muiStyles = {
//   checked: {
//     color: '#517b9c'
//   }
// }

export class RenderRadioGroup extends Component {
  onChange = (event, value) => this.props.onChange(value)

  render() {
    // console.log(this.props)
    const { onChange, renderAsRow, ...rest } = this.props
    return (
      <FormControl style={{ fontSize: 14, width: '100%' }}>
        <RadioGroup
          onChange={this.onChange}
          {...rest}
          onBlur={noop}
          value={this.props.value}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
          row={renderAsRow}
        >
          {this.props.children}
        </RadioGroup>
      </FormControl>
    )
  }
}

export class AnalysisTab extends Component {
  static propTypes = {}

  static defaultProps = {}

  rangeOptions = rangeOptions

  overrideOptions = overrideOptions
  // constructor(props, context) {
  //   super(props, context)
  //   Object.assign(this, {
  //     StartDate: withField({ ...props, field: 'startDate' })(DatePicker),
  //     BranchSearch: withField({ ...props, field: 'branch' })(IndexSearch),
  //   })
  // }

  constructor(props) {
    super(props)

    this.dateType = React.createRef()
  }

  // componentDidMount() {
  //   /*
  //     this fixes non-selected Order/Ship
  //     radio button on load SVE 6/10/2019
  //   */
  //   // if (this.dateType && this.dateType.current && !this.props.dateType) {
  //   //   this.dateType.current.click()
  //   // }
  // }

  renderRangeFilterRows() {
    return this.rangeOptions.map(option => (
      // <MenuItem value={option.value} primaryText={option.primaryText} key={option.value} />
      <MenuItem value={option.value} key={option.value}>
        {option.primaryText}
      </MenuItem>
    ))
  }

  renderOverrideFilterRows() {
    return this.overrideOptions.map(option => (
      // <MenuItem value={option.value} primaryText={option.primaryText} key={option.value} />
      <MenuItem value={option.value} key={option.value}>
        {option.primaryText}
      </MenuItem>
    ))
  }

  makeRadioButtons = data =>
    data && data.length
      ? data.map((item, idx) => (
          <FormControlLabel
            control={
              <Radio
                style={{ color: '#517b9c' }}
                inputRef={item.dataId === 'O' ? this.dateType : null}
              />
            }
            style={{ width: 100, margin: 0 }}
            value={item.dataId}
            label={item.description}
          />
        ))
      : []

  render() {
    const { asModal } = this.props
    let { meta, endDate, startDate } = this.props
    meta = meta?.toJS ? meta.toJS() : meta
    endDate = endDate ? new Date(endDate) : endDate
    startDate = startDate ? new Date(startDate) : startDate

    const colMinHeight = asModal ? 280 : 350

    return (
      <Paper>
        <div id="sales-order-equalize">
          <div
            style={{
              display: 'flex',
              alignItems: 'stretch',
              maxHeight: 360,
              width: '100%',
              padding: asModal ? '0 5px' : 0
            }}
          >
            <Paper
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexBasis: '33%',
                padding: 10,
                margin: 5
              }}
            >
              <AssociatedFieldsWrapper
                style={{
                  float: 'none',
                  clear: 'none',
                  marginBottom: 15,
                  width: 325,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Field
                  component={RenderRadioGroup}
                  propertyName="dateType"
                  defaultSelected="O"
                  initialValue="O"
                  renderAsRow
                  wrapperStyle={null}
                >
                  {this.makeRadioButtons([
                    { dataId: 'O', description: 'Order' },
                    { dataId: 'S', description: 'Ship' }
                  ])}
                </Field>
              </AssociatedFieldsWrapper>
              <div
                className="datepicker-widgets"
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  marginBottom: 35
                }}
              >
                <div
                  style={{
                    margin: '0 15px 0 0',
                    position: 'relative',
                    width: 140
                  }}
                >
                  <DDIDatePicker
                    propertyName="startDate"
                    hintText="Start Date"
                    dialogContainerStyle={{ zIndex: 5000 }}
                    inputStyle={{ marginTop: 5 }}
                    wrapperStyle={null}
                    initialValue={null}
                    // maxDate={new Date()} /* disabled as per 8973 */
                    label="Start Date"
                  />
                  {(endDate && !startDate) ||
                  (startDate && endDate && endDate < startDate) ? (
                    <Tooltip
                      title={
                        endDate && !startDate
                          ? 'Start Date is required'
                          : 'Start Date cannot be after End Date'
                      }
                      style={{ marginTop: '0px' }}
                    >
                      <Icon style={errorIconStyle}>error_outline</Icon>
                    </Tooltip>
                  ) : null}
                </div>
                <div
                  style={{
                    margin: '0 0 0 15px',
                    position: 'relative',
                    width: 140
                  }}
                >
                  <DDIDatePicker
                    propertyName="endDate"
                    hintText="End Date"
                    dialogContainerStyle={{ zIndex: 5000 }}
                    wrapperStyle={null}
                    initialValue={null}
                    label="End Date"
                    // maxDate={new Date()} /* disabled as per 8973 */
                    // disableFuture
                  />
                  {(startDate && !endDate) ||
                  (startDate && endDate && endDate < startDate) ? (
                    <Tooltip
                      title={
                        startDate && !endDate
                          ? 'End Date is required'
                          : 'Start Date cannot be after End Date'
                        }
                      style={{ marginTop: '0px' }}
                    >
                      <Icon style={errorIconStyle}>error_outline</Icon>
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  ...pageStyles.pctOptsWrapper,
                  marginTop: asModal ? 0 : undefined
                }}
              >
                <Table
                  fixedHeader
                  selectable={false}
                  style={{ border: '5px solid #d7d7d7' }}
                >
                  <TableBody stripedRows displayRowCheckbox={false}>
                    <TableRow style={{ height: 5 }}>
                      <TableCell
                        style={{
                          paddingLeft: 5,
                          width: 60,
                          fontSize: 10
                        }}
                      >
                        G/P %
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 75 }}
                      >
                        <DDITextField
                          propertyName="rangeFilters.gpPercent"
                          inputStyle={inputStyle}
                          fullWidth
                          mask="numeric"
                          meta={{ filterType: 27 }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: '5px 24px 5px 5px' }}>
                        <DDISelectField
                          propertyName="rangeFilters.gpPercent.filterTypeOption"
                          values={this.rangeOptions}
                          initialValue="B"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingLeft: 5,
                          width: 60,
                          fontSize: 10
                        }}
                      >
                        G/P $
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 75 }}
                      >
                        <DDITextField
                          propertyName="rangeFilters.gpDollar"
                          inputStyle={inputStyle}
                          fullWidth
                          mask="numeric"
                          meta={{ filterType: 28 }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: '5px 24px 5px 5px' }}>
                        <DDISelectField
                          propertyName="rangeFilters.gpDollar.filterTypeOption"
                          initialValue="B"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          selectValue="value"
                          selectDescription="primaryText"
                          values={this.rangeOptions}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingLeft: 5,
                          width: 60,
                          fontSize: 10
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 75 }}
                      >
                        <DDITextField
                          // component={TextField}
                          type="number"
                          propertyName="rangeFilters.gpTotal"
                          inputStyle={inputStyle}
                          fullWidth
                          meta={{ filterType: 29 }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: '5px 24px 5px 5px' }}>
                        <DDISelectField
                          propertyName="rangeFilters.gpTotal.filterTypeOption"
                          initialValue="B"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          values={this.rangeOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Paper>
            <Paper
              style={{
                flexBasis: '33%',
                padding: 30,
                margin: 5
              }}
            >
              <div style={pageStyles.flexLabels.wrapper}>
                <label htmlFor="branch" style={pageStyles.flexLabels.label}>
                  Branch
                </label>
                <div style={pageStyles.flexLabels.input}>
                  <DDIIndexSearch
                    propertyName="branch"
                    metaKey="Branch"
                    searchType={
                      INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.BRANCH
                    }
                    wrapperMargins={0}
                  />
                </div>
              </div>
              <div style={pageStyles.flexLabels.wrapper}>
                <label htmlFor="salesman" style={pageStyles.flexLabels.label}>
                  Salesman
                </label>
                <div style={pageStyles.flexLabels.input}>
                  <DDIIndexSearch
                    propertyName="salesman"
                    metaKey="Salesman"
                    searchType={
                      INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.SALESMAN
                    }
                    disabled={!!meta.salesmenDisplay}
                    // disabled={!!foo.salesmenDisplay}
                    initialValue={
                      meta.salesmenDisplay ? meta.salesmenDisplay : undefined
                    }
                    // initialValue={foo.salesmenDisplay}
                    wrapperMargins={0}
                  />
                </div>
              </div>
              <div style={pageStyles.flexLabels.wrapper}>
                <label htmlFor="writer" style={pageStyles.flexLabels.label}>
                  Writer
                </label>
                <div style={pageStyles.flexLabels.input}>
                  <DDIIndexSearch
                    propertyName="writer"
                    metaKey="Writer"
                    searchType={
                      INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.SYSTEM_USERS
                    }
                    wrapperMargins={0}
                  />
                </div>
              </div>
              <div style={pageStyles.flexLabels.wrapper}>
                <label htmlFor="shipVia" style={pageStyles.flexLabels.label}>
                  Ship Via
                </label>
                <div style={pageStyles.flexLabels.input}>
                  <DDIIndexSearch
                    propertyName="shipVia"
                    metaKey="Ship Via"
                    searchType={
                      INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.SHIP_VIA
                    }
                    wrapperMargins={0}
                  />
                </div>
              </div>
              <div style={pageStyles.flexLabels.wrapper}>
                <label htmlFor="orderType" style={pageStyles.flexLabels.label}>
                  Order Type
                </label>
                <div style={pageStyles.flexLabels.input}>
                  <DDIIndexSearch
                    propertyName="orderType"
                    metaKey="Order Type"
                    searchType={
                      INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.ORDER_TYPE
                    }
                    wrapperMargins={0}
                  />
                </div>
              </div>
              <div style={pageStyles.flexLabels.wrapper}>
                <label htmlFor="orderType" style={pageStyles.flexLabels.label}>
                  RGA Code
                </label>
                <div style={pageStyles.flexLabels.input}>
                  <DDISelectField
                    propertyName="rgaCode"
                    // values={meta.get('rgaOptions').toJS()}
                    values={meta.rgaOptions}
                    initialValue=""
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      width: '100%'
                    }}
                    selectValue="dataId"
                    selectDescription="description"
                    displayEmpty
                  />
                </div>
              </div>
            </Paper>
            <Paper
              style={{
                flexBasis: '33%',
                margin: 5,
                overflow: 'hidden'
              }}
            >
              <ScrollArea
                style={{ height: 350 }}
                speed={0.2}
                smoothScrolling
                // minScrollSize={350}
                ref={c => (this.scrollArea = c)}
              >
                <Table
                  fixedHeader
                  selectable={false}
                  deselectOnClickaway={false}
                  height={350}
                >
                  <TableBody stripedRows displayRowCheckbox={false}>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.priceCost.enabled"
                          meta={{ filterType: 18 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Price/Cost Changes
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.priceCost.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          values={this.overrideOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.userPrice.enabled"
                          meta={{ filterType: 19 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        User Price Overrides
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.userPrice.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          values={this.overrideOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.nonStocks.enabled"
                          meta={{ filterType: 20 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Non-stocks
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.nonStocks.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          values={this.overrideOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.dropShipments.enabled"
                          meta={{ filterType: 21 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Drop Shipments
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.dropShipments.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          values={this.overrideOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.printed.enabled"
                          meta={{ filterType: 31 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Printed
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.printed.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          values={this.overrideOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.shipConfirmed.enabled"
                          meta={{ filterType: 22 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Ship Confirmed
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.shipConfirmed.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          values={this.overrideOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>{' '}
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.signed.enabled"
                          meta={{ filterType: 23 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Signed
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.signed.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          selectValue="value"
                          selectDescription="primaryText"
                          values={this.overrideOptions}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.truckScheduled.enabled"
                          meta={{ filterType: 24 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Truck Scheduled
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.truckScheduled.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          values={this.overrideOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.pointOfSale.enabled"
                          meta={{ filterType: 25 }}
                          autoWidth
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Point of Sale
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.pointOfSale.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          selectValue="value"
                          selectDescription="primaryText"
                          values={this.overrideOptions}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.cancelled.enabled"
                          meta={{ filterType: 26 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Cancelled
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.cancelled.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          selectValue="value"
                          selectDescription="primaryText"
                          values={this.overrideOptions}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.inBilling.enabled"
                          meta={{ filterType: 30 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        In Billing
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.inBilling.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          selectValue="value"
                          selectDescription="primaryText"
                          values={this.overrideOptions}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.reportNoPrintedByUser.enabled"
                          meta={{ filterType: 34 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Report No Printed By User
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.reportNoPrintedByUser.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          selectValue="value"
                          selectDescription="primaryText"
                          values={this.overrideOptions}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.repairItems.enabled"
                          meta={{ filterType: 39 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Repair Items
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.repairItems.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          values={this.overrideOptions}
                          selectValue="value"
                          selectDescription="primaryText"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.checkboxCol}
                      >
                        <DDIToggle
                          propertyName="overrideFilters.warrantyTags.enabled"
                          meta={{ filterType: 40 }}
                        />
                      </TableCell>
                      <TableCell
                        style={pageStyles.tableStyles.analysisOpts.labelCol}
                      >
                        Warranty Tags
                      </TableCell>
                      <TableCell
                        style={{ padding: '5px 24px 5px 5px', width: 100 }}
                      >
                        <DDISelectField
                          propertyName="overrideFilters.warrantyTags.filterTypeOption"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            width: '100%'
                          }}
                          initialValue="O"
                          autoWidth
                          selectValue="value"
                          selectDescription="primaryText"
                          values={this.overrideOptions}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ScrollArea>
            </Paper>
          </div>
        </div>
      </Paper>
    )
  }
}

const contextObj = {
  dateType: form => {
    const dateType = getIn(form, 'fields.dateType')
    if (getIn(dateType, 'value')) {
      return getIn(dateType, 'value')
    }

    return ''
  },
  startDate: form => getIn(form, 'fields.startDate.value') || null,
  endDate: form => getIn(form, 'fields.endDate.value') || null
}

const analysisTab = withContext(contextObj)(AnalysisTab)
export default analysisTab
