import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { withStyles } from '@mui/styles'

import { selectCardTerminal as selectCardTerminalAction } from '../../actions'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    maxWidth: 700
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
})

const mapStateToProps = (state, ownProps) => {
  const cardTerminals = getIn(state, 'auth.cardTerminals') || []

  return {
    cardTerminals
  }
}

const mapDispatctToProps = dispatch => ({
  selectCardTerminal: dataId => {
    dispatch(selectCardTerminalAction(dataId))
  }
})

export const CardTerminalModal = ({
  cardTerminals,
  selectCardTerminal,
  data: { onChange = false }
}) => {
  const [terminalsList, setTerminalsList] = useState([])
  const [selectedCardTerminal, setSelectedCardTerminal] = useState('')

  useEffect(() => {
    const terminals =
      cardTerminals && cardTerminals.toJS ? cardTerminals.toJS() : cardTerminals

    setTerminalsList(terminals)

    if (!onChange) {
      const dataId = terminals && terminals.length ? terminals[0].dataId : null

      setSelectedCardTerminal(dataId)
      selectCardTerminal(dataId)
    }
  }, [cardTerminals])

  const onRowSelection = (e, dataId = null) => {
    setSelectedCardTerminal(dataId)
    selectCardTerminal(dataId)
  }

  return (
    <div>
      <Table>
        <TableHead style={{ background: '#444', color: '#fff' }}>
          <TableRow style={{ background: '#444' }}>
            <TableCell style={{ width: 35 }}>&nbsp;</TableCell>
            <TableCell
              style={{
                color: '#fff',
                textAlign: 'left',
                width: 50
              }}
            >
              Card Terminal
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {terminalsList.map((row, i) => {
            const isSelected = selectedCardTerminal === row.dataId
            return (
              <TableRow
                key={row.dataId}
                role="checkbox"
                aria-checked={isSelected}
                selected={row.dataId === selectedCardTerminal}
                onClick={e => onRowSelection(e, row.dataId)}
              >
                <TableCell style={{ padding: 5, maxWidth: 25, width: 25 }}>
                  <Checkbox
                    checked={isSelected}
                    style={{ height: 24, width: 24 }}
                  />
                </TableCell>
                <TableCell style={{ textAlign: 'left' }}>
                  {row.dataId}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

const styledModal = withStyles(styles)(CardTerminalModal)

export default connect(
  mapStateToProps,
  mapDispatctToProps,
  null
)(styledModal)
