/* eslint no-lonely-if: 0 */
import MaskedInput from 'react-text-mask'
// // import TextField from 'ddiForm/TextField'
// import { TextField } from '@mui/material'
import React, {useRef} from 'react'

export default function PhoneNumberMask(props) {
  const inputRef = useRef(null);
  const onBlur = e => {
    let value = e.target.value
    const keys = Array.from(value)
    const digitCount = keys.reduce((acc, next) => {
      if (Number.isInteger(parseInt(next, 10))) {
        acc += 1
      }
      return acc
    }, 0)

    if (props.validate) {
      if (digitCount === 10) {
        value = value.replace(/[()]/g, '').replace(/[\s]/g, '-')
      }
    } else {
      if (digitCount === 10) {
        value = value.replace(/[()]/g, '').replace(/[\s]/g, '-')
      } else {
        value = ''
      }
    }

    const target = {
      target: {
        value
      }
    }
    props.onBlur(target)
  }
  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef.current = ref ? ref.inputElement : null;
      }}
      mask={[
        '(',
        /[0-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      onBlur={onBlur}
      showMask={!!props.value}
      style={props.style}
    />
  )
}

// const PhoneNumberTextField = props => (
//   <TextField
//     {...props}
//     InputProps={{
//       inputComponent: PhoneNCustom
//     }}
//   />
// )
// export default PhoneNumberTextField
