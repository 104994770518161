/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@mui/material'
import { connect } from 'react-redux'

import {
  otherMessagesSelector,
  addedOtherSelector,
  removedOtherSelector
} from '../selectors'

const style = { width: 340 }

export const mapStateToProps = state => ({
  messages: otherMessagesSelector(state),
  added: addedOtherSelector(state),
  removed: removedOtherSelector(state)
})

export const OtherMessages = props => {
  const { messages, added, removed } = props
  const [snacks, createSnacks] = useState([])
  const [trackedAdd, setAdded] = useState(null)
  const [trackedRemoved, setRemoved] = useState(null)
  const [trackedMessages, setTracked] = useState(messages)

  useEffect(() => {
    let msg
    if (!messages.size && snacks.length) {
      createSnacks([])
      setAdded(null)
      setRemoved(null)
      setTracked(messages)
      return
    }
    if (added != null && trackedAdd !== added) {
      setAdded(added)
      const addedNew = [];
      messages.map(item => {
        const Comp = item.get('component')
        const newComponent = <Comp severity={item.get('type')} key={item.get('id')}>
                              {item.get('message')}
                            </Comp>
        addedNew.push(newComponent);
      })
      createSnacks(
        addedNew
      )  
    }

    if (removed != null && trackedRemoved !== removed) {
      setRemoved(removed)
      msg = trackedMessages.find(x => x.get('id') === removed)
      if (msg) {
        createSnacks(
          trackedMessages
            .filter(x => x.get('id') != msg.get('id'))
            .map(x => {
              const Comp = x.get('component')
              return (
                <Comp severity={x.get('type')} key={x.get('id')}>
                  {x.get('message')}
                </Comp>
              )
            })
            .toJS()
        )
      }
    }

    setTracked(messages)
  }, [messages, added, removed])

  return (
    <Snackbar
      open={!!snacks.length}
      anchorOrigin={props.anchorOrigin}
      style={style}
    >
      <div>{snacks}</div>
    </Snackbar>
  )
}

OtherMessages.propTypes = {
  messages: PropTypes.object.isRequired,
  added: PropTypes.string,
  removed: PropTypes.string
}

export default connect(mapStateToProps)(OtherMessages)
