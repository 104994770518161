import {
  call,
  take,
  fork,
  put,
  putResolve,
  select,
  actionChannel
} from 'redux-saga/effects'
import Dialog from 'modals/Dialog'
import TimerDialog from 'modals/TimerDialog'
import { destroyModal } from 'utils'
import { MODAL_ACTION, CANCELED, CONFIRMED } from './constants'
import { addModal } from './actions'

export function* modalConfirmationListener() {
  const channel = yield actionChannel([CONFIRMED, CANCELED])
  while (true) {
    const action = yield take(channel)
    yield call(
      destroyModal,
      action.payload.id,
      action.meta.form,
      action.payload.override
    )

    if (action.payload.action || action.payload.saga) {
      const newArgs = {}
      const argKeys = Object.keys(action.payload.args)
      for (const key in argKeys) {
        if (action.payload.args[argKeys[key]] instanceof Function) {
          newArgs[argKeys[key]] = yield select(newArgs[key])
        } else {
          newArgs[argKeys[key]] = action.payload.args[argKeys[key]]
        }
      }
      // console.log(newArgs, action.payload.action, action.payload.saga)
      if (action.payload.action) {
        const callee = action.payload.action || action.payload.saga
        yield put(callee(newArgs))
      } else if (action.payload.saga) {
        yield call(action.payload.saga, newArgs)
      }
    }
    // console.log('end of modal listener loop')
    // if (action.action |}|)
    // yield call(action.action || action.saga, action.args)
  }
}

export function* modalActionListener() {
  const channel = yield actionChannel(MODAL_ACTION)
  while (true) {
    const action = yield take(channel)
    // debugger
    const {
      payload: { args = {}, saga },
      payload,
      meta: { form }
    } = action
    let modalAction = payload.action

    const newArgs = {}
    const argKeys = Object.keys(args).filter(x => x !== 'id')
    // console.log(argKeys)
    for (const key in argKeys) {
      if (args[argKeys[key]] instanceof Function) {
        newArgs[argKeys[key]] = yield select(newArgs[key])
      } else {
        newArgs[argKeys[key]] = args[argKeys[key]]
      }
    }
    // debugger
    if (saga) {
      // debugger
      try {
        yield call(saga, { ...newArgs, modalSaga: true })
        yield put({
          payload: { id: args.id },
          meta: { form },
          type: CONFIRMED
        })
        // eslint-disable-next-line no-empty
      } catch {}
    } else if (modalAction) {
      if (typeof modalAction === 'string') {
        modalAction = {
          payload: { ...newArgs },
          meta: { form },
          type: action
        }
      } else if (typeof modalAction === 'object') {
        modalAction = {
          ...modalAction,
          payload: { ...newArgs },
          meta: { form }
        }
      } else {
        modalAction = modalAction({ ...newArgs })
        modalAction.meta = modalAction.meta || {}
        modalAction.meta = { ...modalAction.meta, form }
      }

      yield putResolve(modalAction)
    }
    // yield call(destroyModal, form, args.id)
    yield call(destroyModal, args.id, form)
  }
}

export function* warningModal(message, title, actions, ...rest) {
  const defaultActions = [{ title: 'OK', keyboardfocused: true, primary: true }]
  let form
  if (typeof actions === 'string') {
    form = actions
  }
  if (Array.isArray(actions) && rest.length) {
    ;[form] = rest
  }
  actions =
    typeof actions === 'string'
      ? defaultActions
      : Array.isArray(actions)
      ? actions
      : defaultActions

  const options = {
    component: Dialog,
    options: {
      data: {
        actions,
        message,
        type: 'alert'
      },
      title: title || 'Warning',
      width: 500
    },
    type: 'warning'
  }
  let modal
  if (form) {
    modal = yield call(addModal, form, options)
  } else {
    modal = yield call(addModal, options)
  }

  yield put(modal)
  return modal.payload.id
}

export function* timerWarningModal(message, title, actions, data = {}) {
  const defaultActions = [{ title: 'OK', keyboardfocused: true, primary: true }]
  let form
  if (typeof actions === 'string') {
    form = actions
  }

  const { timer = 120 } = data

  actions =
    typeof actions === 'string'
      ? defaultActions
      : Array.isArray(actions)
      ? actions
      : defaultActions
  // debugger
  const options = {
    component: TimerDialog,
    options: {
      data: {
        actions,
        message,
        type: 'alert',
        timer
      },
      title: title || 'Warning',
      width: 500
    },
    type: 'warning'
  }
  let modal
  if (form) {
    modal = yield call(addModal, form, options)
  } else {
    modal = yield call(addModal, options)
  }

  yield put(modal)
  return modal.payload.id
}
export function* confirmationModal(message, title, opts = {}, confirm, cancel) {
  const actions = [
    {
      confirm: true,
      keyboardfocused: true,
      primary: true,
      title: (confirm && confirm.title) || 'OK'
    },
    {
      cancel: true,
      secondary: true,
      title: (cancel && cancel.title) || 'Cancel'
    }
  ]
  const options = {
    component: Dialog,
    options: {
      data: {
        actions,
        message
      },
      modalOverrideClass: opts.modalOverrideClass || '',
      title: title || 'Cancel?',
      type: 'confirm',
      width: opts.width || 500,
      modalContainerStyle: opts.modalContainerStyle || {}
    }
  }

  if (confirm && confirm.action) {
    // are we providing an action when 'OK' is clicked?
    actions[0].clickEvent = {
      action: confirm.action,
      args: confirm.args || {},
      saga: confirm.saga || undefined
    }
  }

  // console.log(options)
  let modal
  if (opts.form) {
    modal = yield call(addModal, opts.form, options)
  } else {
    modal = yield call(addModal, options)
  }
  yield put(modal)
  return modal.payload.id
}

export default function* modalListeners() {
  yield fork(modalActionListener)
  yield fork(modalConfirmationListener)
}
