import React from 'react'
import { Icon } from '@mui/material'
import { informBlue } from 'theme/colors'

const EmailFaxLogIcon = ({ value }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Icon style={{ color: informBlue }}>
        {value === 'F' ? 'print' : 'email'}
      </Icon>
    </div>
  )
}

export default EmailFaxLogIcon
