import React, { Component } from 'react'

import { Dialog, DialogContent, DialogTitle, Icon } from '@mui/material'
import DDICardWrapper from 'components/DDICardWrapper'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import { mobileStateSelector } from 'mobile/selectors'
import { printDocumentRoutineCompleted } from 'components/PrintDocumentModal/actions'

import Attachments from 'components/MasterScreen/Attachments'
import Footer from './components/Footer'
import Content from './components/Content'
import './styles/send-document.scss'

const defaultStyles = {
  flexContainer: { display: 'flex', flexWrap: 'wrap' },
  half: { flex: 0.5, flexDirection: 'row', display: 'flex' },
  left: { flex: 0.2 },
  right: { flex: 0.8, padding: '0 10px' }
}

const Wrapper = props => (
  <DDICardWrapper
    {...props}
    contentStyle={{ padding: 10, float: 'none', clear: 'none' }}
    headlineStyle={{ padding: '6px 0', float: 'none', clear: 'none' }}
  >
    {props.children}
  </DDICardWrapper>
)

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  console.log('mapStateSendDoc', form)

  const formState = getIn(state, `ddiForm.${form}`)
  const sendInfoSearch = getIn(formState, 'fields.sendInfoSearch.value')

  return {
    mobileState: mobileStateSelector(state),
    sendInfoSearch
  }
}

class SendDocument extends Component {
  scrollAnchor = React.createRef()

  static defaultProps = {
    attachements: [],
    data: {},
    styles: defaultStyles
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('senddoc derived', nextProps, prevState)
    return null
  }

  constructor(...args) {
    super(...args)
    console.log('senddoc', this.props)
    const {
      fromCompany,
      printJobId,
      subject,
      fromName,
      sendTo,
      sendType,
      dataId
    } = this.props
    console.log('senddoc ctor', this.props)
    this.state = {
      attachments: [],
      body: '',
      fromCompany: fromCompany || '',
      fromName: fromName || '',
      sendType,
      subject: subject || '',
      sendTo: sendTo || [],
      printJobId,
      open: true,
      dataId
    }
  }

  onClose = () =>
    this.setState({ open: false }, () => {
      this.close()
    })

  onChange = name => event => this.setState({ [name]: event.target.value })

  onModelChange = model => this.setState({ body: model })

  onSelectChange = event => this.setState({ sendType: event.target.value })

  onSendClick = async () => {
    try {
      await this.props.dispatch({
        type: 'SEND_DOCUMENT_SEND_TRY',
        meta: {
          form: this.props.form,
          thunk: true
        },
        payload: {
          ...this.state,
          sendTo: this.props.sendInfoSearch
          // sendTo: this.sendInfoSearch.state.value
        }
      })
      this.close()
    } catch (e) {
      console.log(e)
    }
  }

  close = () => {
    this.props.dispatch({
      type: 'CLOSE_SEND_DOCUMENT',
      meta: {
        form: this.props.form
      },
      payload: {
        dataId: this.props.dataId
      }
    })

    this.props.dispatch(printDocumentRoutineCompleted(this.props.form))
  }

  handleAttachments = (attachments = []) => this.setState({ attachments })

  onSendDocumentInterfaceExpanded = ({ expanded }) => {
    // console.log('expanded', expanded, this.scrollAnchor)

    if (expanded) {
      setTimeout(() => {
        if (this?.scrollAnchor?.current?.scrollIntoView) {
          this.scrollAnchor.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          })
        }
      }, 1000)
    }
  }

  render() {
    console.log('sendDocumentModal', this)
    const { attachments } = this.state
    const { styles } = this.props

    const attachmentsTitle = Array.isArray(attachments)
      ? `Attachments (${attachments.length})`
      : 'Attachments'

    return (
      <Dialog open={this.state.open} maxWidth="md" fullWidth>
        <DialogTitle>Send To</DialogTitle>
        <DialogContent>
          <Content
            Wrapper={Wrapper}
            styles={styles}
            onSelectChange={this.onSelectChange}
            sendType={this.state.sendType}
            sendTo={this.state.sendTo}
            onChange={this.onChange}
            fromCompany={this.state.fromCompany}
            fromName={this.state.fromName}
            data={this.props.data}
            sendInfoSearch={this.sendInfoSearch}
            subject={this.state.subject}
            body={this.state.body}
            onModelChange={this.onModelChange}
            mobileState={this.props.mobileState}
          />
          {this?.props?.subject ? (
            <div
              className="alert alert-warning"
              style={{
                backgroundColor: '#fcf8e3',
                borderColor: '#faebcc',
                padding: 5,
                marginBottom: 10,
                display: 'flex',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1
                }}
              >
                <Icon size="small" style={{ marginRight: 5 }}>
                  attach_file
                </Icon>
                <span>{`${this.props.subject} is attached`}</span>
              </div>
            </div>
          ) : null}
          <div className="send-document-attachments">
          <DDIExpansionPanel
            title={
              attachmentsTitle && typeof attachmentsTitle === 'string'
                ? attachmentsTitle.replace(
                    'Attachments',
                    'Additional Attachments'
                  )
                : attachmentsTitle
            }
            defaultExpanded={false}
            expansionPanelContentStyle={{
              padding: '10px 10px 35px 10px',
              width: '100%'
            }}
            expansionPanelDetailsStyle={{ padding: 0, width: '100%' }}
            changeCallback={this.onSendDocumentInterfaceExpanded}
          >
            <Attachments
              isSendDocumentEditor
              rowData={attachments}
              additionalAttachmentsData={{
                dataId: this.props.dataId,
                sendType: this.state.sendType,
                // parentId: '',
                // parentType: '',
                existingRowData: attachments,
                parentForm: this.props.form,
                handleAttachmentsCallback: this.handleAttachments
              }}
            />
          </DDIExpansionPanel>
          </div>
          <div ref={this.scrollAnchor} style={{ width: 1, height: 1 }} />
        </DialogContent>
        <Footer onSendClick={this.onSendClick} onClose={this.onClose} />
      </Dialog>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(SendDocument)
