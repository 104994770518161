import React from 'react'
import { Tab } from '@mui/material'

const generateTabs = (tabs = [], maxWidth = 264, maxCharLength = 10) =>
  tabs.map((item, index) => {
    if (item.length > maxCharLength) {
      return <Tab value={item} label={item} style={{ maxWidth }} />
    }
    return <Tab value={item} label={item} />
  })

export default generateTabs
