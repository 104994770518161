import React, { Component } from 'react'
import { Button } from '@mui/material'
import { confirm, modalAction } from 'modals/actions'
import { connect } from 'react-redux'
import { fromJS, getIn } from 'utils'
import screenMap from '../screenMap'

const rightMargin = { marginRight: 5 }

export const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const fields = getIn(formState, 'fields') || fromJS({})

  return { formFields: fields && fields?.toJS ? fields.toJS() : {} }
}

export class MailActions extends Component {
  constructor(...args) {
    super(...args)
    if (this.props.modalRef && this.props.modalRef[this.props.modal.id]) {
      this.container = this.props.modalRef[this.props.modal.id]
    }
    let reply = false
    let sendMail = false
    if (this.props.modal.options.data.newMail) {
      sendMail = true
    } else if (this.props.modal.options.data.openMail) {
      reply = true
    }
    this.state = {
      containerId:
        this.props.modalRef && this.props.modalRef[this.props.modal.id]
          ? this.props.modalRef[this.props.modal.id]
          : '',
      reply,
      sendMail,
      disabled: true
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.modalRef && nextProps.modalRef[nextProps.modal.id]) {
  //     this.container = nextProps.modalRef[nextProps.modal.id].wrappedInstance
  //   }
  //   let disabled = true
  //   if (
  //     nextProps.formFields &&
  //     nextProps.formFields.toUserIds &&
  //     nextProps.formFields.toUserIds.value
  //   ) {
  //     disabled = false
  //   }
  //   // this.setState({
  //   //   disabled
  //   // })
  //   if (this.state.disabled !== disabled) {
  //     this.setState({ disabled })
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    let disabled = true

    if (nextProps.modalRef && nextProps.modalRef[nextProps.modal.id]) {
      if (prevState.containerId !== nextProps.modalRef[nextProps.modal.id]) {
        return { containerId: nextProps.modalRef[nextProps.modal.id] }
      }
    }

    if (
      nextProps.formFields &&
      nextProps.formFields.toUserIds &&
      nextProps.formFields.toUserIds.value
    ) {
      disabled = false
    }

    if (prevState.disabled !== disabled) {
      return { disabled }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.containerId !== this.state.containerId &&
      (this.props.modalRef && this.props.modalRef[this.props.modal.id])
    ) {
      const r = this.props.modalRef[this.props.modal.id]
      // debugger
      this.container = r
    }
  }

  onDelete = () => {
    // const { props } = this.container
    if (!this.container) {
      this.container = this.props.modalRef[this.props.modal.id]
    }
    const { props } = this.container
    const {
      data: { dataId, mailNumber, description }
    } = props
    this.props.deleteMail({
      data: [
        {
          dataId,
          mailNumber,
          description
        }
      ],

      guId: this.props.guId,
      cb: () =>
        this.props.dispatch(confirm(this.props.form, this.props.modal.id))
    })
  }
  // onDelete = () => {
  //   const { props } = this.container
  //   const { data: { dataId, mailNumber, description } } = props
  //   this.props.deleteMail({
  //     dataId,
  //     mailNumber,
  //     description,
  //     guId: this.props.guId,
  //     cb: () =>
  //       this.props.dispatch(confirm(this.props.form, this.props.modal.id))
  //   })
  // }

  onSend = () => {
    this.props.createUserMail({
      guId: this.props.guId,
      modalId: this.props.modal.id
    })
  }

  onReply = async () => {
    // debugger
    if (!this.container) {
      this.container = this.props.modalRef[this.props.modal.id]
    }
    const title = this.props.modalRef[`${this.props.modal.id}-title`]

    const { dataId, guId, mailNumber } = this.props.modal.options.data
    await this.props.respondMail({
      dataId,
      guId,
      mailNumber
    })
    // console.log(payload)
    // debugger
    console.log(this, this.container)
    // await Promise.all([
    //   this.container.setState(
    //     { disabled: false },
    //     () =>
    //       this.container &&
    //       this.container.userDropDown &&
    //       this.container.userDropDown.ref &&
    //       this.container.userDropDown.ref.current &&
    //       this.container.userDropDown.ref.current.wrappedInstance.refs.renderedComponent.wrappedInstance.select()
    //   ),
    await Promise.all([
      this.container.setState(
        { disabled: false },
        () =>
          this.container &&
          this.container.userDropDown &&
          this.container.userDropDown.current &&
          this.container.userDropDown.current.select()
      ),
      title.setState({ title: 'New Mail' })
    ])

    this.setState({ reply: false, sendMail: true })
  }

  getButtons = () => {
    let buttons = []
    if (
      this.state.reply &&
      this.props.modal &&
      this.props.modal.options &&
      this.props.modal.options.data &&
      this.props.modal.options.data.referenceId &&
      this.props.modal.options.data.referenceType
    ) {
      buttons = buttons.concat(
        <Button
          variant="contained"
          color="primary"
          onClick={this.inquire}
          style={rightMargin}
        >
          Inquire
        </Button>
      )
    }

    if (this.state.reply) {
      buttons = buttons.concat([
        [
          <Button
            variant="contained"
            color="secondary"
            onClick={this.onDelete}
            style={rightMargin}
          >
            Delete
          </Button>,
          <Button
            variant="contained"
            color="primary"
            onClick={this.onReply}
            style={rightMargin}
          >
            Reply / Forward
          </Button>
        ]
      ])
    } else {
      // let disabled = true
      // if (
      //   this.props.fields &&
      //   this.props.fields.toUserIds &&
      //   this.props.fields.toUserIds.value
      // ) {
      //   disabled = false
      // }
      buttons = buttons.concat(
        <Button
          variant="contained"
          color="primary"
          onClick={this.onSend}
          style={rightMargin}
          disabled={this.state.disabled}
        >
          Send
        </Button>
      )
    }
    buttons = buttons.concat(
      <Button variant="contained" color="primary" onClick={this.closeMail}>
        Exit
      </Button>
    )
    return buttons
  }

  closeMail = () => {
    this.props.dispatch(
      modalAction(this.props.form, {
        action: this.props.actions.closeMail,
        args: { id: this.props.modal.id }
      })
    )
  }

  inquire = () => {
    const { referenceId, referenceType } = this.props.modal.options.data
    const link = screenMap[referenceType]
    this.props.linkToScreen(link, { referenceId })
  }

  render() {
    return <div>{this.getButtons()}</div>
  }
}
export default connect(mapStateToProps)(MailActions)
