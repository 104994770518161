import {
  take,
  select,
  call,
  put,
  putResolve,
  cancel,
  fork,
  actionChannel,
  delay
} from 'redux-saga/effects'
import { confirmationModal } from 'modals/sagas'
import { show } from 'snackbar/actions'
import { CANCELED, CONFIRMED, HIDE_MODAL } from 'modals/constants'
import { api } from 'services'
import { getFormSelector } from 'ddiForm/utils'
import { getIn } from 'utils'
import { leftAligncolumns } from '../utils'
import {
  EXACT_MATCH_SEARCH,
  GET_TOOLTIP,
  PARTIAL_MATCH_SEARCH,
  FIND_PREV,
  FIND_NEXT,
  GET_SEARCH_FILTERS,
  FOUND_INVALID_VALUES,
  GET_HIDDEN_VALUE
} from './constants'

const getSearchFilters = filtersGrid => {
  let filters = filtersGrid?.get('rowData')
  if (filters) {
    filters = filters.reduce((acc, next) => {
      const value = getIn(next, 'fileAssociationSearchType.value')
      // acc[getIn(next,
      if (value != null && value !== '') {
        // acc[getIn(next, 'fileAssociationSearchType.dataId')] = value
        acc = acc.concat({
          dataId: getIn(next, 'fileAssociationSearchType.dataId'),
          value
        })
      }
      return acc
    }, [])
  }
  return filters?.length ? filters : null
}

// let task
// console.log(api)
export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)
    // console.log(action)
    const {
      payload: {
        indexSearchType,
        keyword = '',
        propertyName,
        subProperty,
        moreInfo,
        includeParent,
        isRecordName,
        allowInvalidValues,
        ...rest
      },
      meta: { form, thunk }
    } = action
    // debugger
    if (task) yield cancel(task)
    task = yield call(exactMatchProcess, {
      form,
      propertyName,
      subProperty,
      indexSearchType,
      keyword,
      moreInfo,
      includeParent,
      isRecordName,
      allowInvalidValues,
      thunk,
      ...rest
    })
  }
}
export function* exactMatchProcess({
  form,
  propertyName,
  subProperty,
  indexSearchType,
  keyword,
  moreInfo,
  includeParent,
  isRecordName,
  allowInvalidValues,
  thunk,
  ...rest
}) {
  
  let filterData
  let filters = null
  if (form) {
    filterData = yield select(
      state =>
        getIn(state, `ddiForm.${form}.fields.${propertyName}.filtersGrid`) // .toJS()
    )
    filters = getSearchFilters(filterData)
  }

  const params = {
    indexSearchType,
    keyword,
    filters,
    moreInfo,
    includeParent,
    isRecordName,
    allowInvalidValues,
    ...rest
  }
  // debugger
  const { response, error } = yield call(api.exactMatchSearch, params)
  // on error?
  // debugger
  if (response) {
    yield put({
      meta: { form, thunk, reducer: 'IndexSearch' },
      payload: {
        ...response,
        propertyName,
        subProperty
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk, reducer: 'IndexSearch' },
      payload: {
        ...error,
        propertyName,
        subProperty
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })

    /* 
      if there is a permissions-related error, show the warning
      explaining why the entry was invalid. Ref VS ticket 9853 et al
      -- SVE 11/29/2021
    */
    if (error?.status && error?.status === 403 && error?.message) {
      yield put(
        show({
          message: {
            message: error.message,
            type: 'warning',
            persist: false
          }
        })
      )
    }
  }
}

export function* partialMatchProcess({
  indexSearchType,
  propertyName,
  form,
  pageNumber,
  keyword,
  thunk,
  parentId,
  searchAll,
  isFiltered,
  parentType,
  allowSpecialProducts,
  ...rest
}) {
  // if (form)
  let filterData
  let filters = null
  // debugger
  if (form) {
    filterData = yield select(state =>
      getIn(state, `ddiForm.${form}.fields.${propertyName}.filtersGrid`)
    )
    filters = getSearchFilters(filterData)
  }
  const params = {
    indexSearchType,
    keyword,
    pageNumber,
    parentId,
    parentType,
    filters,
    isFiltered,
    allowSpecialProducts,
    ...rest
  }
  // debugger
  const { response, error } = yield call(api.partialMatchSearch, params)
  /*
  const { response, error } = yield call(api.partialMatchSearch, {
    indexSearchType,
    keyword,
    pageNumber,
    token,
    isFiltered
  })
  */

  if (response) {
    const { partialMatchResults } = response
    const {
      columnHeaders,
      results: rowData,
      filtered,
      ...meta
    } = partialMatchResults
    const columnDefs = columnHeaders ? columnHeaders.map(leftAligncolumns) : []
    const grid = {
      columnDefs,
      meta,
      rowData: rowData || []
    }
    yield put({
      // meta: { form, propertyName },
      meta: { form, thunk, reducer: 'IndexSearch' },
      payload: {
        grid,
        propertyName,
        searchAll,
        filtered
      },
      type: PARTIAL_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk, reducer: 'IndexSearch' },
      payload: {
        ...error,
        propertyName,
        searchAll
      },
      type: PARTIAL_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchSearchListener() {
  const channel = yield actionChannel(PARTIAL_MATCH_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)
    // console.log(action)
    // debugger
    const {
      payload: {
        allowSpecialProducts,
        indexSearchType,
        parentType,
        propertyName,
        pageNumber,
        keyword = '',
        parentId,
        searchAll,
        isFiltered,
        ...rest
      },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield fork(partialMatchProcess, {
      form,
      indexSearchType,
      keyword,
      pageNumber,
      propertyName,
      thunk,
      parentId,
      searchAll,
      isFiltered,
      parentType,
      allowSpecialProducts,
      ...rest
    })
  }
}

export function* findPrevExactMatchListener() {
  let task
  while (true) {
    const action = yield take(FIND_PREV.REQUEST)
    // console.log(action)
    const {
      payload: { indexSearchType, propertyName, recordName },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield fork(findPrevExactMatchProcess, {
      form,
      indexSearchType,
      propertyName,
      recordName,
      thunk
    })
  }
}

export function* findPrevExactMatchProcess({
  form,
  indexSearchType,
  propertyName,
  recordName,
  thunk
}) {
  const { response, error } = yield call(api.getPrevExactMatch, {
    indexSearchType,
    recordName
  })
  if (response) {
    yield put({
      meta: { form, thunk, reducer: 'IndexSearch' },
      payload: { ...response, propertyName },
      type: FIND_PREV.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk, reducer: 'IndexSearch' },
      payload: { ...error, propertyName },
      error: true,
      type: FIND_PREV.FAILURE
    })
  }
}
export function* findNextExactMatchProcess({
  form,
  indexSearchType,
  propertyName,
  recordName,
  thunk
}) {
  const { response, error } = yield call(api.getNextExactMatch, {
    indexSearchType,
    recordName
  })
  if (response) {
    // debugger
    yield put({
      meta: { form, thunk, reducer: 'IndexSearch' },
      payload: { ...response, propertyName },
      type: FIND_NEXT.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk, reducer: 'IndexSearch' },
      payload: { ...error, propertyName },
      error: true,
      type: FIND_NEXT.FAILURE
    })
  }
}
export function* getTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_TOOLTIP.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getTooltipProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* getTooltipProcess({
  form,
  thunk,
  propertyName,
  apiName = 'getIndexSearchTooltip',
  ...rest
  // indexSearchType,
  // propertyName,
  // recordName,
}) {
  // debugger
  if (form && form.match(/salesOrder-/) && propertyName === 'shipToId') {
    /* 
      salesOrder shipToId has the tooltip info saved as part of the DTO,
      so we don't even need to make an API call for this input. This is
      mainly to accommodate VSO ticket #9356 -- SVE 5/11/2021
    */
    const formState = yield select(getFormSelector(form))
    let tooltip = getIn(formState, 'values.shipToSummary')
    tooltip = tooltip && tooltip?.toJS ? tooltip.toJS() : {}
    if (
      tooltip &&
      typeof tooltip === 'object' &&
      tooltip !== null &&
      tooltip?.title
    ) {
      yield put({
        meta: { form, thunk },
        payload: { summary: { ...tooltip }, propertyName },
        type: GET_TOOLTIP.SUCCESS
      })
    } else {
      yield put({
        meta: { form, thunk },
        payload: { propertyName },
        error: true,
        type: GET_TOOLTIP.FAILURE
      })
    }

    return
  }

  const { response, error } = yield call(api[apiName], {
    // indexSearchType,
    // propertyName,
    // recordName
    propertyName,
    ...rest
  })

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: { ...response, propertyName },
      type: GET_TOOLTIP.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: { ...error, propertyName },
      error: true,
      type: GET_TOOLTIP.FAILURE
    })
  }
}

// export function* getTooltipListener() {
//   while (true) {
//     const action = yield take(GET_TOOLTIP.REQUEST)
//     const {
//       payload: { indexSearchType, propertyName, recordName },
//       meta: { form, thunk }
//     } = action
//     if (task) yield cancel(task)
//     task = yield call(getTooltipProcess, {
//       form,
//       indexSearchType,
//       propertyName,
//       recordName,
//       thunk
//     })
//   }
// }
// export function* getTooltipProcess({
//   form,
//   indexSearchType,
//   propertyName,
//   recordName,
//   thunk
// }) {
//   const { response, error } = yield call(api.getIndexSearchTooltip, {
//     indexSearchType,
//     propertyName,
//     recordName
//   })

//   if (response) {
//     yield put({
//       meta: { form, thunk },
//       payload: { ...response, propertyName },
//       type: GET_TOOLTIP.SUCCESS
//     })
//   } else {
//     yield put({
//       meta: { form, thunk },
//       payload: { ...error, propertyName },
//       error: true,
//       type: GET_TOOLTIP.FAILURE
//     })
//   }
// }

export function* findNextExactMatchListener() {
  let task
  while (true) {
    const action = yield take(FIND_NEXT.REQUEST)
    // console.log(action)
    const {
      payload: { indexSearchType, propertyName, recordName },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(findNextExactMatchProcess, {
      form,
      indexSearchType,
      propertyName,
      recordName,
      thunk
    })
  }
}

export function* getSearchFiltersProcess({
  form,
  indexSearchType,
  propertyName
}) {
  const formState = yield select(getFormSelector(form))
  const prop = getIn(formState, `fields.${propertyName}`)
  if (prop.get('filtersGrid')) {
    yield put({
      meta: { form, reducer: 'IndexSearch' },
      payload: {
        propertyName,
        form
      },
      type: GET_SEARCH_FILTERS.SUCCESS
    })
    return
  }

  const { response, error } = yield call(api.getSearchFilters, {
    indexSearchType
  })
  if (response) {
    yield put({
      meta: { form, reducer: 'IndexSearch' },
      payload: {
        ...response,
        propertyName,
        form
      },
      type: GET_SEARCH_FILTERS.SUCCESS
    })
  } else {
    yield put({
      meta: { form, reducer: 'IndexSearch' },
      payload: { ...error },
      type: GET_SEARCH_FILTERS.FAILURE
    })
  }
}

export function* getSearchFiltersListener() {
  let task
  while (true) {
    const action = yield take(GET_SEARCH_FILTERS.REQUEST)
    const {
      payload: { indexSearchType, propertyName },
      meta: { form }
    } = action
    if (task) yield cancel(task)
    task = yield call(getSearchFiltersProcess, {
      form,
      indexSearchType,
      propertyName
    })
  }
}

export function* foundInvalidValuesListener() {
  while (true) {
    const action = yield take(FOUND_INVALID_VALUES)

    let {
      payload: {
        responses,
        value,
        propertyName,
        message,
        title,
        validationErrors
      }
    } = action
    const { meta } = action
    if (validationErrors && validationErrors.length) {
      message = validationErrors[0].message
      title = `${validationErrors[0].property} Not Found`
    }
    yield call(
      confirmationModal,
      message,
      title,
      {},
      { title: 'YES' },
      { title: 'NO' }
    )
    const confirmed = yield take([CONFIRMED, CANCELED])
    if (confirmed.type === CONFIRMED) {
      // alert('ok now we gotta set the thing...')
      yield put({
        type: `${FOUND_INVALID_VALUES}_SUCCESS`,
        payload: { value },
        meta
      })
    } else {
      // alert('reject it')
      // partial match
      yield put({
        type: `${FOUND_INVALID_VALUES}_REJECTED`,
        payload: { propertyName, value },
        meta,
        error: true
      })
    }
    // yield delay(5000)
    // yield put({
    //   type: `${FOUND_INVALID_VALUES}_REJECTED`,
    //   payload: {},
    //   meta
    //   // error: true
    // })
  }
}

export function* getHiddenValueProcess(meta, propertyName) {
  const { form } = meta
  if (!form) {
    return
  }
  const formState = yield select(getFormSelector(form))
  const value = getIn(formState, `fields.${propertyName}.value`)

  yield putResolve({
    type: `${GET_HIDDEN_VALUE}_SUCCESS`,
    payload: { propertyName, value },
    meta
  })
}

export function* getHiddenValueListener() {
  while (true) {
    const {
      meta,
      payload: { propertyName }
    } = yield take(GET_HIDDEN_VALUE)

    yield call(getHiddenValueProcess, meta, propertyName)
  }
}

export default function* indexSearchSagas() {
  yield fork(partialMatchSearchListener)
  yield fork(exactMatchSearchListener)
  yield fork(findNextExactMatchListener)
  yield fork(findPrevExactMatchListener)
  yield fork(getSearchFiltersListener)
  yield fork(getTooltipListener)
  yield fork(foundInvalidValuesListener)
  yield fork(getHiddenValueListener)
}
