import {
  all,
  select,
  call,
  fork,
  take,
  put,
  putResolve,
  cancel,
  delay,
} from 'redux-saga/effects'
import { show } from 'snackbar/actions'
import { push } from 'connected-react-router'
import { Schema, arrayOf, normalize } from 'normalizr'
import { api, local } from 'services'
import { addDashboardProcess } from 'pages/Dashboard/sagas'
import { formMapToControllerName } from 'ddiForm/sagas'
import { isMobileSelector } from 'mobile/selectors'
import {
  AUTHENTICATION_ERROR,
  CHECK_TOKEN_EXPIRATION,
  CREATE_SYSTEM_USER,
  HEARTBEAT,
  LOGIN_REQUEST,
  LOGOUT_USER,
  SUBMIT_LOGOUT_USER,
  SUBMIT_SELECTED_REGISTER,
  SUBMIT_SELECTED_BRANCH,
  SUBMIT_SELECTED_CARD_TERMINAL,
  CANCEL_BRANCH_SELECTION,
  FORGOT_PASSWORD,
  SHOW_CHANGE_PASSWORD_MODAL,
  SHOW_CHANGE_EMAIL_MODAL,
  FORGOT_USER_NAME,
  LOGIN_OTP
} from 'auth/constants'
import { getCalendarMetaProcess } from 'mobile/pages/Home/sagas'
import { SHOW_BRANCH_MODAL, SHOW_REGISTER_MODAL } from 'pages/Main/constants'
import { initDashboard } from 'pages/Dashboard/actions'
import { CANCELED, CONFIRMED } from 'modals/constants'
import {
  confirmationModal,
  timerWarningModal
} from 'modals/sagas'
import {
  createApiListener,
  getIn,
  destroyModal,
} from 'utils'
import ChangePassword from 'modals/ChangePasswordModal'
import ChangeEmail from 'modals/ChangeEmailModal'
import OneTimePasswordModal from 'auth/modals/OneTimePassword'
import { addModal } from 'modals/actions'
import {
  branchSelector,
  selectedBranchIdSelector,
  posRegisterSelector,
  cardTerminalSelector,
  accessPathListSelector,
  isAuthenticatedSelector,
  authSelector,
  refreshTokenSelector,
  tokenSelector,
  userNameSelector,
  mobileTimeoutSelector
} from './selectors'
import * as actions from './actions'
import BranchListContainer from './modals/BranchList'
import BranchListContainerMobile from './modals/BranchList/BranchListContainerMobile'
import POSRegister from './modals/POSRegister'
import CardTerminal from './modals/CardTerminal'

export const submitSelectedBranchApiListener = createApiListener.bind(
  null,
  actions.submitSelectedBranch,
  api.putSelectedBranch,
  { selectors: { branchId: selectedBranchIdSelector } }
)
export const submitLogoutApiListener = createApiListener.bind(
  null,
  actions.submitLogoutUser,
  api.getLogoutUser
)
let timer = 0
let routine
let listeners

/**
 * kicks off a routine when the user selects or
 * changes the POS register number
 * @listens {SUBMIT_SELECTED_REGISTER.REQUEST} - for the selected register request
 * @listens {selectedBranchIdSelector} - gets the selected branch ID
 * @listens {pOSRegister} - if the noRegister flag not set in the payload, gets the POS register
 * @listens {token} - retrieves the token
 * @emits {api.putSelectedRegister} - calls the putSelectedRegister API
 * @returns {Object} calls submitSelectedRegister.success or failure
 */
export function* submitSelectedRegisterApiListener() {
  while (true) {
    const action = yield take(SUBMIT_SELECTED_REGISTER.REQUEST)
    let pOSRegister = yield select(posRegisterSelector)
    if (action.payload.noRegister || pOSRegister===-1) {
      pOSRegister = null
    }
      const { response, error } = yield call(api.putSelectedRegister, {
        pOSRegister
    })
      if (response != null) {  
        yield put(actions.submitSelectedRegister.success(response))
      } else {
        yield put(actions.submitSelectedRegister.failure(error))
      }
  }
}

export function* submitSelectedCardTerminalApiListener() {
  while (true) {
    const action = yield take(SUBMIT_SELECTED_CARD_TERMINAL.REQUEST)

    let dataId = yield select(cardTerminalSelector)

    if (action.payload.noRegister) {
      dataId = ''
      yield put(actions.selectCardTerminal(dataId))
    }

    const { response, error } = yield call(api.postSelectedCardTerminal, {
      dataId
    })

    if (response) {
      const { userName, selectedBranchId } = yield call(getUserBranchInfo)

      yield put(actions.submitSelectedCardTerminal.success(response))
      // store card terminal to localStorage
      yield call(
        local.set,
        `${userName}-${selectedBranchId}-selectedTerminal`,
        response.statusBar?.selectedTerminal
      )
    } else {
      yield put(actions.submitSelectedCardTerminal.failure(error))
    }
  }
}

/**
 * kicks off a routine when the user logs out of the system
 * @listens {HEARTBEAT.FAILURE} - listens for the HEARTBEAT.FAILURE request
 * @listens {CREATE_SYSTEM_USER.FAILURE} - listens for the CREATE_SYSTEM_USER.FAILURE request
 * @emits {actions.submitLogoutUser.request} - calls the actions.submitLogoutUser.request Acvtion
 * @returns {Object} - calls the local clear method, which empties out localStorage data
 */
export function* systemLogoutListener() {
  while (true) {
    yield take([HEARTBEAT.FAILURE, CREATE_SYSTEM_USER.FAILURE])
    yield put(actions.submitLogoutUser.request())
  }
}

/**
 * logs out the user
 * @listens {LOGOUT_USER} - listens for the LOGOUT_USER action
 * @emits {confirmationModal} - calls the confirmationModal
 * @listens {CONFIRMED|CANCELED} - listens for CONFIRMED or CANCELED modal
 * @emits {actions.submitLogoutUser} - (if the logout confirmation modal is CONFIRMED
 */
export function* userLogoutListener() {
  while (true) {
    yield take(LOGOUT_USER)
    const isMobile = yield select(isMobileSelector)
    yield call(
      confirmationModal,
      `${
        !isMobile
          ? 'Any open tabs will be closed. '
          : 'Any unsaved data will be lost. '
      }Would you like to continue?`,
      'Change User'
    )
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CONFIRMED) {
      yield call(removeSavedRegisterAndTerminal)
      yield put(actions.submitLogoutUser.request())
    }
  }
}

/**
 * kicks off a routine when the user logs out of the network
 * @listens {SUBMIT_LOGOUT_USER.SUCCESS} - listens for the SUBMIT_LOGOUT_USER.SUCCESS request
 * @listens {SUBMIT_LOGOUT_USER.FAILURE} - listens for the SUBMIT_LOGOUT_USER.FAILURE resolution
 * @listens {AUTHENTICATION_ERROR} - listens for an AUTHENTICATION_ERROR
 * @emits {local.clear} - calls the local clear method, which empties out localStorage data
 */
export function* networkLogoutListener() {
  while (true) {
    yield take([
      SUBMIT_LOGOUT_USER.SUCCESS,
      SUBMIT_LOGOUT_USER.FAILURE,
      AUTHENTICATION_ERROR
    ])
    if (routine) yield cancel(routine)
  }
}

/**
 * kicks off the timeoutStartupProcess
 * @listens {CREATE_SYSTEM_USER.SUCCESS} - listens for the CREATE_SYSTEM_USER.SUCCESS request
 * @listens {any} - cancels any existing listeners
 * @emits {actionListener} - forks the acionListener
 * @emits {heartBeatListener} - forks the heartBeatListener
 * @emits {systemLogoutListener} - forks the systemLogoutListener
 * @emits {userLogoutListener} - forks the userLogoutListener
 * @emits {actions.submitLogoutUser.request} - calls the actions.submitLogoutUser.request Acvtion
 * @emits {timeoutProcess} - calls timeoutProcess saga
 */
export function* timeoutStartupProcess() {
  while (true) {
    yield take(CREATE_SYSTEM_USER.SUCCESS)
    if (listeners) {
      for (let i = 0, len = listeners.length; i < len; i++) {
        yield cancel(listeners[i])
      }
    }
    const listenTo = [
      fork(actionListener),
      fork(heartBeatListener),
      fork(systemLogoutListener),
      fork(userLogoutListener)
    ]
    const isMobile = yield select(isMobileSelector)

    if (isMobile) {
      listenTo.push(fork(mobileHeartBeatProcess))
    }
    listeners = yield all(listenTo)
    yield call(timeoutProcess)
  }
}

export function* timeoutProcess() {
  if (routine) yield cancel(routine)
  routine = yield fork(timeoutRoutine)
}

export function* timeoutRoutine() {
  const isMobile = yield select(isMobileSelector)
  let mobileTimeout = yield select(mobileTimeoutSelector)
  mobileTimeout = mobileTimeout || 15
  let first = isMobile ? mobileTimeout * 60 : 10 * 60
  if (process.env.NODE_ENV !== 'production') {
    first = 20 * 60
  }
  while (timer < first) {
    yield delay(1000)
    timer += 1
  }
  timer = 0
  yield call(timeoutModalProcess)
}

export function* actionListener() {
  while (
    yield take(action => {
      if (
        action.type.startsWith('@@USER_MAIL') ||
        action.type.includes('NEWS_FEED') ||
        action.type.includes('HEARTBEAT')
      ) {
        return false
      }
      return true
    })
  ) {
    timer = 0
  }
}

/**
 * throws a modal alert letting the user know
 * they'll be logged out due to a timeout
 * @emits {warningModal} - calls the warning modal, giving the option to extend the session
 * @emits {destroyModal} - destroys the modal after a timeout
 * @emits {actions.submitLogoutUser} - then logs out the user on timeout
 * @emits {actions.submitLogoutUser.request} - calls the actions.submitLogoutUser.request Acvtion
 */
export function* timeoutModalProcess() {
  const second = 2 * 60
  yield fork(
    timerWarningModal,
    'You will be logged out in',
    'Inactivity Notice',
    [
      {
        clickEvent(_, __, c) {
          timer = 0
          c()
          // throw new Error()
        },
        title: 'OK'
      }
    ],
    {
      timer: second
    }
  )
}

/**
 * listens and acts on auth heartbeat requests
 * @listens {HEARTBEAT.REQUEST} - for a heartbeatRequest
 * @emits {timeoutProcess} - forks the timeoutProcess
 * @listens {token} - selects the token
 * @emits {api.heartBeat} - calls the heartBeat API
 * @returns {Object} - returns the result of actions.heartBeat
 */
export function* heartBeatListener() {
  let refreshRequired = false
  while (true) {
    yield take(HEARTBEAT.REQUEST)
    yield fork(timeoutProcess)
    const { response, error } = yield call(api.heartBeat)
    if (response) {
      if (response.refreshRequired && !refreshRequired) {
        refreshRequired = true
        yield put(
          show({
            message: {
              message: 'Company Settings have changed. Please log out now.',
              type: 'warning',
              persist: true
            }
          })
        )
      } else {
        yield put(actions.heartBeat.success(response))
      }
    } else {
      yield put(actions.heartBeat.failure(error))
    }
  }
}

/**
 * handles the branch selection modal
 * @listens {SHOW_BRANCH_MODAL} listens for the showBranchModal action
 * @emits {confirmationModal} - calls the confirmationModal
 * @listens {CONFIRMED|CANCELED} - listens for confirm or cancel modal
 * @emits {actions.clearSelectedBranch} - (if confirmed)
 * @emits {selectBranchAndRegisterProcess} - calls the selectBranchAndRegisterProcess routine
 */
export function* showBranchModalListener() {
  while (true) {
    yield take(SHOW_BRANCH_MODAL)

    const isMobile = yield select(isMobileSelector)

    yield call(
      confirmationModal,
      `${
        !isMobile
          ? 'Any open tabs will be closed. '
          : 'Any unsaved data will be lost. '
      }Would you like to continue?`,
      'Change Branch'
    )
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CONFIRMED) {
      yield delay(500)
      yield fork(selectBranchAndRegisterProcess, null, true, true)
    }
  }
}

/**
 * handles the register selection modal
 * @listens {SHOW_REGISTER_MODAL} - listens for the showRegisterModal action
 * @listens {branch} - grabs the branch via the branchSelector
 * @listens {numberOfPOSRegisters} - grabs the numberOfPOSRegisters
 * @emits {showRegisterModal} - calls the showShowRegisterModal routine with the numberOfPOSRegisters arg
 */
export function* showRegisterListener() {
  while (true) {
    yield take(SHOW_REGISTER_MODAL);
    const branch = yield select(branchSelector);
    const numberOfPOSRegisters = branch.get('numberOfPOSRegisters');
    yield call(showRegisterModal, numberOfPOSRegisters);
  }
}

export function* initDashboardProcess() {
  const userName = yield select(userNameSelector)
  let layouts = yield call(local.get, `layouts-dashboard-${userName}`)
  const isMobile = yield select(isMobileSelector);
  let hasBrokenLayout = false
  if (layouts) {
    for (const [_, value] of Reflect.ownKeys(layouts).entries()) {
      if (layouts[value].some(x => x.w === 1 && x.h === 1)) {
        hasBrokenLayout = true
        break
      }
    }
  }

  const { response } = yield call(api.getMeta, {
    screen: 'dashboard'
  })
  if (response) {
    if (hasBrokenLayout) {
      yield call(local.set, `layouts-dashboard-${userName}`, null)
      layouts = null
    }
    yield put(initDashboard(response.dashboards, layouts));
    if (isMobile) {
    yield fork(getCalendarMetaProcess, 'calendar', response.dashboards.calendar)
    }
  } else {
    console.log('dashboard failure')
  }
}

/**
 * handles the redirect after user login
 * @param {string} redirectRoute - route param
 * @listens {SHOW_REGISTER_MODAL} - listens for the showRegisterModal action
 * @listens {branch} - grabs the branch via the branchSelector
 * @listens {numberOfPOSRegisters} - grabs the numberOfPOSRegisters
 * @emits {showRegisterModal} - calls the showShowRegisterModal routine
 */
export function* redirectAfterLoginProcess(redirectRoute = '/app') {
  const isMobile = yield select(isMobileSelector)
  yield fork(initDashboardProcess)
  if (!isMobile){
   yield put({ type: 'INITIATE_REFRESH_MAIL', payload: {} })
  }
  yield put(actions.loginComplete())
  yield put(push('/app'))
}

/**
 * handles selection of P.O.S. Registers
 * @param {number} numberOfPOSRegisters - number of POS Registers
 * @param {string} redirectRoute - route param
 * @emits {showRegisterModal} - calls the register modal with the number of POS Registers
 * @listens {HIDE_MODAL} - listens for the HIDE_MODAL action
 */
export function* posRegisterProcess(numberOfPOSRegisters, redirectRoute) {
  const { posRegister } = yield call(getUserBranchInfo)


  if (numberOfPOSRegisters) {
    const id = yield call(showRegisterModal, numberOfPOSRegisters)
    yield take(SUBMIT_SELECTED_REGISTER.SUCCESS)
    yield call(destroyModal, id)
  } else {
    const { response, error } = yield call(api.putSelectedRegister, {
      pOSRegister: posRegister
  });

    if (response !== null) {
      yield put(actions.submitSelectedRegister.success(response))
    } else {
      yield put(actions.submitSelectedRegister.failure(error))
    }
  }

  yield fork(cardTerminalProcess, redirectRoute)
}

export function* cardTerminalProcess(redirectRoute) {
  let state = yield select()
  state = state.toJS()
  const { cardTerminals } = state.auth
  if (cardTerminals) {
    const id = yield call(showCardTerminalModal)
      yield take(SUBMIT_SELECTED_CARD_TERMINAL.SUCCESS)
      yield call(destroyModal, id)
  } else {
    const { response, error } = yield call(api.postSelectedCardTerminal, {
      dataId: ''
    });
    if (response) {
      yield put(actions.submitSelectedCardTerminal.success(response))
    } else {
      yield put(actions.submitSelectedCardTerminal.failure(error))
    }
  }

  yield fork(redirectAfterLoginProcess, redirectRoute)
}

/**
 * handles the selection of the branch and register process
 * @param {string} redirectRoute - route param
 * @listens {state} - grabs the current state
 * @emits {showBranchModal} - calls the showBranchModal routine if there is no branch info
 * @listens {SUBMIT_SELECTED_BRANCH.SUCCESS} - listens for completion of branch selection
 * @listens {state} - gets the updated state
 * @emits {posRegisterProcess} calls the posRegisterProcess with the number of P.O.S. registers and the redirect route
 */
export function* selectBranchAndRegisterProcess(
  redirectRoute,
  refresh,
  noClear
) {
  let auth = yield select(authSelector)
  auth = auth.toJS()
  let {  defaultBranchId, branches } = auth;
  let action;
  branches = Reflect.ownKeys(branches).reduce((acc, next) => {
    acc = acc.concat(branches[next])
    return acc;
  }, [])
  if (!defaultBranchId || refresh) {
    if (branches?.length > 1) {
      const id = yield call(showBranchModal)
      action = yield take([
        SUBMIT_SELECTED_BRANCH.REQUEST,
        CANCEL_BRANCH_SELECTION
      ])
      if (action.type === SUBMIT_SELECTED_BRANCH.REQUEST && noClear) {
        yield put(actions.clearSelectedBranch())
      }
      yield call(destroyModal, id)

      if (action.type === CANCEL_BRANCH_SELECTION && !noClear) {
        yield put(
          actions.submitLogoutUser.request({
            status: 'Error',
            statusText: 'Please select a branch.'
          })
        )
      }
    } else {
      // select branch
      yield put(actions.selectBranch(branches[0].dataId))
      yield put(
        actions.submitSelectedBranch.request({ branchId: branches[0].dataId })
      )
    }
    if (refresh) {
      const isMobile = yield select(isMobileSelector)
      if (isMobile) {
        yield fork(getCalendarMetaProcess, 'calendar', action.payload.calendar || {})
      }
    }
  }
  if (!defaultBranchId && action?.type === CANCELED) return
  if (!defaultBranchId || noClear) {
    yield take(SUBMIT_SELECTED_BRANCH.SUCCESS)
  }
  auth = yield select(authSelector)
  auth = auth.toJS()

  const { numberOfPOSRegisters } = auth.branch
  yield fork(posRegisterProcess, numberOfPOSRegisters, redirectRoute)
}

// export function* selectBranchAndRegisterProcess(
//   redirectRoute,
//   refresh,
//   noClear
// ) {
//   let auth = yield select(authSelector)
//   auth = auth.toJS()
//   let { branch } = auth
//   let action
//   if (!branch) {
//     const id = yield call(showBranchModal)
//     action = yield take([SUBMIT_SELECTED_BRANCH.SUCCESS, CANCELED])

//     yield call(destroyModal, id)
//     auth = yield select(authSelector)
//     auth = auth.toJS()
//     branch = auth.branch
//     if (refresh && action.payload.calendar) {
//       const isMobile = yield select(isMobileSelector)
//       if (isMobile) {
//         yield fork(getCalendarMetaProcess, 'calendar', action.payload.calendar)
//       }
//     }
//   }
//   if (action && action.type === CANCELED) return
//   const { numberOfPOSRegisters } = auth.branch
//   yield fork(posRegisterProcess, numberOfPOSRegisters, redirectRoute)
// }
/**
 * handles system user success
 * @param {object} systemUser - system user object from the API
 * @param {string} redirectRoute - route param
 * @param {string} injectReducer - dynamic reducer
 * @emits {actions.submitLogoutUser.request} - if no branches selected, fires the actions.submitLogoutUser request
 * @listens {branches} - otherwise, get the branches via normalizr utility
 * @emits {actions.systemUser.success} - and then runs actions.systemUser.success
 * @emits {selectBranchAndRegisterProcess} Finally, calls the selectBranchAndRegisterProcess routine with the redirect route
 */
export function* handleSystemUserSuccessProcess(
  systemUser,
  redirectRoute,
  injectReducer
) {
  yield putResolve({ type: 'INITIATE_AUTHENTICATED_LISTENERS' })
  const branchSchema = new Schema('branches', { idAttribute: 'dataId' })
  const temp = {}
  temp.branches = systemUser.branchOptionsWithAddress.map((x, i) => ({
    ...x,
    sortIndex: i
  }))
  if (temp.branches.length === 0) {
    // logout
    yield put(
      actions.submitLogoutUser.request({
        status: 'Login Failed',
        statusText: 'Branch is missing from setup.'
      })
    )
  } else {
    const branches = yield call(normalize, temp, {
      branches: arrayOf(branchSchema)
    })
    yield put(
      actions.systemUser.success({
        entities: branches.entities,
        systemUser
      })
    )
    yield fork(selectBranchAndRegisterProcess, redirectRoute)
  }
}

/**
 * handles the creation of a system user
 * @param {function} injectReducer - dynamic reducer function
 * @param {strng} redirectRoute - route param
 * @listens {token} - gets the user's token
 * @emits {actions.systemUser.request} runs the systemUser request
 * @listens {api.getSystemUser} - listens for a response from the getSystemUser API
 * @emits {handleSystemUserSuccessProcess} if response. call handleSystemUserSuccessProcess with redirect route and injectReducer
 */
export function* createSystemUserProcess(injectReducer, redirectRoute) {
  yield put(actions.systemUser.request())
  const refreshToken = yield select(refreshTokenSelector)
  const { response, error } = yield call(api.getSystemUser, {
    refreshToken,
    signatureCapture: true
  })


  // DEAL WITH SPECIAL RESPONSE IF THERE PW IS DEAD
  if (response) {
    if (response.otp) {
      // show OTP modal..
      yield fork(showOneTimePasswordModal)
    } else {
      yield fork(
        handleSystemUserSuccessProcess,
        { ...response },

        redirectRoute,
        injectReducer
      )
    }
    // yield call(api.showTips, { token })
  } else if (error?.message === 'expired password') {
    yield putResolve({ type: 'REQUEST_CANCELED', meta: {}, payload: {} })
    // yield put(push('/resetpassword'))
    yield fork(showChangePasswordModal)
  } else {
    yield put(actions.systemUser.failure(error))
  }
}

/**
 * checks token on startup
 * @param {function} injectReducer - dynamic reducer function
 * @listens {token} - gets the user's token
 * @emits {isTokenExpired} if token, call isTokenExpired with the token
 * @emits {actions.removeToken} if expired, call actions.removeToken and clear local storage
 */
export function* checkTokenOnStartupProcess(injectReducer) {
  yield take(CHECK_TOKEN_EXPIRATION)

  yield put(actions.noToken()) // this has to be changed to deal with login
}

// /**
//  * checks token on startup
//  * @param {function} injectReducer - dynamic reducer function
//  * @listens {token} - gets the user's token
//  * @emits {isTokenExpired} if token, call isTokenExpired with the token
//  * @emits {actions.removeToken} if expired, call actions.removeToken and clear local storage
//  */
// export function* checkTokenOnStartupProcess(injectReducer) {
//   yield take(CHECK_TOKEN_EXPIRATION)
//   const token = yield call(local.get, 'token')
//   let status
//   if (window && window.location) {
//     if (window.location.search) {
//       const params = new URLSearchParams(window.location.search)
//       status = params.get('Status')
//     }
//   }
//   if (!status) {
//     if (token) {
//       const expired = yield call(isTokenExpired, token)
//       if (expired) {
//         yield put(actions.removeToken()) // debugging
//         yield call(local.remove, 'token')
//       } else {
//         // yield put({ type: 'TOKEN_IS_VALID' }) // debugging
//         yield put(actions.token.success(token))
//         yield call(createSystemUserProcess, injectReducer)
//       }
//     } else {
//       yield put(actions.noToken()) // this has to be changed to deal with login
//     }
//   }
// }

/**
 * handles the authentication process
 * @param {string} username - username
 * @param {string} password - password
 * @param {string} redirectRoute - route param
 * @param {function} injectReducer - dynamic reducer function
 * @emits {actions.token.request} - makes the token request
 * @listens {api.getToken} - listens for the results of the token request
 * @emits {actions.token.success} - fires the token success action
 * @emits {local.set} - saves the token to localStorage
 * @emits {createSystemUserProcess} - kicks off the createSystemUserProcess with injectReducer and redirectRoute params
 */
let otpTask
export function* authenticateProcess(
  username,
  password,
  redirectRoute,
  injectReducer
) {
  const isMobile = yield select(isMobileSelector)
  const hasToken = yield select(tokenSelector)
  if (!hasToken) {
    yield put(actions.token.request({ password, username }))
    const { response, error } = yield call(api.getToken, {
      password,
      username,
      isMobile
    })

    if (error) {
      if (error.error === 'otp') {

        if (otpTask) {
          yield cancel(otpTask)
        }
        otpTask = yield fork(
          otpListener,
          username,
          injectReducer,
          redirectRoute
        )
        yield fork(showOneTimePasswordModal)
      } else if (!error.message) {
        const params = new URLSearchParams(error.error)
        const code = params.get('code')
        const qr = params.get('qr')
        const confirmed = params.get('confirmed') || false
        if (otpTask) {
          yield cancel(otpTask)
        }
        otpTask = yield fork(
          otpListener,
          username,
          injectReducer,
          redirectRoute
        )
        yield fork(showOneTimePasswordModal, code, qr, confirmed)
      } else {
        yield put(actions.token.failure(error))
      }
    } else {
      yield put(actions.token.success(response))

      // yield put({ type: 'SET_TOKEN_IN_STORAGE' }) // debugging

      yield fork(createSystemUserProcess, injectReducer, redirectRoute)
    }
  } else {
    yield fork(createSystemUserProcess, injectReducer, redirectRoute)
  }
}

/**
 * listen for the login process, kick off authentication
 * @param {function} injectReducer - dynamic reducer function
 * @listens {LOGIN_REQUEST} - listens for the login request
 * @emits {authenticateProcess} - forks the authenticateProcess with params from the login request
 */
export function* loginListener(injectReducer) {
  while (true) {
    const action = yield take(LOGIN_REQUEST)
    const {
      payload: { username, password, redirectRoute }
    } = action
    yield fork(
      authenticateProcess,
      username,
      password,
      redirectRoute,
      injectReducer
    )
  }
}

/**
 * checks if a screen/tile requires access path check
 * @param {string} name - screen/tile name
 * @listens {accessPaths} - gets the accessPaths object
 * @returns {Boolean} - checks if the access path exists
 */
export function* checkHasAccessProcess(name) {
  const accessPaths = yield select(accessPathListSelector)
  return !accessPaths.get(name)
}


export function* onDelete(form) {
  yield console.log(form)
}
const accessMapping = {
  Add: addDashboardProcess,
  open: accessScreenProcess
}

/**
 * kick off the request access process
 * @param {Object} action - action object
 * @listens {accessPathList} - get the access path list
 * @emits {hasMap} - if there is an access path map, fork the hasMap saga
 */
// this needs to go...
export function* tryRequestAccessProcess(action) {
  const accessPathList = yield select(accessPathListSelector)
  const type = getIn(accessPathList, `${action.payload.name}.type`)

  const hasMap = accessMapping[type]
  if (hasMap) {
    yield fork(hasMap, action.payload)
  }
}

/**
 * handles the access screen process
 * @param {string} accessPath - access path name
 * @param {string} pin - PIN credentials
 * @param {string} password - string password
 * @param {string} name - screen ID name
 * @param {string} username - string username
 * @emits {api.openScreen} - calls the open screen API with params
 * @emits {actions.requestAccess} - dispatches success|failure method based on api.openScreen
 */
export function* accessScreenProcess({
  accessPath,
  pin,
  password,
  name,
  username
}) {
  name = formMapToControllerName[name] ? formMapToControllerName[name] : name
  const { response, error } = yield call(api.openScreen, {
    accessPath,
    name,
    password,
    pin,
    username
  })
  if (response) {
    yield put(actions.requestAccess.success({ name, response }))
  } else {
    yield put(actions.requestAccess.failure({ name }, error))
  }
}

/**
 * shows the "Number of Registers" modal
 * @param {number} numOfRegisters - number of registers
 * @returns {addModal} - shows the registerModal with options
 */
export function* showRegisterModal(numOfRegisters) {
  const options = {
    component: POSRegister,
    options: {
      data: {
        actions: [
          {
            clickEvent: { action: actions.submitSelectedRegister.request },
            keyboardfocused: true,
            primary: true,
            title: 'OK'
          },
          {
            clickEvent: {
              action: actions.submitSelectedRegister.request,
              args: { noRegister: true }
            },
            title: 'No Register'
          }
        ],
        numOfRegisters
      },
      title: `Enter P.O.S. Register # (1-${numOfRegisters})`,
      width: 400,
    }
  }
  const modal = yield call(addModal, options)
  yield put(modal)
  return modal.payload.id
}

export function* showChangePasswordModal(flag, reset, id) {
  const options = {
    component: ChangePassword,
    props: {
      title: reset ? 'Reset Password' : 'Change Password',
      width: 400,
      flag,
      reset,
      forgotPasswordId: id
    }
  }
  const modal = yield call(addModal, options)
  yield put(modal)
  return modal.payload.id
}

export function* showChangeEmailModal() {
  const options = {
    component: ChangeEmail,
    props: {
      title: 'Change Password',
      width: 400
    }
  }
  const modal = yield call(addModal, options)
  yield put(modal)
  return modal.payload.id
}
export function* showOneTimePasswordModal(code, qr, confirmed) {
  const isMobile = yield select(isMobileSelector)
  const options = {
    component: OneTimePasswordModal,
    props: {
      title: 'One Time Password',
      width: isMobile ? '95%' : 400
    }
  }
  if ((code && qr) || confirmed) {
    options.props.title = 'Multifactor Authentication'
    options.props.confirmed = confirmed
    options.props.code = code
    if (!isMobile) {
      options.props.qr = qr
    }
  }
  const modal = yield call(addModal, options)
  yield put(modal)
  return modal.payload.id
}


/**
 * Branch Selection modal
 * @returns {addModal} - shows the branch modal with options
 */
export function* showBranchModal() {
  const isMobile = yield select(isMobileSelector)

  const options = {
    component: isMobile ? BranchListContainerMobile : BranchListContainer,
    options: {
      data: {
        actions: [
          {
            clickEvent: {
              // action: actions.submitLogoutUser.request,
              action: actions.cancelBranchSelection,
              args: { status: 'Error', statusText: 'Please select a branch.' }
            },
            secondary: true,
            title: 'Cancel'
          },
          {
            clickEvent: {
              action: actions.submitSelectedBranch.request,
              args: state => state.systemUser.selectedBranchId
            },
            keyboardfocused: true,
            primary: true,
            title: 'Submit'
          }
        ]
      },
      maxHeight: isMobile ? '100%' : 800,
      marginTop: isMobile ? 25 : null,
      title: 'Select a Branch',
      width: 900
    }
  }

  const modal = yield call(addModal, options)
  yield put(modal)
  return modal.payload.id
}

export function* showCardTerminalModal(onChange = false) {
  const options = {
    component: CardTerminal,
    options: {
      data: {
        actions: [
          {
            clickEvent: { action: actions.submitSelectedCardTerminal.request },
            keyboardfocused: true,
            primary: true,
            title: 'Select'
          },
          {
            clickEvent: {
              action: actions.submitSelectedCardTerminal.request,
              args: { noRegister: true }
            },
            title: 'No Terminal'
          }
        ],
        onChange
      },
      maxHeight: 800,
      title: 'Select Card Terminal',
      width: 450
    }
  }

  const modal = yield call(addModal, options)
  yield put(modal)
  return modal.payload.id
}
export function* routeChangeProcess(action) {
  const auth = yield select(isAuthenticatedSelector)
  if (
    (action.payload.location.pathname === '/app' ||
      action.payload.location.pathname === '/') &&
    !auth
  ) {
    yield put(push('/login'))
  } else if (action.payload.location.pathname === '/signup' && auth) {
    yield put(push('/app'))
  } else if (action.payload.location.pathname === '/login') {
    const args = new URLSearchParams(action.payload.location.search)
    const forgotPassword = args.get('forgotpassword')
    if (forgotPassword) {
      yield fork(showChangePasswordModal, null, true, forgotPassword)
    }
  }
}

export function* routeChangeListener() {
  let action
  /* eslint-disable */
  while ((action = yield take('@@router/LOCATION_CHANGE'))) {
    yield fork(routeChangeProcess, action)
  }
}

// mobile

export function* handleSystemUserSuccessProcessMobile(
  systemUser,
  redirectRoute,
  injectReducer
) {
  yield putResolve({ type: 'INITIATE_AUTHENTICATED_LISTENERS' })
  const branchSchema = new Schema('branches', { idAttribute: 'dataId' })
  const temp = {}

  yield put(
    actions.systemUser.success({
      entities: temp,
      systemUser
    })
  )

  yield fork(selectBranchAndRegisterProcess, redirectRoute)
}

export function* loggedOutListener() {
  while (true) {
    yield take([SUBMIT_LOGOUT_USER.SUCCESS, SUBMIT_LOGOUT_USER.FAILURE])
    yield put(push('/login'))
  }
}

export function* sendForgotPasswordProcess(action) {
  const { email } = action.payload
  // validate first?
  const { response, error } = yield call(api.forgotPassword, { email })

  if (response) {
    yield put(actions.forgotPassword.success(response, action.meta))
  } else {
    yield put(actions.forgotPassword.failure(error, action.meta.thunk))
  }
}

export function* sendForgotPasswordListener() {
  while (true) {
    const action = yield take(FORGOT_PASSWORD.REQUEST)
    yield fork(sendForgotPasswordProcess, action)
  }
}

export function* sendForgotUserNameProcess(action) {
  const { email } = action.payload
  // validate first?
  const { response, error } = yield call(api.forgotUserName, { email })

  if (response) {
    yield put(actions.forgotUserName.success(response, action.meta))
  } else {
    yield put(actions.forgotUserName.failure(error, action.meta.thunk))
  }
}

export function* sendForgotUserNameListener() {
  while (true) {
    const action = yield take(FORGOT_USER_NAME.REQUEST)
    yield fork(sendForgotUserNameProcess, action)
  }
}

export function* getUserBranchInfo() {
  let state = yield select()
  state = state.toJS()
  const { userName, selectedBranchId,posRegister } = state.auth

  return {
    userName,
    selectedBranchId,
    posRegister
  }
}

export function* removeSavedRegisterAndTerminal() {
  const { userName, selectedBranchId } = yield call(getUserBranchInfo)

  const userInfo = `${userName}-${selectedBranchId}`

  yield call(local.remove, `${userInfo}-selectedPosRegister`)
  yield call(local.remove, `${userInfo}-selectedTerminal`)
}

export function* showChangePasswordModalListener() {
  while (true) {
    yield take(SHOW_CHANGE_PASSWORD_MODAL)
    yield fork(showChangePasswordModal, true)
  }
}

export function* showChangeEmailModalListener() {
  while (true) {
    yield take(SHOW_CHANGE_EMAIL_MODAL)
    yield fork(showChangeEmailModal)
  }
}
export function* mobileHeartBeatProcess() {
  let firstRun = true
  let timer
  while (true) {
    timer = firstRun ? 60000 : 300000
    yield delay(timer)

    if (yield select(isAuthenticatedSelector)) {
      if (firstRun) {
        firstRun = false
      }
      yield put(actions.heartBeat.request())
    }
  }
}

export function* timeoutModalExceededListener() {
  while (true) {
    yield take('TIMEOUT_MODAL_EXCEEDED')
    yield put(
      actions.submitLogoutUser.request({
        statusText: 'You have been logged out due to inactivity.'
      })
    )
    yield cancel(routine)
  }
}

export function* otpProcess(action, username, injectReducer, redirectRoute) {
  const { otp } = action.payload
  const isMobile = yield select(isMobileSelector)
  const { response, error } = yield call(api.getToken, {
    username,
    otp,
    isMobile
  })
  if (response) {
    yield put(actions.token.success(response))
    yield put(actions.loginOtp.success(null, action.meta))

    yield call(createSystemUserProcess, injectReducer, redirectRoute)
  } else {
    if (error.error === 'Invalid code') {
      yield put(actions.loginOtp.failure(error, action.meta.thunk))
    } else {
      yield put(actions.loginOtp.failure(error, action.meta.thunk))
    }
  }
}
export function* otpListener(userName, injectReducer, redirectRoute) {
  while (true) {
    const action = yield take(LOGIN_OTP.REQUEST)
    yield fork(otpProcess, action, userName, injectReducer, redirectRoute)
  }
}

export default function* authListeners(injectReducer) {
  yield all([
    fork(checkTokenOnStartupProcess, injectReducer),
    fork(loginListener, injectReducer),
    fork(submitSelectedBranchApiListener),
    fork(submitSelectedRegisterApiListener),
    fork(submitSelectedCardTerminalApiListener),
    fork(submitLogoutApiListener),
    fork(timeoutStartupProcess),
    fork(networkLogoutListener),
    fork(showBranchModalListener),
    fork(showRegisterListener),
    fork(routeChangeListener),
    fork(loggedOutListener),
    fork(sendForgotPasswordListener),
    fork(showChangePasswordModalListener),
    fork(showChangeEmailModalListener),
    fork(sendForgotUserNameListener),
    fork(timeoutModalExceededListener)
  ])
}
