import React, { Component } from 'react'
import { TextField, Chip } from '@mui/material'
import shouldUpdate from 'hoc/shouldUpdate'
import { debounce } from 'lodash'
import { deepEqual } from 'utils'

const whitelist = ['value', 'mouseover', 'disabled']

const toDebounce = ['onSearchClick', 'redoSearch', 'findPrev', 'findNext']

export class TextInput extends Component {
  static defaultProps = {
    fullWidth: true,
    variant: 'outlined'
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let ret = null
    const chips = nextProps.value.reduce((acc, x) => {
      if (x.description) {
        const suffix = nextProps.sendType === 'Email' ? x.email : x.fax
        acc = acc.concat(`${x.description}  (${suffix || ''})`)
      }
      return acc
    }, [])

    if (!deepEqual(chips, prevState.chips)) {
      ret = {
        chips,
        inputValue: ''
      }
    }
    if (nextProps.inputValue !== prevState.inputValue) {
      ret = {
        ...ret,
        inputValue: nextProps.inputValue
      }
    }
    return ret
  }

  constructor(...args) {
    super(...args)
    toDebounce.forEach(prop => {
      Object.assign(this, {
        [prop]: debounce((...args) => this.props[prop](...args), 1000)
      })
    })
    this.state = {
      mode: 'send',
      chips: [], // Initialize the chips list
      inputValue: '' // Input value state for the field
    }
  }

  componentDidMount() {
    if (this.props.autoFocus && this.textInput) {
      setTimeout(() => this.textInput && this.textInput.focus(), 1)
    }
  }

  onBlur = e => {
    e.persist()
    this.props.onBlur(e)
  }

  onChange = e => {
    e.persist()
    const inputValue = e.target.value
    this.setState({ inputValue })
  }

  onDelete = (chip, index) => {
    this.setState(
      prevState => ({
        chips: prevState.chips.filter((_, i) => i !== index)
      }),
      () => this.props.deleteRow(index) // Call parent deleteRow method
    )
  }

  onFocus = e => {
    e.persist()
    if (this.textInput) this.textInput.select()
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  inputRef = c => {
    this.textInput = c
    if (this.props.inputRef) {
      this.props.inputRef(c)
    }
  }

  addChip = (e) => {
    if (e.key === 'Enter' && this.state.inputValue.trim()) {
      const newChip = this.state.inputValue.trim()

      // Check if the chip already exists in the list
      if (!this.state.chips.includes(newChip)) {
        this.setState(
          prevState => ({
            chips: [...prevState.chips, newChip],
            inputValue: '' // Clear input field after chip is added
          }),
          () => this.props.onChange(e)
        )
      }
    }
  }

  render() {
    const {
      label,
      placeholder,
      id,
      disabled,
    } = this.props

    return (
      <div>
        {this.state.chips.map((chip, index) => (
            <Chip
              key={index+1}
              label={chip}
              onDelete={() => this.onDelete(chip, index)} // Remove chip
              style={{ margin: '0px 8px 8px 0px' }}
            />
          ))}
        <TextField
          id={id}
          label={label}
          value={this.state.inputValue}
          onChange={this.onChange}
          onKeyDown={this.addChip} // Add chip on Enter key press
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          disabled={disabled}
          fullWidth={this.props.fullWidth}
          //variant={standard}
          placeholder={placeholder}
          inputRef={this.inputRef}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </div>
    )
  }
}

export default shouldUpdate({ whitelist })(TextInput)
