import React, { Component } from 'react'
import shortid from 'shortid'
import shouldUpdate from 'hoc/shouldUpdate'
import { ContextMenuTrigger } from 'react-contextmenu'
import Grid from 'grid'
import memoize from 'memoize-one'
import './styles/css-mod-ignore.css'
import DefaultContextMenu from './components/DefaultContextMenu'
import Filters from './components/Filters'

import TextInput from './components/TextInput'
import DropDown from './components/DropDown'

const blacklist = [
  'change',
  'focus',
  'setField',
  'onDoubleClick',
  'getFormState'
]

export class SearchBase extends Component {
  static defaultProps = {
    fullWidth: true,
    TextInput,
    ContextMenu: DefaultContextMenu,
    meta: {
      allowInstantSearch: false,
      allowNavigation: false,
      allowSearchAll: false,
      hasFilters: false,
      hideToggle: false,
      minimumKeywordLength: 0
    },
    onSearchClick: () => Promise.resolve({}),
    value: '',
    DropDownComponent: Grid
  }

  constructor(props) {
    super(props)
    this.id = this.props.id || shortid.generate()
  }

  getCustomContextMenuItems = customContextMenuItems => {
    // const customContextMenuItems = this.props.customContextMenuItems
    if (!customContextMenuItems) return []
    if (Array.isArray(customContextMenuItems)) {
      return customContextMenuItems
    }
    if (typeof customContextMenuItems === 'function') {
      return customContextMenuItems.call(this)
    }
    return []
  }

  bindInputRef = c => {
    this.textField = c
    if (this.props.bindInputRef) {
      this.props.bindInputRef(c)
    }
  }

  // prob change this...
  filtersButtonRef = el => (this.filtersButton = el)

  containerRef = React.createRef()

  render() {
    const {
      asModal,
      autoFocus,
      buttonRef,
      ContextMenu,
      contextMenuFn,
      disableLastSearchButton,
      disabled,
      filtersGrid,
      filtersGridIsOpen,
      // grid,
      gridHeight,
      iconStyle,
      indexSearchType,
      label,
      lastSearch,
      meta,
      minWidth,
      onDoubleClick,
      openScreenData,
      popoverStyle,
      openScreenMenuText,
      placeholder,
      customContextMenuItems,
      // isFound,
      value,
      textFieldStyle,
      width,
      wrapperMargins,
      mouseover,
      inputValue,
      requirePassword,
      onOkClick,
      form
    } = this.props

    // console.log(filtersGrid)
    const { id } = this
    const tooltipId = `${id}-tooltip`

    const contextMenuIconStyle = this.props.value
      ? {
          color: '#444',
          fontSize: 16,
          verticalAlign: 'middle'
        }
      : {
          color: '#999',
          fontSize: 16,
          verticalAlign: 'middle'
        }
    const contextMenuItemStyle = this.props.value
      ? { color: '#444' }
      : { color: '#999' }

    const buttonStyle = {} // this.getButtonStyle()

    return (
      <div
        className="input-button-set"
        data-tip
        data-for={tooltipId}
        style={{
          margin: wrapperMargins,
          minWidth,
          position: 'relative',
          width
        }}
        onMouseOver={this.props.onMouseOver}
        onMouseLeave={this.props.onMouseLeave}
        ref={this.containerRef}
        // ref=
      >
        <ContextMenuTrigger
          id={`context-menu-${id}`}
          style={{ ...this.props.style, ...textFieldStyle }}
        >
          <this.props.TextInput
            autoFocus={autoFocus}
            inputRef={this.bindInputRef}
            fullWidth={this.props.fullWidth}
            placeholder={placeholder}
            id={id}
            disabled={disabled}
            value={value}
            onChange={this.props.onChange}
            onDoubleClick={onDoubleClick}
            onFocus={this.props.onFocus}
            onKeyDown={this.props.onKeyDown}
            onBlur={this.props.onBlur}
            meta={meta}
            buttonStyle={buttonStyle}
            onSearchClick={this.props.onSearchClick}
            iconStyle={iconStyle}
            disableLastSearchButton={disableLastSearchButton}
            mouseover={mouseover}
            redoSearch={this.props.redoSearch}
            isSet={this.props.isSet}
            findNext={this.props.findNext}
            findPrev={this.props.findPrev}
            getSearchFilters={this.props.getSearchFilters}
            filtersButtonRef={this.props.filtersButtonRef}
            lastSearch={this.props.lastSearch}
            propertyName={this.props.propertyName}
            isSecure={this.props.isSecure}
            inputValue={this.props.inputValue}
            sendType={this.props.sendType}
            handleFileUpload={this.props.handleFileUpload}
            handleFileDownload={this.props.handleFileDownload}
            documentType={this.props.documentType}
            showQuickEntityClick={this.props.showQuickEntityClick}
            handleBarcodeScanner={this.props.handleBarcodeScanner}
            requirePassword={requirePassword}
            containerRef={this.containerRef}
            onOkClick={this.props.onOkClick}
            label={label}
            disableSearchAll={this.props.disableSearchAll}
            disableRefresh={this.props.disableRefresh}
            filtersGrid={filtersGrid}
            isOpen={this.props.isOpen}
            allowSearchAll={this.props.allowSearchAll}
            allowInstantSearch={this.props.allowInstantSearch}
            variant= {this.props.variant ||"standard"}
            {...this.props.inputProps}
          />
        </ContextMenuTrigger>

        <ContextMenu
          id={`context-menu-${id}`}
          openScreenData={openScreenData}
          openScreenMenuText={openScreenMenuText}
          contextMenuItemStyle={contextMenuItemStyle}
          contextMenuIconStyle={contextMenuIconStyle}
          customContextMenuItems={this.getCustomContextMenuItems(
            customContextMenuItems
          )}
          contextMenuFn={contextMenuFn}
          propertyName={this.props.propertyName}
          value={value}
        />
        <DropDown
          anchorEl={this.textField}
          action={this.props.popoverAction}
          onClose={this.props.onRequestClose}
          open={this.props.isOpen}
          onEntered={this.props.onPopoverEntered}
          disableAutoFocus={this.props.disableAutoFocus}
          disableEnforceFocus={this.props.disableEnforceFocus}
          disableRestoreFocus={this.props.disableRestoreFocus}
          PaperProps={this.props.PaperProps}
          anchorOrigin={this.props.anchorOrigin}
          transformOrigin={this.props.transformOrigin}
          style={this.props.dropDownStyle}
        >
          <div style={this.props.popoverStyle}>
            {this.props.isOpen &&
              this.props.dropDownProps &&
              React.createElement(this.props.DropDownComponent, {
                ...this.props.dropDownProps,
                asModal: this.props.asModal
              })}
          </div>
        </DropDown>
        {meta.hasFilters && (
          <Filters
            textField={this.textField}
            propertyName={this.props.propertyName}
            filtersGridIsOpen={filtersGridIsOpen}
            asModal={asModal}
            filtersGrid={filtersGrid}
            gridHeight={gridHeight}
            indexSearchType={indexSearchType}
            meta={meta}
            popoverStyle={popoverStyle}
            onChange={this.onChange}
            resetFilters={this.resetFilters}
            buttonRef={buttonRef}
            onClose={this.props.onCloseFiltersGrid}
            form={form}
          />
        )}
      </div>
    )
  }
}

export default shouldUpdate({
  blacklist
})(SearchBase)
