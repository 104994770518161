import React from 'react'
import BarcodeScanner from 'components/BarcodeScanner'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { Icon, IconButton } from '@mui/material'
import { once } from 'lodash'

export default React.memo(props => {
  const { height, width } = useWindowDimensions()
  const scanSuccess = once(text => {
    props.onUpdate(text)
  })

  return (
    <div
      style={{
        position: 'fixed',
        background: 'black',
        top: 0,
        zIndex: 99999,
        marginLeft: -20,
        height,
        width
      }}
    >
      <IconButton
        onClick={props.closeScan}
        tabIndex={-1}
      >
        <Icon
          tabIndex={-1}
          style={{ padding: 0, fontSize: 16, color: 'white' }}
        >
          close
        </Icon>
      </IconButton>
      <BarcodeScanner
        width={width}
        height={height - 40}
        scanSuccess={scanSuccess}
        tryHarder
        {...props}
      />
    </div>
  )
})
