/* eslint react/no-unused-state: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Switch,
  TextField,
  FormControlLabel
} from '@mui/material'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import TextArea from './components/TextArea'
import { Field } from 'ddiForm'
import { lockInternalNotes } from './actions'
import { lockLedgerNotes } from 'pages/CustomerMaster/tabs/Ledger/lib/actions'

const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const formData = getIn(state, `ddiForm.${form}`)
  return {
    appendToFinanceCharge:
      getIn(
        formData,
        'values.customerLedger.ledgerNotesModal.appendToFinanceCharge'
      ) || false,
    notesModalEnabled: getIn(formData, 'notesModalEnabled') || false
  }
}

class NotesModal extends Component {
  static propTypes = {
    appendToFinanceCharge: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    notesModalEnabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      appendToFinanceCharge: props.data.appendToFinanceCharge
        ? props.data.appendToFinanceCharge
        : false,
      // appendToFinanceCharge: false,
      dataId: props.data.dataId ? props.data.dataId : '',
      detailGrid: props.data.detailGrid ? props.data.detailGrid : '',
      gridName: props.data.gridName ? props.data.gridName : '',
      id: props.data.id ? props.data.id : '',
      invoiceType: props.data.invoiceType ? props.data.invoiceType : 'Open',
      note: props.data.note ? props.data.note : '',
      notesModalEnabled: props.notesModalEnabled,
      parentId: props.data.parentId ? props.data.parentId : '',
      type: props.data.type ? props.data.type : 'internalNotes'
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.notesModalEnabled !== prevState.notesModalEnabled) {
      return {
        appendToFinanceCharge: nextProps.appendToFinanceCharge,
        notesModalEnabled: nextProps.notesModalEnabled
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.notesModalEnabled &&
      this.state.notesModalEnabled &&
      this.notesField
    ) {
      setTimeout(() => {
        this.notesField.focus()
      }, 50)
    }
  }

  toggleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  onChange = id => event => this.setState({ [id]: event.target.value })

  enableNotesModal = () => {
    const {
      data: { form }
    } = this.props
    const { dataId, type } = this.state
    console.log(this.props, dataId, type)
    if (type === 'internalNotes') {
      this.props.dispatch(lockInternalNotes.try(form))
    }

    if (type === 'disputedNotes' || type === 'onAccountNote') {
      this.props.dispatch(
        lockLedgerNotes.try(form, {
          dataId,
          type
        })
      )
    }
  }

  render() {
    // debugger
    const { notesModalEnabled } = this.props
    const { dataId, note, invoiceType, type } = this.state

    return (
      <div style={{ width: '100%' }}>
        <div onDoubleClick={this.enableNotesModal}>
          {type === 'internalNotes' ? (
            <Field
              propertyName="internalNotes"
              component={TextArea}
              preventAutoDisable
              disabled={!notesModalEnabled}
              fullWidth
              multiline
              minWidth="100%"
              style={{ width: '100%' }}
            />
          ) : (
            <TextField
              name="note"
              preventAutoDisable
              disabled={!notesModalEnabled}
              id="notes-modal-ledger-note"
              value={note}
              onChange={this.onChange('note')}
              fullWidth
              multiline
              minWidth="100%"
              variant="outlined"
              style={{ width: '100%' }}
              inputRef={el => (this.notesField = el)}
            />
          )}
        </div>
        {type === 'disputedNotes' && invoiceType === 'Open' ? (
          <div
            style={{
              background: 'rgba(219, 229, 236, 0.3)',
              borderRadius: 4,
              marginTop: 10,
              padding: '0 10px'
            }}
          >
            <FormControlLabel
              label="Include in Finance Charge Update"
              ref={el => (this.toggleWrapper = el)}
              control={
                <Switch
                  preventAutoDisable
                  disabled={!notesModalEnabled}
                  checked={this.state.appendToFinanceCharge}
                  id="appendToFinanceCharge"
                  name="appendToFinanceCharge"
                  onChange={this.toggleChange('appendToFinanceCharge')}
                  value="appendToFinanceCharge"
                />
              }
            />
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(NotesModal)
