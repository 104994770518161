import React from 'react'
import { FormControl, InputLabel } from '@mui/material'
import TextField from './TextField'

// import Field from './Field'

const Url = props => (
  <FormControl>
    <InputLabel htmlFor="component-simple">{props.label}</InputLabel>
    <div>
      <a href={props.value} target="_blank" rel="noopener noreferrer">
        {props.value}
      </a>
    </div>
  </FormControl>
)

const WebAddressTextField = props => {
  console.log(props)
  return props.isEditing ? (
    // <TextField {...props} />
    <Url {...props} />
  ) : (
    <TextField {...props} fooooo="bar" />
  )
}

export default WebAddressTextField
