import React from 'react'
import { Icon, TextField } from '@mui/material'
import PhoneNumberTextField from 'components/masks/PhoneNumberTextField'

const FaxCellRenderer = props => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%'
    }}
  >
    <div
      style={{
        flex: 0.8
      }}
    >
      <TextField
        value={props.value}
        readOnly
        disabled
        InputProps={{
          inputComponent: PhoneNumberTextField
        }}
        variant="standard"
      />
    </div>
    <div
      style={{
        flex: 0.2,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight: -10
      }}
    >
      {!props.value ? (
        <Icon
          style={{
            color: '#d9534f',
            fontSize: 16
          }}
        >
          error_outline
        </Icon>
      ) : null}
    </div>
  </div>
)

export default FaxCellRenderer
