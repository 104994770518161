import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@mui/material'
import { connect } from 'react-redux'
import shouldUpdate from 'hoc/shouldUpdate'
import { deepEqual, isGrid, getIn } from 'utils'
import { BASE_INFORM_URL } from 'services/constants'
import * as CONSTANTS from './constants'
// import deep from 'deep-diff'
import {
  isDisabled,
  validateField,
  onDoubleClick,
  disableMasterFieldsIfNoRecord
} from './utils'
import { onDoubleClick as onDoubleClickAction } from './MasterScreen/actions'

const loadingIndicator = `${BASE_INFORM_URL}/resources/text-loading.gif`

const propsToNotUpdateFor = [
  // '_ddiForm',
  'dispatch',
  'dataId',
  'blur',
  'change',
  'meta',
  'onChange',
  'onFocus',
  'focus',
  'getFormState',
  // 'propertyChange',
  'setField',
  'style'
]
// paginate: PropTypes.shape({
//   next: PropTypes.func,
//   prev: PropTypes.func,
// }),
class ConnectedSendInfoSearch extends Component {
  static propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
      .isRequired,
    initialValue: PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.any
      }),
      PropTypes.string,
      PropTypes.number
    ]), // PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    metaKey: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    forwardRef: PropTypes.bool,
    wrapperStyle: PropTypes.object
  }

  static defaultProps = {
    initialValue: null,
    metaKey: null,
    forwardRef: false,
    wrapperStyle: {
      clear: 'both',
      // float: 'left',
      position: 'relative'
      // width: '100%'
    }
  }

  getRenderedComponent() {
    return this.refs.renderedComponent // eslint-disable-line react/no-string-refs
  }

  render() {
    const { component, wrapperStyle, ...rest } = this.props
    return (
      <div style={wrapperStyle}>
        {createElement(component, { ...rest, ref: 'renderedComponent' })}
        {rest.isFetching && (
          <div
            style={{
              bottom: 5,
              left: -20,
              position: 'absolute'
            }}
          >
            <img alt="loading indicator" src={loadingIndicator} />
          </div>
        )}
        {rest.errorText && ( // todo: error location
          <div title={rest.errorText}>
            <Icon
              style={{
                bottom: 2,
                color: '#d9534f',
                height: 20,
                position: 'absolute',
                right: 0,
                width: 20,
                fontSize: 16
              }}
            >
              error_outline
            </Icon>
          </div>
        )}
      </div>
    )
  }
}

const connector = connect(
  (state, ownProps) => {
    const {
      metaKey,
      propertyName,

      getEntity,
      getFormState,
      partialMatchSearch,
      exactMatchSearch,
      findPrev,
      findNext,
      form,
      getSearchFilters
    } = ownProps

    let { initialValue } = ownProps
    if (initialValue) {
      if (initialValue.type) {
        initialValue =
          initialValue.type === CONSTANTS.DEFAULT_VALUE_TYPES.VALUE
            ? initialValue.value
            : getIn(ownProps._ddiForm, initialValue.value)
      }
    }
    const formState = getFormState(state)
    const isMaster = !!getIn(formState, 'masterOptions')
    const dataId = getIn(formState, 'dataId') || ''
    const focus = ownProps.focus.bind(null, propertyName)
    const blur = ownProps.blur.bind(null, propertyName)
    const change = ownProps.change.bind(null, propertyName)
    // const propertyChange = ownProps._ddiForm.propertyChange.bind(
    //   null,
    //   propertyName,
    //   dataId
    // )
    const setField = ownProps.setField.bind(null, propertyName)

    let field = getIn(formState, `fields.${propertyName}`) // .toJS()
    field = (field && field.toJS()) || {}
    const isFound = getIn(formState, 'isFound') || false
    const isEditing = getIn(formState, 'isEditing') || false
    const hasRecord = getIn(formState, 'hasRecord') || false
    // const newMode = getIn(formState, 'newMode') || false
    // const preNewMode = getIn(formState, 'preNewMode') || false
    // const hasRecord = getIn(formState, 'hasRecord') || false
    const isFiltered = getIn(field, 'isFiltered')
    let meta = getIn(formState, 'meta')
    if (metaKey && meta) {
      meta = getIn(meta, metaKey)
      meta = meta ? meta.toJS() : {}
    } else if (!metaKey || !meta) {
      meta = {}
    }
    meta = ownProps.meta ? ownProps.meta : meta
    meta = meta || {}

    let disabled = isDisabled(ownProps, field, formState)
    if (meta.disabled) {
      disabled = !!meta.disabled
    }

    // meta = meta ? meta.toJS() : {}
    const isEntitySuccessComplete =
      getIn(formState, 'isEntitySuccessComplete') || false
    const onChange = event => {
      event =
        typeof event === 'object' && event !== null ? event.target.value : event
      setField(event)
    }
    // let onTab
    // if (ownProps.onTab) {
    //   if (typeof ownProps.onTab === 'string') {
    //     debugger
    //     const form = ownProps._ddiForm.childOf
    //       ? `${ownProps._ddiForm.childOf}.${ownProps._ddiForm.form}`
    //       : ownProps._ddiForm.form
    //     const act = getIn(ownProps._ddiForm, ownProps.onTab)

    //     // onTab = act.bind(null, form, ownProps.propertyName)
    //     onTab = value => act(ownProps.propertyName, value)
    //     // console.log(act, onTab)
    //   }
    // }
    // const errorText = validateField(field, formState, ownProps)
    const errorText = validateField(field, formState, ownProps)
    const doubleClick = () => onDoubleClick(formState, ownProps)
    let props = {
      _value: ownProps.value, // save value passed in (for checkboxes)
      onBlur: blur,
      change,
      dataId,
      initialValue,
      exactMatchSearch,
      isEditing,
      isFiltered,
      findPrev,
      findNext,
      focus,
      getEntity,
      isEntitySuccessComplete,
      isFound,
      meta,
      onChange,
      partialMatchSearch,
      // propertyChange,
      setField,
      // onTab,
      errorText,
      disabled,
      getSearchFilters,
      onDoubleClick: doubleClick
    }
    props = {
      ...field,
      ...props,
      disabled
    }
    if (form.toLowerCase().includes('inquiry')) {
      props.isInquiryScreen = true
    }
    if (ownProps.multiline) {
      props.inputProps = { style: { height: 'auto' } }
    }
    if (meta.defaultValue) {
      props.value = meta.defaultValue
    }
    return props
  },
  undefined,
  undefined,
  { forwardRef: true }
)
export default connector(
  shouldUpdate({ blacklist: propsToNotUpdateFor })(ConnectedSendInfoSearch)
)
