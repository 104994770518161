import React, { useState } from 'react'
import { Backdrop, Icon } from '@mui/material'

import { SpeedDial, SpeedDialAction } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import TitleBar from './TitleBar'

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100% - 40px)',
    width: '100%',
    // position: 'sticky',
    position: 'fixed',
    transform: 'translateZ(0px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  speedDial: {
    position: 'absolute',
    bottom: 20,
    left: 20
  }
}))

const footerButtonStyles = {
  background: '#cecece',
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: '5px 0'
}

const MobileScreen = ({
  title,
  titleIcon = '',
  children,
  speedDialActions = [],
  CollapsableSearchComponent = null,
  FooterButtonComponent = null,
  backButtonAction = null,
  helpURL,
  mobileScreenWrapperStyle = {
    flexGrow: 1,
    minHeight: '2em',
    overflow: 'auto'
  },
  secondaryTitle = '',
  secondaryTitleStyle = null
}) => {
  const classes = useStyles()

  const [state, setState] = useState({
    speedDialOpen: false
  })

  const openSpeedDial = (event, reason) => {
    if (reason !== 'focus') {
      setState({ ...state, speedDialOpen: true })
    }
  }

  const closeSpeedDial = () => setState({ ...state, speedDialOpen: false })

  // console.log('MobileScreen', children)
  return (
    <div className={classes.root} data-cloud-mobile-Help={helpURL}>
      <div style={mobileScreenWrapperStyle}>
        {CollapsableSearchComponent ? (
          <DDIExpansionPanel
            title={title}
            titleIcon={titleIcon}
            expansionPanelContentStyle={{ width: '100%' }}
            secondaryTitle={secondaryTitle}
            secondaryTitleStyle={secondaryTitleStyle}
          >
            <CollapsableSearchComponent />
          </DDIExpansionPanel>
        ) : title ? (
          <TitleBar
            title={title}
            titleIcon={titleIcon}
            backButtonAction={backButtonAction}
          />
        ) : null}
        {speedDialActions &&
        Array.isArray(speedDialActions) &&
        speedDialActions.length ? (
          <Backdrop open={state.speedDialOpen} style={{ zIndex: 3 }} />
        ) : null}
        <div style={{ zIndex: 1 }}>{children}</div>
        {speedDialActions &&
        Array.isArray(speedDialActions) &&
        speedDialActions.length ? (
          <SpeedDial
            ariaLabel="required"
            className={classes.speedDial}
            icon={<Icon>build</Icon>}
            // icon={<SpeedDialIcon />}
            onClose={closeSpeedDial}
            onOpen={openSpeedDial}
            open={state.speedDialOpen}
          >
            {speedDialActions.map(speedDialAction => (
              <SpeedDialAction
                key={speedDialAction.name}
                icon={speedDialAction.icon}
                tooltipTitle={speedDialAction.name}
                tooltipOpen
                tooltipPlacement="right"
                // onClick={speedDialAction.onClick}
                onClick={e => {
                  setState({ ...state, speedDialOpen: false })
                  if (
                    speedDialAction.onClick &&
                    typeof speedDialAction.onClick === 'function'
                  ) {
                    speedDialAction.onClick(e)
                  }
                }}
              />
            ))}
          </SpeedDial>
        ) : null}
      </div>
      {FooterButtonComponent ? (
        <div style={footerButtonStyles}>
          <FooterButtonComponent />
        </div>
      ) : null}
    </div>
  )
}

// export default React.memo(MobileScreen, plainDeepEqual)
export default MobileScreen
