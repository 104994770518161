import React, { Component } from 'react'
import {
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup
} from '@mui/material'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

class RenderRadioGroup extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    renderAsRow: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  static defaultProps = {
    className: 'mui-radio-group',
    renderAsRow: false,
    style: {
      padding: '10px 15px',
      width: '100%'
    },
    value: ''
  }

  onChange = (event, value) => {
    this.props.onChange(value)
  }

  render() {
    const { className, onChange, renderAsRow, style, ...rest } = this.props
    return (
      <FormControl style={{ width: '100%' }}>
        <RadioGroup
          className={className}
          onChange={this.onChange}
          // {...rest}
          onBlur={noop}
          value={this.props.value}
          style={style}
          row={renderAsRow}
        >
          {this.props.children}
        </RadioGroup>
      </FormControl>
    )
  }
}

export default RenderRadioGroup
