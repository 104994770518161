/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import { AppBar, Paper, Tabs, Tab } from '@mui/material'
import { debounce, noop } from 'lodash'
import memoize from 'memoize-one'
import {
  getIn,
  getBaseGridContextMenuItems,
  plainDeepEqual,
  emptyList
} from 'utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'

// import IndexSearch from 'components/Search/IndexSearch'
import DDICardWrapper from 'components/DDICardWrapper'
import ComponentStyles from 'theme/ComponentStyles'

import { ddiForm, GridField, Label, withContext } from 'ddiForm'
import DDIButton from 'ddiForm/Button'
import './styles/css-mod-ignore.scss'

import Inquiry from './components/Inquiry'
import sagas from './sagas'
import * as actions from './actions'
import behaviors from './behaviors'
import Analysis from './components/Analysis'

const styles = {
  ddiLabelIconStyle: {
    marginRight: 5,
    verticalAlign: 'middle',
    fontSize: 16
  },
  ddiLabelStyle: {
    width: 'auto'
  },
  flexInput: {
    description: { flex: 1, order: 3 },
    input: { flex: 1, marginRight: 10, maxWidth: 300, minWidth: 300, order: 2 },
    label: {
      flex: 1,
      marginRight: 10,
      maxWidth: 150,
      minWidth: 150,
      order: 1,
      textAlign: 'right'
    },
    wrapper: {
      alignItems: 'center',
      display: 'inline-flex',
      flexWrap: 'wrap',
      margin: '10px 0',
      width: '100%'
    }
  },
  inputText: { fontSize: 12 },
  label: {
    color: '#444',
    fontSize: 12,
    fontWeight: 500,
    margin: 0
  },
  labelColumn: { textAlign: 'right' },
  rowStyle: {
    alignItems: 'center',
    display: 'flex',
    margin: '10px 0'
  }
}

const getDataIds = data =>
  data.reduce((acc, next) => {
    acc = acc.concat(next.dataId)
    return acc
  }, [])

export const pinnedDataFn = rowData => {
  const totalsData = rowData.reduce(
    (acc, next) => {
      acc.orderValue += next.orderValue
      return acc
    },
    { orderValue: 0.0 }
  )
  return [totalsData]
}

const searchAreaDimensionsDefaults = {
  width: -1,
  height: -1
}

export class PurchaseOrderInquiry extends Component {
  static propTypes = { asModal: PropTypes.bool }

  static defaultProps = { asModal: false }

  static ddiType = 'PurchaseOrderInquiry'

  constructor(props) {
    super(props)

    this.state = {
      searchAreaDimensions: searchAreaDimensionsDefaults,
      selected: [],
      tab: 'inquiry'
    }
  }

  handleTabChange = (event, value) => {
    if (typeof value === 'string') {
      this.setState({ tab: value }, () => {})
    }
  }

  onRowSelected = params => {
    if (!this.props.asModal) {
      const selected = getDataIds(params.api.getSelectedRows())

      this.setState({ selected })

      return
    }

    this.props.rowSelected(params.data.dataId)
    if (this.props.onRowSelected) {
      this.props.onRowSelected(params)
      // this.props.rowSelected(params.data.dataId)
    }
  }

  getRowNodeId = data => data.dataId

  getContextMenuItems = params => {
    let columnDefs =
      getIn(this.props.fields, 'inquiryResults.columnDefs') || emptyList
    columnDefs = columnDefs?.toJS ? columnDefs.toJS() : []
    const { dispatch } = this.props
    if (columnDefs && dispatch) {
      return [
        {
          name: 'View Delivery Confirmation',
          action: () =>
            dispatch(
              openScreenAction({
                name: 'pODeliveryConfirmation',
                image: 'purchasing_po_delivery_confirmation_16.png',
                title: 'P/O Delivery Confirmation'
              })
            )
        },
        'separator',
        ...getBaseGridContextMenuItems(params, columnDefs)
      ]
    }
    return []
  }

  printSelected = e => {
    if (
      !this?.props?.asModal &&
      this?.props?.dispatch &&
      this?.props?.form &&
      this?.state?.selected?.length
    ) {
      this.props.dispatch(
        actions.printPurchaseOrders.try(this?.props?.form, {
          dataIds: this.state.selected
        })
      )
    }
  }

  onResize = debounce(contentRect => {
    this.setState({ searchAreaDimensions: contentRect.bounds })
  }, 300)

  getDesktopClientGridHeight = memoize((dimensions = { height: 1 }) => {
    if (this?.outerWrapper?.offsetHeight && dimensions?.height) {
      const height = this.outerWrapper.offsetHeight - dimensions.height - 60
      return height < 325 ? 325 : height
    }

    return 325
  }, plainDeepEqual)

  render() {
    const { asModal, statusOptions, selectInitialValue } = this.props
    const {
      searchAreaDimensions = searchAreaDimensionsDefaults,
      tab
    } = this.state
    const { screenLayout } = ComponentStyles
    const gridHeight = asModal
      ? 200
      : this.getDesktopClientGridHeight(searchAreaDimensions)
    // console.log('gridHeight', gridHeight)

    return (
      <div style={screenLayout.container} ref={el => (this.outerWrapper = el)} data-cloud-help='PurchaseOrderInquiryView'>
        <div
          style={{
            ...screenLayout.contentWrapper,
            // overflow: asModal ? 'auto' : 'hidden',
            padding: 10
          }}
        >
          <Measure bounds onResize={this.onResize}>
            {({ measureRef }) => (
              <div ref={measureRef} style={{ maxWidth: '100%', width: '100%' }}>
                <AppBar position="static">
                  <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    scrollable
                    // indicatorColor="#1abcf7"
                  >
                    <Tab value="inquiry" label="Inquiry" />
                    <Tab value="analysis" label="Analysis" />
                  </Tabs>
                </AppBar>
                <div>
                  {tab === 'inquiry' && (
                    <Inquiry asModal={asModal} styles={styles} />
                  )}
                  {tab === 'analysis' && (
                    <Analysis asModal={asModal} styles={styles} />
                  )}
                </div>
              </div>
            )}
          </Measure>
          <div className="clear" style={{ marginBottom: 10 }}>
            <GridField
              propertyName="inquiryResults"
              getRowNodeId={this.getRowNodeId}
              onRowSelected={this.onRowSelected}
              rowSelection="multiple"
              height={gridHeight}
              reactUi={false}
              enableFilter
              enableSorting
              pinnedData={pinnedDataFn}
              getContextMenuItems={this.getContextMenuItems}
            />
          </div>
        </div>
        <div style={screenLayout.footerWrapper}>
          <Paper
            className="action-buttons clear"
            style={{ ...ComponentStyles.footer.containerStandalone }}
          >
            <div style={{ float: 'left' }}>
              <DDIButton
                label="Reset"
                variant="contained"
                style={{ marginRight: 10 }}
                actionName="reset"
                bindTo="onClick"
              />
            </div>
            <div style={{ float: 'right' }}>
              <DDIButton
                label="Search"
                actionName="executeInquiry"
                bindTo="onClick"
                variant="contained"
                // disabled={(field, formState) =>
                //   getIn(formState, 'fields.inquiryResults.isFetching')
                // }
                style={
                  asModal ? { marginRight: 0 } : ComponentStyles.footer.button
                }
              />
              {!asModal && (
                <span>
                  <DDIButton
                    variant="contained"
                    label="Print"
                    disabled={!this?.state?.selected?.length}
                    style={{ marginRight: 10 }}
                    onClick={this.printSelected}
                  />
                  <DDIButton
                    variant="contained"
                    label="Exit"
                    actionName="exit"
                    bindTo="onClick"
                  />
                </span>
              )}
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export const options = {
  actions: {
    executeInquiry: actions.executeInquiry.try,
    exactMatch: actions.exactMatch,
    exactMatchFromForm: actions.exactMatchFromForm,
    reset: actions.reset,
    rowSelected: actions.rowSelected
  },
  behaviors,
  sagas: { onInit: sagas },
  form: 'purchaseOrderInquiry',
  // disableMeta: true,
  // ignoreMeta: true,
  title: 'Purchase Order Inquiry'
}

export const component = PurchaseOrderInquiry

export default ddiForm(options)(PurchaseOrderInquiry)
