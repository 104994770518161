import React, { useRef } from 'react';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';

export default function EmailAddressTextField(props) {
  const inputRef = useRef(null);

  return (
    <MaskedInput
      ref={(ref) => {
        inputRef.current = ref ? ref.inputElement : null;
      }}
      {...props}
      mask={emailMask}
      placeholderChar={'\u2000'}
      showMask={!!props.value}
    />
  );
}
