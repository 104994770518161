import React, { PureComponent } from 'react'
import { AppBar, Tabs, Tab } from '@mui/material'
import { withStyles } from '@mui/styles'
import { getIn } from 'utils'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import { getFormSelector, filterHidden, trimmedCameled } from '../utils'

const styles = {
  labelIcon: {
    height: 35,
    maxHeight: 35
  },
  MuiAppBar: {
    backgroundColor: '#444'
  },
  MuiTab: {
    backgroundColor: '#565656',
    color: '#c9c9c9'
  }
}
const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getFormSelector(form)(state)
  const masterOptions = getIn(formState, 'masterOptions')
  let tabs = getIn(masterOptions, 'tabs')
  tabs = tabs ? tabs.toJS() : []
  tabs = tabs.filter(filterHidden(formState))
  const selectedTab = getIn(masterOptions, 'selectedPrimaryTab')

  return { tabs, selectedTab }
}

const noBoxShadow = {
  boxShadow: 'none'
}
class PrimaryTabs extends PureComponent {
  // constructor(...args) {
  //   super(...args)
  //   // this.state = { value: this.props.selectedTab || '' }
  // }

  onTabChange = (event, value) => {
    if (value) {
      // this.setState({ value }, () => this.props.changePrimaryTab(value))
      this.props.changeFormTab(value)
    }
  }
  tabs = memoize(tabs =>
    tabs.map(tab => (
      <Tab
        value={trimmedCameled(tab.title)}
        label={tab.title}
        icon={tab.icon}
        key={tab.title}
        data-cloud-help={tab.helpURL}
        data-cloud-help-popup={tab.dataCloudHelpPopup}
        className={this.props.classes.MuiTab}
        iconPosition="start"
      />
    ))
  )

  render() {
    const { classes } = this.props
    // const { value } = this.state

    return (
      <div>
        <AppBar
          position="static"
          style={
            noBoxShadow
          }
          className={classes.MuiAppBar}
        >
          <Tabs
            scrollButtons="on"
            variant="scrollable"
            value={this.props.selectedTab}
            onChange={this.onTabChange}
          >
            {this.tabs(this.props.tabs)}
          </Tabs>
        </AppBar>
      </div>
    )
  }
}
export default connect(mapStateToProps)(withStyles(styles)(PrimaryTabs))
