import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Icon } from '@mui/material'

const style = {
  color: '#d9534f',
  display: 'inline-block',
  fontSize: 16
}

export default class DeleteCell extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object
  }

  static defaultProps = {
    style
  }

  onClick = e => {
    e.preventDefault()
    console.log(this.props)

    const row = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex)
    // this.props.api.redrawRows([row])
    this.props.api.updateRowData({ remove: [row] })
    this.props.api.redrawRows()

    this.props.onClick(this.props.rowIndex)
  }

  render() {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <IconButton onClick={this.onClick}>
            <Icon style={this.props.style}>cancel</Icon>
          </IconButton>
        </div>
      </div>
    )
  }
}
