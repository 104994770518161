import React, { useState, useEffect, createRef } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import { makeStyles } from '@mui/styles'
import { removeModal } from 'modals/actions'
import OtpInput from 'react-otp-input'
import { stopAuth, loginOtp } from 'auth/actions'
import { isMobileSelector } from 'mobile/selectors'

const useStyles = mobile =>
  makeStyles(theme => {
    // debugger
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
        // width: 500
      },
      card: {
        // width: 500
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120
      },
      selectEmpty: {
        marginTop: theme.spacing(2)
      },
      cardMarginTop: {
        // width: 400,
        marginTop: 20
      },
      inputStyle: {
        width: mobile ? '2.7rem !important' : '4rem !important',
        height: mobile ? '3rem' : '4rem',
        margin: mobile ? '0 0.25rem' : '0 1rem',
        fontSize: mobile ? '1.5rem' : '3rem',
        borderRadius: 4,
        border: '1px solid rgba(0,0,0,0.3)'
      },
      error: {
        border: '1px solid red !important'
      },
      iconStyle: {
        color: 'green',
        fontSize: 48,
        animationDuration: '2s',
        animationName: 'fadeIn'
      },
      iconContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
      }
    }
  })
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export function OneTimePassword(props) {
  const { code, qr, confirmed } = props
  const [open, setOpen] = useState(true)
  const [success, setSuccess] = useState(false)

  const [apiActive, setApiActive] = useState(false)

  const [otp, setOtp] = useState('')
  const [isError, setError] = useState(false)

  const handleClose = bool => {
    // setOpen(false)
    props.dispatch(removeModal(null, props.id))
    if (bool) {
      props.dispatch(stopAuth)
    }
  }
  const login = async () => {
    try {
      setApiActive(true)
      await props.dispatch(loginOtp.request({ otp }))
      setSuccess(true)
      await delay(1000)
      setOpen(false)
      handleClose()
    } catch (ex) {
      console.log(ex)

      setError(true)
      setApiActive(false)
      setOtp('')
      if (ex?.error === 'invalid_grant') {
        handleClose()
      }
    }
  }
  const classes = useStyles(props.mobile)()
  React.useEffect(() => {
    if (otp.length === 6 && !apiActive) {
      login()
    }
  }, [otp, success])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">
          {props.title || 'One Time Password'}
        </DialogTitle>
        <DialogContent>
          {code || qr || confirmed ? (
            <div className={classes.container}>
              <div style={{ margin: '25px auto', minHeight: 280 }}>
                <div>
                  <div id="login-logo" className="text-center">
                    {qr && !confirmed ? (
                      <>
                        <p style={{ fontSize: 16, fontWeight: 400 }}>
                          Scan the QR code with an authentication app, such as
                          Google Authenticator, on your phone.
                        </p>
                        <p style={{ marginTop: 20 }}>
                          <img src={qr} alt="qr" />
                        </p>
                      </>
                    ) : null}
                  </div>
                  {!confirmed ? (
                    <div
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <p style={{ fontSize: 14, fontWeight: 400 }}>
                        {qr
                          ? 'If you have any problems with the QR code, e'
                          : 'E'}
                        nter the activation code manually into the app.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <span
                          style={{
                            background: '#ccc',
                            border: '1px solid #999',
                            padding: 10
                          }}
                        >
                          {code}
                        </span>
                      </p>
                    </div>
                  ) : null}
                  <div
                    style={{
                      marginTop: 30,
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        marginBottom: qr ? null : 50
                      }}
                    >
                      Enter the Authentication code.
                    </p>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      separator={<span>-</span>}
                      isInputNum
                      inputStyle={classes.inputStyle}
                      shouldAutoFocus
                      hasErrored={isError}
                      errorStyle={classes.error}
                    />
                  </div>
                  <div className={classes.iconContainerStyle}>
                    {success ? (
                      <Icon className={classes.iconStyle}>done</Icon>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.container}>
              <div style={{ margin: '25px auto', minHeight: 280 }}>
                <div>
                  <div id="login-logo" className="text-center">
                    <p style={{ fontSize: 24, fontWeight: 400 }}>
                      Enter Your One Time Password
                    </p>
                    <p style={{ marginTop: 50 }}>
                      Enter the verification code that has been sent to your
                      registered email.
                    </p>
                  </div>
                  <div
                    style={{
                      marginTop: 50,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      separator={<span>-</span>}
                      isInputNum
                      inputStyle={classes.inputStyle}
                      shouldAutoFocus
                      hasErrored={isError}
                      errorStyle={classes.error}
                    />
                  </div>
                  <div className={classes.iconContainerStyle}>
                    {success ? (
                      <Icon className={classes.iconStyle}>done</Icon>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleClose(true)}
            color="primary"
            disabled={apiActive}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
const mapState = state => ({
  mobile: isMobileSelector(state)
})
export default connect(mapState)(OneTimePassword)
