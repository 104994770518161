/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import Grid from 'grid'
import { Icon } from '@mui/material'
import { formatDateFields, plainDeepEqual } from 'utils'
import EmailFaxLogIcon from './EmailFaxLogIcon'
import DeleteEmailFaxLogButton from './DeleteEmailFaxLogButton'

const getRowNodeId = data => data.dataId

class EmailFaxLogModal extends Component {
  constructor(props) {
    super(props)

    console.log('EmailFaxLogModal', props)
    this.state = {
      errorMessage: '',
      rowData:
        props?.data?.emailFaxLog && Array.isArray(props?.data?.emailFaxLog)
          ? props.data.emailFaxLog
          : [],
      selectedRow: null,
      userName: props?.data?.userName || '',
      form: props?.data?.form
    }
  }

  onGridReady = ({ api }) => (this.gridApi = api)

  onRowSelected = params => {
    const { api } = params
    const selected = api.getSelectedRows()
    // debugger

    if (selected && selected.length) {
      this.setState({ selectedRow: selected[0], errorMessage: '' })
    }
  }

  onAfterDeleteRow = dataId => {
    this.setState(
      prevState => {
        return {
          rowData: prevState.rowData.filter(x => x.dataId !== dataId),
          selectedRow: null,
          errorMessage: ''
        }
      },
      () => {
        if (this.gridApi && this.gridApi.forEachNode) {
          this.gridApi.forEachNode(node => {
            node.setSelected(false)
          })
        }
      }
    )
  }

  getColumnDefs = memoize((form, onAfterDeleteRow) => {
    const columnDefs = [
      {
        field: 'sendType',
        headerName: '',
        cellRendererFramework: EmailFaxLogIcon,
        maxWidth: 80
      },
      { field: 'sendTo', headerName: 'To' },
      { field: 'subject', headerName: 'Subject' },
      {
        cellStyle: { textAlign: 'center' },
        field: 'date',
        headerName: 'Date',
        valueFormatter: params => formatDateFields(params, 'M/D/YY')
      },
      { field: 'status', headerName: 'Status' },
      {
        field: 'dataId',
        headerName: '',
        cellRendererFramework: DeleteEmailFaxLogButton,
        cellRendererParams: {
          form,
          onAfterDeleteRow
        },
        maxWidth: 80
      }
    ]

    return columnDefs
  }, plainDeepEqual)

  render() {
    const { rowData, userName, form, errorMessage } = this.state
    console.log('EmailFaxLogModal2', this.state)

    return (
      <div style={{ width: '100%' }}>
        <p>
          User: <strong>{userName}</strong>
        </p>
        <Grid
          columnDefs={this.getColumnDefs(form, this.onAfterDeleteRow)}
          rowData={rowData}
          getRowNodeId={getRowNodeId}
          height={400}
          onRowSelected={this.onRowSelected}
          rowSelection="single"
          onGridReady={this.onGridReady}
        />
        {errorMessage ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <div
              className="alert alert-warning"
              style={{
                backgroundColor: '#fcf8e3',
                borderColor: '#faebcc',
                padding: 5,
                margin: '10px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Icon size="small" style={{ marginRight: 5 }}>
                warning
              </Icon>
              <span>{errorMessage}</span>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(EmailFaxLogModal)
