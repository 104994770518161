import React from 'react'
import PropTypes from 'prop-types'
import MuiAlert from '@mui/lab/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Icon from '@mui/material/Icon'

export default function Alert(props) {
  const [open, setOpen] = React.useState(true)

  // Function to format message by splitting at '\n' to display multiline notifications
  const formatMessage = (message) => {
    return message.split('\n').map((line, index) => (
      <div key={line}>{line}</div> // Use line content as the key
    ));
  };

  const handleClose =() =>{
    setOpen(false);
  }

  return (
    <Collapse in={open}>
      <MuiAlert
        elevation={6}
        variant="filled"
        style={{ width: '94vw', maxWidth: 340, fontSize: '14px' }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false)
            }}
          >
            <Icon fontSize="inherit">close</Icon>
          </IconButton>
        }
        sx={{ mb: 1 }}
        {...props}
        onClick={handleClose}
      >
        {formatMessage(props.children)} {/* Use the formatMessage function */}
      </MuiAlert>
    </Collapse>
  )
}

Alert.propTypes = {
  children: PropTypes.string.isRequired, 
};
