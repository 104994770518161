import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import { debounce } from 'lodash'
import { handleRowsPerPageModalInteraction } from 'mobile/actions'

const marginRight = { marginRight: 5 }

const ActionButtons = ({ form, modalId, dontShowAgain, rowsPerPage }) => {
  const dispatch = useDispatch()

  const cancel = debounce(e => {
    dispatch(
      handleRowsPerPageModalInteraction(form, {
        interaction: 'cancel',
        dontShowAgain,
        rowsPerPage,
        modalId
      })
    )
  }, 300)

  const confirm = debounce(e => {
    dispatch(
      handleRowsPerPageModalInteraction(form, {
        interaction: 'confirm',
        dontShowAgain,
        rowsPerPage,
        modalId
      })
    )
  }, 300)

  return (
    <div
      className="rows-per-page-modal-action-buttons"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '10px 0 0 0'
      }}
    >
      <Button
        onClick={confirm}
        variant="contained"
        style={marginRight}
        tabIndex={-1}
      >
        Yes
      </Button>
      <Button onClick={cancel} variant="contained" tabIndex={-1}>
        No
      </Button>
    </div>
  )
}

export default ActionButtons
