/* eslint jsx-a11y/anchor-is-valid: 0, jsx-a11y/click-events-have-key-events: 0, jsx-a11y/no-static-element-interactions: 0 */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import { Icon } from '@mui/material'
import { getIn } from 'utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { tryChangeFormTab as tryChangeFormTabAction } from 'ddiForm/actions'
import {
  closeActivityModal as closeActivityModalAction,
  saveActivityAsync as saveActivityAsyncAction,
  unlockActivityAsync as unlockActivityAsyncAction
} from 'modals/EventModal/actions'
import { removeModal } from 'modals/actions'
import { openScreen } from 'mobile/actions'

const pageToOpen = searchType => {
  const searchTypes = {
    Contacts: {
      name: 'contact',
      title: 'Contact Master'
    },
    Customer: {
      image: 'customer_master_16.png',
      name: 'customerMaster',
      title: 'Customer Master'
    }
  }

  if (searchTypes[searchType]) {
    return searchTypes[searchType]
  }

  return null
}

// const mapStateToProps = (state, ownProps) => {
//   const referenceData = getIn(
//     state,
//     'ddiForm.eventModal.fields.referenceId.referenceData'
//   )

//   return { referenceData }
// }

class ReferenceLabel extends Component {
  static propTypes = {
    callLocation: PropTypes.string.isRequired,
    iconStyle: PropTypes.object,
    isNew: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool,
    referenceData: PropTypes.object.isRequired,
    labelStyle: PropTypes.object
  }

  getReferenceLabel = memoize(referenceData => {
    const { isMobile } = this.props
    if (!referenceData) {
      return null
    }

    // console.log('referenceLabelProps', this.props)

    const parent =
      referenceData.parent ||
      (referenceData.moreInfo && referenceData.moreInfo.parent) ||
      {}

    const child =
      referenceData.child ||
      (referenceData.moreInfo && referenceData.moreInfo.child) ||
      {}

    if (parent && parent.description && child && child.description) {
      // console.log('REFERENCE_LABEL_manyConditions', referenceData, parent, child)
      const strOne = `${referenceData.displayName}: ${
        referenceData.description
          ? referenceData.description
          : referenceData.name
      }`
      const strTwo = `${parent.description}`

      let contactId =
        child.recordName && typeof child.recordName === 'string'
          ? child.recordName.split('|')
          : []
      contactId = contactId.length && contactId.length === 3 ? contactId[2] : ''

      return (
        <span>
          <a
            onClick={e =>
              this.openScreenProxy(referenceData.searchType, referenceData.name)
            }
            style={isMobile ? { textDecoration: 'none' } : null}
          >
            {strOne}
          </a>{' '}
          for{' '}
          <a
            onClick={e =>
              this.openScreenProxy(
                child.displayName,
                contactId,
                child.recordName,
                parent.recordName
              )
            }
            style={isMobile ? { textDecoration: 'none' } : null}
          >
            {child.description}
          </a>{' '}
          from{' '}
          <a
            onClick={e =>
              this.openScreenProxy(parent.displayName, parent.recordName)
            }
            style={isMobile ? { textDecoration: 'none' } : null}
          >
            {strTwo}
          </a>
        </span>
      )
    }

    if (parent && parent.description) {
      // console.log('REFERENCE_LABEL_parentDescription', referenceData)
      const strOne = `${referenceData.displayName}: ${
        referenceData.description
          ? referenceData.description
          : referenceData.name
      }`
      const strTwo = `${parent.description}`
      return (
        <span>
          <a
            onClick={e =>
              this.openScreenProxy(
                referenceData.searchType,
                referenceData.name,
                referenceData.recordName,
                parent.recordName
              )
            }
            style={isMobile ? { textDecoration: 'none' } : null}
          >
            {strOne}
          </a>{' '}
          from{' '}
          <a
            onClick={e =>
              this.openScreenProxy(parent.displayName, parent.recordName)
            }
            style={isMobile ? { textDecoration: 'none' } : null}
          >
            {strTwo}
          </a>
        </span>
      )
    }

    if (referenceData.displayName) {
      // console.log('REFERENCE_LABEL_displayName', referenceData)
      const linkText = `${referenceData.displayName}: ${referenceData.description}`
      return (
        <span>
          <a
            onClick={e =>
              this.openScreenProxy(referenceData.searchType, referenceData.name)
            }
            style={isMobile ? { textDecoration: 'none' } : null}
          >
            {linkText}
          </a>
        </span>
      )
    }

    return null
  })

  openScreenProxy = (searchType, dataId, recordName = '', parentId = '') => {
    const { isMobile } = this.props
    // debugger

    if (
      (searchType === 'Contacts' || searchType === 'Contact') &&
      dataId &&
      recordName &&
      parentId &&
      !isMobile
    ) {
      this.closeActivityModal()
      this.openContactMaster(dataId, recordName, parentId)
    }

    if (searchType === 'Customer' && !isMobile) {
      this.closeActivityModal()
      this.openCustomerLedger(dataId)
    }

    if (searchType === 'Job' && !isMobile) {
      this.closeActivityModal()
      this.openCustomerJob(dataId)
    }

    // debugger
    if (searchType === 'SalesOrderHeader' && dataId) {
      this.closeActivityModal()
      this.openSalesOrder(dataId)
    }
  }

  tryChangeFormTab = (form, primaryTab, secondaryTab = '') => {
    if (form && primaryTab) {
      this.props.dispatch(
        tryChangeFormTabAction(form, primaryTab, secondaryTab)
      )
    }
  }

  saveActivity = () => {
    const { callLocation } = this.props
    if (callLocation) {
      this.props.dispatch(saveActivityAsyncAction(callLocation))
    }
  }

  closeActivityModal = () => {
    const { dataId, callLocation, isNew, modalId } = this.props

    if (isNew) {
      this.props.dispatch(closeActivityModalAction(callLocation))
    } else {
      this.props.dispatch(unlockActivityAsyncAction(callLocation, { dataId }))
    }

    this.props.dispatch(removeModal(null, modalId))
  }

  openCustomerLedger = dataId => {
    const { callLocation } = this.props
    // debugger

    if (callLocation === 'customerMaster') {
      this.tryChangeFormTab('customerMaster', 'ledger', 'invoices')
    } else {
      this.props.dispatch(
        openScreenAction({
          dataId,
          image: 'accounting_accounts_receivable_customer_ledger_16.png',
          name: 'customerLedger',
          title: 'Customer Ledger',
          activeKey: 'ledger',
          route: 'customermaster',
          groupNames: ['summary', 'invoices']
        })
      )
    }
  }

  openCustomerJob = dataId => {
    const { callLocation } = this.props
    // debugger

    if (callLocation === 'customerJob') {
      this.tryChangeFormTab(callLocation, 'setup', 'information')
    } else {
      this.props.dispatch(
        openScreenAction({
          dataId,
          image: 'customer_job_16.png',
          name: 'customerJob',
          title: 'Customer Job',
          activeKey: null,
          route: 'customerjob',
          groupNames: ['setup', 'information']
        })
      )
    }
  }

  openSalesOrder = dataId => {
    const { isMobile } = this.props
    // debugger
    if (isMobile) {
      this.props.dispatch(
        openScreen({
          link: '/salesorder',
          dataId,
          readSalesOrder: true
        })
      )
    } else {
      this.props.dispatch(
        openScreenAction({
          dataId,
          image: 'sales_order_entry_32.png',
          imageFileName16: 'sales_order_entry_16.png',
          description: 'Sales Order',
          name: 'salesOrder',
          route: 'salesorder',
          title: 'Sales Order',
          newInstance: true,
          read: true
        })
      )
    }
  }

  openContactMaster = (dataId, recordName, parentId) => {
    const { callLocation } = this.props
    // debugger

    if (
      callLocation &&
      (callLocation === 'contact' || callLocation.match(/contact/))
    ) {
      this.tryChangeFormTab('contact', 'Analysis')
    } else {
      this.props.dispatch(
        openScreenAction({
          dataId,
          groupNames: ['setup', 'analysis'],
          parentId,
          parentType: 'C',
          recordName,
          recordType: 'Contacts',
          name: 'contact', // Master',
          // image: 'contact_master_16.png',
          title: 'Contact Master'
        })
      )
    }
  }

  render() {
    const { labelStyle, iconStyle, referenceData } = this.props
    const referenceLabel = this.getReferenceLabel(referenceData)
    return (
      <>
        {referenceLabel ? (
          <div
            className="ddi-data-label"
            style={{
              background: '#eaeef0',
              borderRadius: 4,
              color: '#517b9c',
              display: 'inline-block',
              fontSize: 12,
              lineHeight: '16px',
              maxWidth: '100%',
              overflow: 'hidden',
              padding: 5,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: 'auto',
              ...labelStyle
            }}
          >
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <Icon
                style={{
                  color: '#517b9c',
                  fontSize: 16,
                  marginRight: 5,
                  verticalAlign: 'middle',
                  ...iconStyle
                }}
              >
                info
              </Icon>
              <span>{referenceLabel}</span>
            </div>
          </div>
        ) : null}
      </>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(ReferenceLabel)
