import React from 'react'
import TooltipField from 'hoc/TooltipField'
import { Button } from '@mui/material'

export const OrderLinkWithoutNewInstance = ({
  value,
  valueFormatted,
  data,
  ...other
}) => {
   return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        height: 27
      }}
    >
      <Button
        size="small"
        style={{
          color: '#517b9c',
          justifyContent: 'start',
          textDecoration: 'underline',
          textAlign: 'left'
        }}
      >
        {valueFormatted || value}
      </Button>
    </div>
  )
}

export default TooltipField({
  recordNameKey: 'value',
  type: 'salesOrder',
  position: 'right-end',
  sensitivity: 100,
})(OrderLinkWithoutNewInstance)