import React, { useState } from 'react'
import Grid from 'grid'
import { GridField, withContext } from 'ddiForm'
import { useDispatch } from 'react-redux'
import { emptyList, getIn, noop } from 'utils'
import {
  AppBar,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from '@mui/material'
import { storeUIFeatureState } from 'pages/SalesOrder/actions'

const headerStyle = {
  background: '#e1e3e4',
  color: '#444',
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '17px',
  margin: 0,
  padding: '5px 0',
  textAlign: 'center',
  width: '100%'
}

const InvoiceInquiryAttachments = ({
  salesOrderAttachmentsList,
  addBlankRowAction = noop,
  columnDefs,
  getRowNodeId,
  form,
  gridHeight,
  attachmentsTab = 'This Invoice'
}) => {
  const dispatch = useDispatch()
  const [tab, setTab] = useState(attachmentsTab)
  const salesOrderAttachments = salesOrderAttachmentsList?.toJS
    ? salesOrderAttachmentsList.toJS()
    : []

  const setActiveTab = tabName => {
    setTab(tabName)
    /* store the tab state in Redux */
    dispatch(
      storeUIFeatureState(form, {
        feature: 'attachmentsTab',
        featureState: tabName
      })
    )
  }

  function handleTabChange(e, val) {
    if (val) {
      setActiveTab(val)
    }
  }

  return (
    <div style={{ width: '100%', maxWidth: '100%' }}>
      <AppBar position="static" style={{ boxShadow: 'none' }}>
        <Toolbar
          style={{
            justifyContent: 'space-between',
            minHeight: 35,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <Tabs value={tab} onChange={handleTabChange} scrollable>
            <Tab value="This Invoice" label="This Invoice" />
            <Tab value="Sales Order" label="Sales Order" />
          </Tabs>
        </Toolbar>
      </AppBar>
      <Paper style={{ background: '#fff' }} square>
        <GridField
          propertyName={
            tab === 'This Invoice' ? 'attachments' : 'attachmentsSalesOrder'
          }
          title={
            tab === 'This Invoice'
              ? 'Invoice Attachments'
              : 'Sales Order Attachments'
          }
          addButtonText="Add Attachment"
          showAddButtonIfHasRecord
          addBlankRow={addBlankRowAction}
          headerStyle={headerStyle}
          columnDefs={columnDefs}
          getRowNodeId={getRowNodeId}
          enableSorting
          height={gridHeight - 35}
          width="100%"
        />
      </Paper>
    </div>
  )
}

export default withContext({
  salesOrderAttachmentsList: form =>
    getIn(form, 'values.attachmentsSalesOrder') || emptyList
})(InvoiceInquiryAttachments)
