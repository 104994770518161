import React from 'react'
import Skeleton from '@mui/lab/Skeleton'


export const MainFormSkeleton = () => (
  <div className="app-layout">
    <Skeleton animation="wave" variant="rect" width="100%" height="50" />
  </div>
)

export default MainFormSkeleton