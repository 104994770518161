import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getRgb, plainDeepEqual } from 'utils'
import memoize from 'memoize-one'
import { IconButton, Icon, Menu, MenuItem } from '@mui/material'

import {
  showPriceDetail,
  showAdjustedPrice
} from 'pages/CustomerOrderPad/actions'

import { readCustomerStockMinimums } from 'pages/InvoiceInquiry/actions'

const ProductCell = ({ colDef, data, value, valueFormatted }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()

  const getColorFormatting = (d, val) => {
    if (d && typeof d === 'object' && d.color) {
      return Object.keys(d.color).length ? getRgb(d.color) : '#444'
    }

    if (val && typeof val === 'object' && val.color) {
      return Object.keys(val.color).length ? getRgb(val.color) : '#444'
    }

    return '#444'
  }

  const displayCellValue = (valFormatted, val) => {
    if (valFormatted) {
      return valFormatted
    }
    if (val && typeof value !== 'object') {
      return val
    }
    return ''
  }

  const getFilteredGridRows = e => {
    const {
      cellRendererParams: { gridCallbackFn }
    } = colDef

    if (gridCallbackFn && typeof gridCallbackFn === 'function') {
      // console.log('gridCallbackFn', gridCallbackFn(), data)
      return gridCallbackFn()
    }

    return []
  }

  const getMenuItems = memoize(d => {
    const form = 'mobileOrderPad'

    const { canAdjustPrice, dataId, uomId } = d

    let items = [
      {
        name: 'Price Detail',
        action: e => {
          setAnchorEl(null)
          dispatch(
            showPriceDetail(form, {
              dataId,
              UOMId: uomId,
              gridCallbackFn: getFilteredGridRows
            })
          )
        }
      },
      {
        name: 'Adjusted Price Change',
        action: e => {
          setAnchorEl(null)
          dispatch(
            showAdjustedPrice(form, {
              dataId,
              UOMId: uomId,
              gridCallbackFn: getFilteredGridRows
            })
          )
        }
      },
      {
        name: 'Customer Stock Minimums',
        action: e => {
          setAnchorEl(null)
          dispatch(
            readCustomerStockMinimums.try(form, {
              dataId,
              uomId
            })
          )
        }
      }
    ]

    if (!canAdjustPrice) {
      items = items.reduce((acc, next, i) => {
        if (i !== 1) {
          acc = acc.concat(next)
        }

        return acc
      }, [])
    }

    return items
  }, plainDeepEqual)

  const openPopover = event => setAnchorEl(event.currentTarget)

  const closePopover = event => setAnchorEl(null)

  const menuItems = getMenuItems(data)

  const popoverOpen = Boolean(anchorEl)

  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <IconButton size="small" onClick={openPopover} style={{ marginRight: 5 }}>
        <Icon>{popoverOpen ? 'close' : 'menu'}</Icon>
      </IconButton>
      <Menu open={popoverOpen} anchorEl={anchorEl} onClose={closePopover}>
        {menuItems.map((item, i) => (
          <MenuItem onClick={item.action}>{item.name}</MenuItem>
        ))}
      </Menu>
      <span style={{ color: getColorFormatting(data, value) }}>
        {displayCellValue(valueFormatted, value)}
      </span>
    </div>
  )
}

export default ProductCell
