import React from 'react'
import {
  actionChannel,
  delay,
  take,
  select,
  call,
  put,
  putResolve,
  fork,
  cancel
} from 'redux-saga/effects'
import { confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { addModal, confirm } from 'modals/actions'
import { api } from 'services'
import { grantAccessProcess } from 'access/sagas'
// import { openScreenProcess } from 'pages/Layout/sagas'
import { fromJS, getIn } from 'utils'
import { show } from 'snackbar/actions'
import { mailDisabledSelector, isAuthenticatedSelector } from 'auth/selectors'
import * as ddiActions from 'ddiForm/actions'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'
import SendDocumentModal from 'components/SendDocument'

import { EXIT } from 'ddiForm/constants'
import { TRY_OPEN_MAIL } from '../Main/constants'
import * as CONSTANTS from './constants'
import * as actions from './actions'
import Mail from './modals/Mail'
import MailActions from './components/MailActions'
import MailTitle from './components/MailTitle'
import EmailFaxLogModal from './components/EmailFaxLogModal'
// import MailNotification from './components/MailNotification'
// TODO start service up on appstart...
// CANCEL on error
const payload = {}
const forms = {}

export function* onOpenProcess(form, response) {
  let guId
  if (!form.includes('dashboard')) {
    if (response && response.guid) {
      guId = response.guid
    }

    // debugger
    yield put(
      ddiActions.setFields(form, [
        {
          propertyName: 'mail',
          value: (response && response.mail) || []
        },
        {
          propertyName: 'userName',
          value: (response && response.userName) || ''
        },
        {
          propertyName: 'emailCount',
          value: (response && response.emailCount) || ''
        },
        {
          propertyName: 'guId',
          value: response && response.guid
        }
      ])
    )
    // start page service with guId context...
    // yield fork(refreshOpenMailProcess, { guId: payload.response.guId, form })
  } else {
    guId = yield select(state =>
      getIn(state, 'dashboard.tiles.Mail.tile.data.guid')
    )
  }

  const task = yield fork(refreshOpenMailWorker, { guId, form })
  forms[form] = task
}

export function* refreshOpenMailWorker({ guId, form }) {
  while (true) {
    yield delay(60000)
    let guid = guId
    // debugger

    if (!guid) {
      guid = yield call(getInstanceGuid, form)
    }

    //  yield call(actions.refreshOpenMail.request(form, { guId }))
    const { response, error } = yield call(api.refreshOpenMail, { guId: guid })
    // console.log(response, error)

    if (response && Array.isArray(response)) {
      yield put(actions.refreshOpenMail.success({ response }, form))
    }
  }
}

// BROKEN
// export function* takeTryOpenUserMailListener() {
//   const channel = yield actionChannel(TRY_OPEN_MAIL)
//   while (true) {
//     const action = yield take(channel)

//     const layout = yield select(state => getIn(state, 'layout.openScreens'))
//     if (!getIn(layout, 'userMail')) {
//       const tempPayload = yield call(grantAccessProcess, {
//         name: 'userMail',
//         image: 'mail_32.png',
//         title: 'User Mail',
//         proc: openScreenProcess
//       })
//       // if (tempPayload)
//       // {
//       //   yield call()
//       // }
//       if (tempPayload && tempPayload.response && tempPayload.response.mail) {
//         payload = tempPayload
//       } else {
//         payload = {}
//       }
//     }
//   }
// }

export function* deleteMailListener() {
  while (true) {
    let action = yield take(CONSTANTS.DELETE_MAIL.TRY)
    // console.log(action)
    // const {
    //   description, dataId, mailNumber, cb, guId
    // } = action.payload
    const { data, guId, cb, onAfterDeleteRow } = action.payload
    debugger
    const { form } = action.meta

    let guid = guId

    if (!guid) {
      guid = yield call(getInstanceGuid, form)
    }

    yield call(
      confirmationModal,
      data.length > 1
        ? 'Are you sure you want to remove selected mail?'
        : `Are you sure you want to delete Mail - "${data[0].description}"`,
      'Delete?',
      { width: 800 }
    )
    // const mails = action.payload
    // yield call(
    //   confirmationModal,
    //   `Are you sure you want to delete Mail - "${description}"`,
    //   'Delete?',
    //   { width: 800 }
    // )
    action = yield take([CONFIRMED, CANCELED, CONSTANTS.CLOSE_MAIL])
    if (action.type === CONFIRMED) {
      yield put(actions.deleteMail.request(form, { data }))
      const { response, error } = yield call(api.deleteUserMails, {
        guId: guid,
        mails: data
      })

      if (response) {
        yield put(actions.deleteMail.success({ data }, form))
        debugger
        if (onAfterDeleteRow && typeof onAfterDeleteRow === 'function') {
          /* callback to deselect the row (for Print feature on full User Mail screen) */
          onAfterDeleteRow()
        }

        // if (form === 'dashboard.userMail') {
        //   yield put(actions.removeDeletedEmails(form, { data }))
        // }
      } else {
        yield put(actions.deleteMail.failure({ data }, form))
      }

      if (cb) {
        cb()
        yield put(actions.closeMail(form))
      }
    }
  }
}

export function* newMailListener() {
  while (true) {
    const action = yield take(CONSTANTS.NEW_MAIL.TRY)
    const { form } = action.meta
    // console.log(action)
    yield call(showMail, {
      ...action.payload,
      form,
      newMail: true
    })
  }
}
// export function* userMailInitiator() {
//   let task
//
//   const channel = yield actionChannel('INITIATE_REFRESH_MAIL')
//   while (true) {
//     yield take(channel)
//
//     try {
//
//       task = yield fork(refreshUserMailRoutine)
//     } finally {
//       yield cancel(task)
//     }
//   }
// }
// export function* userMailInitiator() {
//   let task

//   const channel = yield actionChannel('INITIATE_REFRESH_MAIL')
//   try {
//     while (true) {
//       yield take(channel)

//       task = yield fork(refreshUserMailRoutine)
//     }
//   } finally {
//     debugger
//     if (task) {
//       //
//       yield cancel(task)
//     }
//   }
// }

export function* userMailInitiator() {
  const channel = yield actionChannel('INITIATE_REFRESH_MAIL')

  while (true) {
    yield take(channel)
    // debugger
    yield fork(refreshUserMailRoutine)
  }
}

export function* refreshUserMailRoutine() {
  const refreshRequired = { enabled: false }
  while (true) {
    //
    yield fork(refreshMailProcess, refreshRequired)

    yield delay(15000)
  }
}

export function* refreshMailProcess(refreshRequired) {
  const isAuth = yield select(isAuthenticatedSelector)

  if (!isAuth) return

  yield put(actions.syncMail.try())
  const { response, error } = yield call(api.refreshMail)
  const mailDisabled = yield select(mailDisabledSelector)

  if (response?.refreshRequired) {
    if (!refreshRequired.enabled) {
      refreshRequired.enabled = true
      yield put(
        show({
          message: {
            message: 'Company Settings have changed. Please log out now.',
            type: 'warning',
            persist: true
          }
        })
      )
    }
  } else if (response?.newMail?.length) {
    const newMail = mailDisabled
      ? []
      : response.newMail
          .map(x => {
            const { mailNumber } = x
            return {
              id: mailNumber,
              type: 'mail',
              data: {
                ...x
              }
            }
          })
          .slice(0, 5)
    // debugger
    yield putResolve(
      actions.syncMail.success({ newMail }, 'dashboard.userMail')
    )
    // //
    // yield put(show({ messages: newMail /* component: MailNotification */ }))
    for (const message of newMail) {
      yield put(show({ message }))
      yield delay(10)
    }
  } else if (error) {
    yield put(actions.syncMail.failure(error, 'dashboard.userMail'))
  }
}

export function* openMailListener() {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_MAIL.TRY)
    // debugger

    const {
      dataId,
      mailNumber,
      guId,
      userName // cb
    } = action.payload
    const { form } = action.meta

    yield put(actions.openMail.request(form))
    const { response, error } = yield call(openMailProcess, {
      dataId,
      mailNumber,
      guId
      // form
    })

    if (response) {
      // open mail with details
      const {
        sentOn,
        details,
        dataId,
        description,
        fromUserInitials,
        fromUserName,
        mailNumber,
        highPriority,
        referenceType,
        referenceId
      } = response
      // console.log(cb)
      // cb()
      yield put(actions.openMail.success(response, form))
      yield call(showMail, {
        openMail: true,
        guId,
        form,
        userName,
        sentOn,
        details,
        dataId,
        description,
        fromUserName,
        fromUserInitials,
        mailNumber,
        highPriority,
        referenceType,
        referenceId
      })
    } else {
      yield put(actions.openMail.success(response, form))
      if (error?.message) {
        yield put(
          show({
            message: {
              message: error.message,
              type: 'warning',
              persist: false
            }
          })
        )
      }
    }
  }
}

export function* openMailProcess({ dataId, mailNumber, guId }) {
  const { response, error } = yield call(api.openMail, {
    dataId,
    mailNumber,
    guId
  })
  return {
    response,
    error
  }
}

export function* respondMailListener() {
  while (true) {
    const action = yield take(CONSTANTS.RESPOND_MAIL.REQUEST)
    const { meta } = action
    const { form } = action.meta
    const { dataId, mailNumber, guId, userName } = action.payload

    const { response, error } = yield call(api.respondMail, {
      dataId,
      mailNumber,
      guId,
      userName
    })
    // //
    yield put(
      actions.respondMail.success(
        {
          ...response,
          mailNumber,
          guId
        },
        meta
      )
    )
  }
}

export function* editMailListener() {
  while (true) {
    const action = yield take(CONSTANTS.EDIT_MAIL.TRY)
    const { form } = action.meta
    const { response, error } = yield call(openMailProcess, action.payload)

    if (response) {
      yield call(showMail, {
        ...action.payload,
        ...response,
        form,
        openMail: true
      })
    }
  }
}

export function* getInstanceGuid(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const guid =
    form === 'dashboard.userMail'
      ? yield select(state =>
          getIn(state, 'dashboard.tiles.Mail.tile.data.guid')
        )
      : getIn(formState, 'values.guId')

  return guid
}

export function* createUserMailListener() {
  while (true) {
    const action = yield take(CONSTANTS.CREATE_USER_MAIL.TRY)
    const {
      meta: { form },
      payload: { guId, modalId }
    } = action

    const formState = yield select(state => getIn(state, `ddiForm.${form}`))
    const fields = getIn(formState, 'fields')

    let guid = guId

    if (!guid) {
      guid = yield call(getInstanceGuid, form)
    }

    debugger
    const details = getIn(fields, 'details.value')
    const highPriority = getIn(fields, 'highPriority.value') || false
    const subject = getIn(fields, 'subject.value')
    const toUserIds = Array(getIn(fields, 'toUserIds.value'))

    const { response, error } = yield call(api.createUserMail, {
      guId: guid,
      details,
      subject,
      toUserIds,
      highPriority
    })

    if (modalId) {
      yield put(confirm(form, modalId))
    }
    yield put(actions.createUserMail.success(response, form))
  }
}

export function* showMail({
  guId,
  userName,
  form,
  newMail,
  title,
  openMail,
  details,
  description,
  fromUserInitials,
  fromUserName,
  mailNumber,
  highPriority,
  dataId,
  sentOn,
  referenceType,
  referenceId
}) {
  // debugger
  const options = {
    options: {
      data: {
        actions: [
          {
            async clickEvent(args, formState, cb) {
              await this.props.dispatch(
                formState.actions.createUserMail(formState.form, {
                  guId: this.props.data.guId
                })
              )
              cb()
            },
            secondary: true,
            title: 'Send'
          },
          {
            title: 'Exit',
            clickEvent: { action: actions.closeMail }
          }
        ],
        guId,
        userName,
        newMail,
        openMail,
        details,
        description,
        fromUserName,
        mailNumber,
        highPriority,
        dataId,
        sentOn,
        fromUserInitials,
        referenceType,
        referenceId,
        form
      },
      width: 850,
      maxHeight: 800,
      title: MailTitle,
      actions: MailActions
    },
    component: Mail
  }

  // debugger
  yield put(
    ddiActions.setFields(form, [
      {
        propertyName: 'toUserIds',
        value: dataId
      },
      {
        propertyName: 'highPriority',
        value: highPriority
      },
      {
        propertyName: 'subject',
        value: description
      },
      {
        propertyName: 'details',
        value: details
      }
    ])
  )

  // debugger
  const modal = yield call(addModal, form, options)
  yield put(modal)
  return modal.payload.id
}

export function* onDestroy(form) {
  const saga = forms[form]
  if (saga) {
    yield cancel(saga)
    // console.log(forms)
    delete forms[form]
  }
  // console.log(forms)
}

export function* exitUserMailScreenListener() {
  while (true) {
    const action = yield take(CONSTANTS.EXIT_USER_MAIL_SCREEN.TRY)
    const {
      meta: { form, thunk }
      // payload: { isExitButton }
    } = action

    // debugger
    yield fork(exitUserMailScreenProcess, form, thunk)
  }
}

export function* exitUserMailScreenProcess(form, thunk) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const guid = getIn(formState, 'values.guId')

  yield put(actions.exitUserMailScreen.request(form))
  // debugger

  const { response, error } = yield call(api.closeMail, { guid })
  // debugger

  if (response) {
    yield put(actions.exitUserMailScreen.success(response, { form, thunk }))
    yield put({
      type: EXIT,
      meta: { form }
    })
  } else {
    yield put(actions.exitUserMailScreen.failure(error, { form, thunk }))
  }
}

export function* printUserMailProcess(form, dataId, mailNumber) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const guid = getIn(formState, 'values.guId')

  yield put(actions.printUserMail.request(form))

  const { response, error } = yield call(api.printUserMail, {
    dataId,
    mailNumber,
    guid
  })

  if (response) {
    yield put(actions.printUserMail.success(response, form))
    yield fork(showPrintDocumentModalProcess, form, {
      ...response,
      form
    })
  } else {
    yield put(actions.printUserMail.failure(error, form))
  }
}

export function* printUserMailListener() {
  while (true) {
    const {
      meta: { form },
      payload: { dataId, mailNumber }
    } = yield take(CONSTANTS.PRINT_USER_MAIL.TRY)

    if (dataId && mailNumber) {
      yield fork(printUserMailProcess, form, dataId, mailNumber)
    }
  }
}

export function* openEmailFaxLogModal(form, response) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const userName = getIn(formState, 'values.userName')
  const modalOpts = {
    component: EmailFaxLogModal,
    options: {
      title: 'Sent Status',
      width: 800,
      maxHeight: '100%',
      data: {
        actions: [
          {
            async clickEvent(args, fs, cb) {
              if (!this?.state?.selectedRow) {
                this.setState({ errorMessage: 'Please select a row' })
                return
              }

              try {
                await this.props.dispatch(
                  actions.resendEmailFaxItem.try(form, {
                    selectedRow: this?.state?.selectedRow || null,
                    cb
                  })
                )
              } finally {
                console.log('routine complete')
              }
            },
            primary: true,
            title: 'Re-Send'
          },
          {
            title: 'Exit',
            primary: true
          }
        ],
        emailFaxLog: response,
        userName,
        form
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  return modal.payload.id
}

export function* getEmailFaxLogProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const guid = getIn(formState, 'values.guId')

  yield put(actions.getEmailFaxLog.request(form))

  const { response, error } = yield call(api.getEmailFaxLog, { guid })

  if (response) {
    yield put(actions.getEmailFaxLog.success(response, form))
    yield fork(openEmailFaxLogModal, form, response)
  } else {
    yield put(actions.getEmailFaxLog.failure(error, form))
  }
}

export function* getEmailFaxLogListener() {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.GET_EMAIL_FAX_LOG.TRY)

    yield fork(getEmailFaxLogProcess, form)
  }
}

export function* launchUserMailSendDocumentModal(form, response) {
  const modalOpts = {
    component: SendDocumentModal,
    props: {
      ...response,
      dataId: response.dataId,
      form
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export function* resendEmailFaxItemProcess(form, selectedRow, cb) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const guid = getIn(formState, 'values.guId')

  if (!selectedRow) {
    return
  }

  yield put(actions.resendEmailFaxItem.request(form))

  const { response, error } = yield call(api.resendEmailFaxLogItem, {
    dataId: selectedRow.dataId,
    guid
  })

  if (response) {
    yield put(actions.resendEmailFaxItem.success(response, form))
    if (cb && typeof cb === 'function') {
      cb()
    }

    yield fork(launchUserMailSendDocumentModal, form, response)
  } else {
    yield put(actions.resendEmailFaxItem.failure(error, form))
  }
}

export function* resendEmailFaxItemListener() {
  while (true) {
    const {
      meta: { form },
      payload: { selectedRow, cb }
    } = yield take(CONSTANTS.RESEND_EMAIL_FAX_ITEM.TRY)

    yield fork(resendEmailFaxItemProcess, form, selectedRow, cb)
  }
}

export function* deleteEmailFaxItemProcess(form, dataId, onAfterDeleteRow) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const guid = getIn(formState, 'values.guId')

  if (!dataId) {
    return
  }

  yield call(
    confirmationModal,
    'Are you sure you want to delete the selected entry?',
    'Delete?'
  )

  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CONFIRMED) {
    yield put(actions.deleteEmailFaxItem.request(form))

    const { response, error } = yield call(api.deleteEmailFaxLogItem, {
      logs: [{ dataId }],
      guid
    })

    if (response) {
      yield put(actions.deleteEmailFaxItem.success(response, form))

      if (onAfterDeleteRow && typeof onAfterDeleteRow === 'function') {
        onAfterDeleteRow(dataId)
      }
    } else {
      yield put(actions.deleteEmailFaxItem.failure(error, form))
    }
  }
}

export function* deleteEmailFaxItemListener() {
  while (true) {
    const {
      meta: { form },
      payload: { dataId, onAfterDeleteRow }
    } = yield take(CONSTANTS.DELETE_EMAIL_FAX_ITEM.TRY)

    yield fork(deleteEmailFaxItemProcess, form, dataId, onAfterDeleteRow)
  }
}

export default function* userMailListeners() {
  yield fork(deleteMailListener)
  // yield fork(takeTryOpenUserMailListener)
  yield fork(openMailListener)
  yield fork(newMailListener)
  yield fork(createUserMailListener)
  yield fork(editMailListener)
  yield fork(respondMailListener)
  yield fork(exitUserMailScreenListener)
  yield fork(printUserMailListener)
  yield fork(getEmailFaxLogListener)
  yield fork(resendEmailFaxItemListener)
  yield fork(deleteEmailFaxItemListener)
}
// export default function* userMailSagas(form) {
//   yield [fork(onOpenProcess, form)]
// }
