import React, { useEffect } from 'react'
import { Switch as MuiToggle, FormControlLabel } from '@mui/material'
import useStateWithCallback from 'hooks/useStateWithCallback'

export const convertToBool = v => {
  if (v == null || v === '') {
    return false
  }
  if (typeof v === 'boolean') {
    return v
  }
  let ret
  try {
    ret = JSON.parse(v.toLowerCase())
  } catch (e) {
    return false
  }
  return ret
}

const defaultCursor = { cursor: 'default' }

export const Toggle = props => {
  const {
    className,
    disabled,
    id,
    label,
    labelStyle,
    onDoubleClick,
    setField,
    valueKey,
    value,
    initialValue
  } = props

  const [localValue, setLocalValue] = useStateWithCallback(
    convertToBool(initialValue)
  )
  useEffect(() => {
    const temp = convertToBool(value)
    if (temp !== localValue) {
      setLocalValue(temp)
    }
  }, [value])

  const handleChange = event => {
    const { checked } = event.target
    setLocalValue(checked, () => setField(checked, null, null, null, valueKey))
  }

  const getToggle = () => (
    <MuiToggle
      checked={localValue}
      id={id}
      value={localValue}
      onChange={handleChange}
      disabled={disabled}
    />
  )

  return (
    <span onDoubleClick={onDoubleClick} style={defaultCursor}>
      {label ? (
        <FormControlLabel
          control={getToggle()}
          label={label}
          style={labelStyle}
          className={className}
        />
      ) : (
        getToggle()
      )}
    </span>
  )
  // }
}
Toggle.defaultProps = {
  className: '',
  labelStyle: {}
}
export default Toggle
