import React from 'react'
import Skeleton from '@mui/lab/Skeleton'
import MobileScreen from 'mobile/components/MobileScreen'
// import { MobileAppBar, MobileTab, TabPanel } from 'mobile/utils'

import SearchFields from './SearchFields'

const InvoiceSummarySkeleton = () => {
  return (
  <MobileScreen
    title="Invoice Summary"
    titleIcon="receipt"
    CollapsableSearchComponent={SearchFields}
  >
    <div style={{ width: '100%' }}>
      <Skeleton animation="wave" variant="rect" width="100%" height="250" />
    </div>
  </MobileScreen>
)}

export default InvoiceSummarySkeleton