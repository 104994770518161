import React from 'react'
import Skeleton from '@mui/lab/Skeleton'
import MobileScreen from 'mobile/components/MobileScreen'

const searchStyle = { margin: '0 30px', width: 150 }

const SalesOpportunitiesSkeleton = () => {

  return (
    <MobileScreen
      title="My Locks"
      titleIcon="lock"
    >

      <div style={{ display: 'flex', padding: 20 }}>
        <div style={searchStyle}>
          <Skeleton animation="wave" varint="rect" width="150" height="50" />

        </div>
        <div style={searchStyle}>
          <Skeleton animation="wave" variant="rect" width="150"  height="50"/>
        </div>

      </div>

      <div style={{ width: '100%' }}>
        <Skeleton animation="wave" variant="rect" width="100%" height="250" />
      </div>

    </MobileScreen>
  )
}

export default SalesOpportunitiesSkeleton