/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import {
  Icon,
  Accordion as MuiExpansionPanel,
  AccordionDetails as MuiExpansionPanelDetails,
  AccordionSummary as MuiExpansionPanelSummary,
  Tooltip
} from '@mui/material'

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel)

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 30,
    padding: '0 20px 0 14px',
    '&$expanded': {
      minHeight: 30,
      margin: 0
    }
  },
  content: {
    margin: '5px 0',
    '&$expanded': {
      margin: '5px 0'
    }
  },
  expandIcon: {
    padding: 0
  },
  expanded: {}
})(MuiExpansionPanelSummary)

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 10
  }
}))(MuiExpansionPanelDetails)
/* END compacted expansion panels for Sales Order interface */

const boxShadow =
  'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px'

class DDIExpansionPanel extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    changeCallback: PropTypes.func,
    classes: PropTypes.object.isRequired,
    defaultExpanded: PropTypes.bool,
    fontAwesomeIcon: PropTypes.string,
    headingFontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    expandIcon: PropTypes.object,
    expansionPanelContentStyle: PropTypes.object,
    expansionPanelDetailsStyle: PropTypes.object,
    primaryTitleStyle: PropTypes.object,
    primaryTitleWrapperStyle: PropTypes.object,
    style: PropTypes.object,
    title: PropTypes.string,
    titleIcon: PropTypes.string,
    wrapperClassName: PropTypes.string,
    secondaryTitle: PropTypes.string,
    secondaryTitleStyle: PropTypes.object
  }

  static defaultProps = {
    changeCallback: () => {},
    defaultExpanded: true,
    expandIcon: (
      <Icon classes={{ root: 'ddi-expansion-icon' }} style={{ color: '#444' }}>
        expand_more
      </Icon>
    ),
    expansionPanelDetailsStyle: { width: '100%', padding: '5px 10px' },
    expansionPanelContentStyle: { padding: 15, width: '100%' },
    fontAwesomeIcon: '',
    headingFontSize: '0.9em',
    primaryTitleStyle: {},
    primaryTitleWrapperStyle: {},
    style: {},
    title: 'Details',
    titleIcon: '',
    wrapperClassName: '',
    secondaryTitle: '',
    secondaryTitleStyle: {}
  }

  onChange = (e, expanded) => {
    const { changeCallback } = this.props
    changeCallback({ expanded })
  }

  togglePanel = (expanded = false) => {
    console.log(this.expansionPanelInner)
    if (this.expansionPanelInner && this.expansionPanelInner.setState) {
      this.expansionPanelInner.setState({ expanded })
    }
  }

  render() {
    const {
      defaultExpanded,
      expandIcon,
      expansionPanelDetailsStyle,
      expansionPanelContentStyle,
      headingFontSize,
      style,
      title,
      titleIcon,
      fontAwesomeIcon,
      wrapperClassName,
      primaryTitleStyle,
      primaryTitleWrapperStyle,
      secondaryTitle,
      secondaryTitleStyle
    } = this.props
    const expandPanelHeadingStyle = { background: '#f6f6f6', padding: '0 10px' }

    return (
      <ExpansionPanel
        // className={wrapperClassName}
        defaultExpanded={defaultExpanded}
        ref={el => (this.expansionPanel = el)}
        innerRef={el => (this.expansionPanelInner = el)}
        onChange={this.onChange}
        style={style}
        square
      >
        <ExpansionPanelSummary expandIcon={expandIcon}>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                ...primaryTitleWrapperStyle
              }}
            >
              {fontAwesomeIcon ? (
                <i
                  className={`fa ${fontAwesomeIcon}`}
                  style={{ color: '#444', marginRight: 10 }}
                />
              ) : null}
              {titleIcon ? (
                <Icon
                  fontSize="small"
                  style={{ color: '#444', marginRight: 10 }}
                >
                  {titleIcon}
                </Icon>
              ) : null}
              <span
                style={{
                  color: '#444',
                  fontFamily: 'Roboto',
                  fontSize: headingFontSize,
                  ...primaryTitleStyle
                }}
              >
                {title}
              </span>
            </div>
            {secondaryTitle ? (
              <Tooltip
                TransitionProps={{
                  style: {
                    background: secondaryTitleStyle?.background
                      ? secondaryTitleStyle.background
                      : '#444'
                  }
                }}
                title={secondaryTitle}
                enterTouchDelay={300}
                arrow
              >
                <div
                  style={{
                    fontSize: '11px',
                    lineHeight: '11px',
                    fontFamily: 'Roboto',
                    maxWidth: 150,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...secondaryTitleStyle
                  }}
                  // title={secondaryTitle}
                >
                  {secondaryTitle}
                </div>
              </Tooltip>
            ) : null}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={expansionPanelDetailsStyle}>
          <div style={expansionPanelContentStyle}>{this.props.children}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

// export default React.memo(DDIExpansionPanel, plainDeepEqual)
export default DDIExpansionPanel
